import React from 'react';
import Select from 'react-select';
import InputMask from 'react-input-mask';
import LawsuitPersonagesService from '../../../services/LawsuitPersonagesService';
import AddressService from '../../../services/AddressService';
import Loading from '../../containers/loading';
import Popover, { ArrowContainer } from 'react-tiny-popover';

const customStyles = {
  clearIndicator: (provided, state) => {
    const color = 'red';

    return { ...provided, color };
  }
}

export default class AddPersonagesLawsuit extends React.Component { 
  _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }


  constructor( props ) {
      super(props);
      this.LawsuitPersonagesService = new LawsuitPersonagesService();
      this.AddressService = new AddressService();

      this.state = {
        opt_types: [
          {value: 1, label: "ACUSADO"}
        ],
        opt_partes: [],
        opt_type: null,
        opt_sides: "",
        inp_nome: "",
        inp_rua: "",
        loadingVisible: false,
        loadingMessage: [],
        inp_numero: "",
        inp_bairro: "",
        inp_cidade: "",
        inp_complemento: "",
        tel: "",
        cel: "",
        telcom: "",
        email: "",
        email2: "",
        email3: "",
        profissao: "",
        pis_pasep: "",
        ctps: "",
        errors: {}
      }
      this.cancel = this.cancel.bind(this);
      this.switch_parte = this.switch_parte.bind(this);
      this.voltar_parte = this.voltar_parte.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.selectChange = this.selectChange.bind(this);
      this.addPersonage = this.addPersonage.bind(this);
      this.addPersonageLawsuit = this.addPersonageLawsuit.bind(this);
      this.modifiedPersonages = this.props.modifiedPersonages.bind(this);
      this.getCEP = this.getCEP.bind(this);
  }

  componentDidMount( ) { 
    this._isMounted = true;


    document.addEventListener("cancelAlert", (e) => {
      if( this._isMounted ){
        this.setState({
          showAlert: false
        });
      }
    });
    document.addEventListener("cancelLoading", (e) => {
      this.setState({
        loadingVisible: false
      });
    });

    this.LawsuitPersonagesService = new LawsuitPersonagesService();
    this.AddressService = new AddressService();

    this.LawsuitPersonagesService.getTypes( ).then(res =>{
      this.setState({
        opt_types: res,
      });

    });

    this.LawsuitPersonagesService.list( ).then(res =>{
      this.setState({
        opt_partes: res,
      });

    });

    this.AddressService.getEstado( ).then(res =>{
          this.setState({
            opt_estados: res,
          });
    });

  }

  componentDidUpdate = () => {
  }

  stateChange = (selected, actionMeta) => {
    let pops = [];
    pops[ actionMeta.name ] = selected;
    console.log(selected);

    if ( typeof actionMeta.name !== undefined && actionMeta.name !== "" && actionMeta.name !== null ){

      this.AddressService.getCidades( selected.value ).then( cities=>{
        this.setState({
          cities: cities
        });
      });

      if ( typeof selected !== undefined && selected !== "" && selected !== null ) {
        this.setState( pops );
      }

    }
  }

  selectChange = (selected, actionMeta) => {

    let pops = [];
    pops[ actionMeta.name ] = selected;
    if ( typeof actionMeta.name !== undefined && actionMeta.name !== "" && actionMeta.name !== null ){

      if ( typeof selected !== undefined && selected !== "" ) {
        this.setState( pops );
      }

    }
  }

  selectCPFChange = (selected, actionMeta) => {
    let pops = [];
    pops[ actionMeta.name ] = selected;
    if ( typeof actionMeta.name !== undefined && actionMeta.name !== "" && actionMeta.name !== null ){

      if ( typeof selected !== undefined && selected !== "" && selected !== null ) {
        this.setState( pops );
      }

      console.log(selected);
      if ( selected.value === "2" ) {
          document.getElementById("form-group-rg").className += ' hide';
          document.getElementById("form-group-cpf").className += ' hide';
          document.getElementById("form-group-nascimento").className += ' hide';
          document.getElementById("form-group-estado-civil").className += ' hide';
          document.getElementById("form-group-nacionalidade").className += ' hide';
          document.getElementById("form-group-cnpj").classList.remove("hide");
      }else{
          document.getElementById("form-group-rg").classList.remove("hide");
          document.getElementById("form-group-cpf").classList.remove("hide");
          document.getElementById("form-group-nascimento").classList.remove("hide");
          document.getElementById("form-group-estado-civil").classList.remove("hide");
          document.getElementById("form-group-nacionalidade").classList.remove("hide");
          document.getElementById("form-group-cnpj").className += ' hide';
      }

    }
  }

  handleChange(e){

       this.setState(
           {
               [e.target.name]: e.target.value
           }
       )
   }

   getCEP(e){
       this.AddressService.getCEP( this.state.cep.replace(".", "").replace("-","") ).then(res =>{
         //console.log(res);69905074

         if ( res !== null ){

               this.AddressService.getCidades( res.state_id ).then( cities=>{


                         this.setState(
                             {
                                 'inp_rua': res.rua,
                                 'inp_bairro': res.bairro,
                                 'cities': cities,
                                 'cidade': cities.filter( ( cidade )=>{ return cidade.value === res.city_id } ),
                                 'opt_estado': this.state.opt_estados.filter( ( ufs )=>{ return ufs.uf === res.uf } )
                             }
                         );

               });

         }else{
           window.alert("CEP não encontrado, preencha manualmente os campos de rua, bairro, cidade e estado");
         }

       });
   }

  cancel() {

      document.getElementById("table-partes").classList.remove("hide");
      document.getElementById("bnt-incluir-parte").classList.remove("hide");
      document.getElementById("addPersonagesLawsuit").className += ' hide';
      document.getElementById("help_icon_incluir_parte").classList.remove("hide");

  }

  switch_parte () {
    document.getElementById("div-parte-cadastrada").className += ' hide';
    document.getElementById("btn-switch-parte").className += ' hide';
    document.getElementById("div-parte-nova").classList.remove("hide");
  }

  voltar_parte () {
    document.getElementById("div-parte-cadastrada").classList.remove("hide");
    document.getElementById("btn-switch-parte").classList.remove("hide");
    document.getElementById("div-parte-nova").className += ' hide';
  }

  clearFields() {
          this.setState( {
            inp_nome: "",
            opt_pessoaTipo: "",
            cpf: "",
            cnpj: "",
            rg: "",
            profissao: "",
            pis_pasep: "",
            ctps: "",
            data_nascimento: "",
            opt_estado_civil: "",
            opt_nacionalidade: "",
            obs: "",
            cep: "",
            inp_rua: "",
            inp_numero: "",
            inp_complemento: "",
            inp_bairro: "",
            opt_estado: "",
            cidade: "",
            tel: "",
            cel: "",
            email: "",
            email2: "",
            email3: "",
            telcom: ""
          } );
  }

  addPersonage (e) {
    e.preventDefault();

    if (this.validateForm()) {

          let opt_type_value = ""; let opt_pessoaTipo = ""; let opt_estado_civil = ""; let opt_nacionalidade = "";
          let opt_estado = ""; let cep = ""; let cidade = "";

          if ( typeof this.state.opt_pessoaTipo !== "undefined"){
            opt_pessoaTipo = this.state.opt_pessoaTipo.value;
          }

          if ( typeof this.state.opt_estado_civil !== "undefined"){
            opt_estado_civil = this.state.opt_estado_civil.value;
          }

          if ( typeof this.state.opt_nacionalidade !== "undefined"){
            opt_nacionalidade = this.state.opt_nacionalidade.value;
          }

          if ( typeof this.state.opt_estado !== "undefined"){
            if ( this.state.opt_estado instanceof Array && this.state.opt_estado.length > 0 ){
              opt_estado = this.state.opt_estado[0].value;
            }else{
              opt_estado = this.state.opt_estado.value;
            }
          }

          if ( typeof this.state.cidade !== "undefined"){
            if ( this.state.cidade instanceof Array && this.state.cidade.length > 0 ){
              cidade = this.state.cidade[0].value;
            }else{
              cidade = this.state.cidade.value;
            }
          }


          if ( typeof this.state.cep !== "undefined"){
            cep = this.state.cep.replace(".", "").replace("-", "")
          }

          document.getElementById("bnt-cadastrar-nova-parte").disabled = true;
          document.getElementById("bnt-cadastrar-nova-parte-1").disabled = true;
          document.body.style.cursor = "wait";

          this.setState({
            loadingVisible: true,
            loadingMessage: ["O cadastro da parte está sendo efetuado.", "Por gentileza, aguarde...", "Tempo estimado de 15 a 90 segundos"]
          });

          let data = {
            'name': this.state.inp_nome,
            'personage_category_id': opt_pessoaTipo,
            'cpf': this.state.cpf,
            'cnpj': this.state.cnpj,
            'rg': this.state.rg,
            'profissao': this.state.profissao,
            'pis_pasep': this.state.pis_pasep,
            'ctps': this.state.ctps,
            'data_nascimento': (typeof this.state.data_nascimento !== "undefined") ? this.state.data_nascimento.split('/').reverse().join('-') : "",
            'estado_civil': opt_estado_civil,
            'nacionalidade': opt_nacionalidade,
            'observation': this.state.obs,
            'PersonageAddresses': [
              {
                'cep': cep,
                'street': this.state.inp_rua,
                'number': this.state.inp_numero,
                'complement': this.state.inp_complemento,
                'neighbor': this.state.inp_bairro,
                'state_id': opt_estado,
                'city_id': cidade
              }
            ],
            'PersonageContacts': [
              {
                'personage_contact_type_id': 1,
                'value': this.state.tel
              },
              {
                'personage_contact_type_id': 1,
                'value': this.state.cel
              },
              {
                'personage_contact_type_id': 3,
                'value': this.state.email
              },
              {
                'personage_contact_type_id': 3,
                'value': this.state.email2
              },
              {
                'personage_contact_type_id': 3,
                'value': this.state.email3
              },
              {
                'personage_contact_type_id': 1,
                'value': this.state.telcom
              }
            ]

          }

          this.LawsuitPersonagesService.add( data ).then(res =>{

            if ( res.success === true ) {
                let personage_token = res.data.Personages.token;
                console.log( document.getElementById("lawsuit_token").value );

                if ( typeof this.state.opt_type !== "undefined" && this.state.opt_type !== null ){
                  opt_type_value = this.state.opt_type.value;
                }

                let data_lawsuit = {
                  'personage_token': personage_token,
                  'lawsuit_token': document.getElementById("lawsuit_token").value,
                  'personage_side_id': this.state.opt_side.value,
                  'personage_type_id': opt_type_value,
                }

                this.LawsuitPersonagesService.addToLawsuit( data_lawsuit ).then(res =>{

                  document.getElementById("bnt-cadastrar-nova-parte").disabled = false;
                  document.getElementById("bnt-cadastrar-nova-parte-1").disabled = false;
                  document.body.style.cursor = "default";

                  this.setState({
                    loadingVisible: false,
                    loadingMessage: []
                  });

                  if ( res.success === true ) {
                    localStorage.setItem('alert_message', "Salvo com sucesso");
                    localStorage.setItem('alert_type', 'alert-success');
                    let time = new Date().getTime();
                    localStorage.setItem('alert_time', time );

                    document.getElementById("table-partes").classList.remove("hide");
                    document.getElementById("bnt-incluir-parte").classList.remove("hide");
                    document.getElementById("help_icon_incluir_parte").classList.remove("hide");
                    document.getElementById("addPersonagesLawsuit").className += ' hide';
                    document.getElementById("div-parte-nova").className += ' hide';
                    document.getElementById("div-parte-cadastrada").classList.remove("hide");
                    document.getElementById("btn-switch-parte").classList.remove("hide");

                    var url_string = window.location.href;
                    var url = new URL(url_string);
                    var parte = url.searchParams.get("parte");

                    if(parte !== null){
                      window.scrollTo(0, 221);
                    }

                  }else {
                    localStorage.setItem('alert_message', res.message);
                    localStorage.setItem('alert_type', 'alert-danger');
                    let time = new Date().getTime();
                    localStorage.setItem('alert_time', time );
                  }

                  setTimeout(() => {
                    this.modifiedPersonages();
                  }, 100);
                  this.setState(
                      {
                        'opt_parte': '',
                        'opt_side': '',
                        'opt_type': null
                      }
                  );

                }).catch(err =>{
                   document.getElementById("bnt-cadastrar-nova-parte").disabled = false;
                   document.getElementById("bnt-cadastrar-nova-parte-1").disabled = false;
                   document.body.style.cursor = "default";
                   console.log(err);
                   this.setState({
                     loadingVisible: false,
                     loadingMessage: [],
                     'opt_parte': '',
                     'opt_side': '',
                     'opt_type': null
                   });
               });

               this.clearFields();

            }

          });

    }

  }

  addPersonageLawsuit (e) {
    let opt_pessoaTipo = "";
    e.preventDefault();
    if (this.validatePersonageLawsuitForm()) {

          document.getElementById("bnt-cadastrar-parte-cadastrada").disabled = true;
          document.body.style.cursor = "wait";
          this.setState({
            loadingVisible: true,
            loadingMessage: ["O cadastro da parte está sendo efetuado.", "Por gentileza, aguarde...", "Tempo estimado de 15 a 90 segundos"]
          });

                //console.log( document.getElementById("lawsuit_token").value );

                if ( typeof this.state.opt_type !== "undefined" && this.state.opt_type !== null ){
                  opt_pessoaTipo = this.state.opt_type.value;
                }

                let data_lawsuit = {
                  'personage_token': this.state.opt_parte.value,
                  'lawsuit_token': document.getElementById("lawsuit_token").value,
                  'personage_side_id': this.state.opt_side.value,
                  'personage_type_id': opt_pessoaTipo
                }

                this.LawsuitPersonagesService.addToLawsuit( data_lawsuit ).then(res =>{

                  document.getElementById("bnt-cadastrar-parte-cadastrada").disabled = false;
                  document.body.style.cursor = "default";
                  this.setState({
                    loadingVisible: false,
                    loadingMessage: []
                  });

                  if ( res.success === true ) {
                    localStorage.setItem('alert_message', "Salvo com sucesso");
                    localStorage.setItem('alert_type', 'alert-success');
                    let time = new Date().getTime();
                    localStorage.setItem('alert_time', time );
                    document.getElementById("table-partes").classList.remove("hide");
                    document.getElementById("bnt-incluir-parte").classList.remove("hide");
                    document.getElementById("help_icon_incluir_parte").classList.remove("hide");
                    document.getElementById("addPersonagesLawsuit").className += ' hide';

                    var url_string = window.location.href;
                    var url = new URL(url_string);
                    var parte = url.searchParams.get("parte");

                    if(parte !== null){
                      window.scrollTo(0, 221);
                    }

                  }else {
                    localStorage.setItem('alert_message', res.message);
                    localStorage.setItem('alert_type', 'alert-danger');
                    let time = new Date().getTime();
                    localStorage.setItem('alert_time', time );
                  }
                  setTimeout(() => {
                    this.modifiedPersonages();
                  }, 100);

                  this.setState(
                      {
                        'opt_parte': '',
                        'opt_side': '',
                        'opt_type': null
                      }
                  );

                }).catch(err =>{
                   document.getElementById("bnt-cadastrar-parte-cadastrada").disabled = false;
                   document.body.style.cursor = "default";
                   console.log(err);
                   this.setState({
                     loadingVisible: false,
                     loadingMessage: []
                   });
               });
    }
  }

  validateForm() {
      let errors = {};
      let formIsValid = true;

      if ( this.state.inp_nome===0 || this.state.inp_nome==="" || typeof this.state.inp_nome === "undefined" ) {
        formIsValid = false;
        errors["inp_nome"] = "*Por favor entre com o nome da parte";
      }

      if ( typeof this.state.opt_side === "undefined" || this.state.opt_side === null ) {
        formIsValid = false;
        errors["opt_side"] = "*Escolha se é cliente ou oponente";
      }

      this.setState({
         errors: errors
       });

      return formIsValid;

  }

  validatePersonageLawsuitForm() {
      let errors = {};
      let formIsValid = true;

      if ( typeof this.state.opt_parte === "undefined" || this.state.opt_parte === null ) {
        formIsValid = false;
        errors["opt_parte"] = "*Escolha a parte cadastrada";
      }

      if ( typeof this.state.opt_side === "undefined" || this.state.opt_side === null ) {
        formIsValid = false;
        errors["opt_side"] = "*Escolha se é cliente ou oponente";
      }

      this.setState({
         errors: errors
       });

      return formIsValid;

  }

  render() {

        let url_base = localStorage.getItem('url_base');

        let opt_sides = [
          {value: 1, label: "Cliente"},
          {value: 2, label: "Oponente"}
        ]

        //let selectedOptSides = ( typeof this.state.opt_side === "undefined" || this.state.opt_side === "" ) ? opt_sides[0] : this.state.opt_side;

        let opt_estado_civil = [
          {value: "Casado", label: "Casado(a)"},
          {value: "Solteiro", label: "Solteiro(a)"},
          {value: "Separado", label: "Separado(a)"},
          {value: "Divorciado", label: "Divorciado(a)"},
          {value: "Viúvo", label: "Viúvo(a)"},
          {value: "União Estável", label: "União Estável"}
        ]

        let opt_pessoaTipo = [
          {value: "1", label: "Pessoa Física"},
          {value: "2", label: "Pessoa Jurídica"},
        ]

        let opt_nacionalidade = [
          {value: "Brasileiro", label: "Brasileiro(a)"},
          {value: "Estrangeiro", label: "Estrangeiro(a)"},
        ]

      return <div id="addPersonagesLawsuit" className="wrap-form hide">

                  <Loading visible={this.state.loadingVisible} message1={this.state.loadingMessage[0]} message2={this.state.loadingMessage[1]} message3={this.state.loadingMessage[2]}   />
                  <form  id="form" name="form" className="form-horizontal  cadastro-partes-cadastrada bv-form" noValidate="novalidate">

                        <div id="div-parte-nova" className="hide">
                              <div className="header-personage">
                                <div className="col-md-12 text-center">
                                    <h4>
                                          Cadastre uma nova parte
                                          <Popover
                                              isOpen={this.state.isAddPopoverOpen}
                                              position={['top', 'right', 'left', 'bottom']}
                                              padding={10}
                                              onClickOutside={() => this.setState({ isAddPopoverOpen: false })}
                                              content={({ position, targetRect, popoverRect }) => (
                                                  <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                                                      position={position}
                                                      targetRect={targetRect}
                                                      popoverRect={popoverRect}
                                                      arrowColor={'#94bcd8'}
                                                      arrowSize={10}
                                                  >
                                                      <div className="popover-box"
                                                          onClick={() => this.setState({ isAddPopoverOpen: !this.state.isAddPopoverOpen })}
                                                      >
                                                            Preencha todos os dados para que seja possível extrair informações precisas do processo e gerar relatórios mais completos, e possibilitar gerar documentos no Doc-Facil
                                                      </div>
                                                  </ArrowContainer>
                                              )}
                                          >
                                              <img onMouseOut={() => this.setState({ isAddPopoverOpen: false })}  onMouseOver={() => this.setState({ isAddPopoverOpen: !this.state.isAddPopoverOpen })} className="help_icon" alt="ajuda" src={url_base+"/images/i_branco.png"}></img>
                                          </Popover>
                                    </h4>
                                </div>
                              </div>
                              <div className="form-group col-md-12 text-right action-buttons">
                                    <input type="submit" className="btn btn-primary" value="Cadastrar" id="bnt-cadastrar-nova-parte-1" onClick={this.addPersonage} />
                                    <span className="btn btn-danger  horizontal-space" id="bnt-voltar" onClick={this.voltar_parte}>Voltar</span>
                              </div>
                              <div className="form-group col-md-12 text-left">
                                      <label htmlFor="inp_nome" className="control-label">Nome<span className="text-red">*</span></label>
                                      <input type="text" name="inp_nome" value={this.state.inp_nome} id="inp_nome" className="form-control input-lg uppercase" onChange={this.handleChange} required="required" data-bv-field="inp_nome" />
                                      <div className="errorMsg">{this.state.errors.inp_nome}</div>
                              </div>
                              <div className="form-group col-md-4 text-left">
                                    <label htmlFor="opt_sides" className="">Cliente ou Oponente<span className="text-red">*</span></label>
                                    <Select
                                        className="opt_side"
                                        name="opt_side"
                                        placeholder="Selecione"
                                        options={opt_sides}
                                        value={this.state.opt_side}
                                        onChange={this.selectChange}
                                      />
                                      <div className="errorMsg">{this.state.errors.opt_side}</div>
                              </div>
                              <div className="form-group col-md-4">
                                    <label htmlFor="opt_tipo" className="">Tipo<span className="text-red"></span></label>
                                    <Select
                                        styles={customStyles}
                                        className="opt_type"
                                        name="opt_type"
                                        value={this.state.opt_type}
                                        options={this.state.opt_types}
                                        placeholder="Selecione"
                                        isClearable={true}
                                        onChange={this.selectChange}
                                    />
                              </div>
                              <div className="form-group col-md-4 text-left">
                                      <label htmlFor="opt_pessoaTipo" className="control-label"> Tipo de pessoa</label>
                                      <Select
                                          className="opt_pessoaTipo"
                                          name="opt_pessoaTipo"
                                          options={opt_pessoaTipo}
                                          defaultValue={opt_pessoaTipo[0]}
                                          placeholder="(escolha um)"
                                          onChange={this.selectCPFChange}
                                      />
                              </div>
                              <div id="form-group-rg" className="form-group col-md-4 text-left">
                                  <label htmlFor="inp_rg" className="control-label">RG / Orgão Expedidor</label>
                                  <input className="form-control input-lg" name="rg" onChange={this.handleChange} />
                              </div>
                              <div id="form-group-cpf" className="form-group col-md-4 text-left">
                                    <label htmlFor="inp_cpf" className="control-label">CPF</label>
                                    <InputMask className="form-control input-lg" name="cpf" mask="999.999.999-99" maskChar="_" onChange={this.handleChange} />
                              </div>
                              <div id="form-group-cnpj" className="form-group col-md-4 text-left hide">
                                    <label htmlFor="inp_cnpj" className="control-label">CNPJ</label>
                                    <InputMask className="form-control input-lg" name="cnpj" mask="99.999.999/9999-99" maskChar="_" onChange={this.handleChange} />
                              </div>
                              <div id="form-group-nascimento" className="form-group col-md-4 text-left">
                                      <label htmlFor="data_nascimento" className="control-label">Nascimento</label>
                                      <InputMask className="form-control input-lg" name="data_nascimento" mask="99/99/9999" maskChar="_" onChange={this.handleChange} />
                              </div>
                              <div id="form-group-profissao" className="form-group col-md-4 text-left">
                                      <label htmlFor="profissao" className="control-label">Profissão</label>
                                      <input className="form-control input-lg" name="profissao" onChange={this.handleChange} />
                              </div>
                              <div id="form-group-pis" className="form-group col-md-4 text-left">
                                      <label htmlFor="data_pis_pasep" className="control-label">PIS/PASEP</label>
                                      <input className="form-control input-lg" name="pis_pasep" onChange={this.handleChange} />
                              </div>
                              <div id="form-group-ctps" className="form-group col-md-4 text-left">
                                      <label htmlFor="data_ctps" className="control-label">CTPS</label>
                                      <input className="form-control input-lg" name="ctps" onChange={this.handleChange} />
                              </div>
                              <div id="form-group-estado-civil" className="form-group col-md-4 text-left">
                                    <label htmlFor="opt_estado_civil" className="control-label">Estado Civil</label>
                                    <Select
                                        className="opt_estado_civil"
                                        name="opt_estado_civil"
                                        options={opt_estado_civil}
                                        placeholder="Selecione"
                                        onChange={this.selectChange}
                                      />
                              </div>
                              <div id="form-group-nacionalidade" className="form-group col-md-3 text-left">
                                      <label htmlFor="opt_nacionalidade" className="control-label"> Nacionalidade</label>
                                      <Select
                                          className="opt_nacionalidade"
                                          name="opt_nacionalidade"
                                          defaultValue={opt_nacionalidade[0]}
                                          options={opt_nacionalidade}
                                          placeholder="(escolha um)"
                                          onChange={this.selectChange}
                                        />
                              </div>
                              <div className="form-group col-md-3 text-left">
                                    <label htmlFor="inp_cep" className="control-label">CEP</label>
                                    <InputMask className="form-control input-lg" id="cep" name="cep" mask="99.999-999" maskChar="_" onChange={this.handleChange} />
                              </div>
                              <div className="form-group form-group-cep text-left">
                                    <span id="cep-btn" className="btn btn-success cep-btn" onClick={this.getCEP}><i className="fa fa-search"></i> Pesquisa CEP</span>
                              </div>
                              <div className="form-group col-md-4 text-left">
                                    <label htmlFor="inp_rua" className="control-label">Endereço</label>
                                    <input type="text" name="inp_rua" value={this.state.inp_rua} id="inp_rua" className="form-control input-lg uppercase" onChange={this.handleChange} />
                              </div>
                              <div className="form-group col-md-4 text-left">
                                    <label htmlFor="inp_numero" className="control-label">N°</label>
                                    <input type="text" name="inp_numero" value={this.state.inp_numero} autoComplete="new-password" id="inp_numero" className="form-control input-lg uppercase" onChange={this.handleChange} />
                              </div>
                              <div className="form-group col-md-4 text-left">
                                    <label htmlFor="inp_complemento" className="control-label">Complemento</label>
                                    <input type="text" name="inp_complemento" value={this.state.complemento} id="inp_complemento" className="form-control input-lg uppercase" onChange={this.handleChange} />
                              </div>
                              <div className="form-group col-md-4 text-left">
                                    <label htmlFor="inp_bairro" className="control-label">Bairro</label>
                                    <input type="text" name="inp_bairro" value={this.state.inp_bairro} id="inp_bairro" className="form-control input-lg uppercase" onChange={this.handleChange} />
                              </div>
                              <div className="form-group col-md-4 text-left">
                                    <label htmlFor="inp_estado" className="control-label">Estado</label>
                                    <Select
                                        className="opt_estado"
                                        name="opt_estado"
                                        value={this.state.opt_estado}
                                        options={this.state.opt_estados}
                                        placeholder="Selecione"
                                        onChange={this.stateChange}
                                      />
                              </div>
                              <div className="form-group col-md-4 text-left">
                                    <label htmlFor="cidade" className="control-label">Cidade</label>
                                    <Select
                                        className="cidades"
                                        name="cidade"
                                        value={this.state.cidade}
                                        options={this.state.cities}
                                        placeholder="Selecione"
                                        onChange={this.selectChange}
                                      />
                              </div>
                              <div className="form-group col-md-4 text-left">
                                    <label htmlFor="tel" className="control-label">Telefone</label>
                                    <InputMask className="form-control input-lg" id="tel" name="tel" mask={this.state.tel.replace(/\D/g, "").length === 11 ? '(99) 99999-9999' : '(99) 9999-99999'}  maskChar="_" onChange={this.handleChange} />
                              </div>
                              <div className="form-group col-md-4 text-left">
                                    <label htmlFor="cel" className="control-label">Celular</label>
                                    <InputMask className="form-control input-lg" id="cel" name="cel" mask={this.state.cel.replace(/\D/g, "").length === 11 ? '(99) 99999-9999' : '(99) 9999-99999'}  maskChar="_" onChange={this.handleChange} />
                              </div>
                              <div className="form-group col-md-4 text-left">
                                    <label htmlFor="telCom" className="control-label">Telefone Comercial</label>
                                    <InputMask className="form-control input-lg" id="telcom" name="telcom" mask={this.state.telcom.replace(/\D/g, "").length === 11 ? '(99) 99999-9999' : '(99) 9999-99999'}  maskChar="_" onChange={this.handleChange} />
                              </div>
                              <div className="form-group col-md-4 text-left">
                                    <label htmlFor="email" className="control-label">Email</label>
                                    <input type="email" name="email" value={this.state.email} id="email" className="form-control input-lg" onChange={this.handleChange} />
                              </div>
                              <div className="form-group col-md-4 text-left">
                                    <label htmlFor="email2" className="control-label">Email 2</label>
                                    <input type="email" name="email2" value={this.state.email2} id="email2" className="form-control input-lg" onChange={this.handleChange} />
                              </div>
                              <div className="form-group col-md-4 text-left">
                                    <label htmlFor="email3" className="control-label">Email 3</label>
                                    <input type="email" name="email3" value={this.state.email3} id="email3" className="form-control input-lg" onChange={this.handleChange} />
                              </div>
                              <div className="form-group col-md-12 text-left">
                                    <label htmlFor="obs" className="control-label">Observações</label>
                                    <textarea name="obs" id="obs" rows="5" className="form-control" onChange={this.handleChange}></textarea>
                              </div>
                              <div className="form-group col-md-12 text-right">
                                    <input type="submit" className="btn btn-primary" value="Cadastrar" id="bnt-cadastrar-nova-parte" onClick={this.addPersonage} />
                                    <span className="btn btn-danger  horizontal-space" id="bnt-voltar" onClick={this.voltar_parte}>Voltar</span>
                              </div>

                        </div>

                        <div id="div-parte-cadastrada">
                              <div className="header-personage">
                                <div className="col-md-12 text-center">
                                    <h4>Selecione uma parte cadastrada ou cadastre uma nova</h4>
                                </div>
                              </div>
                              <div className="row text-center">
                                  <span className="btn btn-success" id="btn-switch-parte" onClick={this.switch_parte}>Cadastrar Nova Parte</span>
                                  <Popover
                                      isOpen={this.state.isPopoverOpen}
                                      position={['top', 'right', 'left', 'bottom']}
                                      padding={10}
                                      onClickOutside={() => this.setState({ isPopoverOpen: false })}
                                      content={({ position, targetRect, popoverRect }) => (
                                          <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                                              position={position}
                                              targetRect={targetRect}
                                              popoverRect={popoverRect}
                                              arrowColor={'#94bcd8'}
                                              arrowSize={10}
                                          >
                                              <div className="popover-box"
                                                  onClick={() => this.setState({ isPopoverOpen: !this.state.isPopoverOpen })}
                                              >
                                                    Antes de cadastrar uma Nova Parte, verifique  abaixo se a mesma já  está cadastro no sistema "Parte Cadastrada" , digitando as primeiras letras do nome da parte. Caso já esteja cadastrada, selecione-a.
                                              </div>
                                          </ArrowContainer>
                                      )}
                                  >
                                      <img onMouseOut={() => this.setState({ isPopoverOpen: false })}  onMouseOver={() => this.setState({ isPopoverOpen: !this.state.isPopoverOpen })} className="help_icon" alt="ajuda" src={url_base+"/images/interrogation_cropped.png"}></img>
                                  </Popover>
                              </div>
                              <div className="row">
                                          <label htmlFor="sel_parte_cadastrada_buscar" className="col-md-3 control-label">Parte cadastrada<span className="text-red">*</span></label>
                                          <div className="col-md-7">
                                              <Select
                                                  className="opt_parte"
                                                  name="opt_parte"
                                                  options={this.state.opt_partes}
                                                  value={this.state.opt_parte}
                                                  placeholder="Selecione"
                                                  onChange={this.selectChange}
                                                />
                                                <div className="errorMsg">{this.state.errors.opt_parte}</div>
                                            </div>
                              </div>
                              <div className="row">
                                    <label htmlFor="opt_sides" className="col-md-3 control-label">Cliente ou Oponente<span className="text-red">*</span></label>
                                    <div className="col-md-7">
                                          <Select
                                              className="opt_sides"
                                              name="opt_side"
                                              options={opt_sides}
                                              placeholder="Selecione"
                                              value={this.state.opt_side}
                                              onChange={this.selectChange}
                                            />
                                            <div className="errorMsg">{this.state.errors.opt_side}</div>
                                      </div>
                              </div>
                              <div className="row">
                                    <label htmlFor="opt_tipo" className="col-md-3 control-label">Tipo<span className="text-red"></span></label>
                                    <Select
                                        className="opt_type col-md-7"
                                        styles={customStyles}
                                        name="opt_type"
                                        options={this.state.opt_types}
                                        placeholder="Selecione"
                                        isClearable={true}
                                        value={this.state.opt_type}
                                        onChange={this.selectChange}
                                    />
                              </div>
                              <div className="row">
                                  <div className="form-group  col-md-10 group-buttons-actions text-right">
                                          <span className="btn btn-primary" id="bnt-cadastrar-parte-cadastrada" onClick={this.addPersonageLawsuit}>Salvar</span>
                                          <span className="btn btn-danger horizontal-space" onClick={this.cancel}>Cancelar</span>
                                  </div>
                              </div>
                        </div>
                    </form>
              </div>

  }
}
