import React from 'react';
import Popover, { ArrowContainer } from 'react-tiny-popover';
import { DatePicker } from 'antd'; import locale from 'antd/es/date-picker/locale/pt_BR';
import { Modal, Button } from 'antd';
import { Switch } from 'antd';
import Select from 'react-select';
import Loading from '../containers/loading';
import Confirm from '../containers/confirm';
import IntlCurrencyInput from "react-intl-currency-input";
import FlashMessage from '../containers/FlashMessage';
import Alert from '../containers/alert';
import FinanceService from '../../services/FinanceService';
import LawsuitService from '../../services/LawsuitService';
import PersonageService from '../../services/LawsuitPersonagesService';

import moment from "moment";
//import ptBR from 'antd/lib/locale-provider/pt_BR';
import 'moment/locale/pt-br';

moment.locale('pt-BR');

const customStyles = {
  clearIndicator: (provided, state) => {
    const color = 'red';

    return { ...provided, color };
  }
}

const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
}

/*const formaPagamentoList = [
  {value: '1', label: 'Dinheiro'},
  {value: '2', label: 'Cartão'},
  {value: '3', label: 'Cheque'},
  {value: '4', label: 'Boleto'},
  {value: '6', label: 'Em Carteira'},
  {value: '7', label: 'Depósito / Transferência'},
  {value: '5', label: 'Outros'},
]*/

/*let clienteList = [
  {value: '1', label: 'João'},
  {value: '2', label: 'Maria'},
];*/

/*let lawsuitList = [
  {value: '1', label: '1002056-84.2017.5.02.0204'},
  {value: '2', label: '1001085-12.2016.5.02.0018'},
];*/

export default class FormOutgoingFinance extends React.Component { 
        _isMounted = false;

        componentWillUnmount() {
          this._isMounted = false;
        }


      constructor(props) {
        super(props)

        this.modifiedOutgoing = this.props.modifiedOutgoing.bind(this);
        this.newOutgoing = this.newOutgoing.bind(this);
        this.moeda = this.moeda.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.addOutgoing = this.addOutgoing.bind(this);
        this.onSwitchBaixaChange = this.onSwitchBaixaChange.bind(this);
        this.handleCancel = this.handleCancel.bind(this);

        this.FinanceService = new FinanceService();

        this.personageService = new PersonageService();

        this.state = {
          loading: false,
          visible: false,
          lawsuitList: [],
          data_vencimento: null,
          data_pagamento: null,
          descricao: "",
          errors: {},
          loadingVisible: false,
          loadingMessage: [],
          categoryList: [],
          parcelas: 1,
          valor: 0,
          centroCustoList: [],
          forma_pagamento: null,
          formaPagamentoList: [],
          showModal: false,
          message: "",
          showAlert: false,
          showConfirm: false,
        }
      }

      componentWillReceiveProps(nextProps) {

        if ( nextProps.token !== this.props.token && nextProps.showModal ) {
                console.log(this.state.visible);
                this.setState({
                  token: nextProps.token,
                  visible: nextProps.showModal,
                });
    
                console.log(nextProps);
                console.log(this.props);
    
                setTimeout(() => {        
                  this.showModal();
                }, 200);
        }
    
      }

      componentDidMount( ) { 
        this._isMounted = true;

        document.addEventListener("cancelDialogConfirm", (e) => {
          if( this._isMounted ){
            this.setState({
              showConfirm: false
            });
          }
        });
    
        document.addEventListener("confirmDialogConfirm", (e) => {
          if( this._isMounted ){
            if(this.state.showConfirm){
              this.setState({
                showConfirm: false,
              });
      
              this.handleCancel(e, true);
            }
          }
        });

        document.addEventListener("cancelLoading", (e) => {
          this.setState({
            loadingVisible: false
          });
        });

        document.addEventListener("cancelAlert", (e) => {
          if( this._isMounted ){
            this.setState({
              showAlert: false
            });
          }
        });

        this.lawsuitService = new LawsuitService();
        this.FinanceService = new FinanceService();

          this.lawsuitService.officeList().then(response =>{

            this.setState({
              lawsuitList: response
            });
          });

          this.FinanceService.centroCustoList().then(response =>{

            this.setState({
              centroCustoList: response
            });
          });

          this.FinanceService.categoryOutgoingList().then(response =>{

            this.setState({
              categoryList: response
            });
          });

          this.FinanceService.listFinancialPaymentOptions().then(response =>{
              this.setState({
                formaPagamentoList: response
              });
          });

      }

      componentDidUpdate(){

          /*if( this.state.showModal === true ){
            this.showModal();
            this.setState({
              showModal: false
            });
          }*/

      }

      clearFields(){

        if( this._isMounted ){

              document.getElementById("lawsuit_container").className += ' hide';
              document.getElementById("switchBaixaContainer").classList.remove("hide");
              document.getElementById("dados-pagamento").className += ' hide';

              this.setState(
                {
                  loading: false,
                  visible: false,
                  data_vencimento: null,
                  descricao: "",
                  errors: {},
                  loadingVisible: false,
                  loadingMessage: [],
                  parcelas: 1,
                  lawsuit: null,
                  cliente: null,
                  switchBaixa: false,
                  switchCusta: false,
                  data_pagamento: null,
                  forma_pagamento: null,
                  centroCusto: this.state.centroCustoList[0],
                  categoria: null,
                  valor: 0,
                  token: ""
                }
              );

            }

      }

      addOutgoing(){

          this.FinanceService = new FinanceService();

          let lawsuit = null; let forma_pagamento = null; let data_vencimento = null; let centroCusto = null;
          let categoria = null; let data_pagamento = null; let belongs_to_lawsuit = 0;

          if ( typeof this.state.lawsuit !== "undefined" && this.state.lawsuit !== null ) {
            lawsuit = this.state.lawsuit.value;
            belongs_to_lawsuit = 1;
          }

          if ( typeof this.state.forma_pagamento !== "undefined" && this.state.forma_pagamento !== null ) {
            forma_pagamento = this.state.forma_pagamento.value;
          }else{
            forma_pagamento = this.state.formaPagamentoList[0].value;
          }

          if ( typeof this.state.centroCusto !== "undefined" && this.state.centroCusto !== null ) {
            centroCusto = this.state.centroCusto.value;
          }else{
            centroCusto = this.state.centroCustoList[0].value;
          }

          if ( typeof this.state.categoria !== "undefined" && this.state.categoria !== null ) {
            categoria = this.state.categoria.value;
          }

          if ( typeof this.state.data_vencimento !== "undefined" ) {
            data_vencimento = this.state.data_vencimento;
          }

          if ( typeof this.state.data_pagamento !== "undefined" && this.state.forma_pagamento !== null ) {
            data_pagamento = this.state.data_pagamento;
          }

          let data = {
            "lawsuit_token": lawsuit,
            "observacao": this.state.descricao,
            "data_vencimento": data_vencimento.split('/').reverse().join('-')+" 00:00",
            "data_pagamento": (this.state.switchBaixa)? data_pagamento.split('/').reverse().join('-')+" 00:00" : null,
            "financial_payment_option_token": forma_pagamento,
            "parcelas": this.state.parcelas,
            "valor": this.state.valor.replace(/[^0-9-]/g, "")/100,
            "financial_center_cost_token": centroCusto,
            "financial_category_token": categoria,
            "pago": ( this.state.switchBaixa ) ? 1 : 0,
            "custa": this.state.switchCusta,
            "belongs_to_lawsuit": belongs_to_lawsuit
          }

          this.FinanceService.addOutgoing( data ).then(res =>{

                if( res.success === true ){
                      localStorage.setItem('alert_message', "Fatura salva com sucesso");
                      localStorage.setItem('alert_type', 'alert-success');
                      let time = new Date().getTime();
                      localStorage.setItem('alert_time', time );

                      //let incoming_token = res.data.Incomings.token;
                      //console.log( incoming_token );

                      this.modifiedOutgoing();
                }else {
                  localStorage.setItem('alert_message', res.message);
                  localStorage.setItem('alert_type', 'alert-danger');
                  let time = new Date().getTime();
                  localStorage.setItem('alert_time', time );
                }

          });

      }

    moeda( event, value, maskedValue ) {
      event.preventDefault();

      this.setState(
          {
              [event.target.name]: maskedValue
          }
      )
   }

   formataMoeda( valor ){
     let total2 = valor*100;
     total2 = parseInt( total2 );
     var tmp = total2+'';
     total2 = tmp;
     total2 = total2.replace('-','');
     tmp = tmp.replace(/([0-9]{2})$/g, ",$1");
     if( total2.length > 5 ){
         valor = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
     }else{
       valor = valor.toFixed(2);
       valor = valor.replace('.',',');
     }
     return valor;
   }

   onDatePickerChange = (date, dateString, name) => {

     if (dateString === "" ){
       dateString = null;
     }

      this.setState(
          {
              [name]: dateString,
          }
      )

    }

    newOutgoing = () => {

      this.setState({
          token: ""
      });

      setTimeout(() => {
          this.showModal();
      }, 300);

    }

    showModal = () => {

          // Formulário de edição
          if( this.state.token !== "" && typeof this.state.token !== "undefined" ){

            this.FinanceService = new FinanceService();
            this.FinanceService.viewOutgoing( this.state.token ).then(res => {
              console.log( res );

              let lawsuit_token = this.state.lawsuitList.filter( (lawsuit)=>{ return lawsuit.value === res.lawsuit_token })[0];
              let category_token = this.state.categoryList.filter( (category)=>{ return category.value === res.financial_category.token })[0];

              this.setState({
                descricao: res.observacao,
                data_vencimento: ( res.data_vencimento !== null ) ? res.data_vencimento.substr(0,10).split("-").reverse().join("/") : "",
                data_pagamento: ( res.data_pagamento !== null ) ? res.data_pagamento.substr(0,10).split("-").reverse().join("/") : "",
                valor: this.formataMoeda( res.valor ),
                categoria: category_token,
                parcelas: res.numero_parcela,
                lawsuit: lawsuit_token,
                labelSaveButton: "Editar",
                titleModal: "Editar Lançamento",
                showStatus: true,
                visible: true,
              });

            });

          }else {

              this.setState({
                labelSaveButton: "Incluir",
                titleModal: "Novo Lançamento Contas a Pagar",
                showStatus: false,
                visible: true
              });
          }

          this.setState({
            visible: true
          });

    };

    handleOk = () => {
        if (this.validateForm()) {

          this.setState({
            loadingVisible: true,
            loading: true,
            loadingMessage: ["O cadastro está sendo efetuado.", "Por gentileza, aguarde...", "Tempo estimado de 15 a 90 segundos"]
          });

          this.addOutgoing();

            setTimeout( () => {
              this.clearFields();
            }, 3000);

        }

    };

    handleCancel = ( event, r = false ) => {
        //window.confirm("As alterações serão perdidas, deseja realmente sair?") 
        if( r ){
          this.clearFields();
        }else{
          this.setState({
            showConfirm: true,            
            message: "As alterações serão perdidas, deseja realmente sair?"
          });
        }
    };

    handleChange = (e) => {

            this.setState(
                {
                    [e.target.name]: e.target.value
                }
            )

     }

     selectChange = (selected, actionMeta) => {
       let pops = [];
       pops[ actionMeta.name ] = selected;

       if ( typeof actionMeta.name !== undefined && actionMeta.name !== "" && actionMeta.name !== null ){

         if ( typeof selected !== undefined && selected !== "" ) {
           this.setState( pops );
         }

       }
     }

    validateForm = () => {
        let errors = {};
        let formIsValid = true;

        if ( this.state.data_vencimento === 0 || this.state.data_vencimento === "" || typeof this.state.data_vencimento === "undefined" || this.state.data_vencimento === null ) {
          formIsValid = false;
          errors["data_vencimento"] = "*Preencha a data";
        }

        if ( this.state.descricao === 0 || this.state.descricao === "" || typeof this.state.descricao === "undefined" ) {
          formIsValid = false;
          errors["descricao"] = "*Forneça a descrição da entrada";
        }

        if ( this.state.valor === 0 || this.state.valor === "" || typeof this.state.valor === "undefined" ) {
          formIsValid = false;
          errors["valor"] = "*Digite um valor";
        }

        if ( this.state.categoria === 0 || this.state.categoria === "" || typeof this.state.categoria === "undefined" || this.state.categoria === null )  {
          formIsValid = false;
          errors["categoria"] = "*Selecione a categoria";
        }

        if ( this.state.switchCusta === true && ( this.state.lawsuit === null || typeof this.state.lawsuit === "undefined" ) ) {
          formIsValid = false;
          errors["lawsuit"] = "*Escolha o processo";
        }

        if ( this.state.switchBaixa === true && ( this.state.data_pagamento === 0 || this.state.data_pagamento === "" || typeof this.state.data_pagamento === "undefined" || this.state.data_pagamento === null ) ) {
          formIsValid = false;
          errors["data_pagamento"] = "*Preencha a data";
        }

        if ( this.state.switchBaixa === true && ( typeof this.state.forma_pagamento === "undefined" || this.state.forma_pagamento === null ) ) {
          formIsValid = false;
          errors["forma_pagamento"] = "*Escolha uma forma de pagamento";
        }

        this.setState({
           errors: errors
         });

        return formIsValid;

    }

    showCentroCustoModal = () => {

          this.setState({
            centroCustoModalVisible: true,
          });
    };

    handleCentroCustoModalOk = () => {

        this.setState({
          centroCustoModalVisible: false,
        })
    };

    handleCentroCustoModalCancel = () => {
        this.setState({ centroCustoModalVisible: false });
    };


    addCentroCusto = (e) => {
      this.FinanceService = new FinanceService();
      document.body.style.cursor = "wait";
      let centroCusto = {
        name: document.getElementById("centroCustoName").value
      }
      this.FinanceService.addCentroCusto( centroCusto ).then(res =>{

        this.FinanceService.centroCustoList( ).then(response =>{

              document.getElementById("centroCustoName").value = "";
              document.body.style.cursor = "default";
              this.setState({
                centroCustoList: response,
              });

        });

      });
    }

    deleteCentroCusto = (e) => {
      this.FinanceService = new FinanceService();
      document.body.style.cursor = "wait";

      let id = document.getElementById("centroCustoSettings").value;

      console.log( "centro de custo que vai ser deletado: "+id );

      this.FinanceService.deleteCentroCusto( id ).then( res =>{

        if( res.success === true ){
          this.FinanceService.centroCustoList( ).then(response =>{
              document.body.style.cursor = "default";
                this.setState({
                  centroCustoList: response,
                });
          });
        }else{
          document.body.style.cursor = "default";
          //alert(res.message);
          this.setState({
            showAlert: true,
            message: res.message
          });
        }

      });
    }

    showCategoryModal = () => {

          this.setState({
            categoryModalVisible: true,
          });
    };

    handleCategoryModalOk = () => {

        this.setState({
          categoryModalVisible: false,
        })
    };

    handleCategoryModalCancel = () => {
        this.setState({ categoryModalVisible: false });
    };


    addCategory = (e) => {
      this.FinanceService = new FinanceService();
      document.body.style.cursor = "wait";
      let categoria = {
        name: document.getElementById("categoryName").value
      }
      this.FinanceService.addCategoryOutgoing( categoria ).then(res =>{

        this.FinanceService.categoryOutgoingList( ).then(response =>{

              document.getElementById("categoryName").value = "";
              document.body.style.cursor = "default";
              this.setState({
                categoryList: response,
              });

        });

      });
    }

    deleteCategory = (e) => {
      this.FinanceService = new FinanceService();
      document.body.style.cursor = "wait";

      let id = document.getElementById("categorySettings").value;

      console.log( "categoria que vai ser deletado: "+id );

      this.FinanceService.deleteCategoryOutgoing( id ).then( res =>{

        if( res.success === true ){
          this.FinanceService.categoryOutgoingList( ).then(response =>{
              document.body.style.cursor = "default";
                this.setState({
                  categoryList: response,
                });
          });
        }else{
          document.body.style.cursor = "default";
          //alert(res.message);
          this.setState({
            showAlert: true,
            message: res.message
          });
        }

      });
    }

    onSwitchChange = (checked) => {
      //console.log(`switch to ${checked}`);
      if ( checked ){
        document.getElementById("lawsuit_container").classList.remove("hide");
        //document.getElementById("switchBaixaContainer").className += ' hide';
        //this.onSwitchBaixaChange(false);
        this.setState({
          switchCusta: true,
          forma_pagamento: null,
        });
      }else{
        document.getElementById("lawsuit_container").className += ' hide';
        //document.getElementById("switchBaixaContainer").classList.remove("hide");
        this.setState({
          switchCusta: false,
        });
      }
    }

    onSwitchBaixaChange = (checked) => {
      //console.log(`switch to ${checked}`);
      if ( checked ){
        document.getElementById("dados-pagamento").classList.remove("hide");
        this.setState({
          switchBaixa: true,
        });
      }else{
        document.getElementById("dados-pagamento").className += ' hide';
        this.setState({
          switchBaixa: false,
        });
      }
    }

  render() {

      var arrCategory = [];
      for (var k = 0; k < this.state.categoryList.length; k++) {
          arrCategory.push(<option key={k} value={this.state.categoryList[k].value}> {this.state.categoryList[k].label} </option>);
      }

      var arrCentroCusto = [];
      for (var l = 0; l < this.state.centroCustoList.length; l++) {
          arrCentroCusto.push(<option key={l} value={this.state.centroCustoList[l].value}> {this.state.centroCustoList[l].label} </option>);
      }

      var url_base = localStorage.getItem('url_base');

      return <div>
                <FlashMessage time={ new Date().getTime() } />
                <Alert show={this.state.showAlert} text={this.state.message} />
                <Confirm show={this.state.showConfirm} text={this.state.message} />
                <Loading visible={this.state.loadingVisible} message1={this.state.loadingMessage[0]} message2={this.state.loadingMessage[1]} message3={this.state.loadingMessage[2]} cancel={true}  />
                <span className="btn btn-primary form-submit add-incoming" onClick={this.newOutgoing}>Novo Lançamento</span>
                <Modal
                  visible={this.state.visible}
                  title="Financeiro"
                  width="650px"
                  onOk={this.handleOk}
                  onCancel={ (event)=>{this.handleCancel(event)} }
                  footer={[
                    <Button key="submit" id="handleOk" type="primary" loading={this.state.loading} onClick={this.handleOk}>
                      {this.state.labelSaveButton}
                    </Button>,
                    <Button key="back" id="close" className="btn-danger" onClick={ (event)=>{this.handleCancel(event)} }>
                      Fechar
                    </Button>,
                  ]}
                >
                  <div id="lancamento-container" className="fieldset contas-pagar-container">
                        <span className="col-md-12 title-container">{this.state.titleModal}</span>
                        <div className="form-group select-container">
                            <label htmlFor="centroCusto">
                                     Centro de Custo
                                     <Popover
                                         isOpen={this.state.isCostCenterPopoverOpen}
                                         position={['top', 'right', 'left', 'bottom']}
                                         padding={10}
                                         onClickOutside={() => this.setState({ isCostCenterPopoverOpen: false })}
                                         content={({ position, targetRect, popoverRect }) => (
                                             <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                                                 position={position}
                                                 targetRect={targetRect}
                                                 popoverRect={popoverRect}
                                                 arrowColor={'#94bcd8'}
                                                 arrowSize={10}
                                             >
                                                 <div className="popover-box"
                                                     onClick={() => this.setState({ isCostCenterPopoverOpen: !this.state.isCostCenterPopoverOpen })}
                                                 >
                                                       Permite criar vários Centros de Custos, separando de onde vai ser pago ou recebido o valor lancado. Ex.: Filial A, Filial B, Matriz, Particular, Pareceirias, e outros.
                                                 </div>
                                             </ArrowContainer>
                                         )}
                                     >
                                         <img onMouseOut={() => this.setState({ isCostCenterPopoverOpen: false })}  onMouseOver={() => this.setState({ isCostCenterPopoverOpen: !this.state.isUserNotifyPopoverOpen })} className="help_icon " alt="ajuda" src={url_base+"/images/interrogation_cropped.png"}></img>
                                     </Popover>
                            </label>
                            <Select
                                className="centroCustoSelect"
                                styles={customStyles}
                                options={this.state.centroCustoList}
                                placeholder="Selecione"
                                name="centroCusto"
                                defaultValue={this.state.centroCustoList[0]}
                                value={this.state.centroCusto}
                                onChange={this.selectChange}
                              />
                              <i className="material-icons settings" onClick={this.showCentroCustoModal} title="Clique para adicionar ou excluir centro de Custos">settings</i>
                        </div>
                        <div className="form-group select-container">
                            <label htmlFor="categoria">
                                    Categoria<span className="text-red">*</span>
                                    <Popover
                                        isOpen={this.state.isCategoryPopoverOpen}
                                        position={['top', 'right', 'left', 'bottom']}
                                        padding={10}
                                        onClickOutside={() => this.setState({ isCategoryPopoverOpen: false })}
                                        content={({ position, targetRect, popoverRect }) => (
                                            <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                                                position={position}
                                                targetRect={targetRect}
                                                popoverRect={popoverRect}
                                                arrowColor={'#94bcd8'}
                                                arrowSize={10}
                                            >
                                                <div className="popover-box"
                                                    onClick={() => this.setState({ isCategoryPopoverOpen: !this.state.isCategoryPopoverOpen })}
                                                >
                                                      Separe os lançamentos por categorias para  que se possa ter uma visão detalhada e relatórios mais precisos.  Podem ser incluidas e excluidas.
                                                </div>
                                            </ArrowContainer>
                                        )}
                                    >
                                        <img onMouseOut={() => this.setState({ isCategoryPopoverOpen: false })}  onMouseOver={() => this.setState({ isCategoryPopoverOpen: !this.state.isCategoryPopoverOpen })} className="help_icon " alt="ajuda" src={url_base+"/images/interrogation_cropped.png"}></img>
                                    </Popover>
                            </label>
                            <Select
                                className="categorySelect"
                                styles={customStyles}
                                options={this.state.categoryList}
                                placeholder="Selecione"
                                name="categoria"
                                isClearable={true}
                                value={this.state.categoria}
                                onChange={this.selectChange}
                              />
                              <i className="material-icons settings" onClick={this.showCategoryModal} title="Clique para adicionar ou excluir categorias">settings</i>
                              <div className="errorMsg">{this.state.errors.categoria}</div>
                        </div>
                        <div className="form-group switch-container col-md-12">
                            <label htmlFor="custas" className="control-label">
                                  Custas de Processo
                                  <Popover
                                      isOpen={this.state.isCustaPopoverOpen}
                                      position={['top', 'right', 'left', 'bottom']}
                                      padding={10}
                                      onClickOutside={() => this.setState({ isCustaPopoverOpen: false })}
                                      content={({ position, targetRect, popoverRect }) => (
                                          <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                                              position={position}
                                              targetRect={targetRect}
                                              popoverRect={popoverRect}
                                              arrowColor={'#94bcd8'}
                                              arrowSize={10}
                                          >
                                              <div className="popover-box"
                                                  onClick={() => this.setState({ isCustaPopoverOpen: !this.state.isCustaPopoverOpen })}
                                              >
                                                    Possibilita lançar todos os valores  gastos com um processo. Estes valores serão lançados no fluxo de caixa como Débitos, e simultaneamente  serão lançados  como Débitos no Controle Financeiro do Processo.
                                              </div>
                                          </ArrowContainer>
                                      )}
                                  >
                                      <img onMouseOut={() => this.setState({ isCustaPopoverOpen: false })}  onMouseOver={() => this.setState({ isCustaPopoverOpen: !this.state.isCustaPopoverOpen })} className="help_icon " alt="ajuda" src={url_base+"/images/interrogation_cropped.png"}></img>
                                  </Popover>
                            </label>
                            <Switch defaultChecked={this.state.switchCusta} checked={this.state.switchCusta} onChange={this.onSwitchChange} />
                        </div>
                        <div id="lawsuit_container" className="form-group col-xs-12 col-md-12 hide">
                            <label htmlFor="cliente">Processo</label>
                            <Select
                                styles={customStyles}
                                className="lawsuit"
                                options={this.state.lawsuitList}
                                placeholder="Selecione"
                                value={this.state.lawsuit}
                                name="lawsuit"
                                isClearable={true}
                                onChange={this.selectChange}
                              />
                              <div className="errorMsg">{this.state.errors.lawsuit}</div>
                        </div>
                        <div className="form-group col-md-4 col-xs-12 text-left">
                            <label htmlFor="data_vencimento" className="control-label">Data Vencimento<span className="text-red">*</span></label>
                            <DatePicker locale={locale} className="data_vencimento" placeholder="Data de Vencimento" showToday={false} allowClear={false} format="DD/MM/YYYY" value={( this.state.data_vencimento !== null ) ? moment(this.state.data_vencimento, "DD/MM/YYYY") : null} onChange={(date, dateString) => this.onDatePickerChange(date, dateString, "data_vencimento")} />
                            <div className="errorMsg">{this.state.errors.data_vencimento}</div>
                        </div>
                        <div className="form-group col-md-4 col-xs-12 text-left">
                              <label htmlFor="valorTotal" className="">Valor Total<span className="text-red">*</span></label>
                              <IntlCurrencyInput className="form-control input-lg" currency="BRL" config={currencyConfig} name="valor" id="valor" value={this.state.valor} onChange={this.moeda} />
                              <div className="errorMsg">{this.state.errors.valor}</div>
                        </div>
                        <div className="form-group col-md-4">
                            <label htmlFor="cliente">
                                  Parcelas
                                  <Popover
                                      isOpen={this.state.isParcelasPopoverOpen}
                                      position={['top', 'right', 'left', 'bottom']}
                                      padding={10}
                                      onClickOutside={() => this.setState({ isParcelasPopoverOpen: false })}
                                      content={({ position, targetRect, popoverRect }) => (
                                          <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                                              position={position}
                                              targetRect={targetRect}
                                              popoverRect={popoverRect}
                                              arrowColor={'#94bcd8'}
                                              arrowSize={10}
                                          >
                                              <div className="popover-box"
                                                  onClick={() => this.setState({ isParcelasPopoverOpen: !this.state.isParcelasPopoverOpen })}
                                              >
                                                    Ao parcelar, o Valor Total será dividido e as parcelas serão lançadas a cada 30 dias (Lançamentos Futuros).
                                              </div>
                                          </ArrowContainer>
                                      )}
                                  >
                                      <img onMouseOut={() => this.setState({ isParcelasPopoverOpen: false })}  onMouseOver={() => this.setState({ isParcelasPopoverOpen: !this.state.isParcelasPopoverOpen })} className="help_icon " alt="ajuda" src={url_base+"/images/interrogation_cropped.png"}></img>
                                  </Popover>
                            </label>
                            <input
                                type="number"
                                className="parcelas input-lg form-control"
                                min="1"
                                max="12"
                                value={this.state.parcelas}
                                name="parcelas"
                                onChange={this.handleChange}
                              />
                        </div>
                        <div className="form-group col-md-9 col-xs-12 text-left">
                              <label htmlFor="descricao" className="control-label">Observação<span className="text-red">*</span></label>
                              <textarea name="descricao" id="descricao" maxLength="100" rows="1" value={this.state.descricao} className="form-control input-lg" onChange={this.handleChange}></textarea>
                              <div className="errorMsg">{this.state.errors.descricao}</div>
                        </div>
                        <div id="switchBaixaContainer" className="form-group col-md-3">
                            <label htmlFor="custas" className="control-label">
                                  Conta Paga?
                                  <Popover
                                      isOpen={this.state.isContaPagaPopoverOpen}
                                      position={['top', 'right', 'left', 'bottom']}
                                      padding={10}
                                      onClickOutside={() => this.setState({ isContaPagaPopoverOpen: false })}
                                      content={({ position, targetRect, popoverRect }) => (
                                          <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                                              position={position}
                                              targetRect={targetRect}
                                              popoverRect={popoverRect}
                                              arrowColor={'#94bcd8'}
                                              arrowSize={10}
                                          >
                                              <div className="popover-box"
                                                  onClick={() => this.setState({ isContaPagaPopoverOpen: !this.state.isContaPagaPopoverOpen })}
                                              >
                                                    Se o lançamento já se encontrar quitado, poderá dar baixa no pagamento ao cadastrá-lo.
                                              </div>
                                          </ArrowContainer>
                                      )}
                                  >
                                      <img onMouseOut={() => this.setState({ isContaPagaPopoverOpen: false })}  onMouseOver={() => this.setState({ isContaPagaPopoverOpen: !this.state.isContaPagaPopoverOpen })} className="help_icon " alt="ajuda" src={url_base+"/images/interrogation_cropped.png"}></img>
                                  </Popover>
                            </label>
                            <Switch defaultChecked={this.state.switchBaixa} checked={this.state.switchBaixa} onChange={this.onSwitchBaixaChange} />
                        </div>
                    </div>
                    <div id="dados-pagamento" className="fieldset hide">
                          <div className="form-group col-md-3 text-left">
                              <label htmlFor="data_pagamento" className="control-label">Data do Pagamento<span className="text-red">*</span></label>
                              <DatePicker locale={locale} className="data_pagamento" dropdownClassName="top" placeholder="Data do Pagamento" showToday={false} allowClear={false} format="DD/MM/YYYY" value={( this.state.data_pagamento !== null ) ? moment(this.state.data_pagamento, "DD/MM/YYYY") : null} onChange={(date, dateString) => this.onDatePickerChange(date, dateString, "data_pagamento")} />
                              <div className="errorMsg">{this.state.errors.data_pagamento}</div>
                          </div>
                          <div className="form-group col-md-3">
                              <label htmlFor="forma_pagamento">Forma de Pagamento<span className="text-red">*</span></label>
                              <Select
                                  styles={customStyles}
                                  className="forma-pagamento"
                                  options={this.state.formaPagamentoList}
                                  placeholder="Selecione"
                                  value={this.state.forma_pagamento}
                                  name="forma_pagamento"
                                  onChange={this.selectChange}
                                />
                                <div className="errorMsg">{this.state.errors.forma_pagamento}</div>
                          </div>
                    </div>
                </Modal>
                <Modal
                  visible={this.state.centroCustoModalVisible}
                  className="modalSettings"
                  title="Incluir ou Excluir Centro de Custo"
                  width="450px"
                  onOk={this.handleCentroCustoModalOk}
                  onCancel={this.handleCentroCustoModalCancel}
                  footer={[
                    <Button key="back" className="btn-danger" onClick={this.handleCentroCustoModalCancel}>
                      Fechar
                    </Button>
                  ]}
                >
                        <div className="form-group">
                          <label htmlFor="centroCustoName" className="col-form-label col-md-12">Novo Centro de Custo: </label>
                          <input type="text" className="form-control" id="centroCustoName" />
                          <span className="btn btn-primary" id="add-centro-custo" onClick={this.addCentroCusto} >Incluir</span>
                        </div>
                        <div className="form-group">
                            <label htmlFor="centroCustoSettings" className="col-form-label col-md-12">Opções: </label>
                            <select multiple id="centroCustoSettings" className="centroCusto settings">
                              {arrCentroCusto}
                            </select>
                            <i className="material-icons delete" id="delete-centroCusto" title="remover item selecionado" onClick={this.deleteCentroCusto} >delete</i>
                        </div>
                        <p className="info">As alterações são salvas automaticamente</p>
                </Modal>
                <Modal
                  visible={this.state.categoryModalVisible}
                  className="modalSettings"
                  title="Incluir ou Excluir Categoria"
                  width="450px"
                  onOk={this.handleCategoryModalOk}
                  onCancel={this.handleCategoryModalCancel}
                  footer={[
                    <Button key="back" className="btn-danger" onClick={this.handleCategoryModalCancel}>
                      Fechar
                    </Button>
                  ]}
                >
                        <div className="form-group">
                          <label htmlFor="categoryName" className="col-form-label col-md-12">Nova Categoria: </label>
                          <input type="text" className="form-control" id="categoryName" />
                          <span className="btn btn-primary" id="add-category" onClick={this.addCategory} >Incluir</span>
                        </div>
                        <div className="form-group">
                            <label htmlFor="categorySettings" className="col-form-label col-md-12">Opções: </label>
                            <select multiple id="categorySettings" className="category settings">
                              {arrCategory}
                            </select>
                            <i className="material-icons delete" id="delete-category" title="remover item selecionado" onClick={this.deleteCategory} >delete</i>
                        </div>
                        <p className="info">As alterações são salvas automaticamente</p>
                </Modal>
             </div>
  }

}
