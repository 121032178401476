import React from 'react';
import options from './json/tribunal_options';
import estados from './json/estados';
import estados_allowed from './json/estados_allowed';
import { Link } from 'react-router-dom';

import TribunalService from '../services/TribunalService';
import LawsuitService from '../services/LawsuitService';
import AuthService from '../services/AuthService';

import MyPopover from './containers/mypopover';
import HeaderBar from './header-bar/header-bar';
import NavigationMenu from './sidebar/navigation-menu';
import Loading from './containers/loading';
import Footer from "./containers/footer";
import moment from "moment";
//import Loading from './containers/loading';

const tribunal_options = options;

export default class Tribunal extends React.Component { 
  _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }


  constructor( { match } ) {
      super();
      this.authService = new AuthService();

      let ufs = null//JSON.parse(localStorage.getItem('ufs'));
      let options = null //JSON.parse(localStorage.getItem('options'));
      //console.log(options);
      if ( typeof ufs === undefined || ufs === null || ufs === "" ){
        ufs = JSON.parse(JSON.stringify(estados));
      }
      this.state = {
        'active': (typeof match.params.active !== undefined )? match.params.active : 0,
        'ufs': ufs,
        'tribunals': [],//JSON.parse(localStorage.getItem('tribunals')),
        options: (options === null) ? tribunal_options : options,
        loadingVisible: false,
        loadingMessage: [],
      }

      window.scrollTo(0,0);

  }

  /*componentWillReceiveProps(nextProps) {

    this.tribunalService = new TribunalService();
    this.tribunalService.listAllowed().then(res =>{

      this.getCounts( res );

    })
    .catch(err =>{
        console.log("Conexão Instável."+err);
    })

  }*/

  componentWillMount( ) {


  }

  componentDidMount( ) { 
    this._isMounted = true;

    this.tribunalService = new TribunalService();
    this.tribunalService.listAllowed().then(res =>{
      if( this._isMounted ){

        if( 
            ( JSON.parse(localStorage.getItem('total_tribunals_count')) == null || 
              JSON.parse(localStorage.getItem('total_tribunals_count')) == 0 || 
              JSON.parse( localStorage.getItem('ufs') ) == [] ||
              JSON.parse( localStorage.getItem('ufs') ) == null ||
              typeof JSON.parse(localStorage.getItem('total_tribunals_count')) == "undefined" ||
              typeof JSON.parse(localStorage.getItem('total_tribunals_count')).date == "undefined" ||
              typeof JSON.parse( localStorage.getItem('ufs') ) == "undefined"
            ) || 
              moment().diff(JSON.parse(localStorage.getItem('total_tribunals_count_movements')).date, 'minutes',true ) > 0 
          ){
          setTimeout(() => {      
            this.getCounts( res ); 
          }, 10);
        }else{

          let tribunals = res;//JSON.parse( localStorage.getItem('tribunals') );
          //let ufs = JSON.parse( localStorage.getItem('ufs') );
          let total_tribunals_count = JSON.parse( localStorage.getItem('total_tribunals_count') );
          let total_tribunals_count_movements = JSON.parse( localStorage.getItem('total_tribunals_count_movements') ).total;
          let total_tribunals_count_favorites_movements = JSON.parse( localStorage.getItem('total_tribunals_count_favorites_movements') );

          this.setState({
            total_tribunals_count,
            tribunals,
            total_tribunals_count_movements,
            total_tribunals_count_favorites_movements
          });
          //console.log("usando o do cache: " + JSON.parse(localStorage.getItem('total_tribunals_count')).date)
        }
      }

    })
    .catch(err =>{
        console.log("Conexão Instável."+err);
    })

  }

  getCounts = ( tribunals ) => {

        this.lawsuitService = new LawsuitService();
        this.tribunalService = new TribunalService();

        /*this.setState({
          loadingVisible: true,
          loadingMessage: ["Carregando os tribunais.", "Por gentileza, aguarde..."]
        });*/

        let ufs = JSON.parse(JSON.stringify(estados_allowed));

        //console.log(ufs);

        let total_tribunals_count = 0;

        //console.log(tribunals);

        this.tribunalService.counterTribunalsLawsuits().then( countTribunals =>{

          tribunals.map( ( record, index ) => {

            countTribunals.map( (ct, idx) => { 

              if( ct.tribunal_id === record.id ) {
                  console.log(ct);
                  console.log(record);
                  tribunals[index].count = parseInt( ct.counter );
                  tribunal_options.map( (option, ind, array ) => {

                    option.children.map( (reg, i, arr ) => {

                        if( reg.slug === record.slug.toLowerCase() ){
                          tribunals[index].uf = option.key;
                          reg.allowed = record.allowed;

                          ufs.map( ( item, x ) => {
                              /*console.log(item.uf);
                              console.log(tribunals[index].uf);
                              console.log("===");*/
                              if ( item.uf === tribunals[index].uf ){
                                ufs[x].count += parseInt( ct.counter );
                                total_tribunals_count += parseInt( ct.counter );
                                //localStorage.setItem('tribunals', JSON.stringify(tribunals));
                                //localStorage.setItem('ufs', JSON.stringify(ufs));
                                //localStorage.setItem('total_tribunals_count', JSON.stringify(total_tribunals_count));
                                //console.log(tribunals);
                                //console.log(ufs);
                                //console.log(total_tribunals_count);

                                if( record.allowed === 1 && (item.uf === tribunals[index].uf) ){
                                  item.allowed = 1;
                                  option.allowed = 1;
                                }
                                //if(index === tribunals.length-1){

                                    //setTimeout(() => {
                                      this.setState({
                                        "ufs": ufs,
                                        tribunals,
                                        total_tribunals_count
                                      });
                                    //}, 500);

                                //}
                              }
                              return null;
                          });
                        }
                        return null;
                    });
                    return null;
                  });

              }

            return ct;

          });

            return record;
          });

        });

        setTimeout(() => {

          this.tribunalService.counterTribunalsMovements( ).then(response =>{

            let total_tribunals_count_movements = 0;

            tribunals.map( ( record, index ) => {

              response.map( ( item, i ) => {

                  if ( item.tribunal_id === record.id ){
                      if ( this.authService.can('avisos_de_andamentos', true) ){
                          tribunals[index].count_movements = item.count_movements;
                      }
                  }

                  tribunal_options.map( (option, ind, array ) => {

                    option.children.map( (reg, i, arr ) => {

                          if( reg.slug === record.slug.toLowerCase() ){
                            tribunals[index].uf = option.key;
                            reg.allowed = record.allowed;
                          }


                          ufs.map( ( thing, x ) => {
                              /*console.log(thing);
                              console.log(thing.uf);
                              console.log(record);
                              console.log(record.uf);*/
                              //console.log( reg.slug );
                              //console.log( record.slug.toLowerCase() );
                              //console.log( item.tribunal_id );
                              //console.log( record.id );
                              //console.log( x );

                              if ( (thing.uf === tribunals[index].uf) && ( reg.slug === record.slug.toLowerCase() ) && (item.tribunal_id === record.id) ){
                                    if ( this.authService.can('avisos_de_andamentos', true) ){

                                      ufs[x].count_movements += item.count_movements;
                                      total_tribunals_count_movements += item.count_movements;
                                      //console.log( total_tribunals_count_movements );

                                    }
                              }

                              /*if( record.allowed === 1 && (thing.uf === tribunals[index].uf) ){
                                thing.allowed = 1;
                                option.allowed = 1;
                              }*/

                              return thing;
                          });
                          return reg;

                    });
                    return option;

                  });

                  if(i === response.length-1){

                      //setTimeout(() => {
                        this.setState({
                          "ufs": ufs,
                          options: tribunal_options,
                          tribunals: tribunals,
                          total_tribunals_count_movements: total_tribunals_count_movements
                        });
                      //}, 500);


                  localStorage.setItem('tribunals', JSON.stringify(tribunals));
                  localStorage.setItem('options', JSON.stringify(tribunal_options));
                  localStorage.setItem('ufs', JSON.stringify(ufs));
                  localStorage.setItem('total_tribunals_count_movements', JSON.stringify({total: total_tribunals_count_movements, date: moment()}));

                  }

                  return item;

              } );
            });
          });    

        }, 10);

        setTimeout(() => {

          this.tribunalService.counterTribunalsMovementsFavorites( ).then( fav =>{


            let total_tribunals_count_favorites_movements = 0;

            tribunals.map( ( record, index ) => {
                fav.map( ( item, i ) => {

                  if ( item.tribunal_id === record.id ){
                      if ( this.authService.can('avisos_de_andamentos', true) ){
                          tribunals[index].count_favorite_movements = item.count_movements;
                      }
                  }

                  tribunal_options.map( (option, ind, array ) => {

                    option.children.map( (reg, i, arr ) => {


                          ufs.map( ( thing, x ) => {
                              if ( (thing.uf === tribunals[index].uf) && ( reg.slug === record.slug.toLowerCase() ) && (item.tribunal_id === record.id) ){
                                  if ( this.authService.can('avisos_de_andamentos', true) ){
                                      ufs[x].count_favorite_movements += item.count_movements;
                                      total_tribunals_count_favorites_movements += item.count_movements;
                                  }
                              }
                              return null;
                          });
                          return null;

                    });
                    return null;

                  });

                  if(i === fav.length-1){

                      //setTimeout(() => {
                        this.setState({
                          "ufs": ufs,
                          tribunals,
                          total_tribunals_count_favorites_movements
                        });
                      //}, 500);


                  localStorage.setItem('tribunals', JSON.stringify(tribunals));
                  localStorage.setItem('ufs', JSON.stringify(ufs));
                  localStorage.setItem('total_tribunals_count_favorites_movements', JSON.stringify(total_tribunals_count_favorites_movements));

                  }

                  return null;

                } );
            });

            //console.log(tribunals);
            this.setState({
              loadingVisible: false,
            });

          });

        }, 10);        

  }

  getPanelItem = ({ singleOption }) => {
    //console.log(singleOption);
    const { key, label, children, allowed, regiao } = singleOption;
    //var tribunals = JSON.parse(localStorage.getItem('tribunals'));

    /*console.log(key);
    console.log(label);
    console.log(children);
    console.log(allowed);*/

        if (children) {

              let uf_count = 0; let uf_count_container = null; let uf_count_movement = 0; let uf_count_movement_container = null;
              let uf_count_favorite_movement = 0; let uf_count_favorite_movement_container = null;

              //console.log(key);
              //console.log(this.state.ufs);

              uf_count = this.state.ufs.filter( (item) =>{ return item.uf === key } )[0].count;
              uf_count_movement = this.state.ufs.filter( (item) =>{ return item.uf === key } )[0].count_movements;
              uf_count_favorite_movement = this.state.ufs.filter( (item) =>{ return item.uf === key } )[0].count_favorite_movements;

              if ( uf_count > 0 ){
                uf_count_container = <span data-toggle="tooltip" title="N° de Processos Cadastrados" className="bg-green badge horizontal-space" data-original-title="N° de Processos Cadastrados">
                                          {uf_count}
                                    </span>
              }
              if ( uf_count_movement > 0 ){
                uf_count_movement_container = <span data-toggle="tooltip" title="Andamentos Não visualizados" className="bg-yellow badge horizontal-space" data-original-title="Andamentos Não visualizados">
                                                      {uf_count_movement}
                                              </span>
              }
              if ( uf_count_favorite_movement > 0 ){
                uf_count_favorite_movement_container = <span className="star_container badge bg-gray" data-toggle="tooltip" title="Andamentos Não visualizados  de Processos em Destaque" data-original-title="Andamentos Não visualizados de Processos em Destaque" >
                                                      <i className="material-icons starred">star_rate</i>
                                                      <span className="number_starred">
                                                              {uf_count_favorite_movement}
                                                      </span>
                                              </span>
              }

              if(allowed === 1){

                        return (
                          <li key={key} className={"treeview estado "+regiao}>
                                <a href="/">
                                  <span className="nav-text">
                                    <label className="tribunal-name">{label}</label>
                                    {uf_count_container}
                                    {uf_count_movement_container}
                                  </span>
                                  {uf_count_favorite_movement_container}
                                  <i className="fa fa-angle-left pull-right"></i>
                                </a>
                              <ul className="treeview-menu">

                             {children.map(child => {
                              const linkTo = `/${child.key}`;

                              if ( child.grandchildren){
                                return null;
                              }else if(child.allowed === 1){
                                let tribunal = this.state.tribunals.filter( ( item ) =>{ return item.slug.toLowerCase() === child.slug } );
                                let count = 0; let count_movements = 0; let count_favorite_movements = 0;
                                let count_container = null; let count_movement_container = null; let count_favorite_movement_container = null;

                                if ( tribunal.length > 0 ){
                                  count = tribunal[0].count;
                                  count_movements = tribunal[0].count_movements;
                                  count_favorite_movements = tribunal[0].count_favorite_movements;
                                }

                                if ( count > 0 ){
                                  count_container =  <span data-toggle="tooltip" title="N° de Processos Cadastrados" className="bg-green badge horizontal-space" data-original-title="N° de Processos Cadastrados">
                                                            { count }
                                                     </span>
                                }

                                if ( count_movements > 0){
                                  count_movement_container = <span data-toggle="tooltip" title="Andamentos Não visualizados" className="bg-yellow badge horizontal-space" data-original-title="Andamentos Não visualizados">
                                                                    { count_movements }
                                                              </span>
                                }

                                if ( count_favorite_movements > 0 ){
                                  count_favorite_movement_container = <span className="star_container badge bg-gray" data-toggle="tooltip" title="Andamentos Não visualizados de Processos em Destaque" data-original-title="Andamentos Não visualizados de Processos em Destaque" >
                                                                            <i className="material-icons starred">star_rate</i>
                                                                            <span className="number_starred">
                                                                                { count_favorite_movements }
                                                                            </span>
                                                                          </span>
                                }

                                return (
                                    <li key={child.key}>
                                        <Link to={linkTo}>
                                          <label className="tribunal-name">{child.label}</label>
                                          {count_container}
                                          {count_movement_container}
                                          {count_favorite_movement_container}
                                        </Link>
                                    </li>
                                );
                              }
                              return false;
                            })}
                            </ul>
                          </li>
                        );

              }

        }

  };

  changeUF = () => {
      let regiao = document.getElementById("regioes").value;

      if(regiao == "todos"){
        let elements = document.getElementsByClassName("estado");
        for (var i = 0; i < elements.length; i++){
          elements[i].style.display = 'block';
        }
      }else{
        let elements = document.getElementsByClassName("estado");
        for (var i = 0; i < elements.length; i++){
          elements[i].style.display = 'none';
        }

        let uf = document.getElementsByClassName(regiao);
        for (var i = 0; i < uf.length; i++){
          uf[i].style.display = 'block';
        }
      }
  }

  render() {
    //console.log(this.state.options);
    let conf_tribunal_url = '/config/tribunal';

      return <div className="sheet tribunal">
                <HeaderBar />
                <Loading visible={this.state.loadingVisible} message1={this.state.loadingMessage[0]} message2={this.state.loadingMessage[1]} message3={this.state.loadingMessage[2]} cancel={true}   />
                <NavigationMenu active={ this.state.active } />
                <div className="content-wrapper">
                  <div className="content-wrapper-inner">
                      <section className="content-header">
                        <div className="panel-primary panel">
                            <div className="panel-heading text-center">
                              <h4>
                                Tribunais
                                <MyPopover icon="white" text='Para que a tela inicial possa ficar limpa e com facilidade de visualização, pode-se ocultar os Estados e/ou Tribunais qua não tenha processos. Esta ação pode ser revertida a qualquer tempo pelo administrador ou usuário com acesso à Configurações.' />
                              </h4>
                            </div>
                            <div className="panel-body">
                                <Link to={conf_tribunal_url} className={(this.authService.can('acesso_configuracoes', true) ) ? "btn btn-primary" : "hide"}>
                                  <label>Incluir/Ocultar Estados ou Tribunais</label>
                                </Link>

                                <ul className="tribunal-menu home-menu">
                                      {this.state.options.map(singleOption =>
                                        this.getPanelItem({ singleOption })
                                      )}
                                </ul>
                            </div>
                        </div>
                      </section>
                  </div>
                </div>
                <Footer />
             </div>
  }

}
