import React from 'react';
import Popover, { ArrowContainer } from 'react-tiny-popover';
import HeaderBar from '../header-bar/header-bar';
import NavigationMenu from '../sidebar/navigation-menu';
import Footer from '../containers/footer';
import ReactQuill from 'react-quill';
import FlashMessage from '../containers/FlashMessage';

import eDocsService from '../../services/eDocsService';

import AuthService from '../../services/AuthService';

export default class editTemplate extends React.Component { _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }


  constructor( props ){
      super( props );
      this.viewTemplate = this.viewTemplate.bind(this);
      this.editTemplate = this.editTemplate.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.personageFieldsClick = this.personageFieldsClick.bind(this);
      this.addParte = this.addParte.bind(this);
      this.attachQuillRefs = this.attachQuillRefs.bind(this);

      this.quillRef = null;
      this.reactQuillRef = null;

      this.authService = new AuthService();

      this.authService.can('gerenciar_doc_facil');

      this.state = {
        title: "",
        content: "",
        template_id: props.match.params.id,
        edocs_type_token: "",
        partes: [],
        partes_count: 1,
        template_disabled: true,
        error: null
      }

  }

  componentWillReceiveProps(nextProps) {
    if ( nextProps !== this.props  ) {

      this.setState ({
          template_id: nextProps.match.params.id
      });

      setTimeout(() => {

        this.viewTemplate();

      }, 300);

    }

  }

  componentDidMount( ) { this._isMounted = true;

      this.viewTemplate();
      this.attachQuillRefs();
      this.addParte();

  }

  componentDidUpdate () {
    this.attachQuillRefs()
  }

  attachQuillRefs() {
    // Ensure React-Quill reference is available:
    if (typeof this.reactQuillRef.getEditor !== 'function') return;
    // Skip if Quill reference is defined:
    if (this.quillRef != null) return;

    const quillRef = this.reactQuillRef.getEditor();
    if (quillRef != null) this.quillRef = quillRef;
  }

  viewTemplate(){

    this.eDocsService = new eDocsService();

      this.eDocsService.viewTemplate( this.state.template_id ).then(res =>{
            //console.log(res);

            this.setState({
                title: res.name,
                content: res.content,
                edocs_type_token: res.edocs_type.token,
            });
      });

  }

  handleChange (html) {
      this.setState({ content: html });

      if( html.length > 100 ){
        this.setState({ template_disabled: false, error: "" });
      }else{
        this.setState({ template_disabled: true, error: "Número de caracteres menor que o permitido para o documento." });
      }
  }

  personageFieldsClick( event ) {

    var range = this.quillRef.getSelection();
    let position = range ? range.index : 0; console.log(range);

    let code = event.target.dataset.code;

    this.quillRef.insertText( position, code );

  }

  editTemplate = () => {

      this.eDocsService = new eDocsService();

      let template = {
        content: this.state.content,
        edocs_type_token: this.state.edocs_type_token,
        name: this.state.title
      }

      console.log( template );

        this.eDocsService.editTemplate( template, this.state.template_id ).then(res =>{
              console.log(res);

              if(res.success === true ){
                localStorage.setItem('alert_message', "Modelo alterado com sucesso");
                localStorage.setItem('alert_type', 'alert-success');
                let time = new Date().getTime();
                localStorage.setItem('alert_time', time );

                setTimeout(() => {
                  this.props.history.push( '/gerenciar_modelos' );
                  /*this.setState({
                      template_id: res.data.EdocsModels.token,
                      title: res.data.EdocsModels.name,
                      content: res.data.EdocsModels.content,
                      time: time
                  });*/
                }, 100);

              }

        });

  }

  addParte = () => { console.log("entrou");

      let parte = <div key={this.state.partes_count} className="panel panel-default">
            <div className="panel-heading" role="tab" id={"accordion_parte"+this.state.partes_count}>
                <div className="pointer panel-title" data-toggle="collapse" data-target={"#collapse"+this.state.partes_count}>
                    <strong>Parte {this.state.partes_count}</strong>
                </div>
            </div>
            <div className="panel-body in" id={"collapse"+this.state.partes_count}>
                  <div className="panel-body fields">
                      <button data-code={"[[Nome"+this.state.partes_count+"]]"} onClick={this.personageFieldsClick}>Nome</button>
                      <button data-code={"[[Data de Nascimento"+this.state.partes_count+"]]"} onClick={this.personageFieldsClick}>Nascimento</button>
                      <button data-code={"[[CPF"+this.state.partes_count+"]]"} onClick={this.personageFieldsClick}>CPF</button>
                      <button data-code={"[[RG"+this.state.partes_count+"]]"} onClick={this.personageFieldsClick}>RG/Orgão</button>
                      <button data-code={"[[Nacionalidade"+this.state.partes_count+"]]"} onClick={this.personageFieldsClick}>Nacionalidade</button>
                      <button data-code={"[[Estado Civil"+this.state.partes_count+"]]"} onClick={this.personageFieldsClick}>Estado Civil</button>
                      <button data-code={"[[Cep"+this.state.partes_count+"]]"} onClick={this.personageFieldsClick}>CEP</button>
                      <button data-code={"[[Endereço"+this.state.partes_count+"]]"} onClick={this.personageFieldsClick}>Endereço</button>
                      <button data-code={"[[Número"+this.state.partes_count+"]]"} onClick={this.personageFieldsClick}>Nº</button>
                      <button data-code={"[[Complemento"+this.state.partes_count+"]]"} onClick={this.personageFieldsClick}>Complemento</button>
                      <button data-code={"[[Bairro"+this.state.partes_count+"]]"} onClick={this.personageFieldsClick}>Bairro</button>
                      <button data-code={"[[Estado"+this.state.partes_count+"]]"} onClick={this.personageFieldsClick}>Estado</button>
                      <button data-code={"[[Cidade"+this.state.partes_count+"]]"} onClick={this.personageFieldsClick}>Cidade</button>
                      <button data-code={"[[Telefone"+this.state.partes_count+"]]"} onClick={this.personageFieldsClick}>Telefone</button>
                      <button data-code={"[[Email"+this.state.partes_count+"]]"} onClick={this.personageFieldsClick}>E-mail</button>
                      <button data-code={"[[Data do Documento]]"} onClick={this.personageFieldsClick}>Data do Documento</button>
                      <button data-code={"[[Pis/Pasep"+this.state.partes_count+"]]"} onClick={this.personageFieldsClick}>PIS</button>
                      <button data-code={"[[CTPS"+this.state.partes_count+"]]"} onClick={this.personageFieldsClick}>N CTPS</button>
                      <button data-code={"[[Profiss\u00e3o"+this.state.partes_count+"]]"} onClick={this.personageFieldsClick}>PROFISSÃO</button>
                  </div>
            </div>
      </div>;

      let partes = this.state.partes;
      let partes_count = this.state.partes_count;
      partes_count = partes_count+1;
      partes.push(parte);
      this.setState({
        partes: partes,
        partes_count: partes_count
      });

  }

  modules = {
      toolbar: [
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline','strike'],
        ['blockquote','code-block'],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        [ 'align' ,{ 'align': 'center' },{ 'align': 'right' }, { 'align': 'justify' }],
        [{ 'font': [] }, { 'color': [] }, { 'size': [] }]
      ],
    };

formats = [
      'header',
      'bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block',
      'list', 'bullet', 'indent',
      'align',
      'font', 'color', 'size'
    ];

  render() {
      var url_base = localStorage.getItem('url_base');

      let partes = this.state.partes;

      return <div className="sheet edit-template">
                <FlashMessage time={ new Date().getTime() } />
                <HeaderBar />
                <NavigationMenu />
                <div className="content-wrapper">
                  <div className="content-wrapper-inner">
                      <div className="content-header">
                            <h1>{this.state.title}</h1>
                            <span id="edit-model" className="btn btn-success " disabled={this.state.template_disabled} onClick={this.editTemplate} >
                                Salvar
                            </span>
                            <Popover
                                isOpen={this.state.isEventPopoverOpen}
                                position={['top', 'right', 'left', 'bottom']}
                                padding={10}
                                onClickOutside={() => this.setState({ isEventPopoverOpen: false })}
                                content={({ position, targetRect, popoverRect }) => (
                                    <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                                        position={position}
                                        targetRect={targetRect}
                                        popoverRect={popoverRect}
                                        arrowColor={'#94bcd8'}
                                        arrowSize={10}
                                    >
                                        <div className="popover-box"
                                            onClick={() => this.setState({ isEventPopoverOpen: !this.state.isEventPopoverOpen })}
                                        >
                                              Copie o texto do documento que deseja salvar no espaço em branco, clique no texto onde quer fixar o item, dê 2 cliques no item correspondente no quadro de partes. Todos os itens selecionados terão que estar preenchidos na ficha cadastral do cliente. Observe espaços e virgulas, após fixar os itens.  Pode ser reeditado!
                                        </div>
                                    </ArrowContainer>
                                )}
                            >
                                <img onMouseOut={() => this.setState({ isEventPopoverOpen: false })}  onMouseOver={() => this.setState({ isEventPopoverOpen: !this.state.isEventPopoverOpen })} className="help_icon " alt="ajuda" src={url_base+"/images/interrogation_cropped.png"}></img>
                            </Popover>
                            <div className="errorMsg">{this.state.error}</div>
                            <ReactQuill theme="snow"
                                        ref={(el) => { this.reactQuillRef = el }}
                                        className="editor"
                                        onChange={this.handleChange}
                                        value={this.state.content}
                                        modules={this.modules}
                                        formats={this.formats}
                            />
                            <div className="errorMsg">{this.state.error}</div>
                            <span id="edit-model" className="btn btn-success " disabled={this.state.template_disabled} onClick={this.editTemplate} >Salvar</span>
                      </div>
                      <div className="sidebarPartes">
                          <span id="edit-model" className="btn btn-info " onClick={this.addParte} >Adicionar Parte</span>
                          {partes.map( (record, index) => { return record; })}
                      </div>
                  </div>
                </div>
                <Footer />
             </div>
  }

}
