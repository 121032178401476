import AuthService from './AuthService';
import {api} from '../config';


export default class GedService {
    // Initializing important variables
    constructor(domain) {
        this.domain = domain || api.url // API server domain
        this.fetch = this.fetch.bind(this) // React binding stuff
        this.listDocuments = this.listDocuments.bind(this)
    }

    listDocuments ( token ) {
      return this.fetch(`${this.domain}Arquivos/index/?lawsuit_token=${token}`, {
          method: 'GET',
      }).then(res => {
          let response = [];
          //console.log(res);
          if( res.success === true ){
              return res.data.Arquivos;
          }

          //console.log(response);
          return Promise.resolve(response);
      })
    }

    delete ( token ) {
      console.log(`${this.domain}Arquivos/delete/${token}`);
      return this.fetch(`${this.domain}Arquivos/delete/${token}`, {
          method: 'DELETE',
      }).then(res => {
          return Promise.resolve(res);
      })
    }

    fetch(url, options) {
          let auth = new AuthService();

          options.headers = {
             'Authorization': 'Bearer ' + auth.getToken(),
             'Content-Type': 'application/json',
             'Accept': 'application/json'
           }

          return fetch(url, options)
                  .then(this._checkStatus)
                  .then(response => {

                        if(typeof response !== "undefined"){

                          return Promise.resolve( response.text().then(function(result){
                              //console.log(result);
                              let old_result = result;
                              result = result.split('{"success"');
                              if( result.length > 1 ){
                                  result = result[1].split('<pre');
                                  //console.log(result[0]);
                                  let json = '{"success"'+result[0];
                                  result = JSON.parse(json);
                                  //console.log(result);
                                  return result;
                              }else{
                                  return old_result;
                              }

                          }) );

                      }else{

                          let result = {
                              success: false,
                              message: "Erro desconhecido"
                          }

                          return Promise.resolve( result );

                      }

                  })
    }

    _checkStatus(response) {
        // raises an error in case response status is not a success
        if (response.status >= 200 && response.status < 300) { // Success status lies between 200 to 300
            return response
        } else if( response.status === 401 || response.status === "401" ) {
          console.log("Tentando trapacear? Você será desligado");


          localStorage.removeItem('id_token');
          localStorage.removeItem('loggedUser');
          window.location.href = '/login';

        } else if( response.status === 402 || response.status === "402" ) {
        
            window.location.href = '/config/mudar-plano?plano_vencido';
    
          }else {
            var error = new Error(response.statusText)
            error.response = response
            throw error
        }
    }

    /*fetch(url, options) {
        // performs api calls sending the required authentication headers
        let auth = new AuthService();

        options.headers = {
           'Authorization': 'Bearer ' + auth.getToken(),
           'Content-Type': 'application/json',
           'Accept': 'application/json'
         }

        return fetch(url, options)
            .then(this._checkStatus)
            .then(response => response.json())
    }*/
}
