import React from 'react';
import Popover, { ArrowContainer } from 'react-tiny-popover';
import { DatePicker } from 'antd'; 
import locale from 'antd/es/date-picker/locale/pt_BR';
import { Link } from 'react-router-dom';
import Select from 'react-select';

import HeaderBar from './header-bar/header-bar';
import Footer from './containers/footer';
import NavigationMenu from './sidebar/navigation-menu';
import Loading from './containers/loading';
import Confirm from './containers/confirm';
import FlashMessage from './containers/FlashMessage';
import FormOutgoingFinance from './finance/FormOutgoingFinance';
import BaixarSaida from './finance/BaixarSaida';
import PrintComponent from './reports/printComponent';


import LawsuitService from '../services/LawsuitService';
import FinanceService from '../services/FinanceService';
import AuthService from '../services/AuthService';

import moment from "moment";
//import ptBR from 'antd/lib/locale-provider/pt_BR';
import 'moment/locale/pt-br';

moment.locale('pt-BR');

const baixaList = [
  {value: '1', label: 'Baixados'},
  {value: '0', label: 'Não Baixados'},
  {value: '3', label: 'Ambos'},

]

const customStyles = {
  clearIndicator: (provided, state) => {
    const color = 'red';

    return { ...provided, color };
  }
}

const categoryAcordoList = [
  {value: '5000', label: 'Parcela de Acordo'},
]

/*const clienteList = [
  {value: '1', label: 'João'},
  {value: '2', label: 'Maria'},
];

const lawsuitList = [
  {value: '1', label: '1002056-84.2017.5.02.0204'},
  {value: '2', label: '1001085-12.2016.5.02.0018'},
];

/*const centroCustoList = [
  {value: '1', label: 'Escritório'},
  {value: '2', label: 'Processo'},
];*/

export default class OutgoingFinance extends React.Component { 
  _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }

  constructor(){
    super();

    this.onDatePickerChange = this.onDatePickerChange.bind(this);
    this.getOutgoings = this.getOutgoings.bind(this);
    this.modifiedOutgoing = this.modifiedOutgoing.bind(this);
    this.openEditOutgoing = this.openEditOutgoing.bind(this);
    this.clear = this.clear.bind(this);
    this.modifiedViewPrint = this.modifiedViewPrint.bind(this);

    this.authService = new AuthService();

    this.authService.can("financeiro_contas_pagar", false, 'financeiro');

    this.state = {
        incomings: [],
        date_start: moment().startOf('month').format('DD/MM/YYYY'),
        date_end: moment().endOf('month').format('DD/MM/YYYY'),
        centroCustoList: [],
        clienteList: [],
        lawsuitList: [],
        categoryList: [],
        categoryIncomingList: [],
        token: "",
        loadingMessage: [],
        baixa: baixaList[2],
        centroCusto: null,
        total: 0,
        lawsuit: null,
        print: 0,
        showConfirm: false,
        outgoingToDelete: false,
        message: "",
        showBaixarModal: false,
        showModal: false,
        office: {
          name: "DFOLI COMERCIO E SERVIÇOS LTDA"
        }
    }

    window.scrollTo(0,0);

  }

  componentDidMount( ) { 
    this._isMounted = true;

    document.addEventListener("cancelDialogConfirm", (e) => {
      if( this._isMounted ){
        this.setState({
          showConfirm: false
        });
      }
    });

    document.addEventListener("confirmDialogConfirm", (e) => {
      if( this._isMounted ){
        this.setState({
          showConfirm: false,
          showBaixarModal: false,
          showModal: false
        });

        if( this.state.outgoingToDelete !== false ){
          this.deleteOutgoing(this.state.outgoingToDelete, true);
        }

      }
    });

    document.addEventListener("cancelLoading", (e) => {
      this.setState({
        loadingVisible: false
      });
    });

    this.lawsuitService = new LawsuitService();
    this.FinanceService = new FinanceService();

      this.lawsuitService.officeList().then(response =>{

          if (this._isMounted) {

              this.setState({
                lawsuitList: response
              });

          }
      });

      this.getCentroCusto();

      this.getCategories();

  }

  modifiedViewPrint(){
    //console.log("view print modificado");
    this.setState({
      print: 0
    });
  }

  getCentroCusto(){
    this.FinanceService.centroCustoList().then(response =>{

          if (this._isMounted) {
              this.setState({
                centroCustoList: response
              });
          }
    });
  }

  getCategories(){
    this.FinanceService.categoryOutgoingList().then(response =>{
          if (this._isMounted) {
                this.setState({
                  categoryList: response
                });

                setTimeout(() => {
                  this.getOutgoings();
                }, 500);
          }
    });

    this.FinanceService.categoryIncomingList().then(response =>{
          if (this._isMounted) {
                this.setState({
                  categoryIncomingList: response
                });
          }
    });
  }

  print = (event) => {
    this.setState({
      print: 1,
    });
  }

  screen = (event) => {
    this.setState({
      print: 0,
    });
  }

  clear = ( event ) => {
      this.setState({
        centroCusto: null,
        baixa: baixaList[2],
        date_start: moment().startOf('month').format('DD/MM/YYYY'),
        date_end: moment().endOf('month').format('DD/MM/YYYY'),
      });
  }

  getOutgoings = () => {
      let total = 0; let total2 = 0; let pago = this.state.baixa.value;
      let centro_custo = ( this.state.centroCusto===null ) ? null : this.state.centroCusto.value;
      let lawsuit_token = ( this.state.lawsuit===null ) ? null : this.state.lawsuit.value;
      let catList = []; let outgoings = [];

      //console.log("passa negão");

      this.setState({
        loadingVisible: true,
        loading: true,
        loadingMessage: ["Os lançamentos estão sendo carregados.", "Por gentileza, aguarde...", "Tempo estimado de 15 a 90 segundos"]
      });

      this.FinanceService = new FinanceService();
      this.FinanceService.listOutgoing( this.state.date_start, this.state.date_end, pago, centro_custo, lawsuit_token ).then(res =>{

              this.FinanceService.listIncoming( this.state.date_start, this.state.date_end, pago, centro_custo, lawsuit_token ).then(incomings =>{

                      if (this._isMounted) {
                              console.log(res);
                              if(res === null){
                                res = []
                              }

                              this.state.categoryList.map( ( record, index, array ) => {

                                catList.push(record.key);

                                return record;

                              });

                              console.log(this.state.categoryList);

                              res.map( ( record, index, array ) => {

                                    if( catList.includes( record.financial_category.token ) ){

                                      record.source = 'outgoing';
                                      total += record.valor;
                                      outgoings.push( record );
                                    }

                                    return record;

                              });

                              //console.log(incomings);

                              incomings.map( ( record, index, array ) => {

                                    if( record.financial_category.name === "Recebimento de Acordo" && record.belongs_to_lawsuit === 1 ){

                                      record.source = 'outgoing';
                                      record.financial_category.name =  "Repasse de Acordo";
                                      total += record.valor;
                                      outgoings.push( record );
                                    }

                                    return record;

                              });

                              outgoings.sort(function(a, b){

                                if ( moment(a.data_vencimento.substr(0,10), "YYYY-MM-DD").valueOf() < moment(b.data_vencimento.substr(0,10), "YYYY-MM-DD").valueOf() ){
                                  return -1;
                                }else{
                                  return 1;
                                }

                              });

                              total2 = total*100;
                              total2 = parseInt( total2 );
                              var tmp = total2+'';
                              tmp = tmp.replace(/([0-9]{2})$/g, ",$1");
                              if( tmp.length > 6 ){
                                  total = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
                              }else{
                                total = total.toFixed(2);
                                total = total.replace('.',',');
                              }

                              this.setState({
                                  outgoings: outgoings,
                                  token: "",
                                  showModal: false,
                                  showBaixarModal: false,
                                  total: total,
                                  loadingVisible: false,
                                  loading: false,
                              });
                      }
              });
        });
  }

  modifiedOutgoing = ()=>{
    //console.log("lançamentos modificados");

    this.getCentroCusto();
    this.getCategories();
    this.getOutgoings();
  }

  onDatePickerChange = (date, dateString, name) => {

    if (dateString === "" ){
      dateString = null;
    }

     this.setState(
         {
             [name]: dateString,
         }
     )

   }

   disabledStartDate = startValue => {
     const endValue = moment(this.state.date_end, "DD/MM/YYYY");
     if (!startValue || !endValue) {
       return false;
     }
     return  startValue.valueOf() > endValue.valueOf()  ;
   };

   disabledEndDate = endValue => {
     const startValue = moment(this.state.date_start, "DD/MM/YYYY");
     if (!endValue || !startValue) {
       return false;
     }
     return endValue.valueOf() < startValue.valueOf() ;
   }

   selectChange = (selected, actionMeta) => {
     let pops = [];
     pops[ actionMeta.name ] = selected;

     if ( typeof actionMeta.name !== undefined && actionMeta.name !== "" && actionMeta.name !== null ){

       if ( typeof selected !== undefined && selected !== "" ) {
         this.setState( pops );
       }

     }
   }

   openEditOutgoing = ( event ) => {
     this.setState({
         token: event.target.dataset.token,
         showBaixarModal: true
     });
   }

   deleteOutgoing = ( event, r = false ) => {

         this.FinanceService = new FinanceService();

         //var r = window.confirm("Deseja realmente excluir o lançamento?");

         if ( r ){
           this.FinanceService.deleteOutgoing( event.target.dataset.token )
               .then(res =>{
                    if (this._isMounted) {

                        if( res.success === true ){

                          localStorage.setItem('alert_message', "Lançamento deletado com sucesso");
                          localStorage.setItem('alert_type', 'alert-success');
                          let time = new Date().getTime();
                          localStorage.setItem('alert_time', time );

                          this.getOutgoings();

                        }else{
                          localStorage.setItem('alert_message', res.message);
                          localStorage.setItem('alert_type', 'alert-danger');
                          let time = new Date().getTime();
                          localStorage.setItem('alert_time', time );
                          setTimeout(() => {
                            this.setState({
                              updated: time,
                            });
                          }, 100);
                        }
                    }
               })
               .catch(err =>{
                   document.body.style.cursor = "default";
                   console.log(err);
               })
         }else{
          let evt = {
            target: {
              dataset: {
                token: event.target.dataset.token
              }
            }
          }
          this.setState({
            showConfirm: true,
            outgoingToDelete: evt,
            message: "Deseja realmente excluir o lançamento?"
          });
        }

    }

    formataMoeda( valor ){
      let total2 = valor*100;
      total2 = parseInt( total2 );
      var tmp = total2+'';
      total2 = tmp;
      total2 = total2.replace('-','');
      tmp = tmp.replace(/([0-9]{2})$/g, ",$1");
      if( total2.length > 5 ){
          valor = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
      }else{
        valor = valor.toFixed(2);
        valor = valor.replace('.',',');
      }
      return valor;
    }

   outgoingItem = ( {record, index}, type ) => {
        let category = ""; let lawsuit = ""; let baixa = null;
        let source = (typeof record.source !== "undefined") ? record.source : "";
        //console.log(record);
        /*if(record.reembolso === 1 && record.categoria !== null){
          category = this.state.categoryIncomingList.find( (law)=>{ return law.value === record.categoria } );
          category = ( typeof category === "undefined") ? "" : category.label;
        }else if(record.acordo === 1 && record.categoria !== null){
          category = categoryAcordoList.find( (law)=>{ return law.value === record.categoria } );
          category = ( typeof category === "undefined") ? "" : category.label;
        }else if(record.categoria !== null){
          category = this.state.categoryList.find( (law)=>{ return law.value === record.categoria } );
          //console.log(cliente);
          category = ( typeof category === "undefined") ? "" : category.label;
        }else{
          category = "";
        }*/
        if(record.lawsuit !== null && typeof record.lawsuit !== "undefined"){
          /*lawsuit = this.state.lawsuitList.find( (law)=>{ return law.value === record.lawsuit.toString() } );
          lawsuit = ( typeof lawsuit === "undefined") ? "" : lawsuit.label;*/
          if( record.lawsuit.number === null ){
            lawsuit = record.lawsuit.name;
          }else{
            lawsuit = record.lawsuit.number;
          }
        }else{
          lawsuit = "";
        }

        /*if ( record.custa === true lawsuit !== ""){
          baixa = null;
        }else */if( record.pago === 1 || record.pago === true ){
          baixa =  <div className="toggle">
                         <input className="toggle__input" readOnly type="checkbox" checked />
                         <span className="toggle__label">
                           <span className="toggle__text"></span>
                         </span>
                         <div className="toggle__title">Baixa</div>
                   </div>
        }else{
          baixa = <label className="toggle">
                      <button className="curi" data-source={source}  data-token={record.token} onClick={ ( event ) => { this.openEditOutgoing(event) } }>
                      </button>
                      <div className="toggle__title">Baixa</div>
                  </label>
        }

        let data_vencimento = record.data_vencimento.substr(0, 10).split('-').reverse().join('/');
        let data_pagamento = (record.data_pagamento !== null) ? record.data_pagamento.substr(0, 10).split('-').reverse().join('/') : "";

        if(/*record.custa === true*/ lawsuit !== ""){
          data_pagamento = data_vencimento;
        }

        //console.log(record.valor);

        let excluir = <i className={"fa fa-trash"} data-source={source} data-token={record.token} onClick={ ( event ) => { this.deleteOutgoing(event) } }></i>

       return (
         <tr key={record.token} className={type}>
               <td className="category" >
                       {/*category*/record.financial_category.name}
               </td>
               <td className="description" >
                       {record.observacao}
               </td>
               <td className="">
                       {lawsuit}
               </td>
               <td className="table_valor"  >
                       R$ { this.formataMoeda(record.valor) }
               </td>
               <td className="table_date"  >
                       { data_vencimento }
               </td>
               <td className="table_date"  >
                       { data_pagamento }
               </td>
               <td className="actions">
                   {baixa}
                   {this.authService.can('financeiro_contas_pagar_excluir', true, 'financeiro') ? excluir : null}
               </td>
         </tr>


       );
   };

  render() {
    let url_base = localStorage.getItem('url_base');

    let table =           <div className="table-responsive"><table className="table">
                                  <thead>
                                      <tr>
                                            <th className="table_category">
                                                <div>Categoria</div>
                                            </th>
                                            <th className="table_description">
                                                <div>Observação</div>
                                            </th>
                                            <th className="table_lawsuit">
                                                <div>Processo</div>
                                            </th>
                                            <th className="table_value">
                                                <div>Valor</div>
                                            </th>
                                            <th className="table_date ">
                                                <div>Data do Vcto</div>
                                            </th>
                                            <th className="table_date ">
                                                <div>Data do Pgto</div>
                                            </th>
                                            <th className="table_actions">
                                                <div>Ações</div>
                                            </th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                        { ( typeof this.state.outgoings !== "undefined") ? this.state.outgoings.map( (record, index) => this.outgoingItem( {record, index}, 'outgoing-item' ) ) : null }
                                  </tbody>
                          </table></div>;

          let subtitle = <h2 className="box-title">Lista de Contas a Pagar <span className="label-total">&nbsp;Valor Total = R$ {this.state.total}&nbsp;</span></h2>;

            if( this.state.print === 1 ){
                  return <div>
                            <PrintComponent modifiedViewPrint={this.modifiedViewPrint} content={table} title={"Contas a Pagar"} subtitle={subtitle} />
                       </div>
              }else{
                  return <div className="sheet finance outgoing list-contas-pagar">
                            <HeaderBar />
                            <NavigationMenu />
                            <Confirm show={this.state.showConfirm} text={this.state.message} />
                            <FlashMessage time={ new Date().getTime() } />
                            <Loading visible={this.state.loadingVisible} message1={this.state.loadingMessage[0]} message2={this.state.loadingMessage[1]} message3={this.state.loadingMessage[2]} cancel={true}   />
                            <div className="content-wrapper">
                              <div className="content-wrapper-inner">
                                  <section className="content-header">
                                    <h1>Financeiro</h1>
                                  </section>
                                  <div className="panel panel-primary"><div className="panel-heading text-center">
                                        <h4>
                                              Contas a Pagar

                                              <Popover
                                                  isOpen={this.state.isH4PopoverOpen}
                                                  position={['top', 'right', 'left', 'bottom']}
                                                  padding={10}
                                                  onClickOutside={() => this.setState({ isH4PopoverOpen: false })}
                                                  content={({ position, targetRect, popoverRect }) => (
                                                      <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                                                          position={position}
                                                          targetRect={targetRect}
                                                          popoverRect={popoverRect}
                                                          arrowColor={'#94bcd8'}
                                                          arrowSize={10}
                                                      >
                                                          <div className="popover-box"
                                                              onClick={() => this.setState({ isH4PopoverOpen: !this.state.isH4PopoverOpen })}
                                                          >
                                                              Possibilita lançar todas as contas do Escritório, de  Clientes e Processos, separando-as por centro de custo, categorias, quitadas ou não e por períodos.
                                                          </div>
                                                      </ArrowContainer>
                                                  )}
                                              >
                                                  <img onMouseOut={() => this.setState({ isH4PopoverOpen: false })} onMouseOver={() => this.setState({ isH4PopoverOpen: !this.state.isH4PopoverOpen })} className="help_icon" alt="ajuda" src={url_base+"/images/i_branco.png"}></img>
                                              </Popover>
                                        </h4>
                                  </div></div>
                                  <div className="box-body">
                                      <div className="row filter">
                                                  <div className="actions-container">
                                                      <FormOutgoingFinance modifiedOutgoing={this.modifiedOutgoing} token={this.state.token} showModal={this.state.showModal} />
                                                      <BaixarSaida modifiedOutgoing={this.modifiedOutgoing} token={this.state.token} showModal={this.state.showBaixarModal} />
                                                      <span className="btn btn-default align-right btn-print" onClick={(event)=>{this.print(event)} }>
                                                          <i className="fa fa-print"></i> Imprimir
                                                      </span>
                                                      <div className="form-group">
                                                      </div>
                                                  </div>
                                      </div>
                                    <h2 className="box-title">Filtro:</h2>
                                    <div className="row filter">
                                          <div className="form-group date text-left">
                                              <label htmlFor="date_start" className="control-label">De</label>
                                              <DatePicker locale={locale} className="" disabledDate={this.disabledStartDate} placeholder="De" showToday={false} allowClear={false} format="DD/MM/YYYY" value={( this.state.date_start !== null ) ? moment(this.state.date_start, "DD/MM/YYYY") : null} onChange={(date, dateString) => this.onDatePickerChange(date, dateString, "date_start")} />
                                          </div>
                                          <div className="form-group date text-left">
                                              <label htmlFor="date_end" className="control-label">Até</label>
                                              <DatePicker locale={locale} className="" disabledDate={this.disabledEndDate} placeholder="Até" showToday={false} allowClear={false} format="DD/MM/YYYY" value={( this.state.date_end !== null ) ? moment(this.state.date_end, "DD/MM/YYYY") : null} onChange={(date, dateString) => this.onDatePickerChange(date, dateString, "date_end")} />
                                          </div>
                                          <div className="form-group date text-left">
                                              <Select
                                                  className="baixa"
                                                  options={baixaList}
                                                  defaultValue={baixaList[0]}
                                                  value={this.state.baixa}
                                                  name="baixa"
                                                  onChange={this.selectChange}
                                                />
                                          </div>
                                          <div className="form-group date text-left">
                                              <Select
                                                  className="centro-custo-container"
                                                  options={this.state.centroCustoList}
                                                  placeholder="Centro de Custos"
                                                  value={this.state.centroCusto}
                                                  name="centroCusto"
                                                  isClearable={true}
                                                  styles={customStyles}
                                                  onChange={this.selectChange}
                                                />
                                          </div>
                                      <div className="container-search">
                                          <span className="btn btn-primary" onClick={ ( event ) => { this.getOutgoings() } }>Filtrar</span>
                                          <span className="btn btn-danger" data-type={this.state.type} onClick={ ( event ) => { this.clear(event) } } >Limpar</span>
                                      </div>
                                    </div>
                                    <h2 className="box-title">Lista de Contas a Pagar <span className="label-total">&nbsp;Valor Total = R$ {this.state.total}&nbsp;</span></h2>
                                  </div>
                                  {table}
                              </div>
                            </div>
                            <Footer />
                         </div>
          }
  }

}
