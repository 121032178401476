import React from 'react';
import MyPopover from '../containers/mypopover';
import HeaderBar from '../header-bar/header-bar';
import NavigationMenu from '../sidebar/navigation-menu';
import LevelSwitch from "./containers/levelSwitch";
import ResponsibleSwitch from "./containers/responsibleSwitch";
import AreaSwitch from "./containers/areaSwitch";
import FilialSwitch from "./containers/filialSwitch";
import Footer from "../containers/footer";
import Confirm from '../containers/confirm';
import FlashMessage from '../containers/FlashMessage';

import UsersService from '../../services/UsersService';
import LevelService from '../../services/LevelsService';
import AdditionalDataLawsuitService from '../../services/AdditionalDataLawsuitService';
import AuthService from '../../services/AuthService';

import 'antd/dist/antd.css';
import { Tabs } from 'antd';

const TabPane = Tabs.TabPane;

export default class ConfigLevel extends React.Component { _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }


  constructor( { match } ){
      super();

      this.back = this.back.bind(this);

      this.authService = new AuthService();

      this.authService.can('acesso_configuracoes');

      this.state = {
          id: match.params.id,
          user_token: match.params.id,
          loadingMessage: [],
          userList: [],
          areaList: [],
          filialList: [],
          showConfirm: false,
          message: "",
      }
  }

  componentWillReceiveProps(nextProps) {

      if ( nextProps.match.params.id !== this.props.match.params.id) {

          this.getUser( nextProps.match.params.id );
          this.getUsers( nextProps.match.params.id );
          this.getAreas(  );
          this.getFilial(  );

          this.setState({
            user_token: nextProps.match.params.id
          });

      }
  }

  componentDidMount( ) { 
      this._isMounted = true;

      document.addEventListener("cancelDialogConfirm", (e) => {
        if( this._isMounted ){
          this.setState({
            showConfirm: false
          });
        }
      });
  
      document.addEventListener("confirmDialogConfirm", (e) => {
        if( this._isMounted ){
          this.setState({
            showConfirm: false
          });
  
          this.back(e, true);
        }
      });

      this.getUser( this.props.match.params.id );
      this.getUsers( this.props.match.params.id );
      this.getAreas(  );
      this.getFilial(  );
      this.getPermissions();

  }

  getAreas(  ){
      this.additionalDataLawsuitService = new AdditionalDataLawsuitService();

      this.additionalDataLawsuitService.areaList( ).then(areaList =>{

            this.levelService.ruleAreasList( this.state.user_token ).then(ruleAreas =>{
                  //console.log(ruleAreas);

                  areaList.map( (area, i) => {


                      ruleAreas.map((record, index, array ) => {
                        //console.log(user);
                        if(record.lawsuit_area_id === area.value ) {
                          area.allowed = record.allowed;
                        }
                        return record;
                      });
                      return area;
                  });

                  //console.log(areaList);

                  this.setState({
                    areaList: areaList,
                  });

            });

      });
  }

  getFilial(  ){
      this.additionalDataLawsuitService = new AdditionalDataLawsuitService();
      this.levelService = new LevelService();

      this.additionalDataLawsuitService.filialList( ).then(filialList =>{

        this.levelService.filialsList( this.state.user_token ).then(ruleFilials =>{
              //console.log(ruleFilials);

              filialList.map( (filial, i) => {


                  ruleFilials.map((record, index, array ) => {
                    //console.log(user);
                    if(record.office_token === filial.value ) {
                      filial.allowed = record.allowed;
                    }
                    return record;
                  });
                  return filial;
              });

              //console.log(filialList);

              this.setState({
                filialList: filialList,
              });

        });

      });
  }

  getPermissions(){

      this.levelService = new LevelService();

      this.levelService.permissionsList( this.state.user_token ).then(res =>{

        //console.log(res);

        this.setState({
          permissionsList: res,
        });

      });

  }

  getUser( id ){
    this.usersService = new UsersService();

    //console.log( id );
    if( id === '999' ){
      this.setState({
        name: "Junior"
      });
    }

        this.usersService.view( id ).then(res =>{

              this.setState({
                name: res.name
              });

        });
  }

  getUsers( id ){
      this.usersService = new UsersService();
      this.levelService = new LevelService();

      this.usersService.list( ).then(res =>{
        let options = res; let users = [];

        options.map((record, index, array )=>{
          if(record.value !== id ) {
            users.push(record);
          }
          return record;
        });

        this.levelService.responsiblesList( this.state.user_token ).then(responsibles =>{
              //console.log(responsibles);

              users.map( (user, i, users) => {


                  responsibles.map((record, index, array ) => {
                    //console.log(user);
                    if(record.user_token === user.value ) {
                      user.allowed = record.allowed;
                      user.token = record.user_token;
                    }else{
                      user.token = user.value;
                    }
                    return record;
                  });
                  return user;
              });

              //console.log(users);

              this.setState({
                userList: users,
              });

        });

      });
  }

  back( event, r=false ){
    //console.log("oi");
    //let r = window.confirm("Você já configurou tudo o que este usuário poderá Visualizar, Editar e Excluir ?");

    if ( r ){
      this.props.history.goBack();
    }else{
        this.setState({
          showConfirm: true,
          message: "Você já configurou tudo o que este usuário poderá Visualizar, Editar e Excluir ?"
        });
    }
  }

  onSwitchChange = (checked) => {
    //console.log(`switch to ${checked}`);

    if ( checked ){
      this.setState({
        switchRepeat: true,
      });
    }else{
      this.setState({
        switchRepeat: false,
      });
    }
  }

  permissionItem = ( {record, index}, type ) => {

      return (
          <li key={index} className={type}>
                <span>{record.label}</span>
                <span className="acesso">
                  <ResponsibleSwitch user_token={this.state.id} responsible={record.token} active={record.allowed}  />
                </span>
          </li>
      );
  };

  areaItem = ( {record, index}, type ) => {

      return (
          <li key={index} className={type}>
                <span>{record.label}</span>
                <span className="acesso">
                  <AreaSwitch user_token={this.state.id} area={record.value} active={record.allowed}  />
                </span>
          </li>
      );
  };

  filialItem = ( {record, index}, type ) => {

      return (
          <li key={index} className={type}>
                <span>{record.label}</span>
                <span className="acesso">
                  <FilialSwitch user_token={this.state.id} filial={record.value} active={record.allowed}  />
                </span>
          </li>
      );
  };

  filterPermissionItem = ( {record, index}, type ) => {

      return (
          <li key={index} className={type+" "+record.keycode+"-container"}>
            <span>{record.name}</span>
            <span className="acesso"><LevelSwitch permission={record.keycode} action={'allow'} permission_id={record.id} value={record.allowed} user_token={this.state.id}  /></span>
            <span className={ (typeof record.keycode_excluir !== "undefined" ) ? "excluir" : "hide" }>
              <LevelSwitch permission={record.keycode_excluir} permission_id={record.id_excluir} action={'deleted'} value={record.allowed_excluir} user_token={this.state.id}  />
            </span>
          </li>
      );
  };

  render() {

      return <div className="config-user config-level sheet">
                <FlashMessage time={ new Date().getTime() } />
                <Confirm show={this.state.showConfirm} text={this.state.message} />
                <HeaderBar />
                <NavigationMenu />
                <div className="content-wrapper">
                  <div className="content-wrapper-inner">
                      <section className="content-header">
                        <h1 className="page-header">
                           <span onClick={( event ) => { this.back(event)} } className="btn btn-info btn-back">Voltar</span>
                           <span className="span_title">Configurações - Permissões de Acesso</span>
                        </h1>
                      </section>
                      <div className="panel panel-primary">
                            <div className="panel-heading text-center">
                                  <h4>Permissões de acesso do(a) Usuário(a): {this.state.name}</h4>
                            </div>
                      </div>
                      <div className="box-body">
                      <Tabs defaultActiveKey="1" size="large">
                            <TabPane tab={<span><span className="tab-title">Visualização de Processos</span></span>} key="1" >
                                <div className="panel panel-default container-responsavel">
                                          <div className="panel-heading text-center">
                                              <div className="pointer panel-title" data-toggle="collapse"  data-target="#responsavel_target">
                                                  <strong>Responsável</strong>
                                              </div>
                                          </div>
                                          <div className="panel-body collapse in" id="responsavel_target">
                                              <p>Este usuário poderá visualizar os processos do Responsável:    </p>
                                              <ul className="lista_permissoes">
                                                  {this.state.userList.map( (record, index) => this.permissionItem( {record, index}, 'user-item' ) )}
                                              </ul>
                                          </div>
                                </div>

                                <div className="panel panel-default container-areas">
                                          <div className="panel-heading text-center">
                                              <div className="pointer panel-title" data-toggle="collapse" data-target="#areas_target">
                                                  <strong>Áreas</strong>
                                              </div>
                                          </div>
                                          <div className="panel-body collapse in" id="areas_target">
                                              <p>Este usuário poderá visualizar os processos das Áreas:    </p>
                                              <ul className="lista_permissoes">
                                                  {this.state.areaList.map( (record, index) => this.areaItem( {record, index}, 'area-item' ) )}
                                              </ul>
                                          </div>
                                </div>

                                <div className="panel panel-default container-filiais">
                                          <div className="panel-heading text-center">
                                              <div className="pointer panel-title" data-target="#filiais_target">
                                                  <strong>Filiais</strong>
                                              </div>
                                          </div>
                                          <div className="panel-body " id="filiais_target">
                                            <p>Este usuário poderá visualizar os processos das Filiais:    </p>
                                            <ul className="lista_permissoes">
                                                {this.state.filialList.map( (record, index) => this.filialItem( {record, index}, 'filial-item' ) )}
                                            </ul>
                                          </div>
                                </div>
                            </TabPane>
                            <TabPane tab={<span><span className="tab-title">Permissões e Edições</span><MyPopover icon="white" text="ALERTA -Recomenda-se liberar para exclusão dos itens abaixo, somente usúários capacitados, pois a exclusão é irreversível, não tendo como recuperar no banco de dados. Por precaução, todos já aparecem desabilitados, podendo serem  habilitados em qualquer momento." /> </span>} key="2">
                                <div className="panel panel-default container-processos">
                                          <div className="panel-heading text-center">
                                              <div className="pointer panel-title" data-toggle="collapse" data-target="#processos_target">
                                                  <strong>Processos</strong>
                                              </div>
                                          </div>
                                          <div className="panel-body collapse in" id="processos_target">
                                              <p>Este usuário poderá realizar as ações:    </p>
                                              <ul className="lista_permissoes lista_permissoes_processo">
                                                  <li key="header-list"><span></span><span className="acesso"><b>Visualizar e Editar</b></span><span className="excluir"><b>Excluir</b></span></li>
                                                  { ( typeof this.state.permissionsList !== "undefined") ? this.state.permissionsList.processos.map( (record, index) => this.filterPermissionItem( {record, index}, 'processo-item' ) ) : null }
                                              </ul>
                                          </div>
                                </div>

                                <div className="panel panel-default container-agenda">
                                          <div className="panel-heading text-center">
                                              <div className="pointer panel-title" data-toggle="collapse" data-target="#agenda_target">
                                                  <strong>Agenda</strong>
                                              </div>
                                          </div>
                                          <div className="panel-body collapse in" id="agenda_target">
                                              <p>Este usuário poderá realizar as ações:    </p>
                                              <ul className="lista_permissoes lista_permissoes_processo">
                                                  <li key="header-list"><span></span><span className="acesso"><b>Visualizar e Editar</b></span><span className="excluir"><b>Excluir</b></span></li>
                                                  { ( typeof this.state.permissionsList !== "undefined") ? this.state.permissionsList.agenda.map( (record, index) => this.filterPermissionItem( {record, index}, 'agenda-item' ) ) : null }
                                              </ul>
                                          </div>
                                </div>

                                <div className="panel panel-default container-ged">
                                          <div className="panel-heading text-center">
                                              <div className="pointer panel-title" data-toggle="collapse" data-target="#ged_target">
                                                  <strong>GED</strong>
                                              </div>
                                          </div>
                                          <div className="panel-body collapse in" id="ged_target">
                                              <p>Este usuário poderá realizar as ações:    </p>
                                              <ul className="lista_permissoes lista_permissoes_processo">
                                                  <li key="header-list"><span></span><span className="acesso"><b>Visualizar e Editar</b></span><span className="excluir"><b>Excluir</b></span></li>
                                                  { ( typeof this.state.permissionsList !== "undefined") ? this.state.permissionsList.ged.map( (record, index) => this.filterPermissionItem( {record, index}, 'ged-item' ) ) : null }
                                              </ul>
                                          </div>
                                </div>

                                <div className="panel panel-default container-financeiro">
                                          <div className="panel-heading text-center">
                                              <div className="pointer panel-title" data-toggle="collapse" data-target="#financeiro_target">
                                                  <strong>Financeiro</strong>
                                              </div>
                                          </div>
                                          <div className="panel-body collapse in" id="financeiro_target">
                                              <p>Este usuário poderá realizar as ações:    </p>
                                              <ul className="lista_permissoes lista_permissoes_processo">
                                                  <li key="header-list"><span></span><span className="acesso"><b>Visualizar e Editar</b></span><span className="excluir"><b>Excluir</b></span></li>
                                                  { ( typeof this.state.permissionsList !== "undefined") ? this.state.permissionsList.financeiro.map( (record, index) => this.filterPermissionItem( {record, index}, 'financeiro-item' ) ) : null }
                                              </ul>
                                          </div>
                                </div>

                                <div className="panel panel-default container-publicacoes">
                                          <div className="panel-heading text-center">
                                              <div className="pointer panel-title" data-target="#publicacoes_target">
                                                  <strong>Publicações</strong>
                                              </div>
                                          </div>
                                          <div className="panel-body " id="publicacoes_target">
                                              <p>Este usuário poderá realizar as ações:    </p>
                                              <ul className="lista_permissoes lista_permissoes_processo">
                                                  <li key="header-list"><span></span><span className="acesso"><b>Visualizar e Editar</b></span></li>
                                                  { ( typeof this.state.permissionsList !== "undefined") ? this.state.permissionsList.publicacoes.map( (record, index) => this.filterPermissionItem( {record, index}, 'publicacao-item' ) ) : null }
                                              </ul>
                                          </div>
                                </div>

                            </TabPane>
                      </Tabs>
                      </div>
                  </div>
                </div>
                <Footer />
             </div>
  }

}
