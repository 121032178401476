import React from 'react';
import { DatePicker } from 'antd'; import locale from 'antd/es/date-picker/locale/pt_BR';
import { Modal, Button } from 'antd';
import { Switch } from 'antd';
import Popover, { ArrowContainer } from 'react-tiny-popover';
import Select from 'react-select';
import Loading from '../containers/loading';
import Confirm from '../containers/confirm';
import MyPopover from '../containers/mypopover';
import FlashMessage from '../containers/FlashMessage';
import Alert from '../containers/alert';
import chroma from 'chroma-js';

import UsersService from '../../services/UsersService';
import LawsuitService from '../../services/LawsuitService';
import PersonageService from '../../services/LawsuitPersonagesService';
import EventService from '../../services/EventService';
import AuthService from '../../services/AuthService';

import times from '../json/times_options';

import moment from "moment";
//import ptBR from 'antd/lib/locale-provider/pt_BR';
import 'moment/locale/pt-br';

moment.locale('pt-BR');

const prioridade = [
  { value: '1', label: 'Baixa', color: "yellow" },
  { value: '2', label: 'Média', color: "#e69809" },
  { value: '3', label: 'Alta', color: "#FF5630" },
];

const statusList = [
  {value: '1', label: 'Em andamento', color: '#0052CC'},
  {value: '2', label: 'Realizado', color: '#2dd01b'},
  {value: '3', label: 'Cancelado', color: '#000'},
]

let quando_faltar = [{key: 0, value: 0, label: "No dia"}];
for (var i = 1; i < 31; i++){
  quando_faltar[i] = {key: i, value: i, label: i+" dia"}
}

const dot = (color = '#ccc') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 10,
    width: 10,
  },
});

const customStyles = {
  clearIndicator: (provided, state) => {
    const color = 'red';

    return { ...provided, color };
  },
}

const customStyles2 = {
  clearIndicator: (provided, state) => {
    const color = 'red';

    return { ...provided, color };
  },
  control: styles => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? data.color
        : isFocused
        ? color.alpha(0.1).css()
        : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? chroma.contrast(color, 'white') > 2
          ? 'white'
          : 'black'
        : '#333',
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
      },
    };
  },
  input: styles => ({ ...styles, ...dot() }),
  placeholder: styles => ({ ...styles, ...dot() }),
  singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
}

export default class FormEvent extends React.Component { 
  
  _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }

  constructor(props) {
    super(props)

    this.addCategory    = this.addCategory.bind(this);
    this.deleteCategory = this.deleteCategory.bind(this);
    this.selected       = this.selected.bind(this);
    this.repeatClick    = this.repeatClick.bind(this);
    this.onDatePickerChange = this.onDatePickerChange.bind(this);
    this.modifiedEvents = this.props.modifiedEvents.bind(this);
    this.newEvent = this.newEvent.bind(this);
    this.handleCancel = this.handleCancel.bind(this);

    this.personageService = new PersonageService();

    this.authService = new AuthService();

    this.state = {
      checkedItems: new Map(),
      events: props.events,
      loading: false,
      visible: false,
      switchRepeat: false,
      sendEmail: false,
      switchCallClient: false,
      repeat: "0",
      categoryModalVisible: false,
      categoryList: [],
      lawsuitList: [],
      date_start: null,
      time_start: "",
      date_end: null,
      time_end: "",
      time_start_task: "",
      time_end_task: "",
      date_start_task: null,
      date_end_task: null,
      title: "",
      start: "",
      finish_in: null,
      mensagem: "",
      sponsor_notify: "",
      compromisso: 0,
      tarefa: 0,
      notificar: 0,
      emails: "",
      local: "",
      end: "",
      isFormEdit: false,
      edit: true,
      errors: {},
      loadingVisible: false,
      loadingMessage: [],
      clients: [],
      token: "",
      message: "",
      showConfirm: false,
      showAlert: false,
      showModal: false,
      send_to_users: 1,
      lawsuit_token: props.lawsuitToken,
      lawsuitToken: props.lawsuitToken, /*Crio uma segunda state que nao pode ser alterada
       pelo usuario, pra eu poder desabilitar o select caso eu esteja editando o compromisso na tela de processo*/

    }
  }

  componentWillReceiveProps(nextProps) {

      if( nextProps.token !== this.props.token && nextProps.showModal !== this.state.showModal && nextProps.token !== "" ){
        this.setState({
          token: nextProps.token,
          showModal: nextProps.showModal,
          start: nextProps.start,
          end: nextProps.end,
          events: nextProps.events,
        });
        console.log( nextProps.token );
        console.log( this.props.token );
      }

      if ( nextProps.lawsuitToken !== this.props.lawsuitToken && nextProps.showModal !== this.state.showModal ){
        this.setState({
          lawsuit_token: nextProps.lawsuitToken,
          lawsuitToken: nextProps.lawsuitToken,
          showModal: nextProps.showModal,
          start: nextProps.start,
          end: nextProps.end,
          events: nextProps.events,
        });
        console.log( nextProps.lawsuitToken );
        console.log( this.props.lawsuitToken );
      }

  }

  componentDidMount( ) { 
    this._isMounted = true;

    document.addEventListener("cancelDialogConfirm", (e) => {
      if( this._isMounted ){
        this.setState({
          showConfirm: false
        });
      }
    });

    document.addEventListener("confirmDialogConfirm", (e) => {
      if( this._isMounted && this.state.action === "handleCancel" ){
        this.setState({
          showConfirm: false,
        });

        this.handleCancel(e, true);
      }
      if( this._isMounted && this.state.action === "handleDelete" && this.state.eventToDelete !== false ){
        this.setState({
          showConfirm: false,
        });

        this.deleteEvent(e, true);
      }
    });

    document.addEventListener("cancelAlert", (e) => {
        if( this._isMounted ){
          this.setState({
            showAlert: false
          });
        }
    });

    document.addEventListener("cancelLoading", (e) => {
      this.setState({
        loadingVisible: false
      });
    });

    this.usersService = new UsersService();
    this.lawsuitService = new LawsuitService();

    this.usersService.list( ).then(res =>{

          if (this._isMounted) {

                let options = res; let sponsor = []; let sponsorsList = [];

                let user = JSON.parse( localStorage.getItem( 'loggedUser' ) );

                options.map((value, index, array )=>{

                  if ( user.token === value.value ) {
                    if( this.authService.can("agenda_pessoal", true, 'agenda') ){
                      sponsor = value;
                      sponsorsList.push(value);
                    }
                  }else if( this.authService.can("agenda_escritorio", true, 'agenda') ){
                    sponsorsList.push(value);
                  }

                  return value;
                });

                if( this.authService.can("agenda_escritorio", true, 'agenda') ){
                  sponsorsList.unshift ( {value: -99, label: "Todos"} );
                }

                this.setState({
                  sponsorsList: sponsorsList,
                  defaultSponsor: sponsor,
                  sponsor: sponsor
                });

          }

    });

      this.lawsuitService.officeList().then(response =>{

            if (this._isMounted) {

                this.setState({
                  lawsuitList: response
                });

            }

      });

    this.populateSelect();

  }

  componentDidUpdate(){

      if ( this.state.visible === true ) {

        let job = this;

            window.requestAnimationFrame(function() {
                    if( job.state.compromisso === 1 || job.state.tarefa === 1 || job.state.notificar === 1 ){
                      document.getElementById("handleOk").disabled = false;
                      document.getElementById("handleOk2").disabled = false;
                    }else{
                      document.getElementById("handleOk").disabled = true;
                      document.getElementById("handleOk2").disabled = true;
                    }
              });

      }

      if( this.state.showModal === true ){
        this.showModal();
        this.setState({
          showModal: false
        });
      }

  }

  clearFields(){

        if( this._isMounted ){

          var elmnt = document.getElementsByClassName("ant-modal-content");
          if( typeof elmnt[0] !== "undefined" && elmnt[0].offsetHeight >= 750 ){
            document.getElementById("action-buttons-container").className += ' hide';
          }

          if ( document.getElementById("notificar") !== null && document.getElementById("notificar").classList.contains('rbc-active')) {
                this.setState({ notificar: 0 });
                document.getElementById("notificar").classList.remove("rbc-active");
                document.getElementById("notificar-container").className += ' hide';
          }
          if ( document.getElementById("tarefa") !== null && document.getElementById("tarefa").classList.contains('rbc-active')) {
                this.setState({ tarefa: 0 });
                document.getElementById("tarefa").classList.remove("rbc-active");
                document.getElementById("tarefa-container").className += ' hide';
          }
          if ( document.getElementById("compromisso") !== null &&  document.getElementById("compromisso").classList.contains('rbc-active')) {
                this.setState({ compromisso: 0 });
                document.getElementById("compromisso").classList.remove("rbc-active");
                document.getElementById("compromisso-container").className += ' hide';
          }

          if( this.state.switchRepeat === true ){
            document.getElementById("repeat-group").className += ' hide';
            document.getElementById("finish_in_container").className += ' hide';
          }

          if( this.state.sendEmail === true ){
            //document.getElementById("send-email-group").className += ' hide';
            document.getElementById("field-emails").className += ' hide';
          }

          var x = document.getElementsByName("repeat");
          var i;
          for (i = 0; i < x.length; i++) {
            x[i].classList.remove('active');
          }

          this.setState(
            {
              loadingVisible: false,
              loadingMessage: [],
              loading: false,
              visible: false,
              sendEmail: false,
              switchCallClient: false,
              repeat: "0",
              categoryModalVisible: false,
              date_start: null,
              time_start: "",
              date_end: null,
              time_end: "",
              time_start_task: "",
              time_end_task: "",
              date_start_task: null,
              date_end_task: null,
              title: "",
              start: "",
              finish_in: null,
              descricao_tarefa: "",
              description: "",
              emails: "",
              local: "",
              sponsor_task: null,
              sponsor: this.state.defaultSponsor,
              category: null,
              lawsuit: null,
              mensagem: "",
              sponsor_notify: null,
              priority: prioridade[0],
              quando_faltar: quando_faltar[0],
              switchRepeat: false,
              compromisso: 0,
              checkedItems: new Map(),
              tarefa: 0,
              notificar: 0,
              end: "",
              clients: [],
              errors: {},
              token: ""
            }
          );

          this.onSwitchCallClientChange( false );

          this.modifiedEvents();
          
        }

  }

  validateEmail( email ){
        console.log("this is: "+email);
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  addTarefa( parent_token ){

    let time_start_task=""; let time_end_task=""; let user_token = ""; let task_token = "";
    let lawsuit_token = "";

    if ( typeof this.state.time_start_task !== "undefined" ) {
      time_start_task = this.state.time_start_task.value;
    }

    if ( typeof this.state.time_end_task !== "undefined" ) {
      time_end_task = this.state.time_end_task.value;
    }

    //mudar pra passar array de usuários quando o Rafa ajustar pra receber array
    if ( typeof this.state.sponsor_task !== "undefined" ) {
      if( Array.isArray( this.state.sponsor_task ) ){
        user_token = [];
        for(var i=0; i < this.state.sponsor_task.length; i++){
          user_token.push( this.state.sponsor_task[i].value );
        }
      }else{
        user_token = [this.state.sponsor_task.value];
      }

    }

    if ( typeof this.state.lawsuit !== "undefined" && this.state.lawsuit !== null ) {
      lawsuit_token = this.state.lawsuit.value;
    }

    let data = {
      "name": this.state.descricao_tarefa,
      "description": this.state.descricao_tarefa,
      "date_start": this.state.date_start_task,
      "time_start": time_start_task,
      "date_end": this.state.date_end_task,
      "time_end": time_end_task,
      "event_type_id": 2,
      "priority": 1,
      "events_users": user_token,
      "parent_token": parent_token,
      "lawsuit_token": lawsuit_token,
    }

    if(this.state.status_task !== null && typeof this.state.status_task !== "undefined" ){
      data.event_situation_id = parseInt(this.state.status_task.value);
      if( this.state.status_task.value === "2" ){
        data.description += " - Concluído em:"+moment().format("DD/MM/YYYY");
      }
    }else{
      data.event_situation_id = 1;
    }

    if( this.state.titleModal === "Editar Evento" ){

          if( parent_token === null ) {
            task_token = this.state.token;
          }else{
            task_token = this.state.task_token;
          }

    }

    if( task_token !== "" && typeof task_token !== "undefined" ){
      data.token = task_token;

      //console.log(data);

      this.EventService.edit( data ).then(res =>{

            if (this._isMounted) {

                  if( res.success === true ){
                        localStorage.setItem('alert_message', "Tarefa salva com sucesso");
                        localStorage.setItem('alert_type', 'alert-success');
                        let time = new Date().getTime();
                        localStorage.setItem('alert_time', time );

                        let tarefa_token = res.data.Events.token;
                        console.log( tarefa_token );
                        if( this.state.notificar === 1 ){
                            //lógica para salvar notificações relacionadas
                            this.addNotificacao( tarefa_token );
                        }
                        this.modifiedEvents();
                  }else {
                    localStorage.setItem('alert_message', res.message);
                    localStorage.setItem('alert_type', 'alert-danger');
                    let time = new Date().getTime();
                    localStorage.setItem('alert_time', time );
                  }

            }

      });

    }else{
      this.EventService.add( data ).then(res =>{

        if (this._isMounted) {

              if( res.success === true ){
                    localStorage.setItem('alert_message', "Tarefa salva com sucesso");
                    localStorage.setItem('alert_type', 'alert-success');
                    let time = new Date().getTime();
                    localStorage.setItem('alert_time', time );

                    let tarefa_token = res.data.Events.token;
                    console.log( tarefa_token );
                    if( this.state.notificar === 1 ){
                        //lógica para salvar notificações relacionadas
                        this.addNotificacao( tarefa_token );
                    }
                    this.modifiedEvents();
              }else {
                localStorage.setItem('alert_message', res.message);
                localStorage.setItem('alert_type', 'alert-danger');
                let time = new Date().getTime();
                localStorage.setItem('alert_time', time );
              }

        }

      });
    }

  }

  addNotificacao( parent_token ){

    let user_token = "";

    //mudar pra passar array de usuários quando o Rafa ajustar pra receber array
    if ( typeof this.state.sponsor_notify !== "undefined" ) {
      if( Array.isArray( this.state.sponsor_notify ) ){
        user_token = [];
        for(var i=0; i < this.state.sponsor_notify.length; i++){
          user_token.push( this.state.sponsor_notify[i].value );
        }
      }else{
        user_token = [this.state.sponsor_notify.value];
      }

    }

    user_token.map((user, index, array )=>{

      //console.log( moment().format("DD/MM/YYYY") );

      let data = {
        "name": this.state.mensagem,
        "description": this.state.mensagem,
        "date_start": moment().format("DD/MM/YYYY"),
        "time_start": moment().format("HH:mm"),
        "date_end": moment().format("DD/MM/YYYY"),
        "time_end": moment().format("HH:mm"),
        "event_type_id": 3,
        "priority": 1,
        "events_users": [user],
        "event_situation_id": 1,
        "parent_token": parent_token
      }

      if( this.state.notify_token !== "" && typeof this.state.notify_token !== "undefined" ){
            data.token = this.state.notify_token;

            //console.log(data);

            this.EventService.edit( data ).then(res =>{

            });

      }else{

            this.EventService.add( data ).then(res =>{

            });

      }

      return user;

    });

    localStorage.setItem('alert_message', "Notificação salva com sucesso");
    localStorage.setItem('alert_type', 'alert-success');
    let time = new Date().getTime();
    localStorage.setItem('alert_time', time );

    //let compromisso_token = res.data.Events.token;
    //console.log( compromisso_token );
    this.modifiedEvents();

  }

  addCompromisso(){
          let time_start=""; let time_end=""; let priority=""; let event_category_token="";
          let lawsuit_token=""; let user_token=""; let emails = ""; 

          if( this.state.sendEmail === true  ){
          emails = this.state.emails.trim().replace(/\s/g, '');
          }

          if ( typeof this.state.time_start !== "undefined" ) {
            time_start = this.state.time_start.value;
          }

          if ( typeof this.state.time_end !== "undefined" ) {
            time_end = this.state.time_end.value;
          }

          if ( typeof this.state.priority !== "undefined" ) {
            priority = this.state.priority.value;
          }else{
            priority = prioridade[0].value;
          }

          if ( typeof this.state.category !== "undefined" && this.state.category !== null ) {
            event_category_token = this.state.category.value;
          }

          if ( typeof this.state.lawsuit !== "undefined" && this.state.lawsuit !== null ) {
            lawsuit_token = this.state.lawsuit.value;
          }

          //mudar pra passar array de usuários quando o Rafa ajustar pra receber array
          if ( typeof this.state.sponsor !== "undefined" ) {
            if( Array.isArray( this.state.sponsor ) ){
              user_token = [];
              for(var i=0; i < this.state.sponsor.length; i++){
                user_token.push( this.state.sponsor[i].value );
              }
            }else{
              user_token = [this.state.sponsor.value];
            }

          }

          let data = {
            "name": this.state.title,
            "description": this.state.description,
            "date_start": this.state.date_start,
            "time_start": time_start,
            "date_end": this.state.date_end,
            "time_end": time_end,
            "local": this.state.local,
            "event_type_id": 1,
            "priority": priority,
            "event_category_token": event_category_token,
            "lawsuit_token": lawsuit_token,
            "send_email": 1,
            "events_users": user_token,
            'bcc': emails,
            send_to_users: (this.state.send_to_users) ? 1 : 0
          }

          if(this.state.status !== null && typeof this.state.status !== "undefined" ){
            data.event_situation_id = parseInt(this.state.status.value);
          }else{
            data.event_situation_id = 1;
          }

          //console.log( data );

          if( this.state.token !== "" && typeof this.state.token !== "undefined" ){
            data.token = this.state.token;

            this.EventService.edit( data ).then(res =>{

              if (this._isMounted) {

                    if( res.success === true ){

                          localStorage.setItem('alert_message', "Compromisso alterado com sucesso");
                          localStorage.setItem('alert_type', 'alert-success');
                          let time = new Date().getTime();
                          localStorage.setItem('alert_time', time );

                          let compromisso_token = res.data.Events.token;
                          //console.log( compromisso_token );

                          if( this.state.tarefa === 1 ){
                              //lógica para salvar tarefas relacionadas
                              this.addTarefa( compromisso_token );
                          }else if( this.state.notificar === 1 ){
                              //lógica para salvar notificações relacionadas
                              this.addNotificacao( compromisso_token );
                          }
                          this.modifiedEvents();
                    }else{
                          localStorage.setItem('alert_message', res.message);
                          localStorage.setItem('alert_type', 'alert-danger');
                          let time = new Date().getTime();
                          localStorage.setItem('alert_time', time );
                    }

              }

            });
            document.getElementById("close").disabled = false;
            document.getElementById("close2").disabled = false;
          }else{
            this.EventService.add( data ).then(res =>{

                  if (this._isMounted) {

                      if( res.success === true ){
                            localStorage.setItem('alert_message', "Compromisso salvo com sucesso");
                            localStorage.setItem('alert_type', 'alert-success');
                            let time = new Date().getTime();
                            localStorage.setItem('alert_time', time );

                            let compromisso_token = res.data.Events.token;
                            console.log( compromisso_token );

                            if( this.state.tarefa === 1 ){
                                //lógica para salvar tarefas relacionadas
                                this.addTarefa( compromisso_token );
                            }else if( this.state.notificar === 1 ){
                                //lógica para salvar notificações relacionadas
                                this.addNotificacao( compromisso_token );
                            }
                            this.modifiedEvents();
                      }else {
                        localStorage.setItem('alert_message', res.message);
                        localStorage.setItem('alert_type', 'alert-danger');
                        let time = new Date().getTime();
                        localStorage.setItem('alert_time', time );
                      }

                  }

            });
          }

  }

  newEvent = () => {
    this.setState({
      "start": moment(new Date()).endOf('day').format('YYYY-MM-DD'),
      "end": moment(new Date()).endOf('day').format('YYYY-MM-DD'),
    });

    setTimeout(() => {
        this.showModal();
    }, 300);

  }

  showModal = () => {

          let start = this.state.start;
          let end = this.state.end;

          // Formulário de edição
          if( this.state.token !== "" && typeof this.state.token !== "undefined" ){

            this.EventService = new EventService();
            this.EventService.view( this.state.token ).then(res => {

                    if (this._isMounted) {
                                //console.log( res );

                                start = new Date(res.start);
                                end = new Date(res.end);

                                var userTimezoneOffset = start.getTimezoneOffset() * 60000;
                                start = new Date(start.getTime() + userTimezoneOffset);
                                userTimezoneOffset = end.getTimezoneOffset() * 60000;
                                end = new Date(end.getTime() + userTimezoneOffset);

                                let time_start = ('0' + start.getHours()).slice(-2)+':'+('0' + start.getMinutes()).slice(-2);
                                let time_end = ('0' + end.getHours()).slice(-2)+':'+('0' + end.getMinutes()).slice(-2);

                                //console.log(time_start);

                                  time_start = times.filter( (time)=>{ return time.value === time_start } )[0];
                                  time_end = times.filter( (time)=>{ return time.value === time_end } )[0];


                                let lawsuit_token = this.state.lawsuitList.filter( (lawsuit)=>{ return lawsuit.value === res.lawsuit_token })[0];
                                let category_token = this.state.categoryList.filter( (category)=>{ return category.value === res.event_category_token })[0];
                                let sponsors = [];

                                if( typeof res.events_users != "undefined" ){
                                  for ( let x=0; x < res.events_users.length; x++){
                                          this.state.sponsorsList.map( ( record, index, array ) => {

                                                if ( record.value === res.events_users[x].user.token ) {
                                                  sponsors.push(record);
                                                }

                                                return record;

                                          });
                                  }
                                }

                                let status = [];
                                let situation_id = (res.events_situation !== null && typeof res.events_situation !=  "undefined") ? res.events_situation.id.toString() : -1;
                                //console.log(situation_id);
                                //console.log(statusList);
                                status = statusList.filter( (stat)=>{ return stat.value === situation_id } );
                                if( status.length > 0){
                                  status = status[0];
                                }else{
                                  status = statusList[0];
                                }
                                //console.log(status);

                                if(typeof res.event_type !== "undefined" && res.event_type.id === 1){

                                    this.setState({
                                      isFormEdit: true,
                                      link_selected_lawsuit: '/processo/detalhe_processo/'+res.lawsuit_token,
                                      date_start: ('0' + start.getDate()).slice(-2)+'/'+('0' + (start.getMonth()+1)).slice(-2)+'/'+start.getFullYear(),
                                      date_end: ('0' + end.getDate()).slice(-2)+'/'+('0' + (end.getMonth()+1)).slice(-2)+'/'+end.getFullYear(),
                                      time_start: time_start,
                                      time_end: time_end,
                                      title: res.name,
                                      description: res.description,
                                      priority: prioridade[res.priority-1],
                                      local: res.local,
                                      compromisso: 1,
                                      lawsuit: lawsuit_token,
                                      category: category_token,
                                      sponsor: sponsors,
                                      status: status,
                                      emails: res.bcc,
                                      send_to_users: res.send_to_users,
                                      edit: ( this.state.lawsuitToken !== 0 && typeof this.state.lawsuitToken !== "undefined" ) ? false: true,
                                      labelSaveButton: "Salvar",
                                      titleModal: "Editar Evento",
                                      showStatus: true,
                                      visible: true,
                                    });

                                    setTimeout(() => {
                                      document.getElementById("compromisso").className += ' rbc-active';
                                      document.getElementById("compromisso-container").classList.remove("hide");
                                      var elmnt = document.getElementsByClassName("ant-modal-content");


                                      if( elmnt[0].offsetHeight > 750 ){
                                        document.getElementById('action-buttons-container').classList.remove("hide");
                                      }else{
                                        document.getElementById("action-buttons-container").className += ' hide';
                                      }

                                    }, 300);

                                    if( res.child_events.length > 0 ){

                                      /* sponsors = [];
                                      for ( let x=0; x < res.child_events[0].events_users.length; x++){
                                              this.state.sponsorsList.map( ( record, index, array ) => {

                                                    if ( record.value === res.child_events[0].events_users[x].user.token ) {
                                                      sponsors.push(record);
                                                    }

                                                    return record;

                                              });
                                      }  */

                                      let child_status = statusList.filter( (stat)=>{ return stat.value === res.child_events[0].event_situation_id } );
                                      if( child_status.length > 0){
                                        child_status = child_status[0];
                                      }else{
                                        child_status = statusList[0];
                                      }

                                      if ( res.child_events[0].event_type_id === 2 ){
                                              //let status_task = [];

                                              start = new Date(res.child_events[0].start);
                                              end = new Date(res.child_events[0].end);

                                              let userTimezoneOffset = start.getTimezoneOffset() * 60000;
                                              start = new Date(start.getTime() + userTimezoneOffset);
                                              userTimezoneOffset = end.getTimezoneOffset() * 60000;
                                              end = new Date(end.getTime() + userTimezoneOffset);

                                              time_start = ('0' + start.getHours()).slice(-2)+':'+('0' + start.getMinutes()).slice(-2);
                                              time_end = ('0' + end.getHours()).slice(-2)+':'+('0' + end.getMinutes()).slice(-2);

                                              //console.log(time_start);

                                              time_start = times.filter( (time)=>{ return time.value === time_start } )[0];
                                              time_end = times.filter( (time)=>{ return time.value === time_end } )[0];

                                              this.setState({
                                                date_start_task: ('0' + start.getDate()).slice(-2)+'/'+('0' + (start.getMonth()+1)).slice(-2)+'/'+start.getFullYear(),
                                                date_end_task: ('0' + end.getDate()).slice(-2)+'/'+('0' + (end.getMonth()+1)).slice(-2)+'/'+end.getFullYear(),
                                                time_start_task: time_start,
                                                time_end_task: time_end,
                                                descricao_tarefa: res.child_events[0].description,
                                                tarefa: 1,
                                                status_task: child_status,
                                                sponsor_task: sponsors,
                                                task_token: res.child_events[0].token
                                              });

                                              document.getElementById("tarefa").className += ' rbc-active';
                                              document.getElementById("tarefa-container").classList.remove("hide");
                                              var elmnt = document.getElementsByClassName("ant-modal-content");

                                              setTimeout(() => {

                                                if( elmnt[0].offsetHeight > 750 ){
                                                  document.getElementById('action-buttons-container').classList.remove("hide");
                                                }else{
                                                  document.getElementById("action-buttons-container").className += ' hide';
                                                }

                                              }, 300);

                                              this.EventService.view( res.child_events[0].token ).then(task => {

                                                    if (this._isMounted) {

                                                          if( typeof task.child_events !== "undefined" && task.child_events.length > 0 ){

                                                            let sponsors_notify = [];
                                                            /*for ( let x=0; x < task.child_events[0].events_users.length; x++){
                                                                    this.state.sponsorsList.map( ( record, index, array ) => {

                                                                          if ( record.value === task.child_events[0].events_users[x].user.token ) {
                                                                            sponsors_notify.push(record);
                                                                          }

                                                                          return record;

                                                                    });
                                                            }  */

                                                            let notify_status = statusList.filter( (stat)=>{ return stat.value === res.child_events[0].event_situation_id } );
                                                            if( notify_status.length > 0){
                                                              notify_status = notify_status[0];
                                                            }else{
                                                              notify_status = statusList[0];
                                                            }

                                                            this.setState({
                                                              mensagem: task.child_events[0].description,
                                                              notificar: 1,
                                                              sponsor_notify: sponsors_notify,
                                                              notify_token: task.child_events[0].token,
                                                              status_notify: notify_status,
                                                            });

                                                            document.getElementById("notificar").className += ' rbc-active';
                                                            document.getElementById("notificar-container").classList.remove("hide");
                                                            elmnt = document.getElementsByClassName("ant-modal-content");

                                                            setTimeout(() => {

                                                              if( elmnt[0].offsetHeight > 750 ){
                                                                document.getElementById('action-buttons-container').classList.remove("hide");
                                                              }else{
                                                                document.getElementById("action-buttons-container").className += ' hide';
                                                              }

                                                            }, 300);

                                                          }

                                                    }

                                              });


                                      }else{


                                        this.setState({
                                          mensagem: res.child_events[0].description,
                                          notificar: 1,
                                          sponsor_notify: sponsors,
                                          notify_token: res.child_events[0].token,
                                          status_notify: child_status,
                                        });

                                        document.getElementById("notificar").className += ' rbc-active';
                                        document.getElementById("notificar-container").classList.remove("hide");
                                        let elmnt = document.getElementsByClassName("ant-modal-content");

                                        setTimeout(() => {

                                          if( elmnt[0].offsetHeight > 750 ){
                                            document.getElementById('action-buttons-container').classList.remove("hide");
                                          }else{
                                            document.getElementById("action-buttons-container").className += ' hide';
                                          }

                                        }, 300);

                                      }

                                    }

                                }else if (typeof res.event_type !== "undefined" && res.event_type.id === 2){

                                  let status_task = [];

                                  status_task = statusList.filter( (stat)=>{ return stat.value === situation_id } );
                                  if( status_task.length > 0){
                                    status_task = status_task[0];
                                  }else{
                                    status_task = statusList[0];
                                  }

                                  this.setState({
                                    isFormEdit: true,
                                    date_start_task: ('0' + start.getDate()).slice(-2)+'/'+('0' + (start.getMonth()+1)).slice(-2)+'/'+start.getFullYear(),
                                    date_end_task: ('0' + end.getDate()).slice(-2)+'/'+('0' + (end.getMonth()+1)).slice(-2)+'/'+end.getFullYear(),
                                    time_start_task: time_start,
                                    time_end_task: time_end,
                                    descricao_tarefa: res.description,
                                    tarefa: 1,
                                    status_task: status_task,
                                    sponsor_task: sponsors,
                                    edit: ( this.state.lawsuitToken !== 0 && typeof this.state.lawsuitToken !== "undefined" ) ? false: true,
                                    labelSaveButton: "Salvar",
                                    titleModal: "Editar Evento",
                                    showStatus: true,
                                    visible: true,
                                  });

                                  setTimeout(() => {
                                    document.getElementById("tarefa").className += ' rbc-active';
                                    document.getElementById("tarefa-container").classList.remove("hide");
                                    var elmnt = document.getElementsByClassName("ant-modal-content");


                                    if( elmnt[0].offsetHeight > 750 ){
                                      document.getElementById('action-buttons-container').classList.remove("hide");
                                    }else{
                                      document.getElementById("action-buttons-container").className += ' hide';
                                    }

                                  }, 300);

                                }

                    }

            });

          }else if ( typeof start !== "undefined" && start !== "" ){
              //console.log(start); console.log(end);

              start = new Date(start); end = new Date(end);
              var userTimezoneOffset = start.getTimezoneOffset() * 60000;
              start = new Date(start.getTime() + userTimezoneOffset);
              userTimezoneOffset = end.getTimezoneOffset() * 60000;
              end = new Date(end.getTime() + userTimezoneOffset);

              let date_start = ('0' + start.getDate()).slice(-2)+'/'+('0' + (start.getMonth()+1)).slice(-2)+'/'+start.getFullYear();
              let time_start = ('0' + start.getHours()).slice(-2)+':'+('0' + start.getMinutes()).slice(-2);
              let date_end = ('0' + end.getDate()).slice(-2)+'/'+('0' + (end.getMonth()+1)).slice(-2)+'/'+end.getFullYear();
              let time_end = ('0' + end.getHours()).slice(-2)+':'+('0' + end.getMinutes()).slice(-2);
              let date_start_task = date_start;
              let date_end_task = date_end;
              let time_start_task = null;
              let time_end_task = null;

              if( time_start !== "00:00" && time_start !== "03:00" ){
                time_start = times.filter( (time)=>{ return time.value === time_start } )[0];
                time_end = times[time_start.key+2];
                time_start_task = time_start;
                time_end_task = time_end;
              }
              this.setState({
                date_start: date_start,
                date_start_task: date_start_task,
                time_start: time_start,
                time_start_task: time_start_task,
                date_end: date_end,
                date_end_task: date_end_task,
                time_end: time_end,
                time_end_task: time_end_task,
                start: start,
                end: end,
                edit: ( this.state.lawsuitToken !== 0 && typeof this.state.lawsuitToken !== "undefined" ) ? false: true,
                labelSaveButton: "Salvar",
                titleModal: "Inclusão de Evento",
                showStatus: false,
                visible: true
              });
          }

          if ( this.state.lawsuit_token !== 0 ){

                this.state.lawsuitList.map( ( item ) => {
                      if ( item.value === this.state.lawsuit_token ){
                        this.setState({
                          lawsuit: item,
                          labelSaveButton: "Salvar",
                        });
                      }

                     return item;

               });

          }

          this.setState({
            visible: true
          });

    };

  showCategoryModal = () => {

          this.setState({
            categoryModalVisible: true,
          });
    };

  handleOk = () => {
        if (this.validateForm()) {

          this.setState({
            loadingVisible: true,
            loadingMessage: ["O cadastro está sendo efetuado.", "Por gentileza, aguarde...", "Tempo estimado de 15 a 90 segundos"]
          });

          if( this.state.compromisso === 1 ){
                this.setState({
                  loading: true,
                });

                this.addCompromisso();

          }else if( this.state.tarefa === 1 ){
            this.setState({
              loading: true,
            });

            this.addTarefa( null );
          }else if( this.state.notificar === 1 ){
            this.setState({
              loading: true,
            });

            this.addNotificacao( null );
          }

            setTimeout( () => {
              this.clearFields();
            }, 3000);

        }

    };

  handleCancel = ( e, r = false ) => {
      //window.confirm("As alterações serão perdidas, deseja realmente sair?");
      if( r ){

          this.clearFields();

      }else{
        this.setState({
          showConfirm: true,
          action: "handleCancel",
          message: "As alterações serão perdidas, deseja realmente sair?"
        });
      }
  };

  handleCategoryModalOk = () => {

      this.setState({
        categoryModalVisible: false,
      })
  };

  handleCategoryModalCancel = () => {
      this.setState({ categoryModalVisible: false });
  };

  handleChange = (e) => {

          this.setState(
              {
                  [e.target.name]: e.target.value
              }
          )

   }

  onDatePickerChange = (date, dateString, name) => {

   if (dateString === "" ){
     dateString = null;
   }

   if( name === 'date_start'){

     this.setState(
         {
             'date_end': dateString,
         }
     )
   }

   if( name === 'date_start_task'){
     this.setState(
         {
             'date_end_task': dateString,
         }
     )
   }

    this.setState(
        {
            [name]: dateString,
        }
    )

  }

  disabledStartDate = startValue => {
    const endValue = moment(this.state.date_end_task, "DD/MM/YYYY");
    if (!startValue || !endValue) {
      return false;
    }
    return  startValue.valueOf() > endValue.valueOf()  && startValue.valueOf() < moment().endOf('day');
  };

  disabledEndDate = endValue => {
    const startValue = moment(this.state.date_start_task, "DD/MM/YYYY");
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() < startValue.valueOf();
  }

  disabledCompromissoStartDate = startValue => {
    const endValue = moment(this.state.date_end, "DD/MM/YYYY");
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() > endValue.valueOf() ;
  };

  disabledCompromissoEndDate = endValue => {
    const startValue = moment(this.state.date_start, "DD/MM/YYYY");
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() < startValue.valueOf();
  }

  existeCompromisso = () => {
        let existe = false;
        this.state.events.map( (record, index, array) => {
          //testa se tem compromisso no mesmo dia
          if( record.date_start.substr(0,10) === this.state.date_start.split("/").reverse().join("-") && record.token !== this.state.token ){
              //testa se a hora do compromisso é maior ou igual a alguma que tenha cadastrado e se a hora é menor ou igual que a hora de início.
              //console.log(moment(record.date_start.substr(0,16)).valueOf());
              //console.log(moment( this.state.date_start.split("/").reverse().join("-")+" "+times[selected.key].label ).valueOf());

              if( moment( this.state.date_start.split("/").reverse().join("-")+" "+this.state.time_start.label ).valueOf() >=  moment(record.date_start.substr(0,16)).valueOf() &&
                   moment( this.state.date_start.split("/").reverse().join("-")+" "+this.state.time_start.label ).valueOf()  <= moment(record.date_end.substr(0,16)).valueOf() ){

                     //console.log("compromissos que deram match");
                     //console.log( record );
                     record.events_users.map( (user, index, array) => {
                       if( Array.isArray( this.state.sponsor ) ){
                         this.state.sponsor.map( ( sponsor, i, arr) => {
                           //console.log(sponsor);
                            if( user.user.token === sponsor.value ){
                              existe = true;
                            }
                            return sponsor;
                         });
                       }else{
                         //console.log(this.state.sponsor);
                          if( user.user.token === this.state.sponsor.value ){
                            existe = true;
                          }
                       }

                       return user;

                     });

             }else if( moment( this.state.date_start.split("/").reverse().join("-")+" "+this.state.time_start.label ).valueOf() <= moment(record.date_start.substr(0,16)).valueOf() &&
                        moment( this.state.date_end.split("/").reverse().join("-")+" "+this.state.time_end.label ).valueOf() >= moment(record.date_start.substr(0,16)).valueOf()  ){

                          //console.log("deu match");
                          record.events_users.map( (user, index, array) => {
                            if( Array.isArray( this.state.sponsor ) ){
                              this.state.sponsor.map( ( sponsor, i, arr) => {
                                //console.log(sponsor);
                                 if( user.user.token === sponsor.value ){
                                   existe = true;
                                 }
                                 return sponsor;
                              });
                            }else{
                              //console.log(this.state.sponsor);
                               if( user.user.token === this.state.sponsor.value ){
                                 existe = true;
                               }
                            }
                            return user;
                          });

             }
          }

          return record;
        });
        //console.log("existe? "+existe);
        return existe;
  }

  selectChange = (selected, actionMeta) => {
       let pops = [];
       pops[ actionMeta.name ] = selected;

       if ( typeof actionMeta.name !== undefined && actionMeta.name !== "" && actionMeta.name !== null ){

         if ( typeof selected !== undefined && selected !== "" ) {

           if( actionMeta.name === "time_start_task" ){
             this.setState( {"time_end_task": times[selected.key+60]} );
           }
           if( actionMeta.name === "time_start" ){
             this.setState( {"time_end": times[selected.key+60]} );
           }
           if( actionMeta.name === "quando_faltar" ){

               selected.sort(function(a, b) { return a.value - b.value  });
               pops[ actionMeta.name ] = selected;

           }
           if( actionMeta.name === "lawsuit" ){
             this.setState( {"switchCallClient": false, "checkedItems": new Map() } );
             this.onSwitchCallClientChange( false );

             if(selected !== null){
              this.setState( {link_selected_lawsuit: '/processo/detalhe_processo/'+selected.value} );
              //document.getElementById("link_selected_lawsuit").classList.remove("hide");
             }else{
               if( !document.getElementById("link_selected_lawsuit").classList.contains('hide') ){
                //document.getElementById("link_selected_lawsuit").classList.add('hide');
               }
             }

           }
           if( actionMeta.name === "status" ){
            document.getElementById("close").disabled = true;
            document.getElementById("close2").disabled = true;
           }
           this.setState( pops );

           setTimeout(() => {
             if( this.existeCompromisso() ){
               let errors = {};
               errors["time_start"] = "*Neste horário já existe compromisso para um dos usuários, consulte a Agenda!";
               this.setState({
                  errors: errors
                });
             }else{
               //console.log("agora não tem mais");
               this.setState({
                  errors: {}
                });
             }
           }, 500);

         }

       }
  }

  sponsorSelectChange = (selected, actionMeta) => {
       let pops = [];
       pops[ actionMeta.name ] = selected;

       selected.map( ( record, index, array ) => {
             if ( record.value === -99 ) {
               let list = this.state.sponsorsList;
               var filtered = list.filter(function(value, index, arr){
                    if( value.value !== -99 ){
                      return value
                    }else{
                      return null;
                    }
                });
               pops[ actionMeta.name ] = filtered;
             }

             return record;

       });

       if ( typeof actionMeta.name !== undefined && actionMeta.name !== "" && actionMeta.name !== null ){

         if ( typeof selected !== undefined && selected !== "" && selected !== null ) {
           this.setState( pops );
         }

       }
       //console.log(pops);
       setTimeout(() => {
         if( this.existeCompromisso() ){
           let errors = {};
           errors["time_start"] = "*Neste horário já existe compromisso para um dos usuários, consulte a Agenda!";
           this.setState({
              errors: errors
            });
         }else{
           console.log("agora não tem mais");
           this.setState({
              errors: {}
            });
         }
       }, 500);
  }

  handleSelect = ({ start, end }) => {
      const title = window.prompt('New Event name')
      if (title)
        this.setState({
          events: [
            ...this.state.events,
            {
              start,
              end,
              title,
            },
          ],
        })
  }

  addCategory = (e) => {
      this.EventService = new EventService();
      document.body.style.cursor = "wait";
      let category = {
        name: document.getElementById("categoryName").value
      }
      this.EventService.addCategory( category ).then(res =>{

        this.EventService.categoryList( ).then(res =>{

              if (this._isMounted) {

                  document.getElementById("categoryName").value = "";
                  document.body.style.cursor = "default";
                  this.setState({
                    categoryList: res,
                  });

              }

        });

      });
  }

  deleteCategory = (e) => {
      this.EventService = new EventService();
      document.body.style.cursor = "wait";

      let id = document.getElementById("categorySettings").value;

      //console.log( id );

      this.EventService.deleteCategory( id ).then(res =>{

        this.EventService.categoryList( ).then(res =>{

              if (this._isMounted) {
                  document.body.style.cursor = "default";

                  this.setState({
                    categoryList: res,
                  });
              }

        });

      });
  }

  populateSelect = () => {
      document.body.style.cursor = "wait";
      let categoryList = [];

      this.EventService = new EventService();

        this.EventService.categoryList( ).then(res =>{

            if (this._isMounted) {
                categoryList = res;

                //categoryList.unshift({value: -9999, label: "Não Definido"});
                document.body.style.cursor = "default";

                this.setState({
                  categoryList: categoryList,
                });
            }

        });

        this.EventService.situationsList( ).then(res =>{
              /*categoryList = res;

              //categoryList.unshift({value: -9999, label: "Não Definido"});
              document.body.style.cursor = "default";

              this.setState({
                categoryList: categoryList,
              });*/

        });
  }

  onSwitchChange = (checked) => {
      //console.log(`switch to ${checked}`);
      if ( checked ){
        document.getElementById("repeat-group").classList.remove("hide");
        document.getElementById("finish_in_container").classList.remove("hide");
        this.setState({
          switchRepeat: true,
        });
      }else{
        document.getElementById("repeat-group").className += ' hide';
        document.getElementById("finish_in_container").className += ' hide';
        this.setState({
          switchRepeat: false,
        });
      }
  }

  onSwitchSendToUsersChange = (checked) => {
    this.setState({
      send_to_users: checked,
    });
  }

  onSwitchCallClientChange = (checked) => {
      if ( checked ){

        if ( typeof  this.state.lawsuit === "undefined" || this.state.lawsuit === null ){
          //alert("Selecione o processo");
          this.setState({
            showAlert: true,
            message: "Selecione o processo"
          });
        }else{
                document.body.style.cursor = "wait";
                this.lawsuitService.get( this.state.lawsuit.value ).then( res =>{

                      if (this._isMounted) {

                          var personages = [];

                          res.lawsuits_personages.map( (value, index, array ) => {
                                document.body.style.cursor = "wait";

                                if( value.personage_side_id === 1 ){

                                      this.personageService.view( value.personage.token ).then ( response =>{

                                          let person = {name: "", email: ""};
                                          person.name = value.personage.name;

                                          let emails = "";

                                            response.personage_contacts.map( (contact, i, arr ) => {
                                                if( contact.personage_contact_type_id === 3 ){

                                                  if( emails === "" ){
                                                    emails = contact.value;
                                                  }else{
                                                    emails += ", "+contact.value;
                                                  }


                                                }
                                                return contact;
                                            });

                                            person.email = emails;

                                            personages.push( person );

                                            this.setState({
                                              clients: personages,
                                              switchCallClient: true
                                            });
                                            document.body.style.cursor = "default";
                                            document.getElementById("clientes_container").classList.remove("hide");
                                      });

                                }

                                return value;
                          });

                      }

                });
          }

      }else{
        this.setState({
          switchCallClient: false,
        });
        //document.getElementById("clientes_container").className += ' hide';
      }
  }

  onSwitchSendEmailChange = (checked) => {
      if ( checked ){
        //document.getElementById("send-email-group").classList.remove("hide");
        document.getElementById("field-emails").classList.remove("hide");
        //document.getElementById("clientes_container").classList.remove("hide");
        this.setState({
          sendEmail: true,
        });
      }else{
        //document.getElementById("send-email-group").className += ' hide';
        document.getElementById("field-emails").className += ' hide';
        //document.getElementById("clientes_container").className += ' hide';
        this.setState({
          sendEmail: false,
        });

      }
  }

  selected = (event) => {

    console.log( this.state.isFormEdit );

    if(document.getElementById(event.target.id) !== null ){
            if (document.getElementById(event.target.id).classList.contains('rbc-active') && !this.state.isFormEdit ) {
                  // do some stuff
                  document.getElementById(event.target.id).classList.remove("rbc-active");
                  document.getElementById(event.target.id+"-container").className += ' hide';
                  this.setState(
                      {
                          [event.target.id]: 0
                      }
                  )
            }else{
                  document.getElementById(event.target.id).className += ' rbc-active';
                  document.getElementById(event.target.id+"-container").classList.remove("hide");
                  this.setState(
                      {
                          [event.target.id]: 1
                      }
                  )
            }

            var elmnt = document.getElementsByClassName("ant-modal-content");

            //console.log(elmnt);
            if( elmnt[0].offsetHeight > 750 ){
              document.getElementById('action-buttons-container').classList.remove("hide");
            }else{
              document.getElementById("action-buttons-container").className += ' hide';
            }
    }

  }

  repeatClick = (event) => {

      var x = document.getElementsByName("repeat");
      var i;
      for (i = 0; i < x.length; i++) {
        x[i].classList.remove('active');
      }

      event.target.className += ' active';

      this.setState(
          {
              [event.target.name]: event.target.value
          }
      )

    }

  validateForm = () => {
        let errors = {};
        let formIsValid = true;

        if( this.state.compromisso === 1){
              if ( typeof this.state.sponsor === "undefined" || this.state.sponsor === null ) {
                formIsValid = false;
                errors["sponsor"] = "*Escolha o usuário que você quer atribuir o compromisso";
              }

              if ( this.state.description === 0 || this.state.description === "" || typeof this.state.description === "undefined" ) {
                formIsValid = false;
                errors["description"] = "*Forneça a descrição do compromisso";
              }

              if ( this.state.title === 0 || this.state.title === "" || typeof this.state.title === "undefined" ) {
                formIsValid = false;
                errors["title"] = "*Digite um título para o compromisso";
              }

              if ( this.state.category === 0 || this.state.category === "" || typeof this.state.category === "undefined" || this.state.category === null )  {
                formIsValid = false;
                errors["category"] = "*Selecione a categoria";
              }

              if ( this.state.date_start === 0 || this.state.date_start === "" || typeof this.state.date_start === "undefined" || this.state.date_start === null ) {
                formIsValid = false;
                errors["date_start"] = "*Preencha a data";
              }

              if( moment( this.state.date_start, 'DD/MM/YYYY' ).isBefore(new Date(), 'day') && !this.state.isFormEdit  ){
                formIsValid = false;

                errors["date_start"] = "*A data deve ser posterior a data atual";
              }

              if ( typeof this.state.time_start === "undefined" || this.state.time_start === null || this.state.time_start === "" ) {
                formIsValid = false;
                errors["time_start"] = "*Preencha a hora";
              }

              if( this.existeCompromisso() ){
                formIsValid = false;
                errors["time_start"] = "*Neste horário já existe compromisso para um dos usuários, consulte a Agenda!";
              }

              let date_init = moment( this.state.date_start+" "+this.state.time_start.value+":00", 'DD/MM/YYYY HH:mm:ss' ).add(1, 'hours');

              if( date_init.isBefore(new Date() ) && !this.state.isFormEdit ){
                formIsValid = false;

                errors["time_start"] = "*A hora deve ser posterior à hora atual";
              }

              if ( this.state.date_end === 0 || this.state.date_end === "" || typeof this.state.date_end === "undefined" || this.state.date_end === null ) {
                formIsValid = false;
                errors["date_end"] = "*Preencha a data";
              }

              if ( typeof this.state.time_end === "undefined" || this.state.time_end === null || this.state.time_end === "" ) {
                formIsValid = false;
                errors["time_end"] = "*Preencha a hora";
              }

              if ( this.state.switchRepeat === true && (this.state.repeat > 0 && this.state.repeat < 5 ) && ( this.state.finish_in === 0 || this.state.finish_in === "" || typeof this.state.finish_in === "undefined" || this.state.finish_in === null ) ) {
                formIsValid = false;
                errors["finish_in"] = "*Preencha a data";
              }

              if( this.state.switchRepeat === true && (this.state.repeat > 0 && this.state.repeat < 5 ) && moment( this.state.finish_in, 'DD/MM/YYYY' ).isBefore(new Date(), 'day')  ){
                formIsValid = false;

                errors["finish_in"] = "*A data deve ser posterior a data atual";
              }
              //console.log(this.state.switchCallClient); console.log(this.state.checkedItems);

              if ( this.state.switchCallClient === true &&  this.state.checkedItems.size === 0 ){
                formIsValid = false;
                //console.log(this.state.checkedItems);
                errors["email_client"] = "*Você deve selecionar pelo menos um cliente para receber o email";
              }

              if( this.state.sendEmail === true && this.state.switchCallClient === true && this.state.checkedItems.size === 0 && this.state.emails === "" ){
                formIsValid = false;
                errors["emails"] = "*Não foi selecionado cliente ou acrescentado email adicional para receber e-mails deste compromisso";
              }

              if( this.state.sendEmail === true && this.state.switchCallClient === false && this.state.emails === "" ){
                formIsValid = false;
                errors["emails"] = "*Não foi acrescentado email adicional para receber e-mails deste compromisso";
              }

              if( this.state.sendEmail === true  ){

                  let emails_array = []; let emails = "";

                  emails = this.state.emails.trim().replace(/\s/g, '');

                  emails_array = emails.split(',');

                  console.log( emails_array );

                  if(emails_array.length > 0){
                    for(let i=0; i < emails_array.length; i++){
                      if( !this.validateEmail( emails_array[i] ) ){
                        formIsValid = false;
                        if(typeof errors["emails"] == "undefined"){
                          errors["emails"] = "";
                        }
                        errors["emails"] += "\n *O email "+emails_array[i]+" é inválido";
                      }
                    }
                  }
              }
        }

        if( this.state.tarefa === 1){

              if ( typeof this.state.sponsor_task === "undefined" || this.state.sponsor_task === null ) {
                formIsValid = false;
                errors["sponsor_task"] = "*Escolha o usuário que você quer atribuir a tarefa";
              }

              if ( this.state.descricao_tarefa === 0 || this.state.descricao_tarefa === "" || typeof this.state.descricao_tarefa === "undefined" ) {
                formIsValid = false;
                errors["descricao_tarefa"] = "*Forneça a descrição da tarefa";
              }

              if ( this.state.date_start_task === 0 || this.state.date_start_task === "" || typeof this.state.date_start_task === "undefined" || this.state.date_start_task === null ) {
                formIsValid = false;
                errors["date_start_task"] = "*Preencha a data";
              }

              if( moment( this.state.date_start_task, 'DD/MM/YYYY' ).isBefore(new Date(), 'day') && this.state.edit===false  ){
                formIsValid = false;

                errors["date_start_task"] = "*A data deve ser posterior a data atual";
              }

              if ( typeof this.state.time_start_task === "undefined" || this.state.time_start_task === null || this.state.time_start_task === "" ) {
                formIsValid = false;
                errors["time_start_task"] = "*Preencha a hora";
              }

              if( formIsValid && moment( this.state.date_start_task+" "+this.state.time_start_task.value+":00", 'DD/MM/YYYY HH:mm:ss' ) < moment(new Date(), 'DD/MM/YYYY HH:mm' ) && !this.state.isFormEdit  ){
                formIsValid = false;

                errors["time_start_task"] = "*A hora deve ser posterior à hora atual";
              }

              if ( this.state.date_end_task === 0 || this.state.date_end_task === "" || typeof this.state.date_end_task === "undefined" || this.state.date_end_task === null ) {
                formIsValid = false;
                errors["date_end_task"] = "*Preencha a data";
              }

              if ( typeof this.state.time_end_task === "undefined" || this.state.time_end_task === null || this.state.time_end_task === "" ) {
                formIsValid = false;
                errors["time_end_task"] = "*Preencha a hora";
              }

        }

        if( this.state.notificar === 1){

              if ( typeof this.state.sponsor_notify === "undefined" || this.state.sponsor_notify === null ) {
                formIsValid = false;
                errors["sponsor_notify"] = "*Escolha o usuário que você quer notificar";
              }

              if ( this.state.mensagem === 0 || this.state.mensagem === "" || typeof this.state.mensagem === "undefined" ) {
                formIsValid = false;
                errors["mensagem"] = "*Digite uma mensagem";
              }

        }

        this.setState({
           errors: errors
         });

        return formIsValid;

    }

  checkboxChange = (e) => {
    const item = e.target.name;
    const isChecked = e.target.checked;
    this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(item, isChecked) }));
  }

  clientItem = ( record, index ) => {
    let email = (record.email === "") ? " - Sem email cadastrado" : " <"+record.email+">";
    return (
      <label className={ (record.email === "") ? "red": "client_emails"} key={index}>
            <input type="checkbox" name={record.email} ref={React.createRef()} checked={ (typeof this.state.checkedItems.get(record.email)  === "undefined" ) ? "" : this.state.checkedItems.get(record.email) } onChange={this.checkboxChange} disabled={ (record.email === "") ? true : false } />
            {record.name+email}
      </label>


    );
  }

  deleteEvent = (event, r=false ) => {

    //let result = window.confirm( "Deseja excluir definitivamente este compromisso?");

    if ( r ) {

      this.EventService = new EventService();

      this.EventService.delete( this.state.token ).then(res =>{

          if( res.success === true ){

              localStorage.setItem('alert_message', "Evento removido com sucesso.");
              localStorage.setItem('alert_type', 'alert-success');
              let time = new Date().getTime();
              localStorage.setItem('alert_time', time );

              this.clearFields();

              this.modifiedEvents();

          }else{
              //alert(res.message);
          }

      });

    }else{
      this.setState({
        showConfirm: true,
        eventToDelete: true,
        action: "handleDelete",
        message: "Deseja excluir definitivamente este compromisso?"
      });
    }

  }

  onLowerChange(date, dateString, label) {
    console.log(date, dateString);
  }

  hourFilter = (option, inputValue) => {

    if( inputValue.length <= 2  ){
      if(  option.label.indexOf(inputValue) == 0 ){
        return option;
      }else{
        return false;
      }
    }else{
      if(  option.label.indexOf(inputValue) >= 0 ){
        return option;
      }else{
        return false;
      }
    }
  };

  render() {

      const { visible, loading } = this.state;
      var arrCategory = [];
      for (var k = 0; k < this.state.categoryList.length; k++) {
          arrCategory.push(<option key={k} value={this.state.categoryList[k].value}> {this.state.categoryList[k].label} </option>);
      }

      let date_end_disabled = true;
      if( this.state.date_start !== null ){
        date_end_disabled = false;
      }

      let time_end_disabled = true;
      if( this.state.time_start !== null && this.state.time_start !== "" ){
        time_end_disabled = false;
      }

      let date_end_task_disabled = true;
      if( this.state.date_start_task !== null ){
        date_end_task_disabled = false;
      }

      let time_end_task_disabled = true;
      if( this.state.time_start_task !== null && this.state.time_start_task !== "" ){
        time_end_task_disabled = false;
      }

      var url_base = localStorage.getItem('url_base');

      //console.log(this.state.edit);
      //console.log(this.state.lawsuit_token);

      //função de enviar por email temporariamente removida
      /*


                            <div className="btn-group col-md-12 hide" id="send-email-group" data-toggle="buttons">
                                  <label htmlFor="sendEmail" className="control-label">Avisar Cliente no dia do compromisso</label>
                                  <Switch defaultChecked={false} checked={this.state.switchCallClient} onChange={this.onSwitchCallClientChange } />
                                  <MyPopover icon="white" text='Selecione o cliente que deverá receber o aviso deste compromisso. Os E-mails devem estar devidamente cadastrados na ficha do Cliente. Todas as alterações dos compromissos serão reavisados automaticamente. Estes E-mails serão enviados nas primeiras horas do dia do compromisso.' />
                                  <label htmlFor="quando_faltar" className="control-label hide">Quando Faltar</label>
                                  <Select
                                      isMulti
                                      styles={customStyles}
                                      className="quando_faltar hide"
                                      options={quando_faltar}
                                      defaultValue={quando_faltar[0]}
                                      placeholder="Selecione"
                                      value={this.state.quando_faltar}
                                      name="quando_faltar"
                                      onChange={this.selectChange}
                                    />
                            </div>
                            <div id="clientes_container" className="hide">
                                  { this.state.clients.map( (record, index) =>
                                    this.clientItem( record, index ) )
                                  }
                                  <div className="errorMsg">{this.state.errors.email_client}</div>
                            </div>
                  

      */

      return <span>
                <FlashMessage time={ new Date().getTime() } />
                <Confirm show={this.state.showConfirm} text={this.state.message} />
                <Alert show={this.state.showAlert} text={this.state.message} />
                <Loading visible={this.state.loadingVisible} message1={this.state.loadingMessage[0]} message2={this.state.loadingMessage[1]} message3={this.state.loadingMessage[2]} cancel={true}  />
                <span className="btn btn-primary form-submit add-event" onClick={this.newEvent}>Incluir</span>
                <Modal
                  visible={visible}
                  title={this.state.titleModal}
                  className="formCalendar"
                  onOk={this.handleOk}
                  onCancel={(event) => {this.handleCancel(event)} }
                  footer={[
                    <Button key="submit" id="handleOk" type="primary" loading={loading} onClick={this.handleOk}>
                      {this.state.labelSaveButton}
                    </Button>,
                    <Button key="delete" id="delete" className={( this.authService.can('agenda_processo_excluir', true, 'agenda') && this.state.isFormEdit ) ? "btn-light" : "hide" } onClick={( event ) => this.deleteEvent( event )}>
                      <span style={{color: "#f10"}}>Excluir</span>
                    </Button>,
                    <Button key="back" id="close" className="btn-danger" onClick={(event) => {this.handleCancel(event)} }>
                      Fechar
                    </Button>,
                  ]}
                >

                  <div id="typeEventContainer" className="rbc-toolbar form-group col-md-12">
                      <label htmlFor="rbc" className="control-label">
                            Selecione os eventos que deseja criar
                            <MyPopover icon="white" text="Permite criar Compromisso, Tarefa e Notificar no mesmo evento e direcioná-los para usuários diferentes." />                            
                      </label>
                      <span className="rbc-btn-group">
                          <button id="compromisso" type="button" onClick={this.selected}>
                                Compromisso
                                <MyPopover icon="white" text="Ao agendar um compromisso, ocupará horário, enviará e-mails para todos os usuários selecionados, com lembretes nos gráficos na tela Inicial." />                                
                          </button>
                          <button id="tarefa" type="button" onClick={this.selected}>
                                Tarefa
                                <MyPopover icon="white" text="Crie Tarefas para usuários, sem ocupar horrários especificos nas agendas,  com lembretes nos gráficos na tela inicial." /> 
                          </button>
                          <button id="notificar" type="button" onClick={this.selected}>
                                Notificar
                                <MyPopover icon="white" text="Notificar, dar ciência a um ou vários usuários sobre um compromisso, tarefa ou acontecimento. Lembrete no sininho na tela inicial." /> 
                          </button>
                      </span>
                  </div>
                  <div id="action-buttons-container" className="action-buttons-container hide">
                      <Button id="handleOk2" type="primary" onClick={this.handleOk}>
                        {this.state.labelSaveButton}
                      </Button>
                      <Button id="delete2" className={( this.authService.can('agenda_processo_excluir', true, 'agenda') && this.state.isFormEdit ) ? "btn-light" : "hide" } onClick={( event ) => this.deleteEvent( event )}>
                        <span style={{color: "#f10"}}>Excluir</span>
                      </Button>
                      <Button id="close2" className="btn-danger" onClick={(event) => {this.handleCancel(event)} }>
                        Fechar
                      </Button>
                  </div>
                  <div id="notificar-container" className="fieldset hide">
                        <span className="form-group col-md-12 title-container">Notificar</span>
                        <div className="form-group col-md-12">
                            <label htmlFor="sponsor_notify">
                                  Incluir Usuários<span className="text-red">*</span>
                                  <MyPopover icon="white" text="Escolha usuários para receber uma Notificação deste Compromisso, Tarefa ou somente Notificar sobre um assunto. Estes serão sinalizados na tela inicia (Sininho). Estes não receberão e-mails de aviso." /> 
                            </label>
                            <Select
                                isMulti
                                styles={customStyles}
                                className="sponsor"
                                placeholder="Selecione"
                                options={this.state.sponsorsList}
                                value={this.state.sponsor_notify}
                                name="sponsor_notify"
                                onChange={this.sponsorSelectChange}
                              />
                              <div className="errorMsg">{this.state.errors.sponsor_notify}</div>
                        </div>
                        <div className="form-group col-md-12 text-left">
                              <label htmlFor="mensagem" className="control-label">Mensagem<span className="text-red">*</span></label>
                              <textarea name="mensagem" id="mensagem" rows="3" className="form-control" value={this.state.mensagem} onChange={this.handleChange}></textarea>
                              <div className="errorMsg">{this.state.errors.mensagem}</div>
                        </div>
                  </div>
                  <div id="tarefa-container" className="fieldset hide">
                        <span className="form-group col-md-12 title-container">Tarefa</span>
                        <div className="form-group col-md-12">
                            <label htmlFor="sponsor_task">
                                  Incluir Usuários<span className="text-red">*</span>
                                  <MyPopover icon="white" text="Escolha quais usuários participarão desta Tarefa, e estes serão sinalizados nos graficos da tela inicial. Estes não receberão e-mails de aviso." />                               
                            </label>
                            <Select
                                isMulti
                                styles={customStyles}
                                className="sponsor"
                                options={this.state.sponsorsList}
                                placeholder="Selecione"
                                value={this.state.sponsor_task}
                                name="sponsor_task"
                                onChange={this.sponsorSelectChange}
                              />
                              <div className="errorMsg">{this.state.errors.sponsor_task}</div>
                        </div>
                        <div className="form-group col-md-3 col-xs-6 col-sm-3 text-left">
                            <label htmlFor="date_start_task" className="control-label">Data<span className="text-red">*</span></label>
                            <DatePicker locale={locale} className=" " placeholder="Data Inicial" showToday={false} allowClear={false} format="DD/MM/YYYY" value={( this.state.date_start_task !== null ) ? moment(this.state.date_start_task, "DD/MM/YYYY") : null} onChange={(date, dateString) => this.onDatePickerChange(date, dateString, "date_start_task")} />
                            <div className="errorMsg">{this.state.errors.date_start_task}</div>
                        </div>
                        <div className="form-group col-md-3 col-xs-6 col-sm-3 text-left">
                            <label htmlFor="time_start_task" className="control-label">Hora<span className="text-red">*</span></label>
                            <Select
                                placeholder="Selecione"
                                styles={customStyles}
                                options={times}
                                value={this.state.time_start_task}
                                name="time_start_task"
                                onChange={this.selectChange}
                                filterOption={ (option, inputValue)=>{return this.hourFilter(option, inputValue)}  }
                              />
                              <div className="errorMsg">{this.state.errors.time_start_task}</div>
                        </div>
                        <div className="form-group col-md-3 col-xs-6 col-sm-3 text-left">
                            <label htmlFor="date_end_task" className="control-label">Data Limite<span className="text-red">*</span></label>
                            <DatePicker locale={locale} className=" " disabled={date_end_task_disabled} placeholder="Data Limite" showToday={false} disabledDate={this.disabledEndDate} allowClear={false} format="DD/MM/YYYY" value={( this.state.date_end_task !== null ) ? moment(this.state.date_end_task, "DD/MM/YYYY") : null} onChange={(date, dateString) => this.onDatePickerChange(date, dateString, "date_end_task")} />
                            <div className="errorMsg">{this.state.errors.date_end_task}</div>
                        </div>
                        <div className="form-group col-md-3 col-xs-6 col-sm-3 text-left">
                            <label htmlFor="time_end_task" className="control-label">Hora Limite<span className="text-red">*</span></label>
                            <Select
                                placeholder="Selecione"
                                styles={customStyles}
                                options={times}
                                isDisabled={time_end_task_disabled}
                                value={this.state.time_end_task}
                                name="time_end_task"
                                onChange={this.selectChange}
                                filterOption={ (option, inputValue)=>{return this.hourFilter(option, inputValue)}  }
                              />
                              <div className="errorMsg">{this.state.errors.time_end_task}</div>
                        </div>
                        <div className={ (this.state.compromisso === 1) ? "hide" : "form-group col-md-5" }>
                            <label htmlFor="lawsuit">Processo</label>
                            <Select
                                className="lawsuit"
                                styles={customStyles}
                                options={this.state.lawsuitList}
                                placeholder="Selecione"
                                name="lawsuit"
                                value={this.state.lawsuit}
                                isClearable={this.state.edit}
                                isDisabled={!this.state.edit}
                                onChange={this.selectChange}
                              />
                        </div>
                        <div className="form-group col-md-9 text-left">
                              <label htmlFor="descricao_tarefa" className="control-label">Observação<span className="text-red">*</span></label>
                              <textarea name="descricao_tarefa" id="descricao_tarefa" rows="3" value={this.state.descricao_tarefa} className="form-control" onChange={this.handleChange}></textarea>
                              <div className="errorMsg">{this.state.errors.descricao_tarefa}</div>
                        </div>
                        <div className={(this.state.showStatus)? "form-group col-md-3 text-left show" : "form-group col-md-3 text-left hide" }>
                                <label htmlFor="status" className="control-label">Status</label>
                                <Select
                                    placeholder="Selecione"
                                    className="status_task"
                                    styles={customStyles2}
                                    options={statusList}
                                    value={this.state.status_task}
                                    name="status_task"
                                    onChange={this.selectChange}
                                  />
                        </div>
                  </div>
                  <div id="compromisso-container" className="fieldset hide">
                        <span className="form-group col-md-12 title-container">Compromisso</span>
                        <div className="form-group col-md-12">
                            <label htmlFor="sponsor" id="label-sponsor-event">
                                    <div>
                                      Incluir Usuários<span className="text-red">*</span>
                                      <MyPopover icon="white" text="Permite escolher quais usuários participarão deste agendamento, com reserva de horário nas respectivas agendas. Estes receberão e-mails de aviso automaticamente." />      
                                    </div>
                                    <div>
                                      <span>Enviar e-mail para usuários selecionados</span>
                                      <Switch checked={(this.state.send_to_users) ? true : false} onChange={this.onSwitchSendToUsersChange} />
                                  </div>                         
                            </label>
                            <Select
                                isMulti
                                styles={customStyles}
                                className="sponsor"
                                options={this.state.sponsorsList}
                                defaultValue={this.state.defaultSponsor}
                                placeholder="Selecione"
                                value={this.state.sponsor}
                                name="sponsor"
                                onChange={this.sponsorSelectChange}
                              />
                              <div className="errorMsg">{this.state.errors.sponsor}</div>
                        </div>
                        <div className="form-group col-md-3 col-xs-6 col-sm-3 text-left">
                            <label htmlFor="date_start" className="control-label">Data de Início<span className="text-red">*</span></label>
                            <DatePicker locale={locale} className="" placeholder="Data Inicial" showToday={false} allowClear={false} format="DD/MM/YYYY" value={( this.state.date_start !== null ) ? moment(this.state.date_start, "DD/MM/YYYY") : null} onChange={(date, dateString) => this.onDatePickerChange(date, dateString, "date_start")} />
                            <div className="errorMsg">{this.state.errors.date_start}</div>
                        </div>
                        <div className="form-group col-md-3 col-xs-6 col-sm-3 text-left">
                            <label htmlFor="time_start" className="control-label">Hora<span className="text-red">*</span></label>
                            <Select
                                placeholder="Selecione"
                                styles={customStyles}
                                options={times}
                                value={this.state.time_start}
                                name="time_start"
                                onChange={this.selectChange}
                                filterOption={ (option, inputValue)=>{return this.hourFilter(option, inputValue)}  }
                              />
                              <div className="errorMsg">{this.state.errors.time_start}</div>
                        </div>
                        <div className="form-group col-md-3 col-xs-6 col-sm-3 text-left">
                        <label htmlFor="date_end" className="control-label">Até<span className="text-red">*</span></label>
                        <DatePicker locale={locale} className="" disabled={date_end_disabled} placeholder="Até" showToday={false} disabledDate={this.disabledCompromissoEndDate} allowClear={false} format="DD/MM/YYYY" value={( this.state.date_end !== null ) ? moment(this.state.date_end, "DD/MM/YYYY") : null} onChange={(date, dateString) => this.onDatePickerChange(date, dateString, "date_end")} />
                        <div className="errorMsg">{this.state.errors.date_end}</div>
                        </div>
                        <div className="form-group col-md-3 col-xs-6 col-sm-3 text-left">
                        <label htmlFor="time_end" className="control-label">Hora<span className="text-red">*</span></label>
                        <Select
                            placeholder="Selecione"
                            styles={customStyles}
                            options={times}
                            value={this.state.time_end}
                            name="time_end"
                            isDisabled={time_end_disabled}
                            onChange={this.selectChange}
                            filterOption={ (option, inputValue)=>{return this.hourFilter(option, inputValue)}  }
                          />
                        <div className="errorMsg">{this.state.errors.time_end}</div>
                        </div>
                        <div className="form-group compromisso_title_container col-md-5 text-left">
                                <label htmlFor="title" className="control-label">Título<span className="text-red">*</span></label>
                                <textarea id="title" name="title" className="form-control " maxLength="48" value={this.state.title} onChange={this.handleChange} />
                                <div className="errorMsg">{this.state.errors.title}</div>
                        </div>
                        <div className="form-group col-md-4">
                            <label htmlFor="category">Categoria<span className="text-red">*</span></label>
                            <Select
                                className="category"
                                styles={customStyles}
                                options={this.state.categoryList}
                                placeholder="Selecione"
                                name="category"
                                isClearable={true}
                                value={this.state.category}
                                onChange={this.selectChange}
                              />
                              <i className="material-icons settings" onClick={this.showCategoryModal} title="Clique para adicionar ou excluir categorias">settings</i>
                              <div className="errorMsg">{this.state.errors.category}</div>
                        </div>
                        <div className={(this.state.showStatus)? "form-group col-md-3 text-left show" : "form-group col-md-3 text-left hide" }>
                                <label htmlFor="status" className="control-label">Status</label>
                                <Select
                                    placeholder="Selecione"
                                    className="status"
                                    styles={customStyles2}
                                    options={statusList}
                                    value={this.state.status}
                                    name="status"
                                    onChange={this.selectChange}
                                  />
                        </div>

                        <div className="form-group col-md-12">
                            <label htmlFor="repeat" className="control-label hide">
                                    Repetir
                                    <Popover
                                        isOpen={this.state.isRepeatPopoverOpen}
                                        position={['top', 'right', 'left', 'bottom']}
                                        padding={10}
                                        onClickOutside={() => this.setState({ isRepeatPopoverOpen: false })}
                                        content={({ position, targetRect, popoverRect }) => (
                                            <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                                                position={position}
                                                targetRect={targetRect}
                                                popoverRect={popoverRect}
                                                arrowColor={'#94bcd8'}
                                                arrowSize={10}
                                            >
                                                <div className="popover-box"
                                                    onClick={() => this.setState({ isRepeatPopoverOpen: !this.state.isRepeatPopoverOpen })}
                                                >
                                                      Permite repetir automaticamente o compromisso, até uma data limite.
                                                </div>
                                            </ArrowContainer>
                                        )}
                                    >
                                        <img onMouseOut={() => this.setState({ isRepeatPopoverOpen: false })}  onMouseOver={() => this.setState({ isRepeatPopoverOpen: !this.state.isRepeatPopoverOpen })} className="help_icon " alt="ajuda" src={url_base+"/images/interrogation_cropped.png"}></img>
                                    </Popover>
                            </label>
                            <Switch defaultChecked={this.state.switchRepeat} className="hide" checked={this.state.switchRepeat} onChange={this.onSwitchChange} />
                            <div className="btn-group hide" id="repeat-group" data-toggle="buttons">

                                <button id="daily" className="btn btn-default" name="repeat" type="button" value="1" onClick={this.repeatClick}>Diariamente</button>
                                <button id="weekly" className="btn btn-default" name="repeat" type="button" value="2" onClick={this.repeatClick}>Semanalmente</button>
                                <button id="monthly" className="btn btn-default" name="repeat" type="button" value="3" onClick={this.repeatClick}>Mensalmente</button>
                                <button id="annually" className="btn btn-default" name="repeat" type="button" value="4" onClick={this.repeatClick}>Anualmente</button>

                            </div>
                            <div id="finish_in_container" className="form-group col-md-3 text-left hide">
                                <label htmlFor="finish_in" className="control-label">Repetir até<span className="text-red">*</span></label>
                                <DatePicker locale={locale} className="form-control " placeholder="Repetir até" showToday={false} allowClear={false} format="DD/MM/YYYY" value={( this.state.finish_in !== null ) ? moment(this.state.finish_in, "DD/MM/YYYY") : null} onChange={(date, dateString) => this.onDatePickerChange(date, dateString, "finish_in")} />
                                <div className="errorMsg">{this.state.errors.finish_in}</div>
                            </div>
                        </div>
                        <div className="form-group col-md-5">
                            <label htmlFor="lawsuit">Processo</label>
                            <Select
                                className="lawsuit"
                                styles={customStyles}
                                options={this.state.lawsuitList}
                                placeholder="Selecione"
                                name="lawsuit"
                                value={this.state.lawsuit}
                                isClearable={this.state.edit}
                                isDisabled={!this.state.edit}
                                onChange={this.selectChange}
                              />
                              <a id="link_selected_lawsuit" className={ (this.state.lawsuit !== null) ? "btn btn-info small-link-lawsuit" : "hide"} target="_blank" href={this.state.link_selected_lawsuit}>Visualizar processo</a>
                        </div>
                        <div className="form-group col-md-2">
                            <label htmlFor="priority">Prioridade</label>
                            <Select
                                className="priority"
                                styles={customStyles2}
                                defaultValue={prioridade[0]}
                                options={prioridade}
                                placeholder="Selecione"
                                name="priority"
                                value={this.state.priority}
                                onChange={this.selectChange}
                              />
                        </div>
                        <div className="form-group col-md-5 text-left">
                              <label htmlFor="description" className="control-label">Observação<span className="text-red">*</span></label>
                              <textarea name="description" id="description" rows="2" maxLength="10000" value={this.state.description} className="form-control" onChange={this.handleChange}></textarea>
                              <div className="errorMsg">{this.state.errors.description}</div>
                        </div>

                          <div className="form-group col-md-12">
                            <label htmlFor="sendEmail" id="label-copy-event">
                              <div>Enviar Cópia de E-mail:</div>
                            <div className="form-group text-left">
                              <Switch defaultChecked={false} checked={this.state.sendEmail}  onChange={this.onSwitchSendEmailChange} />

                             <MyPopover icon="white" text='Quando acionado, permite a escolha de envio de e-mails para  Clientes e e-mails adicionais, separando-os por virgula e espaço.' />
                            </div>
                            </label>

                            <div id="field-emails" className="form-group field-emails text-left hide">
                                    <label htmlFor="emails" className="control-label">
                                            Adicionar E-mails
                                            <MyPopover icon="white" text='Cadastre E-mails que não constam neste processo para receber e-mails  deste compromisso, separando-os por virgula.  (Cadastro somente para este evento).' />
                                    </label>
                                    <input id="emails" name="emails" className="form-control " value={this.state.emails} onChange={this.handleChange} />
                                    <div className="errorMsg">{this.state.errors.emails}</div>
                            </div>
                        </div>
                  </div>
                </Modal>

                <Modal
                  visible={this.state.categoryModalVisible}
                  className="modalSettings"
                  title="Incluir ou Excluir Categorias"
                  width="450px"
                  onOk={this.handleCategoryModalOk}
                  onCancel={this.handleCategoryModalCancel}
                  footer={[
                    <Button key="back" className="btn-danger" onClick={this.handleCategoryModalCancel}>
                      Fechar
                    </Button>
                  ]}
                >
                        <div className="form-group">
                          <label htmlFor="categoryName" className="col-form-label col-md-12">Nova Categoria: </label>
                          <input type="text" className="form-control" id="categoryName" />
                          <span className="btn btn-primary" id="add-category" onClick={this.addCategory} >Incluir</span>
                        </div>
                        <div className="form-group">
                            <label htmlFor="categorySettings" className="col-form-label col-md-12">Opções: </label>
                            <select multiple id="categorySettings" className="category settings">
                              {arrCategory}
                            </select>
                            <i className="material-icons delete" id="delete-category" title="remover item selecionado" onClick={this.deleteCategory} >delete</i>
                        </div>
                        <p className="info">As alterações são salvas automaticamente</p>
                </Modal>
             </span>
  }

}
