import React from 'react';
import Popover, { ArrowContainer } from 'react-tiny-popover';
import { DatePicker } from 'antd'; import locale from 'antd/es/date-picker/locale/pt_BR';
import { Modal, Button } from 'antd';
import { Switch } from 'antd';
import Select from 'react-select';
import Loading from '../containers/loading';
import Confirm from '../containers/confirm';
import Alert from '../containers/alert';
import IntlCurrencyInput from "react-intl-currency-input";
import FlashMessage from '../containers/FlashMessage';
import FinanceService from '../../services/FinanceService';
import LawsuitService from '../../services/LawsuitService';

import moment from "moment";
//import ptBR from 'antd/lib/locale-provider/pt_BR';
import 'moment/locale/pt-br';

moment.locale('pt-BR');

const customStyles = {
  clearIndicator: (provided, state) => {
    const color = 'red';

    return { ...provided, color };
  }
}

const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
}

/*let formaPagamentoList = [
  {value: '1', label: 'Dinheiro'},
  {value: '2', label: 'Cartão'},
  {value: '3', label: 'Cheque'},
  {value: '4', label: 'Boleto'},
  {value: '6', label: 'Em Carteira'},
  {value: '7', label: 'Depósito / Transferência'},
  {value: '5', label: 'Outros'},
]*/

let categoryAcordoList = []

/*
let lawsuitList = [
  {value: '1', label: '1002056-84.2017.5.02.0204'},
  {value: '2', label: '1001085-12.2016.5.02.0018'},
];*/

export default class FormIncomingFinance extends React.Component { 
  _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }


  constructor(props) {
    super(props)

    this.modifiedIncoming = this.props.modifiedIncoming.bind(this);
    this.newIncoming = this.newIncoming.bind(this);
    this.moeda = this.moeda.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.addIncoming = this.addIncoming.bind(this);
    this.onSwitchAcordoChange = this.onSwitchAcordoChange.bind(this);
    this.onSwitchReembolsoChange = this.onSwitchReembolsoChange.bind(this);
    this.onSwitchRecebidoChange = this.onSwitchRecebidoChange.bind(this);
    this.onDatePickerChange = this.onDatePickerChange.bind(this);
    this.handleCancel = this.handleCancel.bind(this);

    this.FinanceService = new FinanceService();

    this.state = {
      loading: false,
      visible: false,
      lawsuitList: [],
      data_vencimento: null,
      data_vencimento_acordo: null,
      data_vencimento_repasse: null,
      descricao: "",
      errors: {},
      loadingVisible: false,
      loadingMessage: [],
      parcelas: 1,
      parcelasAcordo: 1,
      switchRecebido: false,
      switchAcordo: false,
      switchReembolso: false,
      valorAcordo: 0,
      forma_pagamento_acordo: null,
      forma_pagamento_repasse: null,
      parcelasRepasse: 1,
      valorRepasse: 0,
      valor: 0,
      categoryList: [],
      centroCustoList: [],
      forma_pagamento: null,
      formaPagamentoList: [],
      token: "",
      message: "",
      showAlert: false,
      showConfirm: false,
    }
  }

  componentWillReceiveProps(nextProps) {

    if ( nextProps.token !== this.props.token && nextProps.showModal ) {
            console.log(this.state.visible);
            this.setState({
              token: nextProps.token,
              visible: nextProps.showModal,
            });

            console.log(nextProps);
            console.log(this.props);

            setTimeout(() => {        
              this.showModal();
            }, 200);
    }

  }

  componentDidMount( ) { 
    this._isMounted = true;

    document.addEventListener("cancelDialogConfirm", (e) => {
      if( this._isMounted ){
        this.setState({
          showConfirm: false
        });
      }
    });

    document.addEventListener("confirmDialogConfirm", (e) => {
      if( this._isMounted ){
        this.setState({
          showConfirm: false,
        });

        this.handleCancel(e, true);
      }
    });

    document.addEventListener("cancelLoading", (e) => {
      this.setState({
        loadingVisible: false
      });
    });

    document.addEventListener("cancelAlert", (e) => {
      if( this._isMounted ){
        this.setState({
          showAlert: false
        });
      }
    });

    this.lawsuitService = new LawsuitService();
    this.FinanceService = new FinanceService();

      this.lawsuitService.officeList().then(response =>{

        this.setState({
          lawsuitList: response
        });
      });

      this.FinanceService.centroCustoList().then(response =>{

        this.setState({
          centroCustoList: response,
          centroCusto: response[0]
        });
      });

      this.FinanceService.categoryIncomingList().then(response =>{
        //Recebimento de Acordo
        response.map( ( record, index, array ) => {
          if(record.label === "Recebimento de Acordo"){
            categoryAcordoList.push(record);
          }
          return record;
        });

        this.setState({
          categoryList: response
        });
      });

      this.FinanceService.listFinancialPaymentOptions().then(response =>{
          this.setState({
            formaPagamentoList: response
          });
      });

  }

  componentDidUpdate(){

      /*if( this.state.showModal === true ){
        this.showModal();
        this.setState({
          showModal: false
        });
      }*/

  }

  clearFields(){
    this.setState(
      {
        loading: false,
        visible: false,
        data_vencimento: null,
        data_vencimento_acordo: null,
        data_vencimento_repasse: null,
        descricao: "",
        errors: {},
        loadingVisible: false,
        loadingMessage: [],
        parcelas: 1,
        lawsuit: null,
        cliente: null,
        forma_pagamento: null,
        centroCusto: this.state.centroCustoList[0],
        categoria: null,
        valor: 0,
        switchRecebido: false,
        switchAcordo: false,
        switchReembolso: false,
        valorAcordo: 0,
        parcelasAcordo: 1,
        forma_pagamento_acordo: null,
        forma_pagamento_repasse: null,
        parcelasRepasse: 1,
        valorRepasse: 0,
        token: ""
      }
    );
  }

  addIncoming(){

      this.FinanceService = new FinanceService();

      let lawsuit = null; let categoria = null; let forma_pagamento = null; let data_vencimento = null; let centroCusto = null;
      let forma_pagamento_acordo = null; let forma_pagamento_repasse = null; let data = {}; let data_outgoing = {};
      let data_vencimento_acordo = null; let data_vencimento_repasse = null; let belongs_to_lawsuit = 0;

      if ( typeof this.state.lawsuit !== "undefined" && this.state.lawsuit !== null ) {
        lawsuit = this.state.lawsuit.value;
        belongs_to_lawsuit = 1;
      }

      if ( typeof this.state.categoria !== "undefined" && this.state.categoria !== null ) {
        categoria = this.state.categoria.value;
      }

      if ( typeof this.state.centroCusto !== "undefined" && this.state.centroCusto !== null ) {
        centroCusto = this.state.centroCusto.value;
      }

      if( this.state.switchAcordo ){
        if ( typeof this.state.forma_pagamento_acordo !== "undefined" && this.state.forma_pagamento_acordo !== null ) {
          forma_pagamento_acordo = this.state.forma_pagamento_acordo.value;
        }

        if ( typeof this.state.data_vencimento_acordo !== "undefined" ) {
          data_vencimento_acordo = this.state.data_vencimento_acordo;
        }

        if ( typeof this.state.forma_pagamento_repasse !== "undefined" && this.state.forma_pagamento_repasse !== null ) {
          forma_pagamento_repasse = this.state.forma_pagamento_repasse.value;
        }

        if ( typeof this.state.data_vencimento_repasse !== "undefined" ) {
          data_vencimento_repasse = this.state.data_vencimento_repasse;
        }

        data = {
          "lawsuit_token": lawsuit,
          "observacao": this.state.descricao,
          "data_vencimento": data_vencimento_acordo.split('/').reverse().join('-'),
          "financial_payment_option_token": forma_pagamento_acordo,
          "financial_category_token": categoria,
          "parcelas": this.state.parcelasAcordo,
          "valor": this.state.valorAcordo.replace(/[^0-9-]/g, "")/100,
          "financial_center_cost_token": centroCusto,
          "pago": (this.state.switchRecebido || this.state.switchReembolso)? 1 : 0,
          "reembolso": (this.state.switchReembolso)? 1 : 0,
          "data_pagamento": (this.state.switchRecebido || this.state.switchReembolso)? data_vencimento : null,
          "acordo": 1,
          "belongs_to_lawsuit": 0
        }

        data_outgoing = {
          "lawsuit_token": lawsuit,
          "observacao": this.state.descricao,
          "financial_category_token": categoria,
          "data_vencimento": data_vencimento_repasse.split('/').reverse().join('-'),
          "financial_payment_option_token": forma_pagamento_repasse,
          "parcelas": this.state.parcelasRepasse,
          "valor": this.state.valorRepasse.replace(/[^0-9-]/g, "")/100,
          "financial_center_cost_token": centroCusto,
          "acordo": 1,
          "belongs_to_lawsuit": belongs_to_lawsuit
        }

        this.FinanceService.addIncoming( data_outgoing ).then(res =>{
        });

        this.add( data );

      }else{
        if ( typeof this.state.forma_pagamento !== "undefined" && this.state.forma_pagamento !== null ) {
          forma_pagamento = this.state.forma_pagamento.value;
        }

        if ( typeof this.state.data_vencimento !== "undefined" ) {
          data_vencimento = this.state.data_vencimento;
          console.log(data_vencimento);
        }

        data = {
          "lawsuit_token": lawsuit,
          "observacao": this.state.descricao,
          "data_vencimento": data_vencimento.split('/').reverse().join('-'),
          "financial_payment_option_token": forma_pagamento,
          "financial_category_token": categoria,
          "parcelas": this.state.parcelas,
          "valor": this.state.valor.replace(/[^0-9-]/g, "")/100,
          "financial_center_cost_token": centroCusto,
          "pago": (this.state.switchRecebido )? 1 : 0,
          "reembolso": (this.state.switchReembolso)? 1 : 0,
          "data_pagamento": (this.state.switchRecebido || this.state.switchReembolso)? data_vencimento.split('/').reverse().join('-')+" 00:00" : null,
          "acordo": 0,
          "belongs_to_lawsuit": belongs_to_lawsuit
        }

        if(this.state.switchReembolso === true || belongs_to_lawsuit === 1 ){
          this.FinanceService.addOutgoing( data ).then(res =>{
            this.modifiedIncoming();
          });
        }else{
          this.add( data );
        }
      }

  }

  add( data ){
    this.FinanceService.addIncoming( data ).then(res =>{

          if( res.success === true ){
                localStorage.setItem('alert_message', "Fatura salva com sucesso");
                localStorage.setItem('alert_type', 'alert-success');
                let time = new Date().getTime();
                localStorage.setItem('alert_time', time );

                //let incoming_token = res.data.Incomings.token;
                //console.log( incoming_token );

                this.modifiedIncoming();
          }else {
            localStorage.setItem('alert_message', res.message);
            localStorage.setItem('alert_type', 'alert-danger');
            let time = new Date().getTime();
            localStorage.setItem('alert_time', time );
          }

    });
  }

  moeda( event, value, maskedValue ) {
    event.preventDefault();

    this.setState(
        {
            [event.target.name]: maskedValue
        }
    )
 }

   onDatePickerChange = (date, dateString, name) => {

     if (dateString === "" ){
       dateString = null;
     }

      this.setState(
          {
              [name]: dateString,
          }
      )

    }

    newIncoming = () => {

      this.setState({
          token: ""
      });

      setTimeout(() => {
          this.showModal();
      }, 300);

    }

    showModal = () => {
          // Formulário de edição
          if( this.state.token !== "" && typeof this.state.token !== "undefined" ){

            this.FinanceService = new FinanceService();
            this.FinanceService.viewIncoming( this.state.token ).then(res => {
              //console.log( res );

              let lawsuit_token = this.state.lawsuitList.filter( (lawsuit)=>{ return lawsuit.value === res.lawsuit_token })[0];

              this.setState({
                descricao: res.descricao,
                lawsuit: lawsuit_token,
                labelSaveButton: "Editar",
                titleModal: "Editar Lançamento",
                showStatus: true,
                visible: true,
              });

            });

          }else {

              this.setState({
                labelSaveButton: "Incluir",
                titleModal: "Novo Lançamento Contas a Receber",
                showStatus: false,
                visible: true
              });
          }

          this.setState({
            visible: true
          });

    };

    handleOk = () => {
        if (this.validateForm()) {

          this.setState({
            loadingVisible: true,
            loading: true,
            loadingMessage: ["O cadastro está sendo efetuado.", "Por gentileza, aguarde...", "Tempo estimado de 15 a 90 segundos"]
          });

          this.addIncoming();

            setTimeout( () => {
              this.clearFields();
            }, 3000);

        }

    };

    handleCancel = ( event, r = false ) => {
        //window.confirm("As alterações serão perdidas, deseja realmente sair?") 
        if( r ){
            this.clearFields();

        }else{
          this.setState({
            showConfirm: true,
            message: "As alterações serão perdidas, deseja realmente sair?"
          });
        }
    };

    handleChange = (e) => {

            this.setState(
                {
                    [e.target.name]: e.target.value
                }
            )

     }

     selectChange = (selected, actionMeta) => {
       let pops = [];
       pops[ actionMeta.name ] = selected;

       if ( typeof actionMeta.name !== undefined && actionMeta.name !== "" && actionMeta.name !== null ){

         if ( typeof selected !== undefined && selected !== "" ) {
           this.setState( pops );
         }

       }
     }

    validateForm = () => {
        let errors = {};
        let formIsValid = true;

        if ( this.state.switchAcordo === true && (this.state.lawsuit === "" || typeof this.state.lawsuit === "undefined" || this.state.lawsuit === null ) ) {
          formIsValid = false;
          errors["lawsuit"] = "*Selecione o processo";
        }

        if ( this.state.switchReembolso === true && (this.state.lawsuit === "" || typeof this.state.lawsuit === "undefined" || this.state.lawsuit === null ) ) {
          formIsValid = false;
          errors["lawsuit"] = "*Selecione o processo";
        }

        if ( this.state.switchAcordo === true ) {
          if ( this.state.valorAcordo === 0 || this.state.valorAcordo === "" || typeof this.state.valorAcordo === "undefined" ) {
            formIsValid = false;
            errors["valorCausa"] = "*Digite um valor para o acordo";
          }
          if ( this.state.data_vencimento_acordo === 0 || this.state.data_vencimento_acordo === "" || typeof this.state.data_vencimento_acordo === "undefined" || this.state.data_vencimento_acordo === null ) {
            formIsValid = false;
            errors["data_vencimento_acordo"] = "*Preencha a data";
          }
          if ( typeof this.state.forma_pagamento_acordo === "undefined" || this.state.forma_pagamento_acordo === null ) {
            formIsValid = false;
            errors["forma_pagamento_acordo"] = "*Escolha uma forma de pagamento";
          }

          if ( this.state.valorRepasse === 0 || this.state.valorRepasse === "" || typeof this.state.valorRepasse === "undefined" ) {
            formIsValid = false;
            errors["valorRepasse "] = "*Digite um valor para o repasse";
          }
          if ( this.state.data_vencimento_repasse === 0 || this.state.data_vencimento_repasse === "" || typeof this.state.data_vencimento_repasse === "undefined" || this.state.data_vencimento_repasse === null ) {
            formIsValid = false;
            errors["data_vencimento_repasse"] = "*Preencha a data";
          }
          if ( typeof this.state.forma_pagamento_repasse === "undefined" || this.state.forma_pagamento_repasse === null ) {
            formIsValid = false;
            errors["forma_pagamento_repasse"] = "*Escolha uma forma de pagamento";
          }
        }else{
          if ( this.state.valor === 0 || this.state.valor === "" || typeof this.state.valor === "undefined" ) {
            formIsValid = false;
            errors["valor"] = "*Digite um valor";
          }
          if ( this.state.data_vencimento === 0 || this.state.data_vencimento === "" || typeof this.state.data_vencimento === "undefined" || this.state.data_vencimento === null ) {
            formIsValid = false;
            errors["data_vencimento"] = "*Preencha a data";
          }

          if ( typeof this.state.forma_pagamento === "undefined" || this.state.forma_pagamento === null ) {
            formIsValid = false;
            errors["forma_pagamento"] = "*Escolha uma forma de pagamento";
          }
        }

        if ( this.state.categoria === 0 || this.state.categoria === "" || typeof this.state.categoria === "undefined" || this.state.categoria === null )  {
          formIsValid = false;
          errors["categoria"] = "*Selecione a categoria";
        }
        console.log(this.state.centroCusto);
        if ( this.state.centroCusto === 0 || this.state.centroCusto === "" || typeof this.state.centroCusto === "undefined" || this.state.centroCusto === null )  {
          formIsValid = false;
          errors["centroCusto"] = "*Selecione o Centro de Custo";
        }

        if ( this.state.descricao === 0 || this.state.descricao === "" || typeof this.state.descricao === "undefined" ) {
          formIsValid = false;
          errors["descricao"] = "*Forneça a descrição da entrada";
        }



        this.setState({
           errors: errors
         });

        return formIsValid;

    }

    showCentroCustoModal = () => {

          this.setState({
            centroCustoModalVisible: true,
          });
    };

    handleCentroCustoModalOk = () => {

        this.setState({
          centroCustoModalVisible: false,
        })
    };

    handleCentroCustoModalCancel = () => {
        this.setState({ centroCustoModalVisible: false });
    };


    addCentroCusto = (e) => {
      this.FinanceService = new FinanceService();
      document.body.style.cursor = "wait";
      let centroCusto = {
        name: document.getElementById("centroCustoName").value
      }
      this.FinanceService.addCentroCusto( centroCusto ).then(res =>{

        this.FinanceService.centroCustoList( ).then(response =>{

              document.getElementById("centroCustoName").value = "";
              document.body.style.cursor = "default";
              this.setState({
                centroCustoList: response,
              });

        });

      });
    }

    deleteCentroCusto = (e) => {
      this.FinanceService = new FinanceService();
      document.body.style.cursor = "wait";

      let id = document.getElementById("centroCustoSettings").value;

      console.log( "centro de custo que vai ser deletado: "+id );

      this.FinanceService.deleteCentroCusto( id ).then( res =>{

        if( res.success === true ){
          this.FinanceService.centroCustoList( ).then(response =>{
              document.body.style.cursor = "default";
                this.setState({
                  centroCustoList: response,
                });
          });
        }else{
          document.body.style.cursor = "default";
          //alert(res.message);
          this.setState({
            showAlert: true,
            message: res.message
          });
        }

      });
    }

    showCategoryModal = () => {

          this.setState({
            categoryModalVisible: true,
          });
    };

    handleCategoryModalOk = () => {

        this.setState({
          categoryModalVisible: false,
        })
    };

    handleCategoryModalCancel = () => {
        this.setState({ categoryModalVisible: false });
    };


    addCategory = (e) => {
      this.FinanceService = new FinanceService();
      document.body.style.cursor = "wait";
      let categoria = {
        name: document.getElementById("categoryName").value
      }
      this.FinanceService.addCategoryIncoming( categoria ).then(res =>{

        this.FinanceService.categoryIncomingList( ).then(response =>{

              document.getElementById("categoryName").value = "";
              document.body.style.cursor = "default";
              this.setState({
                categoryList: response,
              });

        });

      });
    }

    deleteCategory = (e) => {
      this.FinanceService = new FinanceService();
      document.body.style.cursor = "wait";

      let id = document.getElementById("categorySettings").value;

      //console.log( "categoria que vai ser deletado: "+id );

      this.FinanceService.deleteCategoryIncoming( id ).then( res =>{

        if( res.success === true ){
          this.FinanceService.categoryIncomingList( ).then(response =>{
              document.body.style.cursor = "default";
                this.setState({
                  categoryList: response,
                });
          });
        }else{
          document.body.style.cursor = "default";
          //alert(res.message);
          this.setState({
            showAlert: true,
            message: res.message
          });
        }

      });
    }

    onSwitchAcordoChange = (checked) => {
      if ( checked ){
        //document.getElementById("lawsuit_container").classList.remove("hide");
        //document.getElementById("switchBaixaContainer").className += ' hide';
        this.onSwitchReembolsoChange(false);
        this.setState({
          switchAcordo: true,
          categoryBackupList: this.state.categoryList,
          categoryList: categoryAcordoList,
          categoria: categoryAcordoList[0]
        });
      }else{
        //document.getElementById("lawsuit_container").className += ' hide';
        //document.getElementById("switchBaixaContainer").classList.remove("hide");
        this.setState({
          switchAcordo: false,
          categoryList: this.state.categoryBackupList,
          categoria: null
        });
      }
    }

    onSwitchReembolsoChange = (checked) => {
      if ( checked ){
        //document.getElementById("lawsuit_container").classList.remove("hide");
        //document.getElementById("switchBaixaContainer").className += ' hide';
        this.onSwitchRecebidoChange(false);
        this.setState({
          switchReembolso: true,
        });
      }else{
        //document.getElementById("lawsuit_container").className += ' hide';
        //document.getElementById("switchBaixaContainer").classList.remove("hide");
        this.setState({
          switchReembolso: false,
        });
      }
    }

    onSwitchRecebidoChange = (checked) => {
      if ( checked ){
        //document.getElementById("lawsuit_container").classList.remove("hide");
        //document.getElementById("switchBaixaContainer").className += ' hide';
        this.setState({
          switchRecebido: true,
        });
      }else{
        //document.getElementById("lawsuit_container").className += ' hide';
        //document.getElementById("switchBaixaContainer").classList.remove("hide");
        this.setState({
          switchRecebido: false,
        });
      }
    }

  render() {

      var arrCategory = [];
      for (var k = 0; k < this.state.categoryList.length; k++) {
          arrCategory.push(<option key={k} value={this.state.categoryList[k].value}> {this.state.categoryList[k].label} </option>);
      }

      var arrCentroCusto = [];
      for (var l = 0; l < this.state.centroCustoList.length; l++) {
          arrCentroCusto.push(<option key={l} value={this.state.centroCustoList[l].value}> {this.state.centroCustoList[l].label} </option>);
      }

      var url_base = localStorage.getItem('url_base');

      return <div>
                <FlashMessage time={ new Date().getTime() } />
                <Alert show={this.state.showAlert} text={this.state.message} />
                <Confirm show={this.state.showConfirm} text={this.state.message} />
                <Loading visible={this.state.loadingVisible} message1={this.state.loadingMessage[0]} message2={this.state.loadingMessage[1]} message3={this.state.loadingMessage[2]} cancel={true}   />
                <span className="btn btn-primary form-submit add-incoming" onClick={this.newIncoming}>Novo Lançamento</span>
                <Modal
                  visible={this.state.visible}
                  title="Financeiro"
                  width="650px"
                  className="form-add-incoming"
                  onOk={this.handleOk}
                  onCancel={ (event)=>{this.handleCancel(event)} }
                  footer={[
                    <Button key="submit" id="handleOk" type="primary" loading={this.state.loading} onClick={this.handleOk}>
                      {this.state.labelSaveButton}
                    </Button>,
                    <Button key="back" id="close" className="btn-danger" onClick={ (event)=>{this.handleCancel(event)} }>
                      Fechar
                    </Button>,
                  ]}
                >

                  <div id="lancamento-container" className="fieldset contas-receber-container">
                        <span className="col-md-12 title-container">{this.state.titleModal}</span>
                        <div className={(this.state.switchAcordo)? "switches col-md-9 col-xs-12" : "switches col-md-5 col-xs-12"}>
                            <label htmlFor="acordo">
                                  Lançamento de Acordo
                                  <Popover
                                      isOpen={this.state.isAcordoPopoverOpen}
                                      position={['top', 'right', 'left', 'bottom']}
                                      padding={10}
                                      onClickOutside={() => this.setState({ isAcordoPopoverOpen: false })}
                                      content={({ position, targetRect, popoverRect }) => (
                                          <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                                              position={position}
                                              targetRect={targetRect}
                                              popoverRect={popoverRect}
                                              arrowColor={'#94bcd8'}
                                              arrowSize={10}
                                          >
                                              <div className="popover-box"
                                                  onClick={() => this.setState({ isAcordoPopoverOpen: !this.state.isAcordoPopoverOpen })}
                                              >
                                                    Em caso de Acordos Trabalhista, onde o valor total será depositado na conta do escritório, em parcelas, é possivel lançar os recebimentos futuros e os repasses para o Reclamante / Processo.
                                              </div>
                                          </ArrowContainer>
                                      )}
                                  >
                                      <img onMouseOut={() => this.setState({ isAcordoPopoverOpen: false })}  onMouseOver={() => this.setState({ isAcordoPopoverOpen: !this.state.isAcordoPopoverOpen })} className="help_icon " alt="ajuda" src={url_base+"/images/interrogation_cropped.png"}></img>
                                  </Popover>
                            </label>
                            <Switch defaultChecked={this.state.switchAcordo} checked={this.state.switchAcordo} onChange={this.onSwitchAcordoChange} />
                        </div>
                        <div className={(this.state.switchAcordo)? "hide" : "show switches col-md-4 col-xs-12"}>
                            <label htmlFor="reembolso">
                                  Lançar no Processo
                                  <Popover
                                      isOpen={this.state.isReembolsoPopoverOpen}
                                      position={['top', 'right', 'left', 'bottom']}
                                      padding={10}
                                      onClickOutside={() => this.setState({ isReembolsoPopoverOpen: false })}
                                      content={({ position, targetRect, popoverRect }) => (
                                          <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                                              position={position}
                                              targetRect={targetRect}
                                              popoverRect={popoverRect}
                                              arrowColor={'#94bcd8'}
                                              arrowSize={10}
                                          >
                                              <div className="popover-box"
                                                  onClick={() => this.setState({ isReembolsoPopoverOpen: !this.state.isReembolsoPopoverOpen })}
                                              >
                                                    Possibilita debitar de um processo, valores que não sairam do caixa. Ex.: Valores de consultas, cobrança de cálculos, diligências, etc... Estes valores não serão lançados no Fluxo de Caixa, somente no financeiro do processo selecionado.
                                              </div>
                                          </ArrowContainer>
                                      )}
                                  >
                                      <img onMouseOut={() => this.setState({ isReembolsoPopoverOpen: false })}  onMouseOver={() => this.setState({ isReembolsoPopoverOpen: !this.state.isReembolsoPopoverOpen })} className="help_icon " alt="ajuda" src={url_base+"/images/interrogation_cropped.png"}></img>
                                  </Popover>
                            </label>
                            <Switch defaultChecked={this.state.switchReembolso} checked={this.state.switchReembolso} onChange={this.onSwitchReembolsoChange} />
                        </div>

                        <div className="form-group col-md-5  col-xs-12">
                            <label htmlFor="cliente">Processo<span className={(this.state.switchAcordo || this.state.switchReembolso)? "text-red" : "hide"}>*</span></label>
                            <Select
                                styles={customStyles}
                                className="lawsuit"
                                options={this.state.lawsuitList}
                                placeholder="Selecione"
                                value={this.state.lawsuit}
                                name="lawsuit"
                                isClearable={true}
                                onChange={this.selectChange}
                              />
                              <div className={(this.state.switchAcordo || this.state.switchReembolso)? "errorMsg" : "hide"}>{this.state.errors.lawsuit}</div>
                        </div>
                        <div className="form-group col-md-4  col-xs-12">
                            <label htmlFor="categoria">
                                      Categoria<span className="text-red">*</span>
                                      <Popover
                                          isOpen={this.state.isCategoryPopoverOpen}
                                          position={['top', 'right', 'left', 'bottom']}
                                          padding={10}
                                          onClickOutside={() => this.setState({ isCategoryPopoverOpen: false })}
                                          content={({ position, targetRect, popoverRect }) => (
                                              <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                                                  position={position}
                                                  targetRect={targetRect}
                                                  popoverRect={popoverRect}
                                                  arrowColor={'#94bcd8'}
                                                  arrowSize={10}
                                              >
                                                  <div className="popover-box"
                                                      onClick={() => this.setState({ isCategoryPopoverOpen: !this.state.isCategoryPopoverOpen })}
                                                  >
                                                        Separe os lançamentos por categorias para  que se possa ter uma visão detalhada e relatórios mais precisos.  Podem ser incluidas e excluidas.
                                                  </div>
                                              </ArrowContainer>
                                          )}
                                      >
                                          <img onMouseOut={() => this.setState({ isCategoryPopoverOpen: false })}  onMouseOver={() => this.setState({ isCategoryPopoverOpen: !this.state.isCategoryPopoverOpen })} className="help_icon " alt="ajuda" src={url_base+"/images/interrogation_cropped.png"}></img>
                                      </Popover>
                            </label>
                            <Select
                                className="categorySelect"
                                styles={customStyles}
                                options={this.state.categoryList}
                                placeholder="Selecione"
                                name="categoria"
                                isClearable={!this.state.switchAcordo}
                                value={this.state.categoria}
                                onChange={this.selectChange}
                              />
                              <i className="material-icons settings" onClick={this.showCategoryModal} title="Clique para adicionar ou excluir categorias">settings</i>
                              <div className="errorMsg">{this.state.errors.categoria}</div>
                        </div>

                        <div className="form-group col-md-3  col-xs-12">
                            <label htmlFor="centroCusto">
                                  Centro de Custo<span className="text-red">*</span>
                                  <Popover
                                      isOpen={this.state.isCostCenterPopoverOpen}
                                      position={['top', 'right', 'left', 'bottom']}
                                      padding={10}
                                      onClickOutside={() => this.setState({ isCostCenterPopoverOpen: false })}
                                      content={({ position, targetRect, popoverRect }) => (
                                          <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                                              position={position}
                                              targetRect={targetRect}
                                              popoverRect={popoverRect}
                                              arrowColor={'#94bcd8'}
                                              arrowSize={10}
                                          >
                                              <div className="popover-box"
                                                  onClick={() => this.setState({ isCostCenterPopoverOpen: !this.state.isCostCenterPopoverOpen })}
                                              >
                                                    Permite criar vários Centros de Custos, separando de onde vai ser pago ou recebido o valor lancado. Ex.: Filial A, Filial B, Matriz, Particular, Pareceirias, e outros.
                                              </div>
                                          </ArrowContainer>
                                      )}
                                  >
                                      <img onMouseOut={() => this.setState({ isCostCenterPopoverOpen: false })}  onMouseOver={() => this.setState({ isCostCenterPopoverOpen: !this.state.isCostCenterPopoverOpen })} className="help_icon " alt="ajuda" src={url_base+"/images/interrogation_cropped.png"}></img>
                                  </Popover>
                            </label>
                            <Select
                                className="centroCustoSelect"
                                styles={customStyles}
                                options={this.state.centroCustoList}
                                placeholder="Selecione"
                                name="centroCusto"
                                defaultValue={this.state.centroCustoList[0]}
                                value={this.state.centroCusto}
                                onChange={this.selectChange}
                              />
                              <i className="material-icons settings" onClick={this.showCentroCustoModal} title="Clique para adicionar ou excluir centro de Custos">settings</i>
                              <div className="errorMsg">{this.state.errors.centroCusto}</div>
                        </div>

                        <div className={(this.state.switchAcordo)? "show acordo-container" : "hide"}>
                          <h5 className="col-md-12  col-xs-12 subtitle">
                                Recebimento Empresa
                                <Popover
                                    isOpen={this.state.isRecebimentoPopoverOpen}
                                    position={['top', 'right', 'left', 'bottom']}
                                    padding={10}
                                    onClickOutside={() => this.setState({ isRecebimentoPopoverOpen: false })}
                                    content={({ position, targetRect, popoverRect }) => (
                                        <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                                            position={position}
                                            targetRect={targetRect}
                                            popoverRect={popoverRect}
                                            arrowColor={'#94bcd8'}
                                            arrowSize={10}
                                        >
                                            <div className="popover-box"
                                                onClick={() => this.setState({ isRecebimentoPopoverOpen: !this.state.isRecebimentoPopoverOpen })}
                                            >
                                                  Permite lançar todas as parcelas que serão recebidas pelo escritório. Somente entrarão no Fluxo de Caixa quando forem baixadas/recebidas pelo escritório.
                                            </div>
                                        </ArrowContainer>
                                    )}
                                >
                                    <img onMouseOut={() => this.setState({ isRecebimentoPopoverOpen: false })}  onMouseOver={() => this.setState({ isRecebimentoPopoverOpen: !this.state.isRecebimentoPopoverOpen })} className="help_icon " alt="ajuda" src={url_base+"/images/interrogation_cropped.png"}></img>
                                </Popover>
                          </h5>
                          <div className="form-group col-md-3  col-xs-12 text-left">
                                <label htmlFor="valorAcordo" className="">Valor Total do Acordo<span className="text-red">*</span></label>
                                <IntlCurrencyInput className="form-control input-lg" currency="BRL" config={currencyConfig} name="valorAcordo" id="valorAcordo" value={this.state.valorAcordo} onChange={this.moeda} />
                                <div className="errorMsg">{this.state.errors.valorAcordo}</div>
                          </div>
                          <div className="form-group col-md-3  col-xs-12">
                              <label htmlFor="parcelasAcordo">
                                      Parcelas
                              </label>
                              <input
                                  type="number"
                                  className="parcelas input-lg form-control"
                                  min="1"
                                  max="12"
                                  value={this.state.parcelasAcordo}
                                  name="parcelasAcordo"
                                  onChange={this.handleChange}
                                />
                          </div>
                          <div className="form-group col-md-3  col-xs-12 text-left">
                              <label htmlFor="data_vencimento_acordo" className="control-label">Data Vencimento 1°<span className="text-red">*</span></label>
                              <DatePicker locale={locale}  className="data_vencimento" placeholder="Data de Vencimento" showToday={false} allowClear={false} format="DD/MM/YYYY" value={( this.state.data_vencimento_acordo !== null ) ? moment(this.state.data_vencimento_acordo, "DD/MM/YYYY") : null} onChange={(date, dateString) => this.onDatePickerChange(date, dateString, "data_vencimento_acordo")} />
                              <div className="errorMsg">{this.state.errors.data_vencimento_acordo}</div>
                          </div>
                          <div className="form-group col-md-3">
                              <label htmlFor="formaPagamentoAcordo">Forma de Pagamento<span className="text-red">*</span></label>
                              <Select
                                  styles={customStyles}
                                  className="forma-pagamento"
                                  options={this.state.formaPagamentoList}
                                  placeholder="Selecione"
                                  value={this.state.forma_pagamento_acordo}
                                  name="forma_pagamento_acordo"
                                  onChange={this.selectChange}
                                />
                                <div className="errorMsg">{this.state.errors.forma_pagamento_acordo}</div>
                          </div>

                          <h5 className="col-md-12  col-xs-12 subtitle">
                              Repasse p/ Cliente
                              <Popover
                                  isOpen={this.state.isRepassePopoverOpen}
                                  position={['top', 'right', 'left', 'bottom']}
                                  padding={10}
                                  onClickOutside={() => this.setState({ isRepassePopoverOpen: false })}
                                  content={({ position, targetRect, popoverRect }) => (
                                      <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                                          position={position}
                                          targetRect={targetRect}
                                          popoverRect={popoverRect}
                                          arrowColor={'#94bcd8'}
                                          arrowSize={10}
                                      >
                                          <div className="popover-box"
                                              onClick={() => this.setState({ isRepassePopoverOpen: !this.state.isRepassePopoverOpen })}
                                          >
                                                Programe o repasse, com intervalo de 2 dias após a data de recebimento do acordo. Somente entrarão no Fluxo de Caixa quando baixados.
                                          </div>
                                      </ArrowContainer>
                                  )}
                              >
                                  <img onMouseOut={() => this.setState({ isRepassePopoverOpen: false })}  onMouseOver={() => this.setState({ isRepassePopoverOpen: !this.state.isRepassePopoverOpen })} className="help_icon " alt="ajuda" src={url_base+"/images/interrogation_cropped.png"}></img>
                              </Popover>
                          </h5>
                          <div className="form-group col-md-3  col-xs-12 text-left">
                                <label htmlFor="valorRepasse" className="">Valor Total do Repasse<span className="text-red">*</span></label>
                                <IntlCurrencyInput className="form-control input-lg" currency="BRL" config={currencyConfig} name="valorRepasse" id="valorRepasse" value={this.state.valorRepasse} onChange={this.moeda} />
                                <div className="errorMsg">{this.state.errors.valorRepasse}</div>
                          </div>
                          <div className="form-group col-md-3">
                              <label htmlFor="parcelasRepasse">
                                    Parcelas
                              </label>
                              <input
                                  type="number"
                                  className="parcelas input-lg form-control"
                                  min="1"
                                  max="12"
                                  value={this.state.parcelasRepasse}
                                  name="parcelasRepasse"
                                  onChange={this.handleChange}
                                />
                          </div>
                          <div className="form-group col-md-3  col-xs-12 text-left">
                              <label htmlFor="data_vencimento_repasse" className="control-label">Data Vencimento 1°<span className="text-red">*</span></label>
                              <DatePicker locale={locale} className="data_vencimento" placeholder="Data de Vencimento" showToday={false} allowClear={false} format="DD/MM/YYYY" value={( this.state.data_vencimento_repasse !== null ) ? moment(this.state.data_vencimento_repasse, "DD/MM/YYYY") : null} onChange={(date, dateString) => this.onDatePickerChange(date, dateString, "data_vencimento_repasse")} />
                              <div className="errorMsg">{this.state.errors.data_vencimento_repasse}</div>
                          </div>
                          <div className="form-group col-md-3  col-xs-12">
                              <label htmlFor="formaPagamentoAcordo">Forma de Pagamento<span className="text-red">*</span></label>
                              <Select
                                  styles={customStyles}
                                  className="forma-pagamento"
                                  options={this.state.formaPagamentoList}
                                  placeholder="Selecione"
                                  value={this.state.forma_pagamento_repasse}
                                  name="forma_pagamento_repasse"
                                  onChange={this.selectChange}
                                />
                                <div className="errorMsg">{this.state.errors.forma_pagamento_repasse}</div>
                          </div>
                        </div>

                        <div className={(this.state.switchAcordo)? "hide" : "show"}>
                              <div className="form-group col-md-3  col-xs-12 text-left">
                                    <label htmlFor="valorTotal" className="">Valor Total<span className="text-red">*</span></label>
                                    <IntlCurrencyInput className="form-control input-lg" currency="BRL" config={currencyConfig} name="valor" id="valor" value={this.state.valor} onChange={this.moeda} />
                                    <div className="errorMsg">{this.state.errors.valor}</div>
                              </div>
                              <div className="form-group col-md-3  col-xs-12 text-left">
                                  <label htmlFor="data_vencimento" className="control-label">Data Vencimento<span className="text-red">*</span></label>
                                  <DatePicker locale={locale} className="data_vencimento" placeholder="Data de Vencimento" showToday={false} allowClear={false} format="DD/MM/YYYY" value={( this.state.data_vencimento !== null ) ? moment(this.state.data_vencimento, "DD/MM/YYYY") : null} onChange={(date, dateString) => this.onDatePickerChange(date, dateString, "data_vencimento")} />
                                  <div className="errorMsg">{this.state.errors.data_vencimento}</div>
                              </div>
                              <div className="form-group col-md-3  col-xs-12">
                                  <label htmlFor="cliente">
                                        Parcelas
                                        <Popover
                                            isOpen={this.state.isParcelaPopoverOpen}
                                            position={['top', 'right', 'left', 'bottom']}
                                            padding={10}
                                            onClickOutside={() => this.setState({ isParcelaPopoverOpen: false })}
                                            content={({ position, targetRect, popoverRect }) => (
                                                <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                                                    position={position}
                                                    targetRect={targetRect}
                                                    popoverRect={popoverRect}
                                                    arrowColor={'#94bcd8'}
                                                    arrowSize={10}
                                                >
                                                    <div className="popover-box"
                                                        onClick={() => this.setState({ isParcelaPopoverOpen: !this.state.isParcelaPopoverOpen })}
                                                    >
                                                          Ao parcelar, o Valor Total será dividido e as parcelas serão lançadas a cada 30 dias (Lançamentos Futuros).
                                                    </div>
                                                </ArrowContainer>
                                            )}
                                        >
                                            <img onMouseOut={() => this.setState({ isParcelaPopoverOpen: false })}  onMouseOver={() => this.setState({ isParcelaPopoverOpen: !this.state.isParcelaPopoverOpen })} className="help_icon " alt="ajuda" src={url_base+"/images/interrogation_cropped.png"}></img>
                                        </Popover>
                                  </label>
                                  <input
                                      type="number"
                                      className="parcelas input-lg form-control"
                                      min="1"
                                      max="12"
                                      value={this.state.parcelas}
                                      name="parcelas"
                                      onChange={this.handleChange}
                                    />
                              </div>
                              <div className="form-group col-md-3  col-xs-12">
                                  <label htmlFor="cliente">Forma de Pagamento<span className="text-red">*</span></label>
                                  <Select
                                      styles={customStyles}
                                      className="forma-pagamento"
                                      options={this.state.formaPagamentoList}
                                      placeholder="Selecione"
                                      value={this.state.forma_pagamento}
                                      name="forma_pagamento"
                                      onChange={this.selectChange}
                                    />
                                    <div className="errorMsg">{this.state.errors.forma_pagamento}</div>
                              </div>
                        </div>

                        <div className="form-group col-md-6  col-xs-12 text-left">
                              <label htmlFor="descricao" className="control-label">Observação<span className="text-red">*</span></label>
                              <textarea name="descricao" id="descricao" maxLength="100" rows="1" value={this.state.descricao} className="form-control input-lg" onChange={this.handleChange}></textarea>
                              <div className="errorMsg">{this.state.errors.descricao}</div>
                        </div>
                    </div>
                </Modal>
                <Modal
                  visible={this.state.centroCustoModalVisible}
                  className="modalSettings"
                  title="Incluir ou Excluir Centro de Custo"
                  width="450px"
                  onOk={this.handleCentroCustoModalOk}
                  onCancel={this.handleCentroCustoModalCancel}
                  footer={[
                    <Button key="back" className="btn-danger" onClick={this.handleCentroCustoModalCancel}>
                      Fechar
                    </Button>
                  ]}
                >
                        <div className="form-group">
                          <label htmlFor="centroCustoName" className="col-form-label col-md-12">Novo Centro de Custo: </label>
                          <input type="text" className="form-control" id="centroCustoName" />
                          <span className="btn btn-primary" id="add-centro-custo" onClick={this.addCentroCusto} >Incluir</span>
                        </div>
                        <div className="form-group">
                            <label htmlFor="centroCustoSettings" className="col-form-label col-md-12">Opções: </label>
                            <select multiple id="centroCustoSettings" className="centroCusto settings">
                              {arrCentroCusto}
                            </select>
                            <i className="material-icons delete" id="delete-centroCusto" title="remover item selecionado" onClick={this.deleteCentroCusto} >delete</i>
                        </div>
                        <p className="info">As alterações são salvas automaticamente</p>
                </Modal>
                <Modal
                  visible={this.state.categoryModalVisible}
                  className="modalSettings"
                  title="Incluir ou Excluir Categoria"
                  width="450px"
                  onOk={this.handleCategoryModalOk}
                  onCancel={this.handleCategoryModalCancel}
                  footer={[
                    <Button key="back" className="btn-danger" onClick={this.handleCategoryModalCancel}>
                      Fechar
                    </Button>
                  ]}
                >
                        <div className="form-group">
                          <label htmlFor="categoryName" className="col-form-label col-md-12">Nova Categoria: </label>
                          <input type="text" className="form-control" id="categoryName" />
                          <span className="btn btn-primary" id="add-category" onClick={this.addCategory} >Incluir</span>
                        </div>
                        <div className="form-group">
                            <label htmlFor="categorySettings" className="col-form-label col-md-12">Opções: </label>
                            <select multiple id="categorySettings" className="category settings">
                              {arrCategory}
                            </select>
                            <i className="material-icons delete" id="delete-category" title="remover item selecionado" onClick={this.deleteCategory} >delete</i>
                        </div>
                        <p className="info">As alterações são salvas automaticamente</p>
                </Modal>
             </div>
  }

}
