import React from 'react';
import HeaderBar from '../header-bar/header-bar';
import NavigationMenu from '../sidebar/navigation-menu';
import Badge from '../containers/Badge';
import LawsuitService from '../../services/LawsuitService';
import AuthService from '../../services/AuthService';

import Select from 'react-select';
import { Link } from 'react-router-dom';
import 'antd/dist/antd.css';
import { Tabs } from 'antd';
import { Divider } from 'antd';
import Popover, { ArrowContainer } from 'react-tiny-popover';

import Footer from "../containers/footer";
import Alert from '../containers/alert';
import Confirm from '../containers/confirm';
import FlashMessage from "../containers/FlashMessage";

const TabPane = Tabs.TabPane;

const opt_pesquisa = [
  { value: 'cliente', label: 'CLIENTE' },
  { value: 'oponente', label: 'OPONENTE' },
  { value: 'cliente_e_oponente', label: 'CLIENTE E OPONENTE' },
  { value: 'processo', label: 'PROCESSO' },
];

let totalSteps = ( list ) => {

  var sum = 0;

  if( list instanceof Array ){
    for  ( var item of list ) {
      sum += item.movimentos_nao_visualizados;
    }
  }

  return sum;

}

export default class ManualLawsuit extends React.Component { 
  _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }


  constructor(  ) {
      super();
      this.lawsuitService = new LawsuitService();
      this.find = this.find.bind(this);
      this.clear = this.clear.bind(this);
      this.handleChange = this.handleChange.bind(this);

      this.authService = new AuthService();

      this.state = {
          opt_pesquisa: opt_pesquisa[0],
          inp_pesquisa: "",
          updated: "",
          'isEditPopoverOpen': false,
          'isAddPopoverOpen': false,
          message: "",
          showAlert: false,
          lawsuitsRaw: []
      }

  }

  componentDidMount( ) { 
      this._isMounted = true;  

      document.addEventListener("cancelDialogConfirm", (e) => {
        if( this._isMounted ){
          this.setState({
            showConfirm: false
          });
        }
      });

      document.addEventListener("confirmDialogConfirm", (e) => {
        if( this._isMounted ){
          this.setState({
            showConfirm: false
          });

          if( this.state.lawsuitToDelete ){
            this.deleteLawsuit(this.state.lawsuitToDelete, true);
          }
        }
      });

      document.addEventListener("cancelAlert", (e) => {
        if( this._isMounted ){
          this.setState({
            showConfirm: false,
            showAlert: false
          });
        }
      });   

      document.addEventListener("cancelLoading", (e) => {
        this.setState({
          loadingVisible: false
        });
      });

  }

  componentWillReceiveProps(nextProps) {
    if ( nextProps.match.params.type !== this.props.match.params.type ) {

      this.lawsuitService = new LawsuitService();

      document.body.style.cursor = "wait";

      this.getLawsuits( null );

    }
  }

  componentWillMount( ) {
    this.lawsuitService = new LawsuitService();

    document.body.style.cursor = "wait";

    this.getLawsuits( null );

  }

  getLawsuits( message, page=1 ) {

    this.lawsuitService = new LawsuitService();

    this.lawsuitService.list( null, null, null, page ).then(res =>{

      let response = ""; let highlight = [];  

      if ( message !== null  && this._isMounted ){
        localStorage.setItem('alert_message', message);
        localStorage.setItem('alert_type', 'alert-success');
        let time = new Date().getTime();
        localStorage.setItem('alert_time', time );
        setTimeout(() => {
          this.setState({
            updated: time,
          });
        }, 100);
      }

      /* tratar se tem numero do processo nao é processo manual*/
      let lawsuitList = this.state.lawsuitsRaw;
      res.data.Lawsuits.map( ( record, index, array ) => {
          if ( record.number === null ) {
            lawsuitList.push(record);
          }

          return record;

      });

      if( res.pagination.nextPage === true){
          this.setState({
            lawsuitsRaw: lawsuitList
          });

          this.getLawsuits( null, page+1 );
      }else{
        response = this.getHighlights ( lawsuitList );

        highlight = response.highlight;
  
        response = this.getArchived ( response.lawsuit );
  
        document.body.style.cursor = "default";
  
        if( this._isMounted ){
  
              this.setState({
  
                lawsuitList: response.lawsuit,
                qtd: response.lawsuit.length,
                totalStepsActive: totalSteps ( response.lawsuit ),
                lawsuitListHighlight: highlight,
                qtdHighlight: highlight.length,
                totalStepsHighlight: totalSteps ( highlight ),
                lawsuitListArchived: response.archived,
                qtdArchived: response.archived.length,
        
              });
  
        }
      }

    })
    .catch(err =>{
          if( this._isMounted ){
              document.body.style.cursor = "default";
              this.setState({
    
                lawsuitList: [],
                qtd: 0,
                totalStepsActive: 0,
                lawsuitListHighlight: [],
                qtdHighlight: 0,
                totalStepsHighlight: 0,
                lawsuitListArchived: [],
                qtdArchived: [],
    
              });
              //alert("Tribunal não encontrado.");

              this.setState({
                showAlert: true,
                message: "Tribunal não encontrado."
              });
          }
    });

  }

  getHighlights ( lawsuits ) {

    let highlight = []; let newe = []; let response = {};

    lawsuits.map( ( record, index, array ) => {

          if ( record.favorito === 1 ) {
            highlight.push(record);
          }else{
            newe.push(record);
          }

          return record;

    });

    response = {
      'highlight': highlight,
      'lawsuit': newe
    };

    return response;

  }

  getArchived ( lawsuits ) {

    let archived = []; let newe = []; let response = {};

    lawsuits.map( ( record, index, array ) => {

          if ( record.arquivo === 1 ) {
            archived.push(record);
          }else{
            newe.push(record);
          }

          return record;

    });

    response = {
      'archived': archived,
      'lawsuit': newe
    };

    return response;

  }

  find ( event ){
      document.body.style.cursor = "wait";
      this.lawsuitService.find( this.state.inp_pesquisa, this.state.opt_pesquisa.value, "&list_manual=1" ).then(res =>{
        if( this._isMounted ){

              let response = ""; let highlight = [];

              response = this.getHighlights ( res );
      
              highlight = response.highlight;
      
              response = this.getArchived ( response.lawsuit );
      
              document.body.style.cursor = "default";
      
              this.setState({
      
                lawsuitList: response.lawsuit,
                qtd: response.lawsuit.length,
                totalStepsActive: totalSteps ( response.lawsuit ),
                lawsuitListHighlight: highlight,
                qtdHighlight: highlight.length,
                totalStepsHighlight: totalSteps ( highlight ),
                lawsuitListArchived: response.archived,
                qtdArchived: response.archived.length,
      
              });

        }

      })
      .catch(err =>{
          if( this._isMounted ){

              document.body.style.cursor = "default";
              this.setState({

                lawsuitList: [],
                qtd: 0,
                totalStepsActive: 0,
                lawsuitListHighlight: [],
                qtdHighlight: 0,
                totalStepsHighlight: 0,
                lawsuitListArchived: [],
                qtdArchived: [],

              });
              //alert("Não foi possível estabelecer conexão com o servidor. Contate o administrador");
              this.setState({
                showAlert: true,
                message: "Não foi possível estabelecer conexão com o servidor. Contate o administrador"
              });

          }
      });
  }

  handleChange(e){

       this.setState(
           {
               [e.target.name]: e.target.value
           }
       )
   }

  clear ( event ){
    document.body.style.cursor = "wait";
    this.setState ({
        inp_pesquisa: "",
        opt_pesquisa: opt_pesquisa[0]
    });

    this.getLawsuits( null );

  }

  deleteLawsuit(token, r = false){

        //var r = window.confirm("Este processo e todas as informações, arquivos e documentos serão excluídos definitivamente. Esta ação é irreversível. Deseja realmente excluí-lo?");

        if ( r ){
          this.lawsuitService.delete( token )
              .then(res =>{
                if( res.success === true ){
                  var message = res.message;
                  document.body.style.cursor = "wait";

                  this.getLawsuits( message );

                }else{
                  localStorage.setItem('alert_message', res.message);
                  localStorage.setItem('alert_type', 'alert-danger');
                  let time = new Date().getTime();
                  localStorage.setItem('alert_time', time );
                  setTimeout(() => {
                    this.setState({
                      updated: time,
                    });
                  }, 100);
                }

              })
              .catch(err =>{
                  document.body.style.cursor = "default";
                  console.log(err);
              })
        }else{
          this.setState({
            showConfirm: true,
            lawsuitToDelete: token,
            message: "Este processo e todas as informações, arquivos e documentos serão excluídos definitivamente. Esta ação é irreversível. Deseja realmente excluí-lo?"
          });
        }

   }

   getClientOrOponent ( record, side ) {

     let name = []; let response = "";

     if ( typeof record.lawsuits_personages !== "undefined" ) {

         record.lawsuits_personages.map((value, index, array )=>{

           if ( value.personage_side_id === side ) {
             name.push( value.personage.name );
           }

           return "";

         });

         if( name.length >= 2 ) {
           response = name[0]+" (e outros)";
         }else{
           response = name[0];
         }

     }

     return response;

   }

  onChange = (event) => {
    this.setState({
      value: event.target.value
    });
  }

  addToHighlight = (event) => {

        document.body.style.cursor = "wait";

        let token = ""; let highlight = "";
        token = event.target.dataset.token;
        highlight = event.target.dataset.highlight;

        this.lawsuitService.addToHighlight( token )
            .then(res =>{
              if( res.success === true ){

                  if ( highlight === "1" ) {
                    this.getLawsuits( "Processo removido do destaque." );
                  }else{

                    this.getLawsuits( "Processo destacado com sucesso." );
                  }

              }else{
                localStorage.setItem('alert_message', res.message);
                localStorage.setItem('alert_type', 'alert-danger');
                let time = new Date().getTime();
                localStorage.setItem('alert_time', time );
                setTimeout(() => {
                  this.setState({
                    updated: time,
                  });
                }, 100);
              }

            })
            .catch(err =>{
                document.body.style.cursor = "default";
                console.log(err);
            })
  }

  lawsuitItem = ( {record, index}, type ) => {

    let title = ""; let classes = "";

      if ( record.favorito === 1 ){
        title = "Remover dos destaques";
      }else{
        title = "Destacar";
      }

      if (record.arquivo === 1){
        classes = "hide";
      }

      let button_delete_lawsuit = "";

      button_delete_lawsuit = <i className="fa fa-trash" data-token={record.token} onClick={() => this.deleteLawsuit(record.token)}></i>

      return (
        <tr key={record.token} className={type}>
              <td className="table_lawsuit_number">
                    <Link to={'/processo/detalhe_processo/'+record.token} >
                        {record.name}
                    </Link>
                    <Link to={'/processo/detalhe_processo/'+record.token+'?movements=true'} >
                        { (record.arquivo === 1 )? null : <Badge color="bg-yellow" info={record.movimentos_nao_visualizados} title="Andamentos Não visualizados" /> }
                    </Link>
              </td>
              <td className="table_lawsuit_cliente hidden-xs hidden-sm" title={this.getClientOrOponent(record, 1)} >
                      { this.getClientOrOponent(record, 1) }
              </td>
              <td className="table_lawsuit_oponente hidden-xs hidden-sm" title={this.getClientOrOponent(record, 2)} >
                      { this.getClientOrOponent(record, 2) }
              </td>
              <td className="table_lawsuit_actions">
                      { (record.arquivo === 1 )? null : <i className={ classes+" fa fa-star"} data-token={record.token} data-highlight={record.favorito} title={title} onClick={(event) => this.addToHighlight(event)}></i> }
                      {  (record.arquivo === 1 || !this.authService.can("cadastrar_processo_excluir", true, 'processos')  )? null : <Divider type="vertical" /> }
                      { this.authService.can("cadastrar_processo_excluir", true, 'processos') ? button_delete_lawsuit : null }
              </td>
        </tr>


      );
  };

  showLawsuits = ( e ) => {
    let itens = document.getElementsByClassName(e.target.dataset.type);

    for (let n = itens.length - 1; n >= 0; n--) {

         if ( n >= 10 ) {
           itens[n].className += ' show-table';
         }
     }

     document.getElementById("show-button-"+e.target.dataset.type).className += ' hide';
     document.getElementById("hide-button-"+e.target.dataset.type).className += ' show';

  }

  hideLawsuits = ( e ) => {
    let itens = document.getElementsByClassName(e.target.dataset.type);

    for (let n = itens.length - 1; n >= 0; n--) {

         if ( n >= 10 ) {
           itens[n].classList.remove('show-table');
         }
     }

     document.getElementById("hide-button-"+e.target.dataset.type).classList.remove('show');
     document.getElementById("show-button-"+e.target.dataset.type).classList.remove('hide');

  }

  show_hide_button_container = ( record, index, type ) => {

      if ( index > 8 && index < 10 ) {
        return (
          <div key={index} className="show-hide-button-container">
              <span id={"show-button-"+type} className="btn-small btn-info show-button" data-type={type} onClick={this.showLawsuits}>Ver mais</span>
              <span id={"hide-button-"+type} className="btn-small btn-info hide-button" data-type={type} onClick={this.hideLawsuits}>Ocultar registros</span>
          </div>
        );
      }else {
        return "";
      }

  };

  selectChange = (selected, actionMeta) => {
    let pops = [];
    pops[ actionMeta.name ] = selected;
    if ( typeof actionMeta.name !== undefined && actionMeta.name !== "" && actionMeta.name !== null ){

      if ( typeof selected !== undefined && selected !== "" && selected !== null ) {
        this.setState( pops );
      }

    }
  }

  render() {

      let url_base = localStorage.getItem('url_base');

      //var selectedOption = opt_pesquisa[0];
      let button_add_lawsuit = <div>
                                        <Link to={"add-manual"} className="btn btn-primary add-lawsuit">
                                            <label>Novo Processo Manual</label>
                                        </Link>
                                        <Popover
                                            isOpen={this.state.isAddPopoverOpen}
                                            position={['top', 'right', 'left', 'bottom']}
                                            padding={10}
                                            onClickOutside={() => this.setState({ isAddPopoverOpen: false })}
                                            content={({ position, targetRect, popoverRect }) => (
                                                <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                                                    position={position}
                                                    targetRect={targetRect}
                                                    popoverRect={popoverRect}
                                                    arrowColor={'#94bcd8'}
                                                    arrowSize={10}
                                                >
                                                    <div className="popover-box"
                                                        onClick={() => this.setState({ isAddPopoverOpen: !this.state.isAddPopoverOpen })}
                                                    >
                                                        Inicie o cadastro de um novo cliente, mesmo sem ter um processo.
                                                    </div>
                                                </ArrowContainer>
                                            )}
                                        >
                                            <img onMouseOut={() => this.setState({ isAddPopoverOpen: false })} onMouseOver={() => this.setState({ isAddPopoverOpen: !this.state.isAddPopoverOpen })} className="help_icon" alt="ajuda" src={url_base+"/images/interrogation_cropped.png"}></img>
                                        </Popover>
                               </div>

      return <div className="list-lawsuit manual-list-lawsuit sheet">
                <FlashMessage time={ new Date().getTime() } />
                <HeaderBar />
                <NavigationMenu />
                <Confirm show={this.state.showConfirm} text={this.state.message} />
                <Alert show={this.state.showAlert} text={this.state.message} />
                <div className="content-wrapper">
                  <div className="content-wrapper-inner">
                      <section className="content-header">
                        <h1 className="page-header">Listar Processos Manuais</h1>
                      </section>
                      <div className="box-body">
                          <div className="actions-container">
                              <div className="form-group lista-processos-action-buttons">
                                  { this.authService.can('cadastrar_processo', true, 'processos') ? button_add_lawsuit : null }
                              </div>

                              <form acceptCharset="utf-8"  className="form-pesquisa-tribunal" >
                                  <div className="row">
                                        <div className=" text-right container-label-pesquisa">
                                            <label htmlFor="inp_pesquisa" className="control-label">Pesquisar</label>
                                            <Popover
                                                isOpen={this.state.isEditPopoverOpen}
                                                position={['top', 'right', 'left', 'bottom']}
                                                padding={10}
                                                onClickOutside={() => this.setState({ isEditPopoverOpen: false })}
                                                content={({ position, targetRect, popoverRect }) => (
                                                    <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                                                        position={position}
                                                        targetRect={targetRect}
                                                        popoverRect={popoverRect}
                                                        arrowColor={'#94bcd8'}
                                                        arrowSize={10}
                                                        className={"arrow-"+position}
                                                    >
                                                        <div className={"popover-box"}
                                                            onClick={() => this.setState({ isEditPopoverOpen: !this.state.isEditPopoverOpen })}
                                                        >
                                                            Pesquisa N° de processo, Cliente e/ou Oponente somente dentro dos processos manuais.
                                                        </div>
                                                    </ArrowContainer>
                                                )}
                                            >
                                                <img onMouseOut={() => this.setState({ isEditPopoverOpen: false })} onMouseOver={() => this.setState({ isEditPopoverOpen: !this.state.isEditPopoverOpen })} className="help_icon" alt="ajuda" src={url_base+"/images/interrogation_cropped.png"}></img>
                                            </Popover>
                                        </div>
                                        <div className="container-input-pesquisa">
                                            <input type="text" className="form-control" name="inp_pesquisa" value={this.state.inp_pesquisa} onChange={this.handleChange} placeholder="Digite o termo" id="inp_pesquisa" />
                                        </div>
                                        <Select
                                            id="opt_pesquisa"
                                            className="opt_pesquisa"
                                            classNamePrefix="mySelect"
                                            options={opt_pesquisa}
                                            value={this.state.opt_pesquisa}
                                            name="opt_pesquisa"
                                            onChange={this.selectChange}
                                          />
                                        <div className="container-search">
                                            <span className="btn btn-primary" onClick={this.find}>Pesquisar</span>
                                            <span className="btn btn-danger" data-type={this.state.type} onClick={this.clear} >Limpar</span>
                                        </div>
                                    </div>
                              </form>
                          </div>
                          <div className="alert alert-info" role="alert">Processos Manuais: <strong>{this.state.qtd}</strong> / Processos Manuais Destacados: <strong>{this.state.qtdHighlight}</strong> / Processos Manuais Arquivados: <strong>{this.state.qtdArchived}</strong> </div>
                          <p></p>
                          <p></p>
                          <p></p>
                          <Tabs defaultActiveKey="1" size="large">
                                <TabPane tab={<span>Manuais Ativos <Badge color="bg-blue" info={this.state.qtd} title="N° de processos ativos" /> </span>} key="1">
                                    <table>
                                            <thead>
                                                <tr>
                                                      <th className="table_lawsuit_number">
                                                          <div>Processo</div>
                                                      </th>
                                                      <th className="table_lawsuit_cliente hidden-xs hidden-sm">
                                                          <div>Cliente</div>
                                                      </th>
                                                      <th className="table_lawsuit_oponente hidden-xs hidden-sm">
                                                          <div>Oponente</div>
                                                      </th>
                                                      <th className="table_lawsuit_actions">
                                                          <div>Ações</div>
                                                      </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                  { ( typeof this.state.lawsuitList !== "undefined") ? this.state.lawsuitList.map( (record, index) => this.lawsuitItem( {record, index}, 'lawsuit-item' ) ) : null }
                                            </tbody>
                                    </table>
                                    { ( typeof this.state.lawsuitList !== "undefined" ) ? this.state.lawsuitList.map( (record, index) =>
                                      this.show_hide_button_container( record, index, 'lawsuit-item' ) ) : ""
                                    }
                                </TabPane>
                                <TabPane tab={<span><i className="fa fa-star"></i><span> Manuais Destacados <Badge color="bg-blue" info={this.state.qtdHighlight} title="N° de processos destacados" /> </span></span>} key="2">
                                    <table className="highlight-table" >
                                            <thead>
                                                <tr>
                                                      <th className="table_lawsuit_number">
                                                          <div>Manuais</div>
                                                      </th>
                                                      <th className="table_lawsuit_cliente hidden-xs hidden-sm">
                                                          <div>Cliente</div>
                                                      </th>
                                                      <th className="table_lawsuit_oponente hidden-xs hidden-sm">
                                                          <div>Oponente</div>
                                                      </th>
                                                      <th className="table_lawsuit_actions">
                                                          <div>Ações</div>
                                                      </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                  { ( typeof this.state.lawsuitListHighlight !== "undefined") ? this.state.lawsuitListHighlight.map( (record, index) => this.lawsuitItem( {record, index}, 'lawsuit-highlight-item' ) ) : null }
                                            </tbody>
                                    </table>
                                    { ( typeof this.state.lawsuitListHighlight !== "undefined" ) ? this.state.lawsuitListHighlight.map( (record, index) =>
                                      this.show_hide_button_container( record, index, 'lawsuit-highlight-item' ) ) : ""
                                    }
                                </TabPane>
                                <TabPane tab={<span>Arquivados  <Badge color="bg-blue" info={this.state.qtdArchived} title="N° de processos arquivados" /></span>}  key="3">
                                      <table>
                                              <thead>
                                                  <tr>
                                                        <th className="table_lawsuit_number">
                                                            <div>Manuais</div>
                                                        </th>
                                                        <th className="table_lawsuit_cliente hidden-xs hidden-sm">
                                                            <div>Cliente</div>
                                                        </th>
                                                        <th className="table_lawsuit_oponente hidden-xs hidden-sm">
                                                            <div>Oponente</div>
                                                        </th>
                                                        <th className="table_lawsuit_actions">
                                                            <div>Ações</div>
                                                        </th>
                                                  </tr>
                                              </thead>
                                              <tbody>
                                                    { ( typeof this.state.lawsuitListArchived !== "undefined") ? this.state.lawsuitListArchived.map( (record, index) => this.lawsuitItem({ record, index }, 'lawsuit-archived-item') ) : null }
                                              </tbody>
                                      </table>
                                      { ( typeof this.state.lawsuitListArchived !== "undefined" ) ? this.state.lawsuitListArchived.map( (record, index) =>
                                        this.show_hide_button_container( record, index, 'lawsuit-archived-item' ) ) : ""
                                      }
                                </TabPane>
                          </Tabs>
                      </div>
                  </div>
                </div>
                <Footer />
             </div>
  }

}
