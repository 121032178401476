const options = [
    {
        key: 'sp',
        label: 'São Paulo',
        allowed: 1,        
        regiao: "sudeste",
        children: [
            {
                key: 'processo/listar_processo/tjsp',
                label: 'SP - Tribunal de Justiça',
                slug: "tjsp",
                allowed: 1
            },
            {
                key: 'processo/listar_processo/tjmsp',
                label: 'SP - TM - Tribunal Militar',
                slug: "tjmsp",
                allowed: 1
            },
            {
                key: 'processo/listar_processo/trt2',
                label: 'SP - TRT 2ª Região',
                slug: "trt2",
                allowed: 1
            },
            /*{
                key: 'processo/listar_processo/trt2pje',
                label: 'SP - TRT 2ª Região PJE',
                slug: "trt2pje",
                allowed: 0
            },
            {
                key: 'processo/listar_processo/trt15pje',
                label: 'SP - TRT 15ª Região - PJE',
                slug: "trt15pje",
                allowed: 0
            },*/
            {
                key: 'processo/listar_processo/trt15',
                label: 'SP - TRT 15ª Região',
                slug: "trt15",
                allowed: 1
            },
            {
                key: 'processo/listar_processo/jfsp',
                label: 'SP - 1º Grau Justiça Federal',
                slug: "jfsp", //1jf
                allowed: 1
            },
            /*{
                key: 'processo/listar_processo/trf3pje',
                label: 'SP - TRF3 - PJE',
                slug: "trf3pje",
                allowed: 0
            },*/
            {
                key: 'processo/listar_processo/trf3',
                label: 'SP - TRF3',
                slug: "trf3",
                allowed: 1
            },
            {
                key: 'processo/listar_processo/jef',
                label: 'SP - Juizado Especial Federal',
                slug: "jef",
                allowed: 1
            },
        ]
    },
    {
        key: 'rj',
        label: 'Rio de Janeiro',
        allowed: 0,
        regiao: "sudeste",
        children: [
            {
                key: 'processo/listar_processo/tjrj',
                label: 'RJ - Tribunal de Justiça',
                slug: "tjrj",
                allowed: 0
            },
            {
                key: 'processo/listar_processo/trt01-rj',
                label: 'RJ - TRT 1° Região',
                slug: "trt01-rj",
                allowed: 0
            },
            /*{
                key: 'processo/listar_processo/trt1pje',
                label: 'RJ - TRT 1° Região - PJE',
                slug: "trt1pje",
                allowed: 0
            },*/
            {
                key: 'processo/listar_processo/jfrj',
                label: 'RJ - Justiça Federal', //RJ - Justiça Federal 1º Grau
                slug: "jfrj", //jfrj1grau
                allowed: 0
            },
            {
                key: 'processo/listar_processo/trf2',
                label: 'RJ - TRF2 / E-Proc / JEF V1 e V2',
                slug: "trf2",
                allowed: 0
            },
        ]
    },
    {
        key: 'mg',
        label: 'Minas Gerais',
        allowed: 0,
        regiao: "sudeste",
        children: [
            {
                key: 'processo/listar_processo/tjmgprojudi',
                label: 'MG - TJMG - PROJUDI',
                slug: "tjmgprojudi",
                allowed: 0
            },
            {
                key: 'processo/listar_processo/tjmg',
                label: 'MG - Tribunal de Justiça',
                slug: "tjmg",
                allowed: 0
            },
            /*{
                key: 'processo/listar_processo/tjmgpje',
                label: 'MG - Tribunal de Justiça - PJE',
                slug: "tjmgpje",
                allowed: 0
            },*/
            {
                key: 'processo/listar_processo/trt3',
                label: 'MG - TRT 3ª Região',
                slug: "trt3",
                allowed: 0
            },
            /*{
                key: 'processo/listar_processo/trt3pje',
                label: 'MG - TRT 3ª Região - PJE',
                slug: "trt3pje",
                allowed: 0
            },*/
            {
                key: 'processo/listar_processo/trf1',
                label: 'MG - TRF1 / JFMG',
                slug: "trf1",
                allowed: 0
            },
        ]
    },
    {
        key: 'es',
        label: 'Espírito Santo',
        allowed: 0,
        regiao: "sudeste",
        children: [
            {
                key: 'processo/listar_processo/tjes',
                label: "ES - Tribunal de Justiça",
                slug: "tjes",
                allowed: 0
            },
            /*{
                key: 'processo/listar_processo/tre-es',
                label: "ES - TRE",
                slug: "tre-es",
                allowed: 0
            },*/
            {
                key: 'processo/listar_processo/trt17-es',
                label: "ES - TRT 17ª Região",
                slug: "trt17-es",
                allowed: 0
            },
            {
                key: 'processo/listar_processo/jfes',
                label: "ES - TRF2 / JFES",
                slug: "jfes",
                allowed: 0
            },
        ]
    },
    {
        key: 'pr',
        label: 'Paraná',
        allowed: 0,
        regiao: "sul",
        children: [
            {
                key: 'processo/listar_processo/tjpr',
                label: "PR - Tribunal de Justiça",
                slug: "tjpr",
                allowed: 0
            },
            /*{
                key: 'processo/listar_processo/tre-pr',
                label: "PR - TRE",
                slug: "tre-pr",
                allowed: 0
            },*/
            {
                key: 'processo/listar_processo/trt09-pr',
                label: "PR - TRT 9ª Região",
                slug: "trt09-pr",
                allowed: 0
            },
            {
                key: 'processo/listar_processo/jfpr',
                label: "PR - TRF4 / JFPR",
                slug: "jfpr",
                allowed: 0
            },
        ]
    },
    {
        key: 'sc',
        label: 'Santa Catarina',
        allowed: 0,
        regiao: "sul",
        children: [
            {
                key: 'processo/listar_processo/tjsc',
                label: "SC - Tribunal de Justiça",
                slug: "tjsc",
                allowed: 0
            },
            /*{
                key: 'processo/listar_processo/tre-sc',
                label: "SC - TRE",
                slug: "tre-sc",
                allowed: 0
            },*/
            {
                key: 'processo/listar_processo/trt12-sc',
                label: "SC - TRT 12ª Região",
                slug: "trt12-sc",
                allowed: 0
            },
            {
                key: 'processo/listar_processo/jfsc',
                label: "SC - TRF4 / JFSC",
                slug: "jfsc",
                allowed: 0
            },
        ]
    },
    {
        key: 'rs',
        label: 'Rio Grande do Sul',
        allowed: 0,
        regiao: "sul",
        children: [
            {
                key: 'processo/listar_processo/tjrs',
                label: "RS - Tribunal de Justiça",
                slug: "tjrs",
                allowed: 0
            },
            /*{
                key: 'processo/listar_processo/tre-rs',
                label: "RS - TRE",
                slug: "tre-rs",
                allowed: 0
            },*/
            {
                key: 'processo/listar_processo/trt04-rs',
                label: "RS - TRT 4ª Região",
                slug: "trt04-rs",
                allowed: 0
            },
            {
                key: 'processo/listar_processo/jfrs',
                label: "RS - TRF4 / JFRS",
                slug: "jfrs",
                allowed: 0
            },
        ]
    },
    {
        key: 'go',
        label: 'Goiás',
        allowed: 0,
        regiao: "centro-oeste",
        children: [
            {
                key: 'processo/listar_processo/tjgo',
                label: "GO - Tribunal de Justiça",
                slug: "tjgo",
                allowed: 0
            },
            /*{
                key: 'processo/listar_processo/tre-go',
                label: "GO - TRE",
                slug: "tre-go",
                allowed: 0
            },*/
            {
                key: 'processo/listar_processo/trt18-go',
                label: "GO - TRT 18ª Região",
                slug: "trt18-go",
                allowed: 0
            },
            {
                key: 'processo/listar_processo/jfgo',
                label: "GO - TRF1 / JFGO",
                slug: "jfgo",
                allowed: 0
            },
        ]
    },
    {
        key: 'ms',
        label: 'Mato Grosso do Sul',
        allowed: 0,
        regiao: "centro-oeste",
        children: [
            {
                key: 'processo/listar_processo/tjms',
                label: "MS - Tribunal de Justiça",
                slug: "tjms",
                allowed: 0
            },
            /*{
                key: 'processo/listar_processo/tre-ms',
                label: "MS - TRE",
                slug: "tre-ms",
                allowed: 0
            },*/
            {
                key: 'processo/listar_processo/trt24-ms',
                label: "MS - TRT 24ª Região",
                slug: "trt24-ms",
                allowed: 0
            },
            {
                key: 'processo/listar_processo/jfms',
                label: "MS - TRF3 / JFMS",
                slug: "jfms",
                allowed: 0
            },
        ]
    },
    {
        key: 'mt',
        label: 'Mato Grosso',
        allowed: 0,
        regiao: "centro-oeste",
        children: [
            {
                key: 'processo/listar_processo/tjmt',
                label: "MT - Tribunal de Justiça",
                slug: "tjmt",
                allowed: 0
            },
            /*{
                key: 'processo/listar_processo/tre-mt',
                label: "MT - TRE",
                slug: "tre-mt",
                allowed: 0
            },*/
            {
                key: 'processo/listar_processo/trt23-mt',
                label: "MT - TRT 23ª Região",
                slug: "trt23-mt",
                allowed: 0
            },
            {
                key: 'processo/listar_processo/jfm',
                label: "MT - TRF1 / JFM",
                slug: "jfm",
                allowed: 0
            },
        ]
    },
    {
        key: 'df',
        label: 'Distrito Federal',
        allowed: 0,
        regiao: "centro-oeste",
        children: [
            {
                key: 'processo/listar_processo/tjdf',
                label: "DF - Tribunal de Justiça",
                slug: "tjdf",
                allowed: 0
            },
            /*{
                key: 'processo/listar_processo/tre-df',
                label: "DF - TRE",
                slug: "tre-df",
                allowed: 0
            },*/
            {
                key: 'processo/listar_processo/trt10-df',
                label: "DF - TRT 10ª Região",
                slug: "trt10-df",
                allowed: 0
            },
            {
                key: 'processo/listar_processo/jfdf',
                label: "DF - TRF1 / JFDF",
                slug: "jfdf",
                allowed: 0
            },
        ]
    },
    {
        key: 'to',
        label: 'Tocantins',
        allowed: 0,
        regiao: "norte",
        children: [
            {
                key: 'processo/listar_processo/tjto',
                label: "TO - Tribunal de Justiça",
                slug: "tjto",
                allowed: 0
            },
            /*{
                key: 'processo/listar_processo/tre-to',
                label: "TO - TRE",
                slug: "tre-to",
                allowed: 0
            },*/
            {
                key: 'processo/listar_processo/trt10-to',
                label: "TO - TRT 10ª Região",
                slug: "trt10-to",
                allowed: 0
            },
            {
                key: 'processo/listar_processo/jfto',
                label: "TO - TRF1 / JFTO",
                slug: "jfto",
                allowed: 0
            },
        ]
    },
    {
        key: 'ro',
        label: 'Rondônia',
        allowed: 0,
        regiao: "norte",
        children: [
            {
                key: 'processo/listar_processo/tjro',
                label: "RO - Tribunal de Justiça",
                slug: "tjro",
                allowed: 0
            },
            /*{
                key: 'processo/listar_processo/tre-ro',
                label: "RO - TRE",
                slug: "tre-ro",
                allowed: 0
            },*/
            {
                key: 'processo/listar_processo/trt14-ro',
                label: "RO - TRT 14ª Região",
                slug: "trt14-ro",
                allowed: 0
            },
            {
                key: 'processo/listar_processo/jfro',
                label: "RO - TRF1 / JFRO",
                slug: "jfro",
                allowed: 0
            },
        ]
    },
    {
        key: 'am',
        label: 'Amazonas',
        allowed: 0,
        regiao: "norte",
        children: [
            {
                key: 'processo/listar_processo/tjam',
                label: "AM - Tribunal de Justiça",
                slug: "tjam",
                allowed: 0
            },
            /*{
                key: 'processo/listar_processo/tre-am',
                label: "AM - TRE",
                slug: "tre-am",
                allowed: 0
            },*/
            {
                key: 'processo/listar_processo/trt11-am',
                label: "AM - TRT 11ª Região",
                slug: "trt11-am",
                allowed: 0
            },
            {
                key: 'processo/listar_processo/jfam',
                label: "AM - TRF1 / JFAM",
                slug: "jfam",
                allowed: 0
            },
        ]
    },
    {
        key: 'ac',
        label: 'Acre',
        allowed: 0,
        regiao: "norte",
        children: [
            {
                key: 'processo/listar_processo/tjac',
                label: "AC - Tribunal de Justiça",
                slug: "tjac",
                allowed: 0
            },
            /*{
                key: 'processo/listar_processo/teac',
                label: "AC - TRE",
                slug: "tre-am",
                allowed: 0
            },*/
            {
                key: 'processo/listar_processo/trt14-ac',
                label: "AC - TRT 14ª Região",
                slug: "trt14-ac",
                allowed: 0
            },
            {
                key: 'processo/listar_processo/jfac',
                label: "AC - TRF1 / JFAC",
                slug: "jfac",
                allowed: 0
            },
        ]
    },
    {
        key: 'rr',
        label: 'Roraima',
        allowed: 0,
        regiao: "norte",
        children: [
            {
                key: 'processo/listar_processo/tjrr',
                label: "RR - Tribunal de Justiça",
                slug: "tjrr",
                allowed: 0
            },
            /*{
                key: 'processo/listar_processo/tre-rr',
                label: "RR - TRE",
                slug: "tre-rr",
                allowed: 0
            },*/
            {
                key: 'processo/listar_processo/trt11-rr',
                label: "RR - TRT 11ª Região",
                slug: "trt11-rr",
                allowed: 0
            },
            {
                key: 'processo/listar_processo/jfrr',
                label: "RR - TRF1 / JFRR",
                slug: "jfrr",
                allowed: 0
            },
        ]
    },
    {
        key: 'pa',
        label: 'Pará',
        allowed: 0,
        regiao: "norte",
        children: [
            {
                key: 'processo/listar_processo/tjpa',
                label: "PA - Tribunal de Justiça",
                slug: "tjpa",
                allowed: 0
            },
            /*{
                key: 'processo/listar_processo/tre-pa',
                label: "PA - TRE",
                slug: "tre-pa",
                allowed: 0
            },*/
            {
                key: 'processo/listar_processo/trt08-pa',
                label: "PA - TRT 8ª Região",
                slug: "trt08-pa",
                allowed: 0
            },
            {
                key: 'processo/listar_processo/jfpa',
                label: "PA - TRF1 / JFPA",
                slug: "jfpa",
                allowed: 0
            },
        ]
    },
    {
        key: 'ap',
        label: 'Amapá',
        allowed: 0,
        regiao: "norte",
        children: [
            {
                key: 'processo/listar_processo/tjap',
                label: "AP - Tribunal de Justiça",
                slug: "tjap",
                allowed: 0
            },
            /*{
                key: 'processo/listar_processo/tre-ap',
                label: "AP - TRE",
                slug: "tre-ap",
                allowed: 0
            },*/
            {
                key: 'processo/listar_processo/trt08-ap',
                label: "AP - TRT 8ª Região",
                slug: "trt08-ap",
                allowed: 0
            },
            {
                key: 'processo/listar_processo/jfap',
                label: "AP - TRF1 / JFAP",
                slug: "jfap",
                allowed: 0
            },
        ]
    },
    {
        key: 'ma',
        label: 'Maranhão',
        allowed: 0,
        regiao: "nordeste",
        children: [
            {
                key: 'processo/listar_processo/tjma',
                label: "MA - Tribunal de Justiça",
                slug: "tjma",
                allowed: 0
            },
            /*{
                key: 'processo/listar_processo/tre-ma',
                label: "MA - TRE",
                slug: "tre-ma",
                allowed: 0
            },*/
            {
                key: 'processo/listar_processo/trt16-ma',
                label: "MA - TRT 16ª Região",
                slug: "trt16-ma",
                allowed: 0
            },
            {
                key: 'processo/listar_processo/jfma',
                label: "MA - TRF1 / JFMA",
                slug: "jfma",
                allowed: 0
            },
        ]
    },
    {
        key: 'pi',
        label: 'Piauí',
        allowed: 0,
        regiao: "nordeste",
        children: [
            {
                key: 'processo/listar_processo/tjpi',
                label: "PI - Tribunal de Justiça",
                slug: "tjpi",
                allowed: 0
            },
            /*{
                key: 'processo/listar_processo/tre-pi',
                label: "PI - TRE",
                slug: "tre-pi",
                allowed: 0
            },*/
            {
                key: 'processo/listar_processo/trt22-pi',
                label: "PI - TRT 22ª Região",
                slug: "trt22-pi",
                allowed: 0
            },
            {
                key: 'processo/listar_processo/jfpi',
                label: "PI - TRF1 / JFPI",
                slug: "jfpi",
                allowed: 0
            },
        ]
    },
    {
        key: 'ce',
        label: 'Ceará',
        allowed: 0,
        regiao: "nordeste",
        children: [
            {
                key: 'processo/listar_processo/tjce',
                label: "CE - Tribunal de Justiça",
                slug: "tjce",
                allowed: 0
            },
            /*{
                key: 'processo/listar_processo/tre-ce',
                label: "CE - TRE",
                slug: "tre-ce",
                allowed: 0
            },*/
            {
                key: 'processo/listar_processo/trt07-ce',
                label: "CE - TRT 7ª Região",
                slug: "trt07-ce",
                allowed: 0
            },
            {
                key: 'processo/listar_processo/jfce',
                label: "CE - TRF5 / JFCE",
                slug: "jfce",
                allowed: 0
            },
        ]
    },
    {
        key: 'rn',
        label: 'Rio Grande do Norte',
        allowed: 0,
        regiao: "nordeste",
        children: [
            {
                key: 'processo/listar_processo/tjrn',
                label: "RN - Tribunal de Justiça",
                slug: "tjrn",
                allowed: 0
            },
            /*{
                key: 'processo/listar_processo/tre-rn',
                label: "RN - TRE",
                slug: "tre-rn",
                allowed: 0
            },*/
            {
                key: 'processo/listar_processo/trt21-rn',
                label: "RN - TRT 21ª Região",
                slug: "trt21-rn",
                allowed: 0
            },
            {
                key: 'processo/listar_processo/jfrn',
                label: "RN - TRF5 / JFRN",
                slug: "jfrn",
                allowed: 0
            },
        ]
    },
    {
        key: 'pb',
        label: 'Paraíba',
        allowed: 0,
        regiao: "nordeste",
        children: [
            {
                key: 'processo/listar_processo/tjpb',
                label: "PB - Tribunal de Justiça",
                slug: "tjpb",
                allowed: 0
            },
            /*{
                key: 'processo/listar_processo/tre-pb',
                label: "PB - TRE",
                slug: "tre-pb",
                allowed: 0
            },*/
            {
                key: 'processo/listar_processo/trt13-pb',
                label: "PB - TRT 13ª Região",
                slug: "trt13-pb",
                allowed: 0
            },
            {
                key: 'processo/listar_processo/jfpb',
                label: "PB - TRF5 / JFPB",
                slug: "jfpb",
                allowed: 0
            },
            {
                key: 'processo/listar_processo/jfpb-pje',
                label: "PB - TRF5 / JFPB - PJE",
                slug: "jfpb-pje",
                allowed: 0
            },
        ]
    },
    {
        key: 'pe',
        label: 'Pernambuco',
        allowed: 0,
        regiao: "nordeste",
        children: [
            {
                key: 'processo/listar_processo/tjpe',
                label: "PE - Tribunal de Justiça",
                slug: "tjpe",
                allowed: 0
            },
            /*{
                key: 'processo/listar_processo/tre-pe',
                label: "PE - TRE",
                slug: "tre-pe",
                allowed: 0
            },*/
            {
                key: 'processo/listar_processo/trt06-pe',
                label: "PE - TRT 6ª Região",
                slug: "trt06-pe",
                allowed: 0
            },
            {
                key: 'processo/listar_processo/jfpe',
                label: "PE - TRF5 / JFPE",
                slug: "jfpe",
                allowed: 0
            },
        ]
    },
    {
        key: 'al',
        label: 'Alagoas',
        allowed: 0,
        regiao: "nordeste",
        children: [
            {
                key: 'processo/listar_processo/tjal',
                label: "AL - Tribunal de Justiça",
                slug: "tjal",
                allowed: 0
            },
            /*{
                key: 'processo/listar_processo/tre-al',
                label: "AL - TRE",
                slug: "tre-al",
                allowed: 0
            },*/
            {
                key: 'processo/listar_processo/trt19-al',
                label: "AL - TRT 19ª Região",
                slug: "trt19-al",
                allowed: 0
            },
            {
                key: 'processo/listar_processo/jfal',
                label: "AL - TRF5 / JFAL",
                slug: "jfal",
                allowed: 0
            },
        ]
    },
    {
        key: 'se',
        label: 'Sergipe',
        allowed: 0,
        regiao: "nordeste",
        children: [
            {
                key: 'processo/listar_processo/tjse',
                label: "SE - Tribunal de Justiça",
                slug: "tjse",
                allowed: 0
            },
            /*{
                key: 'processo/listar_processo/tre-se',
                label: "SE - TRE",
                slug: "tre-se",
                allowed: 0
            },*/
            {
                key: 'processo/listar_processo/trt20-se',
                label: "SE - TRT 20ª Região",
                slug: "trt20-se",
                allowed: 0
            },
            {
                key: 'processo/listar_processo/jfse',
                label: "SE - TRF5 / JFSE",
                slug: "jfse",
                allowed: 0
            },
        ]
    },
    {
        key: 'ba',
        label: 'Bahia',
        allowed: 0,
        regiao: "nordeste",
        children: [
            {
                key: 'processo/listar_processo/tjba',
                label: "BA - Tribunal de Justiça",
                slug: "tjba",
                allowed: 0
            },
            /*{
                key: 'processo/listar_processo/tre-ba',
                label: "BA - TRE",
                slug: "tre-ba",
                allowed: 0
            },*/
            {
                key: 'processo/listar_processo/trt05-ba',
                label: "BA - TRT 5ª Região",
                slug: "trt05-ba",
                allowed: 0
            },
            {
                key: 'processo/listar_processo/jfba',
                label: "BA - TRF1 / JFBA",
                slug: "jfba",
                allowed: 0
            },
        ]
    },
    {
        key: 'federais',
        label: 'Federais',
        allowed: 0,
        children: [
            {
                key: 'processo/listar_processo/stf',
                label: 'STF - Supremo Tribunal Federal',
                slug: "stf",
                allowed: 0
            },
            {
                key: 'processo/listar_processo/stj',
                label: 'STJ - Supremo Tribunal de Justiça',
                slug: "stj",
                allowed: 0
            },
            {
                key: 'processo/listar_processo/tse',
                label: 'TSE - Tribunal Superior Eleitoral',
                slug: "tse",
                allowed: 0
            },
            {
                key: 'processo/listar_processo/tst',
                label: 'TST - Tribunal Superior do Trabalho',
                slug: "tst",
                allowed: 0
            },
        ]
    },
];
export default options;
