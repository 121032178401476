import React from 'react';
import ReactQuill from 'react-quill';

import pdfComponent from './pdfComponent';

import company_logo from '../json/company_logo';

import print from './print-style.js';

import OfficeService from '../../services/OfficeService';

export default class PrintComponent extends React.Component { 
  _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }


  constructor( props ) {
      super(props);

      this.back = this.back.bind(this);
      this.modifiedViewPrint = this.props.modifiedViewPrint.bind(this);

      this.state = {
        office: {
          name: "DFOLI COMERCIO E SERVIÇOS LTDA"
        },
        logo: company_logo,
        title: "",
        subtitle: ""
      }

  }

  componentWillReceiveProps(nextProps) {

    if ( nextProps !== this.props) {
      this.initParams( nextProps );
    }else{
      this.initParams( this.props );
    }

  }

  componentWillMount() {

    this.initParams( this.props );
    this.getHeader();

  }

  initParams( params ){

      this.setState({
        content: params.content,
        title: params.title,
        subtitle: params.subtitle,
        name: params.name,
        panel_filtros: params.panel_filtros
      });

  }

  getHeader = () => {
        this.OfficeService = new OfficeService();
        let token = JSON.parse(localStorage.getItem('loggedUser')).office_token;//'4eb7f0a8-4541-4e96-890e-9318b232f945';

        //console.log("carregou");

        this.OfficeService.getConfigs( token ).then(res =>{

              let logo = (res.logo !== null ) ? res.logo : this.state.logo;

              this.setState({
                office: {
                  name: res.cabecalho
                },
                logo: logo
              });
        });
  }

  back(){
    this.props.history.goBack();
  }

  setStyle() {
    return { __html: print[0].key };
  }

  print = (event) => {
    this.setState({
      print: 1,
    });
  }

  screen = (event) => {
    this.modifiedViewPrint();
    this.setState({
      print: 0,
    });
  }

  /*createMarkup = () => {
    return {__html: this.state.office.name};
  }*/

    modules = {
      toolbar: [

      ],
    };

    formats = [
      'header',
      'bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block',
      'list', 'bullet', 'indent',
      'image', 'align',
      'font', 'color', 'size'
    ];

  render() {
      //let url_base = localStorage.getItem('url_base');

          return <div>
                    <style dangerouslySetInnerHTML={ this.setStyle() } />
                    <table id="table-layout">

                          <thead>
                            <tr>
                              <th>
                                    <div className="page-header-space">
                                            <div className="topo">
                                                  <div className="headline">
                                                        <div className="image-top">
                                                              <img alt="dfoli" src={"data:image/png;charset=utf-8;base64, "+this.state.logo} width="150px"  />
                                                        </div>
                                                        <div id="office" className="office">
                                                                <ReactQuill
                                                                    theme="snow"
                                                                    readOnly={true}
                                                                    value={this.state.office.name}
                                                                    modules={this.modules}
                                                                    formats={this.formats}
                                                                />
                                                        </div>
                                                  </div>
                                            </div>
                                    </div>
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                              <tr>
                                <td>
                                        <div className="sub">
                                              <p className="report-title">{this.state.title}</p>
                                              <div className="subtitle">
                                                  {this.state.subtitle} {( typeof this.state.name !== "undefined" || this.state.name !== "" ) ? this.state.name : "" }
                                              </div>
                                              <div className="controls">
                                                  <button onClick={(event) => this.screen(event)} >Voltar</button>
                                                  <button onClick={ () => window.print() }>Imprimir</button>
                                                  <button onClick={() => { pdfComponent("root", this.state.title) }}> Gerar PDF</button>
                                              </div>
                                        </div>
                                        {this.state.panel_filtros}
                                        {this.state.content}
                                        <div className="right">
                                            <button onClick={(event) => this.screen(event)} >Voltar</button>
                                            <button onClick={ () => window.print() }>Imprimir</button>
                                            <button onClick={() => { pdfComponent("root", this.state.title) }}> Gerar PDF</button>
                                        </div>
                              </td>
                            </tr>
                          </tbody>
                          <tfoot>
                                  <tr>
                                        <td>
                                          <div className="page-footer-space"></div>
                                        </td>
                                  </tr>
                          </tfoot>
                  </table>
               </div>

  }

}
