import React from 'react';
import Select from 'react-select';
import Loading from '../../containers/loading';
import LawsuitService from '../../../services/LawsuitService';
import UsersService from '../../../services/UsersService';
import RJLawsuitInput from './rjLawsuitInput';

import FlashMessage from "../../containers/FlashMessage";
import Alert from '../../containers/alert';
const instancia = [
  { value: '1', label: 'Processo 1ª Instância' },
  { value: '2', label: 'Processo 2ª Instância' },
];

export default class AddTRF2 extends React.Component { _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }


  constructor( props ) {
      super(props);
      this.handleChange = this.handleChange.bind(this);
      this.handleFormSubmit = this.handleFormSubmit.bind(this);
      this.LawsuitService = new LawsuitService();

            let message = "";
      let showAlert = "";
      if( props.tribunal_id === 999 ){
               // alert("Não foi possível estabelecer conexão com o servidor. Contate o administrador");
        message = "Não foi possível estabelecer conexão com o servidor. Contate o administrador";
        showAlert = true;
      }

      console.log(props.tribunal_id);

      this.state = { sponsorsList: [{}], sponsor: 0, instance: instancia[0], lawsuit_number: "", message, showAlert,
       tribunal_id: props.tribunal_id, errors: {}, loadingVisible: false, loadingMessage: [] };

  }

  handleFormSubmit(e){
       e.preventDefault();

       if (this.validateForm()) {
         document.getElementById("addButton").disabled = true;
         document.body.style.cursor = "wait";

         this.setState({
          loadingVisible: true,
          loadingMessage: [
            "O cadastro está sendo efetuado.", 
            "Por gentileza, aguarde...", 
            "Tempo estimado para leitura de Captchas/Dados: \nde 15 a 90 segundos \n \n \n"+
            "Verifique o Status deste cadastro: \n"+
            "Cadastrado = Trouxe todos os dados \n"+
            "Validando = Aguarde, busca em andamento \n"+
            "Erro = Nº ou Tribunal com erro."
           ] 
         });

         let lawsuit = {
           user_token: this.state.sponsor.value,
           instancia: parseInt( this.state.instance.value ),
           number: this.state.lawsuit_number,
           tribunal_id: this.state.tribunal_id.toString()
         }

         this.LawsuitService.add( lawsuit ).then(res =>{

          if (this._isMounted) {

            document.getElementById("addButton").disabled = false;
            document.body.style.cursor = "default";
 
            if ( res.success === true ) {
              localStorage.setItem('alert_message', "Processo cadastrado com sucesso");
              localStorage.setItem('alert_type', 'alert-success');
              let time = new Date().getTime();
              localStorage.setItem('alert_time', time );
              this.props.history.push( '/processo/detalhe_processo/'+res.data.Lawsuits[0].token+'?parte=true' );
            }else {
              localStorage.setItem('alert_message', res.message);
              localStorage.setItem('alert_type', 'alert-danger');
              let time = new Date().getTime();
              localStorage.setItem('alert_time', time );
            }
 
            setTimeout(() => {
 
              this.setState({
                loadingVisible: false,
                loadingMessage: []
              });
 
            }, 1000);

          }

         })
         .catch(err =>{

          if (this._isMounted) {

              document.getElementById("addButton").disabled = false;
              document.body.style.cursor = "default";
              console.log(err);
              this.setState({
                loadingVisible: false,
                loadingMessage: []
              });

          }

         });


       }

  }

  sponsorChange = (selectedOption) => {
    this.setState({ "sponsor": selectedOption });
  }

  instanceChange = (selectedOption) => {
    this.setState({ "instance": selectedOption });
  }

  handleChange(e){

       this.setState(
           {
               [e.target.name]: e.target.value
           }
       )
   }

   validateForm() {
       let errors = {};
       let formIsValid = true;

       /*if ( !this.state.sponsor ) {
          formIsValid = false;
          errors["sponsor"] = "*Por favor escolha um responsável";
        }

        if (typeof this.state.sponsor !== "undefined") {
          if ( this.state.sponsor===0 ) {
            formIsValid = false;
            errors["sponsor"] = "*Por favor escolha um responsável";
          }
        }

        if ( !this.state.instance ) {
           formIsValid = false;
           errors["instance"] = "*Por favor selecione uma instância";
         }

         if (typeof this.state.instance !== "undefined") {
           if ( this.state.instance===0 ) {
             formIsValid = false;
             errors["instance"] = "*Por favor selecione uma instância";
           }
         }*/

         if ( !this.state.lawsuit_number ) {
            formIsValid = false;
            errors["lawsuit_number"] = "*Preencha o número do processo";
          }

          if ( this.state.lawsuit_number.search("_") !== -1 ) {
             formIsValid = false;
             errors["lawsuit_number"] = "*Número de Processo incorreto";
           }

          if (typeof this.state.lawsuit_number !== "undefined") {
            if ( this.state.lawsuite_number==="" ) {
              formIsValid = false;
              errors["lawsuit_number"] = "*Preencha o número do processo";
            }
          }

        this.setState({
           errors: errors
         });

         return formIsValid;
  }


  componentDidMount( ) { 
this._isMounted = true;


    document.addEventListener("cancelAlert", (e) => {
      if( this._isMounted ){
        this.setState({
          showAlert: false
        });
      }
    });
    document.addEventListener("cancelLoading", (e) => {
      this.setState({
        loadingVisible: false
      });
    });

    this.usersService = new UsersService();

    this.usersService.list( ).then(res =>{

      let options = res; let sponsor = [];

      let user = JSON.parse( localStorage.getItem( 'loggedUser' ) );

      options.map((value, index, array )=>{
        if ( user.token === value.value ) {
          sponsor = value;
        }
        return value;
      });

      this.setState({
        sponsorsList: options,
        sponsor: sponsor,
      });
    });

  }

  render() {

      return <div className="box box-success">
                  <FlashMessage time={ new Date().getTime() } />
                  <Alert show={this.state.showAlert} text={this.state.message} />
                  <Loading visible={this.state.loadingVisible} message1={this.state.loadingMessage[0]} message2={this.state.loadingMessage[1]} message3={this.state.loadingMessage[2]} cancel={true}   />
                  <div className="box-header with-border">
                    <h3 className="box-title">Cadastrar processo - RJ - TRF2</h3>
                  </div>
                  <div className="box-body">
                    <form>
                      <div className="form-group">
                          <label htmlFor="tribunal-type">Tribunal</label>
                          <input className="form-control input-lg" name="tribunal-type" type="text" placeholder= "RJ - TRF2" readOnly="readonly" />
                      </div>
                      <div className="form-group">
                          <label htmlFor="sponsor">Responsável pelo Processo<span className="text-red">*</span></label>
                          <Select
                              className="sponsor"
                              options={this.state.sponsorsList}
                              defaultValue={this.state.sponsor}
                              value={this.state.sponsor}
                              name="sponsor"
                              onChange={this.sponsorChange}
                            />
                            <div className="errorMsg">{this.state.errors.sponsor}</div>
                      </div>
                      <div className="form-group">
                          <label htmlFor="instance">Instância<span className="text-red">*</span></label>
                          <Select
                              className="instance"
                              defaultValue={instancia[0]}
                              options={instancia}
                              placeholder="(escolha um)"
                              name="instance"
                              onChange={this.instanceChange}
                            />
                          <div className="errorMsg">{this.state.errors.instance}</div>
                      </div>
                    <br />
                    <div className="form-group">
                        <label htmlFor="lawsuit-number">Número do Processo<span className="text-red">*</span></label>
                        <RJLawsuitInput className="form-control input-lg" name="lawsuit_number" onChange={this.handleChange} required />
                        <div className="errorMsg">{this.state.errors.lawsuit_number}</div>
                    </div>
                    <div className="form-group">
                        <input
                            className="btn btn-primary form-submit"
                            value="Cadastrar"
                            id="addButton"
                            type="submit"
                            onClick={this.handleFormSubmit.bind(this)}
                        />
                    </div>
                    <div className="well">
                        <strong>Exemplos:</strong>
                        <p>Número do Processo: 9999999-99.9999.4.02.9999</p>
                    </div>
                   </form>
                  </div>

          </div>

  }

}
