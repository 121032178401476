const print = [
    {
        key:`

      .titulo {
        text-align: center;
      }
      .titulo p {
          font-size: 22px;
      }
      .btns-toogle {
          display: inline-flex;
          width: 100%;
          max-width: 390px;
      }

      .icon-txt.no-content .content {
          padding: 0 5%;
          background: none;
          box-shadow: none;
      }
      .icon-txt.no-content .content i {
          height: 88px;
      }
      .icon-txt.no-content .content i svg {
          height: auto;
      }
      .tabs-container .tab__content.bg-azul {
          color: #fff;
          background-color: #0061ce;
      }
      .tabs-container .tab__content ul.list-icon {
          padding: 15px 35px;
      }

      .btns-toogle {
          display: inline-flex;
          width: 100%;
          max-width: 390px;
      }
      .btns-toogle a {
          width: 50%;
          height: 50px;
          line-height: 50px;
          text-align: center;
          text-decoration: none;
          display: block;
          font-size: 16px;
          font-weight: 700;
          background-color: #ededed;
          color: #333;
          transition: all 0.3s ease-in-out;
      }
      .btns-toogle a:hover {
          background-color: #cecece;
      }
      .btns-toogle a.active {
          color: #fff;
          font-weight: 400;
          background-color: #0061ce;
          cursor: default;
      }
      .btns-toogle a.btn-mensal {
          border-radius: 100px 0 0 100px;
      }
      .btns-toogle a.btn-anual {
          border-radius: 0 100px 100px 0;
      }

      .info-anual {
          display: none;
      }

      .slider {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
      }
      .slider > i {
          display: none;
      }
      .slider .content {
          width: 100%;
      }
      .slider .content .servico-titulo {
          display: flex;
          align-items: baseline;
          padding-bottom: 25px;
      }
      .slider .content .servico-titulo h4 {
          position: relative;
          margin-bottom: 0;
          font-size: 18px;
          font-weight: 700;
      }
      .slider .content .servico-titulo h4 .ultima-palavra {
          padding-right: 30px;
          position: relative;
          display: inline-block;
      }
      .slider .content .servico-titulo h4 .ultima-palavra i {
          width: 21px;
          position: absolute;
          bottom: 0px;
          right: 0;
      }
      .slider .content > em {
          display: block;
          height: 25px;
          margin-top: -25px;
          font-size: 13px;
      }
      .slider .content input {
          /*margin-top: 22px;*/
      }
      .slider .valores {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          align-self: flex-end;
          margin-bottom: 30px;
          margin-left: 30px;
      }
      .slider input.qtd,
      .slider input.preco {
          height: 60px;
          border-radius: 13px;
          border: 2px solid #ededed;
          margin-bottom: 15px;
          font-size: 22px;
          padding: 10px 20px;
          font-weight: 300;
          color: #757575;
      }
      .slider input.qtd {
          width: 38.1%;
          max-width: 120px;
          text-align: center;
      }
      .slider input.preco {
          width: calc(61.9% - 5px);
          max-width: 190px;
          margin-left: 5px;
          text-align: right;
      }
      .slider-total {
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: flex-end;
      }
      .slider-total p {
          font-weight: 700;
          font-size: 18px;
          margin: 1em 0;
      }
      .slider-total input.preco {
          border: 3px solid #0061ce;
          margin-left: 40px;
          margin-bottom: 0;
      }
      .slider_2 {
          position: relative;
          transition: all 0.3s ease-in-out;
      }
      .slider_2 > span {
          position: absolute;
          width: 11px;
          height: 8px;
          border-radius: 4px 0 0 4px;
          background-color: #ccc;
          bottom: 21px;
          left: 0;
          transition: all 0.3s ease-in-out;
      }
      .slider_2 > span > em {
          position: absolute;
          left: 10px;
          bottom: 100%;
          white-space: nowrap;
          font-size: 13px;
          border-left: 1px solid #ccc;
          padding: 0 0 15px 10px;
      }
      @media screen and (min-width: 768px) {
          .slider {
              flex-direction: row;
              flex-wrap: wrap;
          }
          .slider > i {
              display: block;
              width: 95px;
              margin-bottom: 0;
              justify-content: center;
          }
          .slider .content {
              width: calc(100% - 125px);
          }
      }
      @media screen and (min-width: 992px) {
          .slider .content {
              width: calc(100% - 470px);
              min-height: auto;
          }
          .slider .valores {
              max-width: 315px;
              height: 100%;
              margin-bottom: 0;
              align-items: flex-end;
          }
          .slider .valores input {
              margin-bottom: 25px;
          }
      }

      .tpd-tooltip .tpd-content,
      .tpd-tooltip .tpd-title,
      .tpd-tooltip .tpd-close {
          color: #fff;
      }
      .tpd-tooltip .tpd-background-content {
          background-color: #0061ce;
      }
      .tpd-tooltip .tpd-background {
        border: none;
      }
      .tpd-tooltip .tpd-content, .tpd-tooltip .tpd-title {
          padding: 25px!important;
          font-size: 13px;
          line-height: 1.5;
      }
      .tpd-tooltip .tpd-background-title {
          background-color: #0061ce;
      }
      /* shadow */
      .tpd-tooltip .tpd-background-shadow { box-shadow: 7px 6px 50px rgba(0,0,0,0.2); }

      input[type=range] {
          -webkit-appearance: none;
          margin: 0;
          padding: 0;
          width: 100%;
      }
      input[type=range]:focus {
          outline: none;
      }
      input[type=range]::-webkit-slider-runnable-track {
          width: 100%;
          height: 8px;
          cursor: pointer;
          animate: 0.2s;
          background: #ededed;
          border-radius: 25px;
      }
      input[type=range]::-webkit-slider-thumb {
          height: 22px;
          width: 22px;
          border-radius: 22px;
          border: none;
          background: #0061ce;
          cursor: pointer;
          -webkit-appearance: none;
          margin-top: -7px;
          position: relative;
      }
      input[type=range]:focus::-webkit-slider-runnable-track {
          background: #ededed;
      }
      input[type=range]::-moz-range-track {
          width: 100%;
          height: 8px;
          cursor: pointer;
          animate: 0.2s;
          background: #ededed;
          border-radius: 25px;
      }
      input[type=range]::-moz-range-thumb {
          height: 22px;
          width: 22px;
          border-radius: 22px;
          background: #0061ce;
          border: none;
          cursor: pointer;
      }
      input[type=range]::-ms-track {
          width: 100%;
          height: 8px;
          cursor: pointer;
          animate: 0.2s;
          background: transparent;
          border-color: transparent;
          border-width: 22px 0;
          color: transparent;
      }
      input[type=range]::-ms-fill-lower {
          background: #ededed;
          border-radius: 50px;
      }
      input[type=range]::-ms-fill-upper {
          background: #ededed;
          border-radius: 50px;
      }
      input[type=range]::-ms-thumb {
          height: 22px;
          width: 22px;
          border-radius: 22px;
          background: #0061ce;
          border: none;
          cursor: pointer;
      }
      input[type=range]:focus::-ms-fill-lower {
          background: #ededed;
      }
      input[type=range]:focus::-ms-fill-upper {
          background: #ededed;
      }


      .cta-interno {
          background-color: #0061ce;
          border-radius: 5px;
          box-shadow: 7px 6px 50px rgba(0,0,0,0.2);
          margin-top: 100px;
          padding: 0 45px 55px 45px;
          color: #fff;
      }
      .cta-interno.acao .form-assinar {
          width: calc(100% + 60px);
          padding-top: 0;
          margin-top: -33px;
          margin-left: -30px;
          margin-right: -30px;
          margin-bottom: 50px;
      }
      .form-assinar button {
          width: 300px;
          border-radius: 100px;
          font-size: 20px;
          height: 66px;
          margin-top: 30px;
          margin-right: 20px;
          border: 3px solid #fff;
          background: #57b317;
          color: white;
          float: right;
      }
      .form-assinar button:hover {
        color: #fff;
        background: #6ae713;
      }
      .cta-interno.acao .form-assinar em {
          font-size: 13px;
      }
      .cta-interno h2 {
          font-size: 26px;
          text-align: center;
          font-weight: 300;
          margin-bottom: 40px;
      }
      @media screen and (min-width: 576px) {
          .cta-interno {
              padding: 0 60px 60px 60px;
          }
          .cta-interno.acao .form-assinar {
              max-width: 385px;
              margin-left: auto;
              margin-right: auto;
          }
      }
      @media screen and (min-width: 768px) {
          .cta-interno {
              padding: 0 60px 50px 60px;
          }
      }


      .list-depoimentos {
          width: 70%;
          margin-left: auto;
          margin-right: auto;
          overflow: hidden;
          text-align: center;
      }
      .list-depoimentos ul,
      .list-depoimentos ul li {
          padding: 0;
          margin: 0;
          list-style: none;
      }
      .swiper-wrapper {
          align-items: center;
      }
      .cta-depoimentos .swiper-button {
          color: #fff;
      }
      .swiper-button:after {
          font-size: 30px;
      }


      .accordion {
          counter-reset: section;
          border-bottom: 1px solid #e4e4e4;
      }
      .accordion h3 {
          counter-increment: section;
          position: relative;
          font-size: 16px;
          border-top: 1px solid #e4e4e4;
          padding: 15px 28px;
          margin: 0;
          cursor: pointer;
          transition: all 0.3s ease-in-out;
      }
      .accordion h3.active,
      .accordion h3:hover {
          color: #0061ce;
      }
      .accordion h3:first-child {
          border: none;
      }
      .accordion h3::before {
          content: counter(section) " -";
          position: absolute;
          left: 0;
          top: 15px;
      }
      .accordion h3::after {
          content: '';
          position: absolute;
          right: 5px;
          top: 19px;
          width: 15px;
          height: 15px;
          transform: rotate(45deg);
          border: 2px solid rgba(0,97,206,1);
          border-radius: 2px;
          border-top-color: rgba(0,97,206,0);
          border-left-color: rgba(0,97,206,0);
          transition: all 0.3s ease-in-out;
      }
      .accordion h3.active::after {
          border-left-color: rgba(0,97,206,1);
          border-top-color: rgba(0,97,206,1);
          border-right-color: rgba(0,97,206,0);
          border-bottom-color: rgba(0,97,206,0);
      }

      .accordion .descricao {
          display: none;
          padding-bottom: 30px;
      }

      footer {
          color: #fff;
          background-color: #333;
          padding-top: 60px;
          padding-bottom: 60px;
      }
      footer .logo {
          width: 230px;
      }
      footer a {
          color: #fff;
      }
      footer .nav-social a {
          margin: 0 15px;
      }
      footer .nav-social a i svg {
          fill: #fff;
          transition: all 0.3s ease-in-out;
      }
      footer .nav-social a:hover i svg {
          fill: #6ae713;
      }
      footer .nav {
          display: flex;
          align-items: center;
      }
      footer .nav .btn-linha {
          border-color: #fff;
          color: #fff;
      }
      footer .nav .btn-linha:hover {
          background-color: #fff;
          color: #333;
      }
      footer .link-ssl {
          width: 80px;
          display: inline-block;
          margin-left: 30px;
          transition: all 0.3s ease-in-out;
      }
      footer .link-ssl:hover {
          opacity: 0.7;
      }


      .conteudo .obs {
          font-size: 14px;
      }
      .info-cadastro {
          padding: 60px 15px 0 15px;
      }
      .info-cadastro h3 {
          font-weight: 300;
          font-size: 26px;
      }
      .info-cadastro .list-icon li i svg {
          fill: #0061ce;
      }
      .info-cadastro .list-icon li strong {
          color: #0061ce;
      }

      .form-row {
          margin-left: -15px;
          margin-right: -15px;
      }
      .form-group {
          margin-bottom: 25px;
          /*display: flex;
          flex-direction: column;
          justify-content: flex-end;*/
      }
      .form-group label {
          display: block;
          color: #0061ce;
          font-weight: 700;
          font-size: 14px;
          margin-bottom: 5px;
      }
      .form-group label span {
          font-size: 12px;
      }
      .form-group label i {
          transform: translateY(2px);
      }
      .form-group input,
      .form-group select {
          width: 100%;
          border-radius: 8px;
          border: 2px solid #ededed;
          padding: 10px 10px;
          height: 50px;
          font-size: 16px;
      }
      .form-group input[type=range] {
          padding-left: 0;
          padding-right: 0;
          border: none;
      }
      .form-group select {
          -webkit-appearance: none;
          -moz-appearance: none;
      }
      .form-group select[multiple] {
          height: 150px;
      }
      .form-group .pretty {
          display: block;
          margin-top: 0.5em;
          margin-bottom: 0.5em;
      }
      .form-group .pretty label {
          color: #000
      }
      .form-group.form-group-radio-check label.error {
          float: left;
      }
      .form-group legend {
          font-size: 13px;
          text-align: right;
          font-style: italic;
      }
      .form-group .form-control.error {
          border-color: #d63a3a;
      }
      .form-group .form-control.valid {
          border-color: #57b317;
      }
      .form-group label.error {
          font-weight: 400;
          font-style: italic;
          font-size: 13px;
          text-align: right;
          color: #d63a3a;
          display: none;
          margin-top: 5px;
          float: right;
      }
      .form-check label.error {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: calc(100% + 5px);
          font-size: 13px;
          color: #d63a3a;
          font-style: italic;
      }

      .form-group.slider-total input.preco {
          margin-left: 0;
      }
      @media screen and (min-width: 768px) {
          .form-group input,
          .form-group select {
              padding: 10px 20px;
          }
          .form-group select:not([multiple]) {
              padding: 10px 40px 10px 20px;
          }
      }


      .termo-uso .titulo {
          display: flex;
          align-items: center;
          padding: 40px 30px 0;
          flex-wrap: wrap;
          margin-bottom: 0;
      }
      .termo-uso .titulo h2 {
          width: 100%;
          font-size: 20px;
          font-weight: 700;
          text-align: left;
          color: #004aa2;
      }
      .termo-uso .titulo i {
          margin-bottom: 20PX;
      }
      .termo-uso .titulo i svg {
          fill: #57b317;
      }
      .termo-uso .conteudo {
          padding: 30px 30px 50px 30px;
      }
      .cadastro a {
          font-weight: 700;
          color: #0061ce;
      }
      .cadastro a:hover {
          color: #57b317;
      }
      .cadastro .acao .form-check {
          margin-top: 30px;
          margin-bottom: 40px;
      }

      @media screen and (min-width: 576px) {
          .form-group label {
              font-size: 16px;
          }
      }
      @media screen and (min-width: 768px) {
          .termo-uso .titulo i {
              width: 110px;
              padding: 0 20px 0 0;
          }
          .termo-uso .titulo h2 {
              width: calc(100% - 110px);
          }
          .info-cadastro {
              padding: 40px 0 0 30px;
          }
      }
      @media screen and (min-width: 992px) {
          .termo-uso .titulo h2 {
              padding-right: 10%;
          }
          .termo-uso .titulo i {
              width: 130px;
              padding: 0 20px;
          }
          .termo-uso .titulo h2 {
              width: calc(100% - 130px);
          }
      }
      @media screen and (min-width: 992px) {
          .info-cadastro {
              padding: 60px 0 0 50px;
          }
      }

      .cadastro .acao {
          text-align: center;
      }
      .cadastro .acao .btn {
          text-align: center;
      }

      .cadastro .acao .pretty {
          white-space: normal;
      }
      .cadastro .acao .pretty .state label:after,
      .cadastro .acao .pretty .state label:before {
          width: calc(1.5em + 2px);
          height: calc(1.5em + 2px);
          top: 50%;
          transform: translateY(-50%);
      }
      .cadastro .acao .pretty .state label:before {
          border: 2px solid #ededed;
          border-radius: 8px;
      }
      .cadastro .acao .pretty.p-svg .state .svg {
          top: calc(50% - 0.5em - 1px);
          left: 0.25em;
          fill: #57b317;
      }
      .cadastro .acao .pretty .state label {
          text-indent: 0;
          padding-left: 35px;
      }
      .cadastro .acao .pretty .state label {
          font-weight: 700;
          color: #0061ce;
      }
      .cadastro .acao .btn {
          min-height: 60px;
          padding-left: 50px;
          padding-right: 50px;
          font-size: 20px;
      }
      @media screen and (min-width: 992px) {
          .cadastro .acao .btn {
              padding-left: 75px;
              padding-right: 75px;
          }
      }


      .iframe-contrato {
          width: 100%;
          height: 175px;
          border-radius: 5px;
      }

      .fancybox-slide--iframe {
          height: 100vh!important;
          padding: 44px!important;
      }
      .fancybox-slide--iframe .fancybox-content {
          width: 100%!important;
          height: 100%!important;
      }

      .hero-page-obrigado {
          padding-bottom: 90px;
      }
      .hero-page-obrigado .titulo {
          text-align: left;
          margin-bottom: 0;
      }
      .hero-page-obrigado .titulo .btn {
          display: inline-flex;
          font-size: 18px;
          height: 60px;
          padding-left: 50px;
          padding-right: 50px;
          align-items: center;
      }
      .change-plan-screen .alterarPagamento {
          margin-top: 30px;
      }
      .change-plan-screen .alterarPagamento div{
        padding-right: 0px;
      }
      #modo_pagamento {
          margin-top: 20px;
      }
      @media screen and (min-width: 768px) {
          .hero-page-obrigado {
              padding: 130px 0 130px;
          }
      }`}];
export default print;
