import React, { Component } from 'react';
import { Switch } from 'antd';

import LevelService from '../../../services/LevelsService';

export default class extends Component {

  constructor( props ){
      super();

      this.setActive = this.setActive.bind(this);

      this.state = {
        switch: (props.active === 1) ? true : false,
        responsible: props.responsible,
        user_token: props.user_token
      }
  }

  componentWillReceiveProps(nextProps) {

      if ( nextProps.active !== this.props.active ) {

        this.setState({
          switch: ( nextProps.active === 1) ? true : false,
          responsible: nextProps.responsible,
          user_token: nextProps.user_token
        });

      }
  }

  setActive( allow ){
    this.levelService = new LevelService();

    let responsible_elements = document.getElementsByClassName("responsible-item");

    let permissions = {responsibles : [] };

    for (let n = responsible_elements.length - 1; n >= 0; n--) {
         if ( responsible_elements[n].dataset.responsible !== this.state.responsible ) {
           const allowed = (responsible_elements[n].value === "true") ? 1: 0;
           permissions.responsibles.push( {user_token: responsible_elements[n].dataset.responsible, allowed: allowed } );
         }
     }

     permissions.responsibles.push( { user_token: this.state.responsible, allowed: (allow) ? 1 : 0 } );

     //console.log(permissions);

    this.levelService.setResponsibles( permissions, this.state.user_token ).then(res =>{
      console.log("permissão setada");
      console.log(res);

    });
  }

  onSwitchChange = (checked) => {
    if ( checked ){
      /*Fazer Chamada endpoint para salvar permissão*/
      this.setState({
        switch: true,
      });

      this.setActive(true);

    }else{
      /*Fazer Chamada endpoint para salvar permissão*/
      this.setState({
        switch: false,
      });

      this.setActive(false);

    }
  }

  render() {

    return (
      <div>
        <input type="hidden" className="responsible-item" data-responsible={this.state.responsible} value={this.state.switch} />
        <Switch title={(this.state.switch)? "Desbloqueado": "Bloqueado"} checked={this.state.switch} onChange={ this.onSwitchChange }  />
      </div>
    );
  }
}
