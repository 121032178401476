import React from 'react';

import Select from 'react-select';

import HeaderBar from '../header-bar/header-bar';
import NavigationMenu from '../sidebar/navigation-menu';
import tribunalTypes from '../json/tribunalTypes';
import estados_allowed from '../json/estados_allowed';
import tribunal_options_json from '../json/tribunal_options';

import TribunalService from '../../services/TribunalService';
import AuthService from '../../services/AuthService';

import Footer from "../containers/footer";


const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: '13px',
    marginLeft: '15px',
    width: '100%-15px',
  }),
  group: (provided, state) => ({
    ...provided,
    fontSize: '16px',
    color: 'black'
  }),
}

export default class AddLawsuit extends React.Component { 
  _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }

  constructor( { match } ) {
      super();
      let tribunal = "";

      console.log(tribunalTypes);

      if( match.params.type === "add" ){
        tribunal = "add";
        this.state = {
            type: match.params.type,
            form: "",
            tribunalOption: "0",
            tribunal,
            options: []
        }
      }else{
        tribunal = tribunalTypes.filter( (tribunalTypes)=>{ return tribunalTypes.key === match.params.type } )[0];
        this.state = {
            type: match.params.type,
            form : require ( './'+tribunal.path ).default,
            tribunalOption: "0",
            tribunal,
            options: [],
            tribunals: []
        }
      }

      this.authService = new AuthService();
      this.back = this.back.bind(this);

      this.authService.can('cadastrar_processo', false, 'processos');

       this.tribunalService = new TribunalService();
       this.tribunalService.list().then(res =>{

       })
       .catch(err =>{
           console.log("Conexão Instável."+err);
       })

  }
  state = {
    value: ''
  }

  componentDidMount( ) { 
    this._isMounted = true;

    this.tribunalService = new TribunalService();
    this.tribunalService.listAllowed().then(res =>{
      if( this._isMounted ){
            //console.log(res);
            this.getAlloweds( res );
      }

    })
    .catch(err =>{
        console.log("Conexão Instável."+err);
    })

  }

  getAlloweds = ( tribunals ) => {

        this.tribunalService = new TribunalService();

        let ufs = JSON.parse(JSON.stringify(estados_allowed));

        tribunals.map( ( record, index ) => {

            /*this.lawsuitService.counterByTribunal( record.id ).then( count1 =>{
              console.log(count1+" "+record.name);
            });*/

            if( this._isMounted ){

              tribunal_options_json.map( (option, ind, array ) => {

                option.children.map( (reg, i, arr ) => {
                    if( reg.slug === record.slug.toLowerCase() ){
                          //tribunals[index].uf = option.key;
                          reg.allowed = record.allowed;
                          //console.log(reg);

                          //console.log(ufs);

                          option.children.map( (reg, i, arr ) => {

                                if( reg.slug === record.slug.toLowerCase() ){
                                  
                                    tribunals[index].uf = option.key;
                                    reg.allowed = record.allowed;

                                    ufs.map( ( item, x ) => {
                                        //console.log( item );
                                        //console.log(item.uf);
                                        //console.log(tribunals[index]);                                              

                                        if ( item.uf === tribunals[index].uf ){

                                          if( record.allowed === 1 ){
                                            item.allowed = 1;
                                            reg.allowed = 1;
                                            option.allowed = 1;
                                          }

                                        }
                                        return item;
                                    });

                                }

                                return reg;

                          });
                    }
                    return reg;
                });
                return option;
              });

                  localStorage.setItem('options', JSON.stringify(tribunal_options_json));

                  this.setState({
                    options: tribunal_options_json,
                    tribunals
                  });

            }

          return record;


      });

    }

  componentWillReceiveProps(nextProps) {
    if ( nextProps.match.params.type !== this.props.match.params.type ) {
      let tribunal = "";

      if( nextProps.match.params.type === "add" ){
        tribunal = "add";
        this.setState( {
            type: nextProps.match.params.type,
            form: ""
        });
      }else{
        tribunal = tribunalTypes.filter( (tribunalTypes)=>{ return tribunalTypes.key === nextProps.match.params.type } )[0];

        this.setState({  
          type: nextProps.match.params.type,
          form : require ( './'+tribunal.path ).default,
          tribunal
        });
      }

      this.tribunalService = new TribunalService();

    }
  }

  onChange = (event) => {
    //console.log( event.target[event.target.selectedIndex].dataset.slug );

    let tribunal = tribunalTypes.filter( (tribunalTypes)=>{ return tribunalTypes.key === event.target[event.target.selectedIndex].dataset.slug.toLowerCase() } )[0];

    this.setState({
      tribunalOption: event.target.value,
      form : require ( './'+tribunal.path ).default,
      tribunal
    });

  }

  back(){
    this.props.history.goBack();
  }

  selectChange = (selected, actionMeta) => {

    if ( typeof actionMeta.name !== undefined && actionMeta.name !== "" && actionMeta.name !== null ){

      if ( typeof selected !== undefined && selected !== "" ) {
        //this.setState( pops );
        let tribunal = tribunalTypes.filter( (tribunalTypes)=>{ return tribunalTypes.key === selected.slug.toLowerCase() } )[0];

        this.setState({
          [actionMeta.name]: selected,
          form : require ( './'+tribunal.path ).default,
          tribunal
        });

      }

    }
  }

  tribunalOption( tribunal, index) {

    //console.log(tribunal);

    return (
            <option key={index} data-slug={tribunal.slug} value={tribunal.id}>{tribunal.name+" - "+tribunal.slug}</option>
    );

  }

  render() {
      let ComponentToRender = this.state.form; let tribunal_id = 1;
      let selectTribunal = null;

      let tribunals = JSON.parse(localStorage.getItem('tribunals'));

      let groupedOptions = [];

      this.state.options.map( ( option ) => {
          //console.log(option);
          let sub = [];
          option.children.map( ( tribunal ) => {
              tribunals.map( ( tr )=> {
                    if( tribunal.slug === tr.slug.toLowerCase() && tribunal.allowed === 1 ){
                        sub.push( {value: tr.id, label: tr.name+" - "+tr.slug, slug: tr.slug} );
                    }
              } );
          });
          groupedOptions.push( {label: option.label, options: sub} );
      });

      //let tribList = tribunals.map( (tribunal, index) => this.trib( tribunal, index ) ) ;

      //console.log(tribList);

      //console.log(this.state.tribunal);

      selectTribunal = <div className="">
              <Select
                styles={customStyles}
                id="tribunalOptions"
                className="baixa"
                placeholder="Selecione um tribunal ou digite tjsp, trt2, trf3, etc..."
                options={groupedOptions}
                value={this.state.tribunalOption}
                name="tribunalOption"
                onChange={this.selectChange}
              />
      </div>

      if (tribunals !== null && tribunals.length > 0 && this.state.type !== "add" ){
        tribunal_id = tribunals.filter( (tribunalTypes)=>{ return tribunalTypes.slug.toLowerCase() === this.state.type } )[0].id;
      }else{

        if( this.state.type !== "add" ){
          this.tribunalService.listAllowed().then(res =>{
            if( this._isMounted ){
              tribunal_id = res.filter( (tribunalTypes)=>{ return tribunalTypes.slug.toLowerCase() === this.state.type } )[0].id;
              this.setState({
                tribunal_id
              });
            }
      
          });
        }

      }

      setTimeout(() => {
        
      }, 900);

      return <div className="add-lawsuit sheet">
                <HeaderBar />
                <NavigationMenu />
                <div className="content-wrapper">
                  <div className="content-wrapper-inner">
                      <section className="content-header">
                        <h1 className="page-header">{(tribunal_id===24)? "Adicionar Processo INSS" : "Tribunais"}</h1>
                      </section>
                      <span onClick={this.back} className="btn btn-info btn-back horizontal-space vertical-space">Voltar</span>
                      { (this.state.type !== "add") ? <ComponentToRender tribunal_id={tribunal_id} history={this.props.history} type={this.state.type} title={this.state.tribunal.title} placeholder={this.state.tribunal.placeholder} mask={this.state.tribunal.mask}  /> : selectTribunal}
                      { ( this.state.tribunalOption !== "0" ) ? <ComponentToRender tribunal_id={this.state.tribunalOption.value} history={this.props.history} type={this.state.type} title={this.state.tribunal.title} placeholder={this.state.tribunal.placeholder} mask={this.state.tribunal.mask}  /> : ""}
                  </div>
                </div>
                <Footer />
             </div>
  }

}
