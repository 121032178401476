import React from 'react';
import { Modal, Button } from 'antd';
import Popover, { ArrowContainer } from 'react-tiny-popover';
import { Link } from 'react-router-dom';

import LawsuitSearchSection from '../tribunals/lawsuitSearchSection';
import user_icon from '../json/user_icon';
import Alert from '../containers/alert';

import AuthService from '../../services/AuthService';
import EventService from '../../services/EventService';
import UsersService from '../../services/UsersService';

import { /*Link,*/ withRouter } from 'react-router-dom';
import moment from "moment";
//import ptBR from 'antd/lib/locale-provider/pt_BR';
import 'moment/locale/pt-br';

moment.locale('pt-BR');

var check = 0;

const Auth = new AuthService();

        class HeaderBar extends React.Component { 
              _isMounted = false;

              componentWillUnmount() {
                this._isMounted = false;
              }


            constructor(){
              super();
              this.handleNotificationModalCancel = this.handleNotificationModalCancel.bind(this);
              this.moreDetails = this.moreDetails.bind(this);
              this.keep = this.keep.bind(this);
              this.delete = this.delete.bind(this);
              this.hideDetails = this.hideDetails.bind(this);
              this.state = {
                totalNotification: 0,
                notificationModalVisible: false,
                notifications: [],
                'isEditPopoverOpen': false,
                image_path: user_icon
              }

            }

            componentDidMount( ) { 
                this._isMounted = true;
                this.getNotifications();
                this.getUserInfo();
                document.addEventListener("cancelAlert", (e) => {
                  this.setState({
                  showAlert: false
                  });
                });
            }

            getUserInfo(){
                  //método de buscar as notificações
                  this.UsersService = new UsersService();


                  let user = JSON.parse( localStorage.getItem( 'loggedUser' ) );

                  if( user !== null ){
                    this.UsersService.view( user.token ).then(res =>{

                      if( this._isMounted ){

                            user.admin = res.admin;
                            user.name = res.name;

                            localStorage.setItem('loggedUser', JSON.stringify(user));
                            
                            if(res.imagem !== null){
            
                              this.setState({
                                image_path: res.imagem
                              });
                            }

                      }

                    });
                  }

            }

            getNotifications(){
              //método de buscar as notificações
              this.EventService = new EventService();

              let start = moment().startOf('day').subtract(180, 'days').format('YYYY-MM-DD');
              let end = moment().endOf('day').add(180, 'days').format('YYYY-MM-DD');

              let user = JSON.parse( localStorage.getItem( 'loggedUser' ) );

              if( user !== null ){
                this.EventService.listNotifications( start, end, user.token ).then(res =>{

                  if( this._isMounted ){

                        //console.log(res);

                        this.setState({
                          totalNotification: ( Array.isArray( res ) ) ? res.length : 0,
                          notifications: res
                        });

                  }

                });
              }

            }

            handleLogout(){

                  //console.log(e.target.id);
                  let test = true;
                  let shouldTest = false;
                  //let message = "Não foram selecionados Cliente e Oponente deste processo, fazendo com que o processo fique sem partes. \n Esta ação PREJUDICARÁ o filtro de clientes e outras funções! \n Selecione o Cliente e o Oponente!";
                  
                  if( document.getElementById("formIsHalfFilledOut") ){
                    test = ( document.getElementById("formIsHalfFilledOut").value === "true" ) ? true : false ;
                  }              
                  
                  if( document.getElementById("lawsuit_manual") && document.getElementById("lawsuit_manual").value === "manual" ) {
                    if( document.getElementById("cliente") ){
                      test = (document.getElementById("cliente").value === "true") ? false : true;
                    }
                    shouldTest = true;
                    //message = "Não foi incluído um Cliente deste processo, fazendo com que o processo fique sem partes. \n Esta ação PREJUDICARÁ o filtro de clientes e outras funções! \n Selecione ou inclua um Cliente para este processo.";
                  }else if( document.getElementById("lawsuit_status") && document.getElementById("lawsuit_status").value === "CADASTRADO" ){
                    shouldTest = true;
                  }

                  if(  test && shouldTest ){
                    this.setState({
                      showAlert: true,
                      message: "Não foram selecionados Cliente e Oponente deste processo, fazendo com que o processo fique sem partes. \n Esta ação PREJUDICARÁ o filtro de clientes e outras funções!"
                    });
                  }else {
                    Auth.logout();
                    this.props.history.replace('/login');
                  }
            }

            pushMenu(e) {
                e.preventDefault();
                var body = document.body;
                if(body.clientWidth > 768){
                    if(body.className.indexOf('sidebar-collapse') === -1){
                        body.className += ' sidebar-collapse';
                    }else {
                        body.className = body.className.replace(' sidebar-collapse', '');
                    }
                }else{
                    if (body.className.indexOf('sidebar-open') === -1) {
                        body.className += ' sidebar-open';
                    }else{
                        body.className = body.className.replace(' sidebar-open','');
                    }
                }
            }

            handleNotificationModalCancel(){

                  if( this.state.totalNotification > 0 &&  this.state.notifications.length > 0 && Array.isArray( this.state.notifications ) ){

                      //marcar no banco de dados notificação como lida
                      this.EventService = new EventService();

                      this.state.notifications.map((item, index, array )=>{
                        //console.log(item.events_situation.id);

                        if( item.events_situation !== null && item.events_situation.id === 1 ){
                          console.log(item);
                          let notification = {
                            "name": item.raw.title,
                            "description": item.raw.description,
                            "date_start": item.raw.start.substr(0,10).split("-").reverse().join("/"),
                            "time_start": item.raw.start.substr(11,5),
                            "date_end": item.raw.end.substr(0,10).split("-").reverse().join("/"),
                            "time_end": item.raw.end.substr(11,5),
                            "event_type_id": 3,
                            "priority": 1,
                            "event_situation_id": 3,
                            token: item.token
                          }
                          this.EventService.deleteNotification ( notification ).then(res =>{
                          });
                          console.log("deletado notify");
                        }

                        return item;

                      });

                  }

                  this.setState({
                    totalNotification: 0,
                    notificationModalVisible: false
                  });

            }

            openNotification(e){

              this.setState({
                notificationModalVisible: true,
              });

            }

            moreDetails = ( event ) => {
              event.target.className += ' hide';
            }

            keep = ( event ) => {
              this.EventService = new EventService();

              let data = JSON.parse(event.target.dataset.raw);
              //console.log(data);

              let notification = {
                "name": data.title,
                "description": data.description,
                "date_start": data.start.substr(0,10).split("-").reverse().join("/"),
                "time_start": data.start.substr(11,5),
                "date_end": data.end.substr(0,10).split("-").reverse().join("/"),
                "time_end": data.end.substr(11,5),
                "event_type_id": 3,
                "priority": 1,
                "event_situation_id": 2,
                token: data.token
              }

              this.EventService.changeKeepNotification ( notification ).then(res =>{

                    if( this._isMounted ){
                          this.getNotifications();
                    }

              });
            }

            delete = ( event ) => {
              this.EventService = new EventService();
              let data = JSON.parse(event.target.dataset.raw);
              //console.log(data);

              let notification = {
                "name": data.title,
                "description": data.description,
                "date_start": data.start.substr(0,10).split("-").reverse().join("/"),
                "time_start": data.start.substr(11,5),
                "date_end": data.end.substr(0,10).split("-").reverse().join("/"),
                "time_end": data.end.substr(11,5),
                "event_type_id": 3,
                "priority": 1,
                "event_situation_id": 1,
                token: data.token
              }

              this.EventService.changeDeleteNotification ( notification ).then(res =>{
                    if( this._isMounted ){
                          this.getNotifications();
                    }
              });
            }

            hideDetails = ( event ) => {
                document.getElementById(event.target.dataset.id).classList.remove("hide");
            }

            /*tarefaContainer = ( tarefa ) => {
              return (
                  <div>
                        <label>Tarefa anexada ao compromisso: </label><span>{tarefa.descricao_tarefa}</span>
                        <div>
                            <label>Data: </label> <span>{tarefa.data}</span> <label>Data Limite</label>
                            <span>{tarefa.data_limite}</span>
                        </div>
                        <label>Executor da Tarefa: </label>
                        <span>{tarefa.executor}</span>
                  </div>
              );
            }*/

            infoTarefa = (token) => {

              this.EventService = new EventService();

              if(check < this.state.totalNotification){

                    this.EventService.view( token ).then(event =>{

                          if( this._isMounted ){

                                //console.log(token);

                                if( event.parent_event !== null && this.state.notificationModalVisible ){
                                  document.getElementById("tarefa-type-"+token).innerHTML = (event.parent_event.event_type_id === 1) ? "Compromisso" : "Tarefa";
                                  document.getElementById("tarefa-desc-"+token).innerHTML = event.parent_event.description;
                                  document.getElementById("date-"+token).innerHTML = event.parent_event.start.substr(0,10).split("-").reverse().join("/")+" "+event.parent_event.start.substr(11,5);
                                  document.getElementById("date-limit-"+token).innerHTML = event.parent_event.end.substr(0,10).split("-").reverse().join("/")+" "+event.parent_event.end.substr(11,5);
                                  //document.getElementById("sponsor-"+token).innerHTML = event.parent_event.events_users[0].user.name;
                                }

                                if(event.parent_event === null && this.state.notificationModalVisible ){
                                  document.getElementById("more-details-"+token).className += " hide";
                                }

                          }


                    });
                    check++;
              }

              return (
                      <div>
                            <div className="col-md-12">
                              <span id={"tarefa-type-"+token} className="cap"></span> - <span id={"tarefa-desc-"+token}></span>
                            </div>
                            <div className="col-md-5">
                                <label>Data: </label>     <span id={"date-"+token}></span>
                            </div>
                            <div className="col-md-7">
                                <label>Data Limite: </label><span id={"date-limit-"+token}></span>
                            </div>
                            <div className="col-md-12">
                                    <label>Responsável(is): </label>
                                    <span id={"sponsor-"+token}></span>
                            </div>
                      </div>
                      )
            }

            notificationItem = ( {record, index}, type ) => {

                //let more_details = ""; 
                let keep_or_delete = "";

                if( record.events_situation.id === 1 ){
                  keep_or_delete = <span className="btn keep btn-success" data-raw={ JSON.stringify(record.raw) } onClick={ this.keep }>
                                          Manter
                                   </span>;
                }else{
                  keep_or_delete = <span className="btn delete btn-danger" data-raw={ JSON.stringify(record.raw) } onClick={ this.delete }>
                                          Excluir
                                   </span>;
                }

                return (
                  <div key={index} className={type} >
                        <label>De:</label><span className="reg"> {record.de}</span><label>Data: <i className="fa fa-calendar"></i></label> <span>{record.data}</span>
                        <p className="subject">Mensagem: </p>
                        <p className="note">
                              {record.mensagem}
                        </p>
                        <span data-toggle="collapse" id={"more-details-"+record.token} data-target={"#notification-item-details-"+index} className="btn more-details btn-info" onClick={this.moreDetails}>Mais Detalhes</span>
                        {keep_or_delete}
                        <div id={"notification-item-details-"+index} className="collapse notification-item-details">
                              {this.infoTarefa(record.token)}

                              <span data-toggle="collapse" data-target={"#notification-item-details-"+index} className="btn hide-details btn-info" data-id={"more-details-"+record.token} onClick={this.hideDetails}>Ocultar</span>
                        </div>
                  </div>

                );
            };

            render() {
                //var that = this;
                var url_base = localStorage.getItem('url_base');
                let user = JSON.parse( localStorage.getItem( 'loggedUser' ) );
                //console.log( user );
                let lawsuit_search = <div className='blank'></div>;
                var today = new Date();
                var time = today.getHours();
                let name = "";
                let fullname = "";

                let image_path = this.state.image_path;

                //console.log(time);
                let welcome = "";

                if( user !== null ){

                  if( time < 12 ){
                    welcome = "Bom dia, "+user.name+" !";
                  }else if ( time >= 12 && time < 18 ){
                    welcome = "Boa tarde, "+user.name+" !";
                  }else{
                    welcome = "Boa noite, "+user.name+" !";
                  }

                  name = user.name;
                  fullname = user.name;
                  //console.log(name);
                  name = name.split(" ");
                  //console.log(name);
                  name = name[0];

                }
                 
                lawsuit_search = <LawsuitSearchSection history={this.props.history} />;                 

                 let badge_notification = "";
                 if(this.state.totalNotification > 0){
                   badge_notification = <span className="badge badge-grey badge-dot badge-dot-sm bg-red">{this.state.totalNotification}</span>;
                 }

                 let notification_title = <div>
                                                 Visualizar Notificações
                                                 <Popover
                                                     isOpen={this.state.isEditPopoverOpen}
                                                     position={['top', 'right', 'left', 'bottom']}
                                                     padding={10}
                                                     onClickOutside={() => this.setState({ isEditPopoverOpen: false })}
                                                     content={({ position, targetRect, popoverRect }) => (
                                                         <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                                                             position={position}
                                                             targetRect={targetRect}
                                                             popoverRect={popoverRect}
                                                             arrowColor={'#94bcd8'}
                                                             arrowSize={10}
                                                         >
                                                             <div className="popover-box"
                                                                 onClick={() => this.setState({ isEditPopoverOpen: !this.state.isEditPopoverOpen })}
                                                             >
                                                                 Os avisos de notificações aparecerão uma única vez. Caso queira fixá-los, clique em Manter. Em Relatórios, poderá visualizar todas as notificações destinadas para um ou todos usuários.
                                                             </div>
                                                         </ArrowContainer>
                                                     )}
                                                 >
                                                     <img onMouseOut={() => this.setState({ isEditPopoverOpen: false })} onMouseOver={() => this.setState({ isEditPopoverOpen: !this.state.isEditPopoverOpen })} className="help_icon" alt="ajuda" src={url_base+"/images/interrogation_cropped.png"}></img>
                                                 </Popover>
                                          </div>

                return (
                    <header className="main-header">
                    <Alert show={this.state.showAlert} text={this.state.message} />
                        {/* Logo */}
                        <a href={url_base} className="logo">
                            {/* mini logo for sidebar mini 50x50 pixels */}
                            <span className="logo-mini"><img src={url_base+"/images/favicon.png"} alt="nosso logo" /></span>
                            {/* logo for regular state and mobile devices */}
                            <span className="logo-lg"><img src={url_base+"/images/logo_avvocato.png"} alt="logo em formato normal" /></span>
                        </a>
                        {/* Header Navbar: style can be found in header.less */}
                        <nav className="navbar navbar-static-top" role="navigation">
                            {/* Sidebar toggle button*/}
                            <a href="/" className="sidebar-toggle" data-toggle="offcanvas" role="button" onClick={this.pushMenu}>
                                <span className="sr-only">Toggle navigation</span>
                            </a>
                            <div className="welcome">
                                  { /*( this.props.screen === "Home" && this.props.active !== "1" )? */ welcome /*: null*/ }
                            </div>
                            {lawsuit_search}                            
                            <div className="navbar-custom-menu">
                                <span className="notification" onClick={this.openNotification.bind(this) }>
                                    <i className="material-icons">notifications</i>
                                    {badge_notification}
                                </span>
                                <ul className="nav navbar-nav">
                                    {/* User Account: style can be found in dropdown.less */}
                                    <li className="dropdown user user-menu">
                                        <div className="dropdown-toggle" data-toggle="dropdown">
                                            <img src={"data:image/png;charset=utf-8;base64, "+image_path} className="user-image" alt="User" />
                                            <span className="">{( user !== null ) ? name : "" }</span>
                                        </div>
                                        <ul className="dropdown-menu">
                                            {/* User image */}
                                            <li className="user-header">
                                                <img src={"data:image/png;charset=utf-8;base64, "+image_path} className="img-circle" alt="User" />
                                                <p>
                                                    {( user !== null ) ? fullname : "" }
                                                </p>
                                                <small>{( user !== null ) ? user.email : ""}</small>
                                            </li>
                                            {/* Menu Footer */}
                                            <li className="user-footer">
                                                <div className="center">
                                                    <Link to={"/perfil"}>
                                                        <span className="btn btn-default btn-flat">Perfil</span>
                                                    </Link>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                                <span className="logout" onClick={ this.handleLogout.bind(this) }><i className="material-icons">power_settings_new</i><span>Sair</span></span>
                            </div>
                        </nav>
                        <Modal
                          visible={this.state.notificationModalVisible}
                          className="modalNotifications"
                          title={notification_title}
                          width="550px"
                          onCancel={this.handleNotificationModalCancel }
                          footer={[
                            <Button key="back" className="btn-danger" onClick={this.handleNotificationModalCancel }>
                              Fechar
                            </Button>
                          ]}
                        >
                                <div className="form-group">
                                  { ( typeof this.state.notifications !== "undefined" && this.state.notifications.length > 0 && Array.isArray( this.state.notifications ) ) ? this.state.notifications.map( (record, index) => this.notificationItem( {record, index}, 'notification-item' ) ) : null }
                                </div>
                        </Modal>
                    </header>
                )
            }
        }

        export default withRouter(HeaderBar);
