import React from 'react';
import Header from "./containers/header";
import Footer from "./containers/footer";
//import { Link } from 'react-router-dom';
import FlashMessage from "./containers/FlashMessage";

//import AuthService from '../services/AuthService';
//import OfficeService from '../services/OfficeService';
import './Login.css';

export default class AccessDenied extends React.Component { _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }


  constructor() {
      super();

      window.scrollTo(0,0);

  }

  componentWillMount(){
  }

  componentDidMount(){

          /*localStorage.setItem('alert_message', res.message);
          localStorage.setItem('alert_type', 'alert-success');
          let time = new Date().getTime();
          localStorage.setItem('alert_time', time );

          setTimeout(() => {

            this.setState ({
                update: 1
            });

          }, 1);*/

  }

  render() {

      setTimeout(() => {

        window.location.href = localStorage.getItem('url_base')+'/logout';

      }, 7000);

      return <div>
                  <Header />
                  <FlashMessage time={ new Date().getTime() } />
                  <div className="center">
                      <div className="card">
                          <center><h3>Você não tem permissão para acessar o sistema neste momento.</h3></center>
                          <center>Você será redirecionado para a tela de Login em 08 segundos.</center>
                      </div>
                  </div>
                  <Footer />
             </div>
  }

}
