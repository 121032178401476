import React from 'react';
import Popover, { ArrowContainer } from 'react-tiny-popover';

import HeaderBar from '../header-bar/header-bar';
import NavigationMenu from '../sidebar/navigation-menu';
import LawsuitService from '../../services/LawsuitService';
import AuthService from '../../services/AuthService';
import Footer from "../containers/footer";

export default class AddManualLawsuit extends React.Component { _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }


  componentWillReceiveProps(nextProps) {
    if ( nextProps.match.params.type !== this.props.match.params.type ) {

    }
  }

  constructor( props ) {
      super(props);

      this.handleChange = this.handleChange.bind(this);
      this.handleFormSubmit = this.handleFormSubmit.bind(this);
      this.cancel = this.cancel.bind(this);
      this.LawsuitService = new LawsuitService();

      this.authService = new AuthService();

      this.authService.can('cadastrar_processo', false, 'processos');

      this.state = {

        lawsuit: {
          tribunal: {},
          valorCausa: "",
          lawsuits_personages: [],
          movements: [],
          name: "",
          //token: "18--66--81",
          //tribunal_id: -99,
          favorito: 0,
          arquivo: 0,
          loadingVisible: false,
          loadingMessage: [],
          isPopoverOpen: false
        }
      };

      //localStorage.setItem('manual_lawsuit', JSON.stringify(this.state.lawsuit) );

      //this.props.history.replace( '/processo/detalhe_processo/'+this.state.lawsuit.token+'?parte=true' );

  }

  handleChange(e){

       this.setState(
           {
               [e.target.name]: e.target.value
           }
       )
   }

   cancel(e){
     this.props.history.goBack();
   }

  handleFormSubmit(e){
       e.preventDefault();

       document.getElementById("addButton").disabled = true;
       document.body.style.cursor = "wait";
       this.setState({
         loadingVisible: true,
         loadingMessage: ["O cadastro está sendo efetuado.", "Por gentileza, aguarde...", "Tempo estimado de 15 a 90 segundos"]
       });

       let user = JSON.parse( localStorage.getItem( 'loggedUser' ) );

       let lawsuit = {
         user_token: user.token,
         instancia: 1,
         name: this.state.name,
       }

       this.LawsuitService.add( lawsuit ).then(res =>{

         document.getElementById("addButton").disabled = false;
         document.body.style.cursor = "default";
         this.setState({
           loadingVisible: false,
           loadingMessage: []
         });

         if ( res.success === true ) {
           localStorage.setItem('alert_message', "Processo cadastrado com sucesso");
           localStorage.setItem('alert_type', 'alert-success');
           let time = new Date().getTime();
           localStorage.setItem('alert_time', time );
           this.props.history.push( '/processo/detalhe_processo/'+res.data.Lawsuits[0].token+'?parte=true&manual=true' );
         }else {
           localStorage.setItem('alert_message', res.message);
           localStorage.setItem('alert_type', 'alert-danger');
           let time = new Date().getTime();
           localStorage.setItem('alert_time', time );
         }

       })
       .catch(err =>{
           document.getElementById("addButton").disabled = false;
           document.body.style.cursor = "default";
           console.log(err);
           this.setState({
             loadingVisible: false,
             loadingMessage: []
           });
       });
  }

  render() {

      let url_base = localStorage.getItem('url_base');

      return <div className="add-lawsuit sheet">
                <HeaderBar />
                <NavigationMenu />
                <div className="content-wrapper">
                  <div className="content-wrapper-inner">
                      <section className="content-header">
                        <h1 className="page-header">Adicionar Processo Manual</h1>
                      </section>
                      <div className="panel-body">
                          <div className="box box-success">
                                <div className="box-header with-border">
                                    <h3 className="box-title">
                                        N° do Processo Manual
                                    </h3>
                                </div>
                                <div className="box-body">
                                    <div className="form-group">
                                        <label htmlFor="name">Digite um nome ou número para controle interno</label>
                                        <Popover
                                            isOpen={this.state.isPopoverOpen}
                                            position={['top', 'right', 'left', 'bottom']}
                                            padding={10}
                                            onClickOutside={() => this.setState({ isPopoverOpen: false })}
                                            content={({ position, targetRect, popoverRect }) => (
                                                <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                                                    position={position}
                                                    targetRect={targetRect}
                                                    popoverRect={popoverRect}
                                                    arrowColor={'#94bcd8'}
                                                    arrowSize={10}
                                                >
                                                    <div className="popover-box"
                                                        onClick={() => this.setState({ isPopoverOpen: !this.state.isPopoverOpen })}
                                                    >
                                                        Indique um N° de Pasta, Placa do Veículo, N° de Contrato ou Benefício, nome do cliente, etc... ,algo para identificar o processo. Padronize esta informação para facilitar a sua localização.
                                                    </div>
                                                </ArrowContainer>
                                            )}
                                        >
                                            <img onMouseOut={() => this.setState({ isPopoverOpen: false })} onMouseOver={() => this.setState({ isPopoverOpen: !this.state.isPopoverOpen })} className="help_icon" alt="ajuda" src={url_base+"/images/interrogation_cropped.png"}></img>
                                        </Popover>
                                        <input id="name" className="name form-control input-lg" name="name" onChange={this.handleChange} />
                                        <span className="btn btn-danger" id="cancelButton" onClick={this.cancel.bind(this)} >Cancelar</span>
                                        <input className="btn btn-primary" value="Avançar" type="submit" id="addButton" onClick={this.handleFormSubmit.bind(this)} />
                                    </div>
                                </div>
                          </div>
                      </div>
                  </div>
                </div>
                <Footer />
             </div>
  }

}
