const print = [
    {
        key:`
                    * {
                      text-shadow:none !important;
                      filter:none !important;
                      -ms-filter:none !important;
                    }
                    body {
                      margin:0;
                      padding:0;
                      line-height: 1.4em;
                      color: #222;
                      font-size: 14pt;
                      min-height: 100%;
                      background: #222;
                    }
                    @page {
                        margin-top: 0.5cm;
                        margin-left: 1.5cm;
                        margin-right: 1.5cm;
                        margin-bottom: 2.0cm;
                    }
                    nav, footer, video, audio, object, embed {
                      display:none;
                    }
                    img {
                      max-width: 100%;
                    }
                    #root {
                      margin-top: 50px;
                    }
                    .wrapper {
                      background-color: #fff !important;
                      width: 21cm;
                      height: auto;
                      min-height: 29.7cm !important;
                      margin: 0px auto;
                      float: none !important;
                    }
                    .print {
                      margin-top: 0.5cm;
                      margin-left: 1.cm;
                      margin-right: 1.cm;
                      margin-bottom: 2.0cm;
                    }
                    h1 {
                      font-size: 24pt;
                      font-weight: bold;
                      text-align: center;
                    }

                    h2 {
                      font-size: 18pt;
                      font-weight: bold;
                    }

                    h3 {
                      font-size: 14pt;
                    }
                    p {
                      widows: 10;
                    }
                    p {
                      orphans: 5;
                    }
                    p {
                      margin-left: 10px !important;
                      float: none;
                    }
                    label {
                      float: left;
                      clear: both;
                    }
                    .ql-toolbar.ql-snow, .ql-container.ql-snow {
                      border: none;
                    }
                    button {
                      margin: 0 auto;
                      display: block;
                    }
                    @media screen {
                    }
                    @media print {
                        button {
                          display: none !important;
                        }
                        .wrapper {
                          margin: 0;
                        }
                        .ql-editor {
                          padding: 0;
                        }
                        .print {
                          margin: 0;
                        }
                    }

                            `}];
export default print;
