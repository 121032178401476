import React from 'react';
import MyPopover from '../../containers/mypopover';
import { DatePicker } from 'antd'; import locale from 'antd/es/date-picker/locale/pt_BR';
import { Modal, Button } from 'antd';
import Select from 'react-select';
import Loading from '../../containers/loading';
import Alert from '../../containers/alert';
import Confirm from '../../containers/confirm';
import IntlCurrencyInput from "react-intl-currency-input";
import FlashMessage from "../../containers/FlashMessage";
import FinanceService from '../../../services/FinanceService';
import AuthService from '../../../services/AuthService';

import moment from "moment";
import 'moment/locale/pt-br';

const customStyles = {
  clearIndicator: (provided, state) => {
    const color = 'red';

    return { ...provided, color };
  }
}

const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
}

const formaPagamentoList = [
  {value: '1', label: 'Dinheiro'},
  {value: '2', label: 'Cartão'},
  {value: '3', label: 'Cheque'},
  {value: '4', label: 'Boleto'},
  {value: '6', label: 'Em Carteira'},
  {value: '7', label: 'Depósito / Transferência'},
  {value: '5', label: 'Outros'},
]

const tipoOperacaoList = [
  {value: '1', label: 'Crédito do Cliente'},
  {value: '2', label: 'Débito do Cliente'},
]

export default class FinanceLawsuit extends React.Component { _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }


  constructor( props ) {
    super(props);
    this.roll = this.roll.bind(this);
    this.myRef = React.createRef();

    let url = window.location.pathname.split('/');

    this.novoLancamento = this.novoLancamento.bind(this);
    this.moeda = this.moeda.bind(this);
    this.addEntry = this.addEntry.bind(this);
    this.doBaixa = this.doBaixa.bind(this);
    this.deleteEntry = this.deleteEntry.bind(this);

    this.FinanceService = new FinanceService();
    this.authService = new AuthService();

    this.state = {
      events: [],
      lawsuit_token: url[ url.length-1],
      token: "",
      categoryList: [],
      categoryOutgoingList: [],
      categoryIncomingList: [],
      categoryEntryLawsuitList: [],
      loadingMessage: [],
      loadingVisible: false,
      centroCustoList: [],
      centroCusto: null,
      formaPagamentoList: [],
      forma_pagamento: null,
      tipoOperacao: null,
      errors: [],
      parcelas: 1,
      data_vencimento: null,
      message: "",
      showAlert: false,
      deleteEntry: false,
      doBaixa: false,
      action: ""
    }

  }

  componentWillReceiveProps( nextProps ) {

  }

  roll = (event) => {

    if ( !event.target.classList.contains('collapsed') ) {
      setTimeout(() => {
        window.scrollTo(0, this.myRef.current.offsetTop-67)
      }, 800);
    }

  }

  componentDidMount( ) { 
      this._isMounted = true;

      document.addEventListener("cancelDialogConfirm", (e) => {
        if( this._isMounted ){
          this.setState({
            showConfirm: false,          
          });
        }
      });
  
      document.addEventListener("confirmDialogConfirm", (e) => {
        if( this._isMounted ){
          this.setState({
            showConfirm: false,
          });
  
          if( this.state.deleteEntry !== false ){
              this.deleteEntry(this.state.deleteEntry, true);
          }
          if( this.state.doBaixa !== false ){
            this.doBaixa(this.state.doBaixa, true);
          }
          if( this.state.action === "handleCancel" ){
            this.handleCancel(true);
          }
        }
      });

      document.addEventListener("cancelAlert", (e) => {
        if( this._isMounted ){
          this.setState({
            showAlert: false
          });
        }
      });

      document.addEventListener("cancelLoading", (e) => {
        this.setState({
          loadingVisible: false
        });
      });

      this.listCashflow();

      this.listCategory();

      this.FinanceService.centroCustoList().then(response =>{

        this.setState({
          centroCustoList: response,
          centroCusto: response[0]
        });
      });

      this.FinanceService.listFinancialPaymentOptions().then(response =>{
          this.setState({
            formaPagamentoList: response
          });
      });

  }

  listCategory() {
    this.FinanceService.categoryEntryLawsuitList().then(response =>{

      let categoryIncomingList = []; let categoryOutgoingList = [];

      response.map( ( record )=>{
        if(record.financial_type_id === 1){
          categoryIncomingList.push(record);
        }else{
          categoryOutgoingList.push(record);
        }

      });

      this.setState({
        categoryEntryLawsuitList: response,
        categoryIncomingList: categoryIncomingList,
        categoryOutgoingList: categoryOutgoingList,
        categoryList: ( this.state.tipoOperacao !== null && this.state.tipoOperacao.value === "2" ) ? categoryOutgoingList : categoryIncomingList
      });

    });
  }


  clearFields(){
    this.setState(
      {
        loading: false,
        visible: false,
        data_vencimento: null,
        descricao: "",
        errors: {},
        loadingVisible: false,
        loadingMessage: [],
        parcelas: 1,
        lawsuit: null,
        cliente: null,
        switchBaixa: false,
        switchCusta: false,
        data_pagamento: null,
        forma_pagamento: null,
        centroCusto: this.state.centroCustoList[0],
        categoria: null,
        valor: 0,
        token: ""
      }
    );
  }

  addEntry(){

      this.FinanceService = new FinanceService();

      let tipoOperacao = null; let forma_pagamento = null; let data_vencimento = null; let centroCusto = null;
      let categoria = null; let data_pagamento = null; let baixa = 0; let belongs_to_lawsuit = 1;

      if ( typeof this.state.tipoOperacao !== "undefined" && this.state.tipoOperacao !== null ) {
        tipoOperacao = this.state.tipoOperacao.value;
      }

      if ( typeof this.state.forma_pagamento !== "undefined" && this.state.forma_pagamento !== null ) {
        forma_pagamento = this.state.forma_pagamento.value;
      }

      if ( typeof this.state.centroCusto !== "undefined" && this.state.centroCusto !== null ) {
        centroCusto = this.state.centroCusto.value;
      }

      if ( typeof this.state.categoria !== "undefined" && this.state.categoria !== null ) {
        categoria = this.state.categoria.value;
      }

      if ( typeof this.state.data_vencimento !== "undefined" ) {
        data_vencimento = this.state.data_vencimento.split('/').reverse().join('-')+" 00:00";
      }

      if ( typeof this.state.data_pagamento !== "undefined" ) {
        data_pagamento = this.state.data_pagamento;
      }

      baixa = this.state.switchBaixa;

      if(tipoOperacao === "1"){
        data_pagamento = data_vencimento;
        baixa = 1;
      }

      let data = {
        "lawsuit_token": this.state.lawsuit_token,
        "tipoOperacao": tipoOperacao,
        "observacao": this.state.descricao,
        "data_vencimento": data_vencimento,
        "data_pagamento": data_pagamento,
        "financial_payment_option_token": forma_pagamento,
        "parcelas": this.state.parcelas,
        "valor": this.state.valor.replace(/[^0-9-]/g, "")/100,
        "financial_center_cost_token": centroCusto,
        "financial_category_token": categoria,
        "pago": baixa,
        "custa": this.state.switchCusta,
        "belongs_to_lawsuit": belongs_to_lawsuit
      }

      if(tipoOperacao === "1"){
        this.FinanceService.addIncoming( data ).then(res =>{
          if( res.success === true ){
                localStorage.setItem('alert_message', "Lançamento salvo com sucesso");
                localStorage.setItem('alert_type', 'alert-success');
                let time = new Date().getTime();
                localStorage.setItem('alert_time', time );
          }else {
            localStorage.setItem('alert_message', res.message);
            localStorage.setItem('alert_type', 'alert-danger');
            let time = new Date().getTime();
            localStorage.setItem('alert_time', time );

          }

          this.listCashflow();
        });
      }else{
        this.FinanceService.addOutgoing( data ).then(res =>{
          if( res.success === true ){
                localStorage.setItem('alert_message', "Lançamento salvo com sucesso");
                localStorage.setItem('alert_type', 'alert-success');
                let time = new Date().getTime();
                localStorage.setItem('alert_time', time );
          }else {
            localStorage.setItem('alert_message', res.message);
            localStorage.setItem('alert_type', 'alert-danger');
            let time = new Date().getTime();
            localStorage.setItem('alert_time', time );

          }

          this.listCashflow();
        });
      }

  }

  moeda( event, value, maskedValue ) {
    event.preventDefault();

    this.setState(
        {
            [event.target.name]: maskedValue
        }
    )
 }

    deleteEntry = ( event, r = false ) => {

       this.FinanceService = new FinanceService();

       //r = window.confirm("Deseja realmente excluir o lançamento?");

       if ( r ){

         if( event.target.dataset.source === "entry" ){

           this.FinanceService.deleteEntry( event.target.dataset.token )
               .then(res =>{
                 if( res.success === true ){

                   localStorage.setItem('alert_message', "Lançamento deletado com sucesso");
                   localStorage.setItem('alert_type', 'alert-success');
                   let time = new Date().getTime();
                   localStorage.setItem('alert_time', time );

                   this.listCashflow();

                 }else{
                   localStorage.setItem('alert_message', res.message);
                   localStorage.setItem('alert_type', 'alert-danger');
                   let time = new Date().getTime();
                   localStorage.setItem('alert_time', time );
                   setTimeout(() => {
                     this.setState({
                       updated: time,
                     });
                   }, 100);
                 }

               })
               .catch(err =>{
                   document.body.style.cursor = "default";
                   console.log(err);
               })

         }else if ( event.target.dataset.source === "incoming" ){

           this.FinanceService.deleteIncoming( event.target.dataset.token )
               .then(res =>{
                 if( res.success === true ){

                   localStorage.setItem('alert_message', "Entrada deletada com sucesso");
                   localStorage.setItem('alert_type', 'alert-success');
                   let time = new Date().getTime();
                   localStorage.setItem('alert_time', time );

                   this.listCashflow();

                 }else{
                   localStorage.setItem('alert_message', res.message);
                   localStorage.setItem('alert_type', 'alert-danger');
                   let time = new Date().getTime();
                   localStorage.setItem('alert_time', time );
                   setTimeout(() => {
                     this.setState({
                       updated: time,
                     });
                   }, 100);
                 }

               })
               .catch(err =>{
                   document.body.style.cursor = "default";
                   console.log(err);
               })

         }else if ( event.target.dataset.source === "outgoing" ){
           this.FinanceService.deleteOutgoing( event.target.dataset.token )
               .then(res =>{
                 if( res.success === true ){

                   localStorage.setItem('alert_message', "Lançamento deletado com sucesso");
                   localStorage.setItem('alert_type', 'alert-success');
                   let time = new Date().getTime();
                   localStorage.setItem('alert_time', time );

                   this.listCashflow();

                 }else{
                   localStorage.setItem('alert_message', res.message);
                   localStorage.setItem('alert_type', 'alert-danger');
                   let time = new Date().getTime();
                   localStorage.setItem('alert_time', time );
                   setTimeout(() => {
                     this.setState({
                       updated: time,
                     });
                   }, 100);
                 }

               })
               .catch(err =>{
                   document.body.style.cursor = "default";
                   console.log(err);
               })
         }



       }else{

            let evt = {
              target: {
                dataset: {
                  token: event.target.dataset.token,
                  source: event.target.dataset.source
                }
              }
            }
            this.setState({
              showConfirm: true,
              deleteEntry: evt,
              message: "Deseja realmente excluir o lançamento?"
            });

       }

  }

  doBaixa( event, r = false ){

    if( event.target.dataset.source === "entry" ){

          let data = {
            pago: 1
          };
          //data.data_pagamento = data.data_vencimento;

          this.FinanceService.editEntry( data ).then(res =>{

                if( res.success === true ){
                      localStorage.setItem('alert_message', "Baixa efetuada com sucesso");
                      localStorage.setItem('alert_type', 'alert-success');
                      let time = new Date().getTime();
                      localStorage.setItem('alert_time', time );
                }else {
                  localStorage.setItem('alert_message', res.message);
                  localStorage.setItem('alert_type', 'alert-danger');
                  let time = new Date().getTime();
                  localStorage.setItem('alert_time', time );

                }

                this.listCashflow();

          });

    }else if( event.target.dataset.source === "incoming" ){

          let data = {
            pago: 1
          };
          //data.data_pagamento = data.data_vencimento;

            this.FinanceService.editIncoming( event.target.dataset.token, data ).then(res =>{

                  if( res.success === true ){
                        localStorage.setItem('alert_message', "Baixa efetuada com sucesso");
                        localStorage.setItem('alert_type', 'alert-success');
                        let time = new Date().getTime();
                        localStorage.setItem('alert_time', time );
                  }else {
                    localStorage.setItem('alert_message', res.message);
                    localStorage.setItem('alert_type', 'alert-danger');
                    let time = new Date().getTime();
                    localStorage.setItem('alert_time', time );

                  }

                  this.listCashflow();

            });

    }else if( event.target.dataset.source === "outgoing" ){

          let data = {
            pago: 1
          };
          //data.data_pagamento = data.data_vencimento;

          //window.confirm("Esta parcela foi depositada para o cliente?");

          if( r ){
            this.FinanceService.editOutgoing( event.target.dataset.token, data ).then(res =>{

                  if( res.success === true ){
                        localStorage.setItem('alert_message', "Baixa efetuada com sucesso");
                        localStorage.setItem('alert_type', 'alert-success');
                        let time = new Date().getTime();
                        localStorage.setItem('alert_time', time );
                  }else {
                    localStorage.setItem('alert_message', res.message);
                    localStorage.setItem('alert_type', 'alert-danger');
                    let time = new Date().getTime();
                    localStorage.setItem('alert_time', time );

                  }

                  this.listCashflow();

            });
          }else{
                  let evt = {
                    target: {
                      dataset: {
                        token: event.target.dataset.token,
                        source: event.target.dataset.source
                      }
                    }
                  }

                  this.setState({
                    showConfirm: true,
                    doBaixa: evt,
                    message: "Esta parcela foi depositada para o cliente?"
                  });
          }

    }


  }

 onDatePickerChange = (date, dateString, name) => {

   if (dateString === "" ){
     dateString = null;
   }

    this.setState(
        {
            [name]: dateString,
        }
    )

  }

  novoLancamento = () => {

    this.setState({
        token: ""
    });

    setTimeout(() => {
        this.showModal();
    }, 300);

  }

  showModal = () => {

        // Formulário de edição
        if( this.state.token !== "" && typeof this.state.token !== "undefined" ){

          this.FinanceService = new FinanceService();
          this.FinanceService.viewOutgoing( this.state.token ).then(res => {
            //console.log( res );

            let lawsuit_token = this.state.lawsuitList.filter( (lawsuit)=>{ return lawsuit.value === res.lawsuit_token })[0];

            this.setState({
              descricao: res.descricao,
              lawsuit: lawsuit_token,
              labelSaveButton: "Editar",
              titleModal: "Editar Lançamento",
              showStatus: true,
              visible: true,
            });

          });

        }else {

            this.setState({
              labelSaveButton: "Incluir",
              titleModal: "Novo Lançamento do Processo",
              showStatus: false,
              visible: true
            });
        }

        this.setState({
          visible: true
        });

  };

  handleOk = () => {
      if (this.validateForm()) {

        this.setState({
          loadingVisible: true,
          loading: true,loadingMessage: ["O cadastro está sendo efetuado.", "Por gentileza, aguarde...", "Tempo estimado de 15 a 90 segundos"]
          
        });

        this.addEntry();

          setTimeout( () => {
            this.clearFields();
          }, 3000);

      }

  };

  handleCancel = ( r = false ) => {
      //window.confirm("As alterações serão perdidas, deseja realmente sair?")
      if( r ){

          this.clearFields();

      }else{
        this.setState({
          showConfirm: true,
          action: "handleCancel",
          message: "As alterações serão perdidas, deseja realmente sair?"
        });
      }
  };

  handleChange = (e) => {

          this.setState(
              {
                  [e.target.name]: e.target.value
              }
          )

   }

   showCategoryModal = () => {

         this.setState({
           categoryModalVisible: true,
         });
   };

   handleCategoryModalOk = () => {

       this.setState({
         categoryModalVisible: false,
       })
   };

   handleCategoryModalCancel = () => {
       this.setState({ categoryModalVisible: false });
   };


   addCategory = (e) => {
     this.FinanceService = new FinanceService();
     document.body.style.cursor = "wait";
     let categoria = {
       name: document.getElementById("categoryName").value,
       financial_type_id: ( this.state.tipoOperacao !== null ) ? this.state.tipoOperacao.value : 1
     }
     this.FinanceService.addCategoryEntryLawsuit( categoria ).then(res =>{

         document.getElementById("categoryName").value = "";
         document.body.style.cursor = "default";
         this.listCategory();

     });
   }

   deleteCategory = (e) => {
     this.FinanceService = new FinanceService();
     document.body.style.cursor = "wait";

     let id = document.getElementById("categorySettings").value;

     console.log( "categoria que vai ser deletado: "+id );

     this.FinanceService.deleteCategoryEntryLawsuit( id ).then( res =>{

       if( res.success === true ){
             document.body.style.cursor = "default";
             this.listCategory();
       }else{
         document.body.style.cursor = "default";
         //alert(res.message);
          this.setState({
            showAlert: true,
            message: res.message
          });
       }

     });
   }

   operacaoChange = (selected, actionMeta) => {
     let pops = [];
     pops[ actionMeta.name ] = selected;

     if(selected !== null && selected.value === "1"){
       this.setState({
         "categoryList": this.state.categoryIncomingList,
       })
     }else{
       this.setState({
         "categoryList": this.state.categoryOutgoingList,
       })
     }

     if ( typeof actionMeta.name !== undefined && actionMeta.name !== "" && actionMeta.name !== null ){

       if ( typeof selected !== undefined && selected !== "" ) {
         this.setState( pops );
       }

     }
   }

   selectChange = (selected, actionMeta) => {
     let pops = [];
     pops[ actionMeta.name ] = selected;

     if ( typeof actionMeta.name !== undefined && actionMeta.name !== "" && actionMeta.name !== null ){

       if ( typeof selected !== undefined && selected !== "" ) {
         this.setState( pops );
       }

     }
   }

  validateForm = () => {
      let errors = {};
      let formIsValid = true;

      if ( this.state.data_vencimento === 0 || this.state.data_vencimento === "" || typeof this.state.data_vencimento === "undefined" || this.state.data_vencimento === null ) {
        formIsValid = false;
        errors["data_vencimento"] = "*Preencha a data";
      }

      if ( this.state.descricao === 0 || this.state.descricao === "" || typeof this.state.descricao === "undefined" ) {
        formIsValid = false;
        errors["descricao"] = "*Forneça a descrição da entrada";
      }

      if ( this.state.valor === 0 || this.state.valor === "" || typeof this.state.valor === "undefined" ) {
        formIsValid = false;
        errors["valor"] = "*Digite um valor";
      }

      if ( this.state.categoria === 0 || this.state.categoria === "" || typeof this.state.categoria === "undefined" || this.state.categoria === null )  {
        formIsValid = false;
        errors["categoria"] = "*Selecione a categoria";
      }

      if ( ( typeof this.state.forma_pagamento === "undefined" || this.state.forma_pagamento === null ) ) {
        formIsValid = false;
        errors["forma_pagamento"] = "*Escolha uma forma de pagamento";
      }

      if ( ( typeof this.state.tipoOperacao === "undefined" || this.state.tipoOperacao === null ) ) {
        formIsValid = false;
        errors["tipoOperacao"] = "*Escolha um tipo de operação";
      }

      this.setState({
         errors: errors
       });

      return formIsValid;

  }

  formataMoeda( valor ){
    let total2 = valor*100;
    total2 = parseInt( total2 );
    var tmp = total2+'';
    total2 = tmp;
    total2 = total2.replace('-','');
    tmp = tmp.replace(/([0-9]{2})$/g, ",$1");
    if( total2.length > 5 ){
        valor = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
    }else{
      valor = valor.toFixed(2);
      valor = valor.replace('.',',');
    }
    return valor;
  }

  listCashflow () {
    let totalDebito = 0; let totalCredito = 0; let totalDebitoFuture = 0; let totalCreditoFuture = 0; let resultado = 0;
    let resultadoFuture = 0;
    let entrada = [];
    let entradaFuture = [];
    let saida = [];
    let saidaFuture = [];

    this.FinanceService = new FinanceService();
    this.FinanceService.listEntries().then(entries =>{

      if(entries === null){
        entries = []
      }

        this.FinanceService.listLawsuitIncoming( this.state.lawsuit_token ).then(res =>{
              //console.log(res);
              if(res === null){
                res = []
              }

              this.FinanceService.listLawsuitOutgoing( this.state.lawsuit_token ).then(response =>{
                //console.log(response);
                if(response === null){
                  response = []
                }

                res.map( ( record, index, array ) => {
                      record.source = 'incoming';
                      record.type = "entrada";

                      if( record.belongs_to_lawsuit === 1 ){
                          if( moment( record.data_vencimento.substr(0,10) ).valueOf() <= moment().valueOf() ){
                            totalCredito += record.valor;
                            entrada.push(record);
                            return record;
                          }else{
                            totalCreditoFuture += record.valor;
                            entradaFuture.push(record);
                            return record;
                          }
                      }

                        /* A entrada para o escritório é saída para o cliente do processo*/
                        /*record.type = "saida";

                        if(record.financial_category.name === "Valor Pago/Abatimento"){
                          record.type = "entrada";
                          record.tipoOperacao = 1;
                          if( moment( record.data_vencimento.substr(0,10), "YYYY-MM-DD").valueOf() <= moment().valueOf() ){
                            totalCredito += record.valor;
                            entrada.push(record);
                          }else{
                            totalCreditoFuture += record.valor;
                            entradaFuture.push(record);
                          }

                        }*//*else if(record.financial_category.name !== "Recebimento de Acordo"){

                          if( moment( record.data_vencimento.substr(0,10), "YYYY-MM-DD").valueOf() <= moment().valueOf() ){
                            totalDebito += record.valor;
                            saida.push(record);
                          }else{
                            totalDebitoFuture += record.valor;
                            saidaFuture.push(record);
                          }

                        }*/

                      return record;

                });

                response.map( ( record, index, array ) => {

                  record.source = 'outgoing';

                  //console.log("lawsuit: "+record.lawsuit);
                  //console.log(this.state.lawsuit_token);

                        /*if(record.financial_category.name === "Recebimento de Acordo"){
                          record.type = "entrada";
                          record.tipoOperacao = 1;
                          if( moment( record.data_vencimento.substr(0,10), "YYYY-MM-DD").valueOf() <= moment().valueOf() ){
                            if( record.pago !== 1 ){
                              totalCredito += record.valor;
                            }
                            entrada.push(record);
                          }else{
                            if( record.pago !== 1 ){
                              totalCreditoFuture += record.valor;
                            }
                            entradaFuture.push(record);
                          }
                        }else{*/
                          record.type = "saida";

                          if( record.belongs_to_lawsuit === 1 ){
                              if( moment( record.data_vencimento.substr(0,10) ).valueOf() <= moment().valueOf() ){
                                totalDebito += record.valor;
                                saida.push(record);
                                return record;
                              }else{
                                totalDebitoFuture += record.valor;
                                saidaFuture.push(record);
                                return record;
                              }
                          }

                        //}

                      return record;

                });

                entries.map( ( record, index, array ) => {
                  record.source = 'entry';

                  //console.log("lawsuit: "+record.lawsuit);
                  //console.log(this.state.lawsuit_token);

                      if(record.lawsuit === this.state.lawsuit_token){

                        if( record.tipoOperacao.toString() === "1" ){
                          record.type = "entrada";

                          if( moment( record.data_vencimento.substr(0,10), "YYYY-MM-DD").valueOf() <= moment().valueOf() ){
                            totalCredito += record.valor;
                            entrada.push(record);
                          }else{
                            totalCreditoFuture += record.valor;
                            entradaFuture.push(record);
                          }

                        }else if( record.tipoOperacao.toString() === "2" ){
                          record.type = "saida";

                          if( moment( record.data_vencimento.substr(0,10), "YYYY-MM-DD").valueOf() <= moment().valueOf() ){
                            totalDebito += record.valor;
                            saida.push(record);
                          }else{
                            totalDebitoFuture += record.valor;
                            saidaFuture.push(record);
                          }

                        }

                      }


                    return record;

                });

                let cashflow = entrada.concat(saida);
                cashflow.sort(function(a, b){

                  if ( moment(a.data_vencimento.substr(0,10), "YYYY-MM-DD").valueOf() < moment(b.data_vencimento.substr(0,10), "YYYY-MM-DD").valueOf() ){
                    return -1;
                  }else{
                    return 1;
                  }

                });
                //console.log("cashflow: ");
                //console.log(cashflow);

                let cashflowFuture = entradaFuture.concat(saidaFuture);
                cashflowFuture.sort(function(a, b){

                  if ( moment(a.data_vencimento.substr(0,10), "YYYY-MM-DD").valueOf() < moment(b.data_vencimento.substr(0,10), "YYYY-MM-DD").valueOf() ){
                    return -1;
                  }else{
                    return 1;
                  }

                });

                //console.log(cashflowFuture);

                resultado = totalCredito - totalDebito;
                resultadoFuture = totalCreditoFuture - totalDebitoFuture;

                let classResult = (resultado > 0) ? "credit" : "debito";
                let classFuture = (resultadoFuture > 0) ? "credit" : "debito";

                resultado = this.formataMoeda(resultado);
                resultadoFuture = this.formataMoeda(resultadoFuture);

                this.setState({
                  cashflow: cashflow,
                  cashflowFuture: cashflowFuture,
                  resultado: resultado,
                  resultadoFuture: resultadoFuture,
                  classResult: classResult,
                  classFuture: classFuture
                });

              });

        });
    });


  }

   cashflowItem = ( {record, index}, type, future=false ) => {
       let valor  = this.formataMoeda(record.valor);
       let category = ""; let baixa = null;
       let source = (typeof record.source !== "undefined")? record.source : "";
       //console.log(record);
       /*if(record.acordo === 1 && record.categoria !== null){
         category = categoryAcordoList.find( (law)=>{ return law.value === record.categoria } );
         category = ( typeof category === "undefined") ? "" : category.label;
       }else if(record.categoria !== null && record.type==="saida"){

         /*Se for indefinida testo também na incoming já que a incoming agora pode ser tratada como saída*/
        /* if( typeof record.tipoOperacao !== "undefined" ){
           category = this.state.categoryEntryLawsuitList.find( (law)=>{ return law.value === record.categoria } );
           if ( typeof category !== "undefined"){
             category = category.label;
           }

         }else {
           category = this.state.categoryOutgoingList.find( (law)=>{ return law.value === record.categoria } );

           if( typeof category === "undefined" ){
            category = this.state.categoryIncomingList.find( (law)=>{ return law.value === record.categoria } );
            //console.log(cliente);
            category = ( typeof category === "undefined") ? "" : category.label;

          }else{
             category = category.label;
           }

         }

       }else if(record.categoria !== null && record.type==="entrada"){

         /*Boto a regra, segundo a tela de lançamento dos processos*/
      /*   category = this.state.categoryEntryLawsuitList.find( (law)=>{ return law.value === record.categoria } );
         //console.log(cliente);
         category = ( typeof category === "undefined") ? "" : category.label;

       }else{
         category = "";
       }*/

       baixa = null;

       if( (typeof record.tipoOperacao !== "undefined" && record.tipoOperacao.toString() === "1") ||
            (record.financial_category.name === "Recebimento de Acordo") ){
             if( record.pago === 1 || record.pago === true ){
               baixa =  <div className="toggle">
                              <input className="toggle__input" readOnly type="checkbox" checked />
                              <span className="toggle__label">
                                <span className="toggle__text"></span>
                              </span>
                              <div className="toggle__title">Baixa</div>
                        </div>
             }else{
               baixa = <label className="toggle">
                           <button className="curi" data-source={source} data-token={record.token} onClick={ ( event ) => { this.doBaixa(event) } } >
                           </button>
                           <div className="toggle__title">Baixa</div>
                       </label>
             }
       }

       /*if ( record.custa === true){
         baixa = null;
       }else if( record.baixa === 1 || record.baixa === true ){
         baixa =  <div className="toggle">
                        <input className="toggle__input" readOnly type="checkbox" checked />
                        <span className="toggle__label">
                          <span className="toggle__text"></span>
                        </span>
                        <div className="toggle__title">Baixa</div>
                  </div>
       }else{
         baixa = <label className="toggle">
                     <button className="curi" data-token={record.token} >
                     </button>
                     <div className="toggle__title">Baixa</div>
                 </label>
       }*/

       let data_vencimento = (record.data_vencimento !== null) ? record.data_vencimento.substr(0, 10).split('-').reverse().join('/') : "";
       let data_pagamento = (record.data_pagamento !== null) ? record.data_pagamento.substr(0, 10).split('-').reverse().join('/') : "";

       let excluir = <i className="fa fa-trash" data-source={source} data-token={record.token} onClick={ ( event ) => { this.deleteEntry(event) } } ></i>;

               /*{( record.custa === true) ? record.data_vencimento : record.data_pagamento}*/

      let data_baixa_container = "";
      if(!future){
        data_baixa_container = 
        <td className="date date-baixa" >
                {(record.type === "saida" && record.financial_category.name !== "Recebimento de Acordo" )? data_vencimento : data_pagamento}
        </td>;
      }
       return (
         <tr key={record.token} className={type}>
               <td className="category">
                   {record.financial_category.name}
               </td>
               <td className="description">
                   {record.observacao}
               </td>
               <td className={(record.type==='entrada') ? "credit type": "debito type"}>
                   {(record.type==='entrada') ? "Crédito": "Débito"}
               </td>
               <td className={(record.type==='entrada') ? "credit valor": "debito valor"}>
                   {valor}
               </td>
               <td className="date " >
                       {data_vencimento}
               </td>
               {data_baixa_container}
               <td className="actions">
                   {baixa}
                   {this.authService.can('financeiro_do_processo_excluir', true, 'financeiro') ? excluir : null}
               </td>
         </tr>
       );
   };

  render() {

    if( !this.authService.can("financeiro_do_processo", true, 'financeiro') ){
      return null;
    }

    var arrCategory = [];
    for (var k = 0; k < this.state.categoryList.length; k++) {
        arrCategory.push(<option key={k} value={this.state.categoryList[k].value}> {this.state.categoryList[k].label} </option>);
    }

    var url_base = localStorage.getItem('url_base');

      return (
        <div className="panel panel-default container-parte">
              <FlashMessage time={ new Date().getTime() } />
              <Confirm show={this.state.showConfirm} text={this.state.message} />
              <Alert show={this.state.showAlert} text={this.state.message} />
              <Loading visible={this.state.loadingVisible} message1={this.state.loadingMessage[0]} message2={this.state.loadingMessage[1]} message3={this.state.loadingMessage[2]} cancel={true}  />
              <div className="panel-heading text-center">
                  <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" ref={this.myRef} onClick={(event) => this.roll(event)} data-target="#finance-lawsuit-target">
                      <strong>Controle Financeiro do Processo</strong>
                  </div>
              </div>
              <div className="panel-body collapse" id="finance-lawsuit-target">
              <span className="btn btn-primary form-submit add-lancamento" onClick={this.novoLancamento}>Novo Lançamento</span>
              <MyPopover icon="white" id="addPopover"  text="Possibilita o lançamento e controle de Débitos e Créditos referentes a este processo. O lançamento será automático no Fluxo de Caixa." />
              <Modal
                visible={this.state.visible}
                title="Financeiro do Processo"
                width="875px"
                onOk={this.handleOk}
                onCancel={ ()=>{this.handleCancel()} }
                footer={[
                  <Button key="submit" id="handleOk" type="primary" loading={this.state.loading} onClick={this.handleOk}>
                    {this.state.labelSaveButton}
                  </Button>,
                  <Button key="back" id="close" className="btn-danger" onClick={ ()=>{this.handleCancel()} }>
                    Fechar
                  </Button>,
                ]}
              >
                <div id="lancamento-container" className="fieldset finance-lawsuit">
                      <span className="col-md-12 title-container">{this.state.titleModal}</span>
                      <div className="form-group col-md-3 text-left">
                            <label htmlFor="valorTotal" className="">Valor Total<span className="text-red">*</span></label>
                            <IntlCurrencyInput className="form-control input-lg" currency="BRL" config={currencyConfig} name="valor" id="valor" value={this.state.valor} onChange={this.moeda} />
                            <div className="errorMsg">{this.state.errors.valor}</div>
                      </div>
                      <div className="form-group col-md-3">
                          <label htmlFor="cliente">
                              Parcelas
                              <MyPopover icon="white" text="O Valor total será dividido pelo n° de parcelas. Caso queira lançar 4 x  R$100,00 mensais, o valor total deverá ser R$ 400,00." />
                          </label>
                          <input
                              type="number"
                              className="parcelas input-lg form-control"
                              min="1"
                              max="12"
                              value={this.state.parcelas}
                              name="parcelas"
                              onChange={this.handleChange}
                            />
                      </div>
                      <div className="form-group col-md-3 text-left">
                          <label htmlFor="data_vencimento" className="control-label">Data Vencimento<span className="text-red">*</span></label>
                          <DatePicker locale={locale} className="data_vencimento" placeholder="Data de Vencimento" showToday={false} allowClear={false} format="DD/MM/YYYY" value={( this.state.data_vencimento !== null ) ? moment(this.state.data_vencimento, "DD/MM/YYYY") : null} onChange={(date, dateString) => this.onDatePickerChange(date, dateString, "data_vencimento")} />
                          <div className="errorMsg">{this.state.errors.data_vencimento}</div>
                      </div>
                      <div className="form-group select-container">
                        <label htmlFor="tipo-operacao">Tipo de Operação<span className="text-red">*</span></label>
                        <Select
                            className="operacaoSelect"
                            styles={customStyles}
                            options={tipoOperacaoList}
                            placeholder="Selecione"
                            name="tipoOperacao"
                            isClearable={true}
                            value={this.state.tipoOperacao}
                            onChange={this.operacaoChange}
                          />
                          <div className="errorMsg">{this.state.errors.tipoOperacao}</div>
                      </div>
                      <div className="form-group col-xs-12 col-md-4">
                          <label htmlFor="categoria">Categoria<span className="text-red">*</span></label>
                          <Select
                              className="categorySelect"
                              styles={customStyles}
                              options={this.state.categoryList}
                              placeholder="Selecione"
                              name="categoria"
                              isClearable={true}
                              value={this.state.categoria}
                              onChange={this.selectChange}
                            />
                            <i className="material-icons settings" onClick={this.showCategoryModal} title="Clique para adicionar ou excluir categorias">settings</i>
                            <div className="errorMsg">{this.state.errors.categoria}</div>
                      </div>
                      <div className="form-group col-xs-12 col-md-4">
                          <label htmlFor="centroCusto">Centro de Custo</label>
                          <Select
                              className="centroCustoSelect"
                              styles={customStyles}
                              options={this.state.centroCustoList}
                              placeholder="Selecione"
                              name="centroCusto"
                              defaultValue={this.state.centroCustoList[0]}
                              value={this.state.centroCusto}
                              onChange={this.selectChange}
                            />
                            <i className="material-icons settings" onClick={this.showCentroCustoModal} title="Clique para adicionar ou excluir centro de Custos">settings</i>
                      </div>
                      <div className="form-group col-xs-12 col-md-4">
                          <label htmlFor="forma_pagamento">Forma de Pagamento<span className="text-red">*</span></label>
                          <Select
                              styles={customStyles}
                              className="forma-pagamento"
                              options={this.state.formaPagamentoList}
                              placeholder="Selecione"
                              value={this.state.forma_pagamento}
                              name="forma_pagamento"
                              onChange={this.selectChange}
                            />
                            <div className="errorMsg">{this.state.errors.forma_pagamento}</div>
                      </div>
                      <div className="form-group col-xs-12 col-md-6 text-left">
                            <label htmlFor="descricao" className="control-label">Observação<span className="text-red">*</span></label>
                            <textarea name="descricao" id="descricao" maxLength="68" rows="1" value={this.state.descricao} className="form-control input-lg" onChange={this.handleChange}></textarea>
                            <div className="errorMsg">{this.state.errors.descricao}</div>
                      </div>
                  </div>
              </Modal>
              <Modal
                visible={this.state.categoryModalVisible}
                className="modalSettings"
                title="Incluir ou Excluir Categoria"
                width="450px"
                onOk={this.handleCategoryModalOk}
                onCancel={this.handleCategoryModalCancel}
                footer={[
                  <Button key="back" className="btn-danger" onClick={this.handleCategoryModalCancel}>
                    Fechar
                  </Button>
                ]}
              >
                      <div className="form-group">
                        <label htmlFor="categoryName" className="col-form-label col-md-12">Nova Categoria: </label>
                        <input type="text" className="form-control" id="categoryName" />
                        <span className="btn btn-primary" id="add-category" onClick={this.addCategory} >Incluir</span>
                      </div>
                      <div className="form-group">
                          <label htmlFor="categorySettings" className="col-form-label col-md-12">Opções: </label>
                          <select multiple id="categorySettings" className="category settings">
                            {arrCategory}
                          </select>
                          <i className="material-icons delete" id="delete-category" title="remover item selecionado" onClick={this.deleteCategory} >delete</i>
                      </div>
                      <p className="info">As alterações são salvas automaticamente</p>
              </Modal>
                <h3 className="relative">Extrato Atual <div className={this.state.classResult+" resultado"}>{(this.state.classResult === 'credit')? "Crédito R$ " : "Débito R$ "}{this.state.resultado}</div></h3>
                <div className="table-responsive">
                    <table id="finance-table" className="table">
                            <thead>
                                <tr>
                                      <th className="category">
                                          <div>Categoria</div>
                                      </th>
                                      <th className="description">
                                          <div>Observação</div>
                                      </th>
                                      <th className="type">
                                          <div>Tipo</div>
                                      </th>
                                      <th className="valor">
                                          <div>Valor</div>
                                      </th>
                                      <th className="date">
                                          <div>Data Vcto</div>
                                      </th>
                                      <th className="date">
                                          <div>Data Baixa</div>
                                      </th>
                                      <th className="actions">
                                          <div>Ações</div>
                                      </th>
                                </tr>
                            </thead>
                            <tbody>
                                  { ( typeof this.state.cashflow !== "undefined") ? this.state.cashflow.map( (record, index) => this.cashflowItem({ record, index }, 'finance-lawsuit-item') ) : null }
                            </tbody>
                    </table>
                </div>
                <hr></hr>
                <h3 className="relative">Extrato Futuro <div className={this.state.classFuture+" resultado"}>{(this.state.classFuture === 'credit')? "Crédito R$ " : "Débito R$ "}{this.state.resultadoFuture}</div></h3>
                <div className="table-responsive">
                      <table id="finance-future-table" className="table">
                              <thead>
                                  <tr>
                                        <th className="category">
                                            <div>Categoria</div>
                                        </th>
                                        <th className="description">
                                            <div>Observação</div>
                                        </th>
                                        <th className="type">
                                            <div>Tipo</div>
                                        </th>
                                        <th className="valor">
                                            <div>Valor</div>
                                        </th>
                                        <th className="date">
                                            <div>Data Vcto</div>
                                        </th>
                                        <th className="actions">
                                            <div>Ações</div>
                                        </th>
                                  </tr>
                              </thead>
                              <tbody>
                                    { ( typeof this.state.cashflowFuture !== "undefined") ? this.state.cashflowFuture.map( (record, index) => this.cashflowItem({ record, index }, 'finance-lawsuit-item', true) ) : null }
                              </tbody>
                      </table>
                </div>
              </div>
        </div>
      )

  }
}
