import AuthService from './AuthService';
import {api} from '../config';


export default class LevelsService {
    // Initializing important variables
    constructor(domain) {
        this.domain = domain || api.url // API server domain
        this.fetch = this.fetch.bind(this) // React binding stuff
    }

    filialsList( user_token ){
      return this.fetch(`${this.domain}RuleFilials/index/${user_token}`, {
          method: 'GET',
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res.data.RuleFilials);
      })
    }

    ruleAreasList( user_token ){
      return this.fetch(`${this.domain}RuleLawsuitAreas/index/${user_token}`, {
          method: 'GET',
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res.data.RuleLawsuitAreas);
      })
    }

    responsiblesList( user_token ){
      return this.fetch(`${this.domain}RuleResponsibles/index/${user_token}`, {
          method: 'GET',
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res.data.RuleResponsibles);
      })
    }

    permissionsHardList( user_token ){
      return this.fetch(`${this.domain}RulePermissionUsers/index/${user_token}`, {
          method: 'GET',
      }).then(res => {
          if( res.success === true){
            //console.log(res);
            let result = res.data.RulePermissionUsers;
            let response = "";

            for(var k in result) {

              //console.log(result[k]);
              response = result[k];

            }
            console.log(response);
            return Promise.resolve(response);
          }else if( res.message === "Você não tem permissão para acessar o sistema neste momento."){
              //alert(res.message);
              window.location.href = localStorage.getItem('url_base')+'/access-denied';
              //window.location.href = localStorage.getItem('url_base')+'/logout';
          }

      })
    }

    permissionsList( user_token ){
      return this.fetch(`${this.domain}RulePermissionUsers/index/${user_token}`, {
          method: 'GET',
      }).then(res => {
          //console.log(res);

          if(res.success === true){

            let result = res.data.RulePermissionUsers;
            let response = "";

            for(var k in result) {

              //console.log(result[k]);
              let financeiro = [];
              let financeiro_excluir = [];

              result[k].financeiro.map( (record, index) => {
                if( record.keycode.includes('_excluir') ){
                  let fin = record;
                  let base = record.keycode.split('_excluir');
                  record.base = base[0];
                  financeiro_excluir.push(fin);
                }
                return record;
              });

              result[k].financeiro.map( (record, index) => {

                financeiro_excluir.map( (fin, index) => {

                  if( record.keycode === fin.base ){
                    record.keycode_excluir = fin.keycode;
                    record.id_excluir = fin.id;
                    record.allowed_excluir = fin.allowed;
                    financeiro.push(record);
                  }

                  return fin;

                });

                return record;

              });

              let ged = [];
              let ged_excluir = [];

              result[k].ged.map( (record, index) => {
                if( record.keycode.includes('_excluir') ){
                  let fin = record;
                  let base = record.keycode.split('_excluir');
                  record.base = base[0];
                  ged_excluir.push(fin);
                }
                return record;
              });

              result[k].ged.map( (record, index) => {

                ged_excluir.map( (fin, index) => {

                  if( record.keycode === fin.base ){
                    record.keycode_excluir = fin.keycode;
                    record.id_excluir = fin.id;
                    record.allowed_excluir = fin.allowed;
                    ged.push(record);
                  }

                  return fin;

                });

                return record;

              });

              let agenda = [];
              let agenda_excluir = [];

              result[k].agenda.map( (record, index) => {
                if( record.keycode.includes('_excluir') ){
                  let fin = record;
                  let base = record.keycode.split('_excluir');
                  record.base = base[0];
                  agenda_excluir.push(fin);
                }
                return record;
              });

              result[k].agenda.map( (record, index) => {

                agenda_excluir.map( (fin, index) => {

                  if( record.keycode === fin.base ){
                    record.keycode_excluir = fin.keycode;
                    record.id_excluir = fin.id;
                    record.allowed_excluir = fin.allowed;
                    agenda.push(record);
                  }
                  return fin;

                });

                return record;

              });

              let processos = [];
              let processos_excluir = [];

              result[k].processos.map( (record, index) => {
                if( record.keycode.includes('_excluir') ){
                  let fin = record;
                  let base = record.keycode.split('_excluir');
                  record.base = base[0];
                  processos_excluir.push(fin);
                }
                return record;
              });

              result[k].processos.map( (record, index) => {

                processos_excluir.map( (fin, index) => {

                  if( record.keycode === fin.base ){
                    record.keycode_excluir = fin.keycode;
                    record.id_excluir = fin.id;
                    record.allowed_excluir = fin.allowed;
                    processos.push(record);
                  }

                  return  fin;

                });

                return record;

              });
              
              result[k].processos = processos;
              result[k].agenda = agenda;
              result[k].ged = ged;
              result[k].financeiro = financeiro;

              response = result[k];

            }
            return Promise.resolve(response);

          }else{

          }


      })
    }

    setAreas( permissions, token ){
      return this.fetch(`${this.domain}RuleLawsuitAreas/add/${token}`, {
          method: 'POST',
          body: JSON.stringify(permissions)
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      })
    }

    setFilials( permissions, token ){
      return this.fetch(`${this.domain}RuleFilials/add/${token}`, {
          method: 'POST',
          body: JSON.stringify(permissions)
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      })
    }

    setPermission( permissions ){
      return this.fetch(`${this.domain}RulePermissionUsers/add/`, {
          method: 'POST',
          body: JSON.stringify(permissions)
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      })
    }

    setResponsibles( permissions, token ){
      return this.fetch(`${this.domain}RuleResponsibles/add/${token}`, {
          method: 'POST',
          body: JSON.stringify(permissions)
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      })
    }

    usersPermissionsList( ){
      return this.fetch(`${this.domain}RulePermissionUsers/index/`, {
          method: 'GET',
      }).then(res => {
          //console.log(res);
          let result = res.data.RulePermissionUsers;

          let response = [];
          var i = 0;

          for(var k in result) {
             response[i] = result[k];
             i++;
          }

          return Promise.resolve(response);
      })
    }

    fetch(url, options) {
          let auth = new AuthService();

          options.headers = {
             'Authorization': 'Bearer ' + auth.getToken(),
             'Content-Type': 'application/json',
             'Accept': 'application/json'
           }

          return fetch(url, options)
                  .then(this._checkStatus)
                  .then(response => {

                          if(typeof response !== "undefined"){

                            return Promise.resolve( response.text().then(function(result){
                                //console.log(result);
                                let old_result = result;
                                result = result.split('{"success"');
                                if( result.length > 1 ){
                                    result = result[1].split('<pre');
                                    //console.log(result[0]);
                                    let json = '{"success"'+result[0];
                                    result = JSON.parse(json);
                                    //console.log(result);
                                    return result;
                                }else{
                                    return old_result;
                                }

                            }) );

                        }else{

                            let result = {
                                success: false,
                                message: "Erro desconhecido"
                            }

                            return Promise.resolve( result );

                        }

                  })
    }

    _checkStatus(response) {
        // raises an error in case response status is not a success
        if (response.status >= 200 && response.status < 300) { // Success status lies between 200 to 300
            return response
        } else if( response.status === 401 || response.status === "401" ) {
          console.log("Tentando trapacear? Você será desligado");


          localStorage.removeItem('id_token');
          localStorage.removeItem('loggedUser');
          window.location.href = '/login';

        }else {
            var error = new Error(response.statusText)
            error.response = response
            throw error
        }
    }

    /*fetch(url, options) {
        // performs api calls sending the required authentication headers
        let auth = new AuthService();

        options.headers = {
           'Authorization': 'Bearer ' + auth.getToken(),
           'Content-Type': 'application/json',
           'Accept': 'application/json'
         }

        return fetch(url, options)
            .then(this._checkStatus)
            .then(response => response.json())
    }*/
}
