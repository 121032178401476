import React from 'react';
import MyPopover from '../containers/mypopover';
import HeaderBar from '../header-bar/header-bar';
import NavigationMenu from '../sidebar/navigation-menu';
import Footer from "../containers/footer";
import Alert from '../containers/alert';
import Loading from '../containers/loading';
import FlashMessage from '../containers/FlashMessage';

import company_logo from '../json/company_logo';

import ReactQuill from 'react-quill';
//import GedService from '../../services/GedService';
import AuthService from '../../services/AuthService';
import OfficeService from '../../services/OfficeService';
import { post } from 'axios';
import {api} from '../../config';
import {isTablet} from './mobileDetect';

export default class ConfigLogo extends React.Component { 
  _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }


  constructor( props ) {
    super(props);
    this.fileUpload = this.fileUpload.bind(this);
    this.addDocument = this.addDocument.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.saveHeader = this.saveHeader.bind(this);

    this.state = {
      logo: company_logo,
      header: "",
      content: "",
      loading: false,
      visible: false,
      loadingVisible: false,
      loadingMessage: [],
      message: "",
      showAlert: false
    }    
    
    if(isTablet()){
      alert('Para melhor experiência do usuário esta tela não pode ser acessada por tablets e smartphones. Este acesso será possível somente atravéz de um computador. Você será redirecionado para o Dashboard');
      window.location.href = '/';
    }

  }

  componentDidMount( ) { 
      this._isMounted = true;

      document.addEventListener("cancelLoading", (e) => {
        if( this._isMounted ){
          this.setState({
            showAlert: false
          });
        }
      });

      this.getConfigs();

  }

  fileUpload(file){
    let user = JSON.parse( localStorage.getItem( 'loggedUser' ) );
    let office_token = user.office_token;//"4eb7f0a8-4541-4e96-890e-9318b232f945";
    const url = api.url+'Offices/edit/'+office_token;
    const formData = new FormData();

    //console.log(file);
    let names = [];

    for(let i=0; i < file.length; i++){
      //files.push(file[i]);
      formData.append(i,file[i], file[i].name);
      names.push(this.state.title);
    }

    //formData.append('file',file);
    formData.append('names',JSON.stringify(names));
    let auth = new AuthService();
    const config = {
        headers: {
            'content-type': 'multipart/form-data',
            'Authorization': 'Bearer ' + auth.getToken(),
        }
    }
    return  post(url, formData, config)
  }

  onChange(e) {
    //console.log(e.target.files);
    if (e.target.files.length > 1) {
        //alert("Você pode selecionar somente um arquivo");
        this.setState({
          showAlert: true,
          message: "Você pode selecionar somente um arquivo"
        });
        document.getElementById("handleOk").disabled = true;
    } else {
        this.setState({file:e.target.files});
        document.getElementById("handleOk").disabled = false;
    }

    var maxSize = e.target.dataset.max_size;

    for( let i = 0; i< e.target.files.length; i++){
        if(e.target.files.length){
              var fileSize = e.target.files[0].size; // in bytes
              if(fileSize>maxSize){
                  //alert('o arquivo '+e.target.files[0].name + ' é maior que 15 MegaBytes');
                  this.setState({
                    showAlert: true,
                    message: 'o arquivo '+e.target.files[0].name + ' é maior que 15 MegaBytes'
                  });
                  document.getElementById("handleOk").disabled = true;
              }else{
                  document.getElementById("handleOk").disabled = false;
              }
          }
    }

    var reader = new FileReader();

    reader.onload = (e) => {
      this.setState({
        logo: e.target.result.split(',')[1]
      });
    }

    reader.readAsDataURL(e.target.files[0]);

  }

  handleChange (html) {
      this.setState({ content: html });

      /*if( html.length > 100 ){
        this.setState({ template_disabled: false, error: "" });
      }else{
        this.setState({ template_disabled: true, error: "Número de caracteres menor que o permitido para o documento." });
      }*/
  }

  getConfigs = () => {
        this.OfficeService = new OfficeService();
        let token = JSON.parse(localStorage.getItem('loggedUser')).office_token;//'4eb7f0a8-4541-4e96-890e-9318b232f945';

        this.OfficeService.getConfigs( token ).then(res =>{

              if (this._isMounted) {

                  let logo = (res.logo !== null ) ? res.logo : this.state.logo;

                  this.setState({
                    content: res.cabecalho,
                    logo: logo
                  });

              }
        });
  }

  saveHeader = (event) => {
        this.OfficeService = new OfficeService();
        this.setState({
          loadingVisible: true,
          loadingMessage: ["As informações estão sendo atualizadas.", "Por gentileza, aguarde...", ""]
        });

        let token = JSON.parse(localStorage.getItem('loggedUser')).office_token;//'4eb7f0a8-4541-4e96-890e-9318b232f945';

        let header = {
            'cabecalho': this.state.content,
        };

        this.OfficeService.saveHeader( header, token ).then(res =>{

            if (this._isMounted) {

                  if( res.success === true ){
                      localStorage.setItem('alert_message', "Informações atualizadas com sucesso");
                      localStorage.setItem('alert_type', 'alert-success');
                      let time = new Date().getTime();
                      localStorage.setItem('alert_time', time );
                      setTimeout(() => {
                        this.setState({
                          updated: time,
                        });
                      }, 100);
                      //alert("Comentário adicionado com sucesso");
                  }else{
                      //alert(res.message);
                  }

                  this.setState({visible: false, title: "", file: "", loadingVisible: false});

            }

        });
  }

  addDocument = (event) => {

        if(typeof this.state.file !== "undefined"){
                this.setState({
                  loadingVisible: true,
                  loadingMessage: ["O cadastro está sendo efetuado.", "Por gentileza, aguarde...", ""]
                });

                this.fileUpload(this.state.file).then( (response) =>{

                    if (this._isMounted) {
                          console.log(response);

                          if(response.data.success === 1){
                            localStorage.setItem('alert_message', "Arquivo adicionado com sucesso");
                            localStorage.setItem('alert_type', 'alert-success');
                            let time = new Date().getTime();
                            localStorage.setItem('alert_time', time );
                            setTimeout(() => {
                              this.setState({
                                updated: time,
                              });
                            }, 100);

                            document.getElementById("file_ged").value="";

                          }

                          this.setState({visible: false, title: "", file: "", loadingVisible: false});
                    }

                });
        }else{
          //alert("Selecione uma imagem");
          this.setState({
            showAlert: true,
            message: "Selecione uma imagem"
          });
        }

  }

  modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, 4, 5, 6, false] },
      'bold', 'italic', 'underline','strike',
      'align' ,{ 'align': 'center' },{ 'align': 'right' }, { 'align': 'justify' },{ 'font': [] }, { 'color': [] }, { 'size': [] }]
    ],
  };

  formats = [
    'header',
    'bold', 'italic', 'underline', 'strike','align','font', 'color', 'size'


  ];

  render() {

      return <div className="config-logo sheet">
                <HeaderBar />
                <NavigationMenu />
                <FlashMessage time={ new Date().getTime() } />
                <Alert show={this.state.showAlert} text={this.state.message} />
                <Loading visible={this.state.loadingVisible} message1={this.state.loadingMessage[0]} message2={this.state.loadingMessage[1]} message3={this.state.loadingMessage[2]} cancel={true}  />
                <div className="content-wrapper">
                  <div className="content-wrapper-inner">
                      <section className="content-header">
                        <h1 className="page-header">Configurações - Cabeçalho e Logomarca</h1>
                      </section>
                      <div className="panel panel-primary">
                            <div className="panel-heading text-center">
                                  <h4>
                                        Cabeçalho padrão para os Relatórios e Documentos
                                        <MyPopover icon="blue" text="Personalize seus relatórios e documentos. Poderá editar individualmente as fontes para cada linha do cabeçalho,  tamanho, cores, negritar, sublinhar, etc... " />
                                  </h4>
                            </div>
                      </div>
                      <div className="box-body">
                          <div className="form-group logo-form">
                              <label htmlFor="lawsuit-number">Adicionar Imagem de Logo<span className="text-red">*</span></label>
                              <input type="file" id="file_ged" accept="image/png, image/jpeg" name="file" data-max_size="15728640" onChange={this.onChange} />
                                  <div className="">Dimensões recomendadas para a imagem: 150x100.</div>
                              <img id="logo"  className="imagem_salva" src={"data:image/png;charset=utf-8;base64, "+this.state.logo} alt="imagem salva" />
                              <button className="btn btn-primary" id="handleOk" onClick={this.addDocument}>
                                Alterar Marca
                              </button>
                          </div>
                          <ReactQuill theme="snow"
                                      className="editor"
                                      onChange={this.handleChange}
                                      value={this.state.content}
                                      modules={this.modules}
                                      formats={this.formats}
                          />
                          <button className="btn btn-success" id="saveHeader" onClick={this.saveHeader}>
                            Salvar Cabeçalho
                          </button>
                      </div>
                  </div>
                </div>
                <Footer />
             </div>
  }

}
