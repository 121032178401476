import React from 'react';
import IntlCurrencyInput from "react-intl-currency-input";
import InputMask from 'react-input-mask';
import INSSSection from './inssSection';
import Select from 'react-select';
import LawsuitService from '../../../services/LawsuitService';
import AuthService from '../../../services/AuthService';
import TribunalService from '../../../services/TribunalService';

import Loading from '../../containers/loading';
import Alert from '../../containers/alert';
import Confirm from '../../containers/confirm';
import Popover, { ArrowContainer } from 'react-tiny-popover';

import moment from "moment";


import 'moment/locale/pt-br';

moment.locale('pt-BR');

const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
}

const instancia = [
  { value: '1', label: 'Processo 1ª Instância' },
  { value: '2', label: 'Processo 2ª Instância' },
];

export default class DataLawsuit extends React.Component { _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }


  constructor( props ) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.moeda = this.moeda.bind(this);
    this.save = this.save.bind(this);
    this.modifiedLawsuit = this.props.modifiedLawsuit.bind(this);

    this.lawsuitService = new LawsuitService();
    this.authService = new AuthService();

    //console.log(typeof props.lawsuit.valorCausa);

    this.state = {
      lawsuit: props.lawsuit,
      token: props.lawsuit.token,
      message: "",
      showAlert: false,
      loadingVisible: false,
      loadingMessage: [],
      errors: {},
      instance: instancia[0],
      name: props.lawsuit.name,
      input1: ( props.lawsuit.input1 !== null && typeof props.lawsuit.input1 !== "undefined"  ) ?  props.lawsuit.input1 : "",
      n_processo: ( props.lawsuit.number !== null  ) ?  props.lawsuit.number : "",
      dataDistribuicao: (typeof props.lawsuit.dataDistribuicao !== "undefined" && props.lawsuit.dataDistribuicao !== null )? props.lawsuit.dataDistribuicao.substr(0, 10).split('-').reverse().join('/') : "",
      dataCadastro: (typeof props.lawsuit.created !== "undefined")? props.lawsuit.created.substr(0, 10).split('-').reverse().join('/') : "",
      valorCausa: ( props.lawsuit.valorCausa === null ||  typeof props.lawsuit.valorCausa === 'string') ? 0 : props.lawsuit.valorCausa,
      vara: ( props.lawsuit.vara === null || typeof props.lawsuit.vara === "undefined" ) ? "" : props.lawsuit.vara,
      assunto: ( props.lawsuit.assunto === null || typeof props.lawsuit.assunto === "undefined" ) ? "" : props.lawsuit.assunto,
    }
  }

  componentWillReceiveProps(nextProps) {
    if ( nextProps !== this.props ) {

      //console.log( nextProps );
      //console.log( this.props );

      let dataCadastro = ""; let dataDistribuicao = "";

      if( typeof nextProps.lawsuit.created !== "undefined" ){
        dataCadastro = moment(nextProps.lawsuit.created).format("DD/MM/YYYY");
      }

      if( typeof nextProps.lawsuit.dataDistribuicao !== "undefined" ){
        dataDistribuicao = moment(nextProps.lawsuit.dataDistribuicao).format("DD/MM/YYYY");
      }

      this.setState({
        lawsuit: nextProps.lawsuit,
        token: nextProps.lawsuit.token,
        dataDistribuicao: dataDistribuicao,
        dataCadastro: dataCadastro,
        n_processo: ( nextProps.lawsuit.number !== null  ) ?  nextProps.lawsuit.number : "",
        name: nextProps.lawsuit.name,
        input1: ( nextProps.lawsuit.input1 !== null && typeof nextProps.lawsuit.input1 !== "undefined"  ) ?  nextProps.lawsuit.input1 : "",
        valorCausa: ( nextProps.lawsuit.valorCausa === null ) ? "" : nextProps.lawsuit.valorCausa,
        vara: ( nextProps.lawsuit.vara === null || typeof nextProps.lawsuit.vara === "undefined" ) ? "" : nextProps.lawsuit.vara,
        assunto: ( nextProps.lawsuit.assunto === null || typeof nextProps.lawsuit.assunto === "undefined" ) ? "" : nextProps.lawsuit.assunto
      });

    }
  }

  componentDidMount( ) { 
    this._isMounted = true;

    document.addEventListener("cancelDialogConfirm", (e) => {
      if( this._isMounted ){
        this.setState({
          showConfirm: false,          
        });
      }
    });

    document.addEventListener("confirmDialogConfirm", (e) => {
      if( this._isMounted ){
        this.setState({
          showConfirm: false,
        });

        if( this.state.action === "dataLawsuitSubmit" ){
            this.handleFormSubmit(e, true);
        }
      }
    });

    document.addEventListener("cancelAlert", (e) => {
      if( this._isMounted ){
        this.setState({
          showAlert: false
        });
      }
    });

    document.addEventListener("cancelLoading", (e) => {
      this.setState({
        loadingVisible: false
      });
    });

    let tribunals = [];
    if( JSON.parse(localStorage.getItem('tribunals')) !== null ){

      JSON.parse(localStorage.getItem('tribunals')).map( ( record, index ) => {
        tribunals[index] = {value: record.id, label: record.name};
        return record;
      } );

    }else{
      this.tribunalService = new TribunalService();
      this.tribunalService.listAllowed().then(tribunais =>{
        localStorage.setItem('tribunals', JSON.stringify(tribunais));
        tribunais.map( ( record, index ) => {
          tribunals[index] = {value: record.id, label: record.name};
          return record;
        } );
        this.setState({
          tribunals: tribunals
        });
      });
    }

    this.setState({
      tribunals: tribunals
    })
  }

  handleFormSubmit( e, r = false ){
       //e.preventDefault();
       //window.confirm("Este processo passará de Processo Manual para Processo Ativo e terá atualização automática") === true

       if ( r ){

           if (this.validateForm()) {
             document.getElementById("addButton").disabled = true;
             document.body.style.cursor = "wait";

             let lawsuit = {
               instancia: this.state.instance.value,
               number: this.state.lawsuit_number,
               tribunal_id: this.state.tribunal.value,
               //atualizacao_automatica: 1
             }

             this.setState({
               loadingVisible: true,
               loadingMessage: ["O cadastro está sendo efetuado.", "Por gentileza, aguarde...", "Tempo estimado de 15 a 90 segundos"]
             });

             this.LawsuitService = new LawsuitService();

             this.LawsuitService.edit( lawsuit, this.state.lawsuit.token ).then(res =>{

               document.getElementById("addButton").disabled = false;
               document.body.style.cursor = "default";

               this.LawsuitService.atualizacaoAutomatica( this.state.lawsuit.token ).then( res =>{

               });

               this.setState({
                 loadingVisible: false,
                 loadingMessage: []
               });

               if ( res.success === true ) {
                 localStorage.setItem('alert_message', "Processo cadastrado com sucesso");
                 localStorage.setItem('alert_type', 'alert-success');
                 let time = new Date().getTime();
                 localStorage.setItem('alert_time', time );
                 setTimeout(() => {
                   this.modifiedLawsuit();
                 }, 100);
               }else {
                 localStorage.setItem('alert_message', res.message);
                 localStorage.setItem('alert_type', 'alert-danger');
                 let time = new Date().getTime();
                 localStorage.setItem('alert_time', time );
               }

             })
             .catch(err =>{
                 document.getElementById("addButton").disabled = false;
                 document.body.style.cursor = "default";
                 console.log(err);
                 this.setState({
                   loadingVisible: false,
                   loadingMessage: []
                 });
             });

             document.getElementById("tribunalModal").classList.remove("in");
           }
      }else{
        this.setState({
          showConfirm: true,
          action: 'dataLawsuitSubmit',
          message: "Este processo passará de Processo Manual para Processo Ativo e terá atualização automática"
        });
      }
  }

  validateForm() {
      let errors = {};
      let formIsValid = true;

        if ( !this.state.lawsuit_number ) {
           formIsValid = false;
           errors["lawsuit_number"] = "*Preencha o número do processo";
         }

         if ( this.state.lawsuit_number.search("_") !== -1 ) {
            formIsValid = false;
            errors["lawsuit_number"] = "*Número de Processo incorreto";
          }

         if (typeof this.state.lawsuit_number !== "undefined") {
           if ( this.state.lawsuite_number==="" ) {
             formIsValid = false;
             errors["lawsuit_number"] = "*Preencha o número do processo";
           }
         }

       this.setState({
          errors: errors
        });

        return formIsValid;
 }

  save (e) {
    e.preventDefault();
    document.getElementById("bt-update-edit-dados-processo").disabled = true;
    document.body.style.cursor = "wait";

    let valorCausa = "";

    if( typeof this.state.valorCausa === "string" ){
      valorCausa = this.state.valorCausa.toString().replace(/[^0-9-]/g, "")/100;
    }else{
      valorCausa = this.state.valorCausa;
    }

    let dataDistribuicao = this.state.dataDistribuicao.substr(0, 10).split('/').reverse().join('-')+" 03:00:00";

    if( this.state.dataDistribuicao === ""){
      dataDistribuicao = moment().format('YYYY-MM-DD')+" 00:00:00";
    }

    let data = {
      'dataDistribuicao': dataDistribuicao,
      'valorCausa': valorCausa,
      'vara': this.state.vara,
      'assunto': this.state.assunto,
      'number': this.state.n_processo,
      'input1': this.state.input1,
      'name': this.state.name
    }

    this.lawsuitService.edit( data, this.state.token ).then(res =>{

      document.getElementById("bt-update-edit-dados-processo").disabled = false;
      document.body.style.cursor = "default";

      if ( res.success === true ) {
        setTimeout(() => {
          this.modifiedLawsuit();
        }, 100);
        document.getElementById("bt-edit-dados-topo-processo").classList.remove("hide");
        document.getElementById("view-dados-topo").classList.remove("hide");
        document.getElementById("panel-edit-dados-topo-processo").classList.remove("in");

        document.getElementById("erro-dados-processo").innerHTML = "";
      }else{
        document.getElementById("erro-dados-processo").innerHTML = res.message;

        console.log(res.message);
      }

    })
    .catch(err =>{
      document.getElementById("bt-update-edit-dados-processo").disabled = false;
      document.body.style.cursor = "default";
      //alert("Erro de comunicação com o servidor");
      this.setState({
        showAlert: true,
        message: "Erro de comunicação com o servidor"
      });
    });
  }

  openEdit = () => {
    document.getElementById("bt-edit-dados-topo-processo").className += ' hide';
    document.getElementById("view-dados-topo").className += ' hide';
  }

  closeEdit = () => {
    document.getElementById("bt-edit-dados-topo-processo").classList.remove("hide");
    document.getElementById("view-dados-topo").classList.remove("hide");
  }

  handleChange(e){

    console.log(e.target.name);
    console.log(e.target.value);

       this.setState(
           {
               [e.target.name]: e.target.value
           }
       )
   }

   selectChange = (selected, actionMeta) => {
     let pops = [];
     pops[ actionMeta.name ] = selected;
     if ( typeof actionMeta.name !== undefined && actionMeta.name !== "" && actionMeta.name !== null ){

       if ( typeof selected !== undefined && selected !== "" && selected !== null ) {
         this.setState( pops );
       }

     }
   }

   moeda( event, value, maskedValue ) {
     event.preventDefault();

     this.setState(
         {
             [event.target.name]: maskedValue
         }
     )
  }

  formataMoeda( valor ){
    let total2 = valor*100;
    total2 = parseInt( total2 );
    var tmp = total2+'';
    total2 = tmp;
    total2 = total2.replace('-','');
    tmp = tmp.replace(/([0-9]{2})$/g, ",$1");
    console.log(total2);
    if( total2.length > 5 ){
        valor = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
    }else if( valor === null || valor === "0,00" || valor === "" ) {
        valor = "0,00";
    }else{
      valor = valor.toFixed(2);
      valor = valor.replace('.',',');
    }
    return valor;
  }

  render() {

        let url_base = localStorage.getItem('url_base');

        if( !this.authService.can('dados_processo', true, 'processos') ){
          return null;
        }

        var inssSection; var tribunal_modal_button = null; let lawsuit_name = null; var lawsuit_input_name; let vara = null; let name = "";

        if (typeof this.state.lawsuit.tribunal !== "undefined" && this.state.lawsuit.tribunal !== null && this.state.lawsuit.tribunal.name === "INSS" ) {
          inssSection = <INSSSection lawsuit={this.state.lawsuit} />;
        } else {
          inssSection = "";
        }

        name = (this.state.name !== null) ? this.state.name : "";
        console.log(name);

        let label = <label className="">N° Processo: </label>;

        if ( this.state.lawsuit.tribunal_id === null || (typeof this.state.lawsuit.tribunal !== "undefined" && this.state.lawsuit.tribunal !== null && this.state.lawsuit.tribunal.name === "INSS" ) ) {
          tribunal_modal_button = <span>
                                        <button type="button" className="btn btn-primary tribunal-modal-button" data-toggle="modal" data-target="#tribunalModal" title="Ao cadastrar o Processo, este se tornará Ativo e terá seus andamentos atualizados automaticamente."  >
                                                Monitorar Automático
                                        </button>
                                        <Popover
                                            isOpen={this.state.isPopoverOpen}
                                            position={['top', 'right', 'left', 'bottom']}
                                            padding={10}
                                            onClickOutside={() => this.setState({ isPopoverOpen: false })}
                                            content={({ position, targetRect, popoverRect }) => (
                                                <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                                                    position={position}
                                                    targetRect={targetRect}
                                                    popoverRect={popoverRect}
                                                    arrowColor={'#94bcd8'}
                                                    arrowSize={10}
                                                >
                                                    <div className="popover-box"
                                                        onClick={() => this.setState({ isPopoverOpen: !this.state.isPopoverOpen })}
                                                    >
                                                          Ao dar entrada no processo Manual no Judiciário, cadastre o N° do processo e terá os dados e documentos unificados. Os dados migrados do Tribunal seráo acrescidos a este processo Manual.
                                                    </div>
                                                </ArrowContainer>
                                            )}
                                        >
                                            <img onMouseOut={() => this.setState({ isPopoverOpen: false })}  onMouseOver={() => this.setState({ isPopoverOpen: !this.state.isPopoverOpen })} className="help_icon " alt="ajuda" src={url_base+"/images/interrogation_cropped.png"}></img>
                                        </Popover>
                                  </span>
          lawsuit_input_name =        <div className="form-group col-md-4 text-left">
                                            <label htmlFor="name" className="">N° Processo Manual</label>
                                            <input name="name" id="name" className="form-control" value={name} onChange={this.handleChange} />
                                      </div>
        }else{
          vara =                  <div className="form-group col-md-4 text-left">
                                        <label htmlFor="vara" className="">Vara:</label>
                                        <span>{this.state.vara}</span>
                                  </div>
        }

        if( this.state.name !== null ) {
          lawsuit_name =            <div className="form-group col-md-3 text-left">
                                          <label htmlFor="" className="">N° Processo Manual: </label>
                                          <span >{this.state.name}</span>
                                    </div>
        }

        let valor = 0; let valorCausa = "";

        //valor = this.formataMoeda( this.state.valorCausa);

        valorCausa = (this.state.valorCausa === "") ? "0.00" : parseFloat(this.state.valorCausa).toFixed(2);
        valorCausa = valorCausa.replace('.', ',').replace(/(\d)(?=(\d{3})+\,)/g, "$1.");

        if(typeof this.state.valorCausa === 'string'){
          valor = this.state.valorCausa.replace(/[^0-9-]/g, "")/100;
          valor = parseFloat(valor).toFixed(2);
        }else{
          valor = parseFloat(this.state.valorCausa).toFixed(2);
        }


        let tribunal_processo =   <div className="form-group col-md-4 text-left number_lawsuit">
                                        <label htmlFor="" className="">N° Processo:</label>
                                        {tribunal_modal_button}
                                        <span>{this.state.lawsuit.number}</span>
                                  </div>

        let inss_processo = <div>
                                  <div className="form-group col-md-4 text-left">
                                        <label htmlFor="" className="">N° Processo:</label>
                                        {tribunal_modal_button}
                                  </div>
                                  <div className="form-group col-md-4 text-left beneficio">
                                        <label htmlFor="" className="">N° Processo:</label>
                                        <span>{this.state.lawsuit.number}</span>
                                  </div>
                            </div>

        //valorCausa = this.formataMoeda( this.state.valorCausa );

        /*console.log(this.state.valorCausa);
        console.log(typeof valor);
        console.log(valor);*/
        //console.log(this.state.vara);

      return <div className="panel panel-default container-topo-processo">
                <Confirm show={this.state.showConfirm} text={this.state.message} />
                <Alert show={this.state.showAlert} text={this.state.message} />
                <Loading visible={this.state.loadingVisible} message1={this.state.loadingMessage[0]} message2={this.state.loadingMessage[1]} message3={this.state.loadingMessage[2]} cancel={true}  />
                <div className="panel-heading text-center">
                    <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" data-target="#data-lawsuit-target">
                          <strong>Dados do Processo</strong>
                    </div>
                </div>
                <div className="panel-body collapse in" id="data-lawsuit-target">

                    <div className="panel panel-warning collapse" id="panel-edit-dados-topo-processo">
                            <div className="panel-heading text-center">
                                <strong>Editar Dados do Processo</strong>
                            </div>
                            <div className="form-group col-md-4 text-left">
                                  <label htmlFor="dataDistribuicao" className="">Data Distribuição</label>
                                  <InputMask mask="99/99/9999" className="form-control" maskChar="_" name="dataDistribuicao" id="dataDistribuicao" value={ ( this.state.dataDistribuicao !== null ) ? this.state.dataDistribuicao : "-" } onChange={this.handleChange} />
                            </div>
                            <div className="form-group col-md-4 text-left">
                                  <label htmlFor="dataCadastro" className="">Data Cadastro</label>
                                  <InputMask mask="99/99/9999" className="form-control" maskChar="_" name="dataCadastro" id="dataCadastro" disabled value={ this.state.dataCadastro } />
                            </div>
                            <div className="form-group col-md-4 text-left">
                                  <label htmlFor="valorCausa" className="">Valor da Causa</label>
                                  <IntlCurrencyInput className="form-control" currency="BRL" config={currencyConfig} name="valorCausa" id="valorCausa" value={ valor } onChange={this.moeda} />
                            </div>
                            <div className="form-group col-md-4 text-left">
                                  <label htmlFor="vara" className="">Vara</label>
                                  <input name="vara" id="vara" className="col-md-12 form-control" value={this.state.vara} onChange={this.handleChange} />
                            </div>
                            <div className="form-group col-md-4 text-left">
                                  <label htmlFor="" className="">N° Processo </label>
                                  <input name="n_processo" id="n_processo" className={(this.state.lawsuit.tribunal !== null && typeof this.state.lawsuit.tribunal !== "undefined" && this.state.lawsuit.tribunal.name === "INSS") ? "col-md-12 form-control" : "hide"} value={this.state.n_processo} onChange={this.handleChange} />
                                  <span className= {(this.state.lawsuit.tribunal !== null && typeof this.state.lawsuit.tribunal !== "undefined" && this.state.lawsuit.tribunal.name === "INSS")? "hide" : ""}>{this.state.n_processo}</span>
                            </div>
                            <div className={ (this.state.lawsuit.tribunal !== null && typeof this.state.lawsuit.tribunal !== "undefined" && this.state.lawsuit.tribunal.name === "INSS") ? "form-group col-md-4 text-left" : "hide"}>
                                  <label htmlFor="" className="">N° do Beneficio </label>
                                  <input name="input1" id="n_beneficio" className="col-md-12 form-control"  value={this.state.input1} onChange={this.handleChange} />
                            </div>
                            {lawsuit_input_name}                            
                            <div className="form-group col-md-12 text-left">
                                <label htmlFor="" className="">Assunto</label>
                                <textarea name="assunto" id="assunto" className="col-md-12" maxLength="248"  rows="3" value={this.state.assunto} onChange={this.handleChange} />
                            </div>
                            <div className="row">
                              <p id="erro-dados-processo" className="col-md-12 error"></p>
                            </div>
                            <div className="button-group">
                              <button type="button" className="btn btn-primary" id="bt-update-edit-dados-processo" onClick={this.save} >Alterar</button>
                              <button type="button" className="btn btn-danger horizontal-space" onClick={this.closeEdit} id="bt-cancel-edit-dados-processo" data-toggle="collapse"  data-target="#panel-edit-dados-topo-processo">Cancelar</button>
                            </div>
                    </div>
                    <span className="pointer pull-right" id="bt-edit-dados-topo-processo"onClick={this.openEdit} data-toggle="collapse"  data-target="#panel-edit-dados-topo-processo">
                        <i className="fa fa-pencil-square-o"></i>
                        <span className="edit-dados-processo-label">Editar</span>
                    </span>
                    <div id="view-dados-topo">
                          <div className="form-group col-md-4 text-left">
                                <label htmlFor="dataDistribuicao" className="">Data Distribuição:</label>
                                <span>{ (this.state.dataDistribuicao !== "" && typeof this.state.dataDistribuicao !== "undefined" && this.state.dataDistribuicao !== null ) ? moment(this.state.dataDistribuicao, 'DD/MM/YYYY').format('DD/MM/YYYY') : "" }</span>
                          </div>
                          <div className="form-group col-md-4 text-left">
                                <label htmlFor="dataCadastro" className="">Data Cadastro:</label>
                                <span >{ moment(this.state.dataCadastro, 'DD/MM/YYYY').format('DD/MM/YYYY') }</span>
                          </div>
                          <div className="form-group col-md-4 text-left">
                                <label htmlFor="valorCausa" className="">Valor da Causa:</label>
                                <span >R$ { valorCausa }</span>
                          </div>
                          {vara}
                          { (typeof this.state.lawsuit.tribunal !== "undefined" && this.state.lawsuit.tribunal !== null && this.state.lawsuit.tribunal.name === "INSS" ) ? inss_processo :  tribunal_processo}
                          {lawsuit_name}
                          {inssSection}
                          <div className="form-group col-md-12 text-left">
                              <label htmlFor="" className="">Assunto:</label>
                              <span className="data-lawsuit-assunto">{this.state.assunto}</span>
                          </div>
                    </div>
                </div>

                <div className="modal fade" id="tribunalModal" tabIndex="-1" role="dialog" aria-labelledby="tribunalModalLabel" aria-hidden="true">
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="tribunalModalLabel">Monitorar Automaticamente este processo</h5>
                      </div>
                      <div className="modal-body">
                        <form>
                                <div className="form-group">
                                    <label htmlFor="tribunal">Tribunal<span className="text-red">*</span></label>
                                    <Select
                                        className="tribunal"
                                        options={this.state.tribunals}
                                        placeholder="(escolha um)"
                                        name="tribunal"
                                        onChange={this.selectChange}
                                      />
                                    <div className="errorMsg">{this.state.errors.instance}</div>
                                </div>
                              <br />
                              <div className="form-group">
                                  <label htmlFor="instance">Instância<span className="text-red">*</span></label>
                                  <Select
                                      className="instance"
                                      defaultValue={instancia[0]}
                                      options={instancia}
                                      placeholder="(escolha um)"
                                      name="instance"
                                      onChange={this.selectChange}
                                    />
                                  <div className="errorMsg">{this.state.errors.instance}</div>
                              </div>
                            <br />
                            <div className="form-group">
                                <label htmlFor="lawsuit-number">Digite o N° do Processo<span className="text-red">*</span></label>
                                <input className="form-control input-lg" name="lawsuit_number" onChange={this.handleChange} required />
                                <div className="errorMsg">{this.state.errors.lawsuit_number}</div>
                            </div>
                            <div className="form-group">
                                <span
                                    className="btn btn-primary form-submit"
                                    id="addButton"
                                    data-dismiss="modal"
                                    onClick={this.handleFormSubmit.bind(this)}
                                >
                                  Cadastrar
                                </span>
                            </div>
                        </form>
                      </div>
                      <div className="modal-footer">

                        <button type="button" className="btn btn-danger" data-dismiss="modal">Sair</button>
                      </div>
                    </div>
                  </div>
                </div>

          </div>

  }
}
