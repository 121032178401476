import React from 'react';
import HeaderBar from './header-bar/header-bar';
import NavigationMenu from './sidebar/navigation-menu';
import Calendar from "react-big-calendar";
import Footer from "./containers/footer";
import PopUp from './containers/popup';
import Tasks from './events/Tasks';
import CalendarCharts from './containers/calendarCharts';
import 'antd/dist/antd.css';
import { Tabs } from 'antd';
import FormEvent from './events/FormEvent';

import EventService from '../services/EventService';
import * as dates from '../utils/dates';

import moment from "moment";
//import moment_timezone from "moment-timezone";
//import ptBR from 'antd/lib/locale-provider/pt_BR';
//import 'moment/locale/pt-br';
import 'moment/locale/en-gb';
import "react-big-calendar/lib/css/react-big-calendar.css";

moment.locale('en-GB');
//moment_timezone.tz.setDefault("ETC/utc");
const localizer = Calendar.momentLocalizer(moment);
const TabPane = Tabs.TabPane;

export default class Event extends React.Component { 
  _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }


  constructor(...args) {
    super(...args)
    this.onNavigate = this.onNavigate.bind(this);
    this.onView = this.onView.bind(this);
    this.editEvent = this.editEvent.bind(this);

    this.modifiedEvents = this.modifiedEvents.bind(this);

    this.eventStyleGetter = this.eventStyleGetter.bind(this);

    this.state = {
      events: [],
      start: moment().format('YYYY/MM/DD'),
      end: "",
      token: "",
      tasks: [],
      current_date: moment(new Date()),
      showAlert: !localStorage.getItem('popup_agenda'),
      message: "Os status estão com novas cores:",
      message2: "Os status dos compromissos serão visualizados por uma bolinha ao lado do horário na agenda. \n As cores de fundo dos compromissos sinalizam a prioridade escolhida. \n Amarelo para prioridade baixa. Laranja para média e Vermelha para alta prioridade: ",
      message3: "Assim fica mais fácil a visualização dos prazos, audiências, etc..."
    }
    window.scrollTo(0,0);
  }

  componentDidMount( ) { 
    this._isMounted = true;

    document.addEventListener("cancelLoading", (e) => {
      this.setState({
        loadingVisible: false
      });
    });

    this.EventService = new EventService();

    const loggedUser = JSON.parse(localStorage.getItem('loggedUser'));

    let start_date = moment().startOf('month').format('YYYY-MM-DD');
    let end_date = moment().endOf('month').add(1, 'days').format('YYYY-MM-DD');

    this.setState({
      start: start_date,
      end: end_date,
      view: 'month',
      selectedUser: loggedUser.token
    });

    document.addEventListener("changeUserChart", (e) => {
      //console.log(e.detail);
      this.setState({
        selectedUser: e.detail.user_token
      });
      this.renderEvents( this.state.start, this.state.end, e.detail.user_token );
    });

    this.renderEvents( start_date, end_date, loggedUser.token  );

  }

  componentDidUpdate(){

  }


    updateTimes( date, view ){
          let start, end;
          // if view is day: from moment(date).startOf('day') to moment(date).endOf('day');
          if(view === 'day'){
            start = moment(date).startOf('day').format('YYYY-MM-DD');
            end   = moment(date).endOf('day').add(1, 'days').format('YYYY-MM-DD');
          }
          // if view is week: from moment(date).startOf('isoWeek') to moment(date).endOf('isoWeek');
          else if(view === 'week'){
            start = moment(date).startOf('isoWeek').format('YYYY-MM-DD'); //console.log(start);
            end   = moment(date).endOf('isoWeek').add(1, 'days').format('YYYY-MM-DD'); //console.log(end);
          }
          //if view is month: from moment(date).startOf('month').subtract(7, 'days') to moment(date).endOf('month').add(7, 'days'); i do additional 7 days math because you can see adjacent weeks on month view (that is the way how i generate my recurrent events for the Big Calendar, but if you need only start-end of month - just remove that math);
          else if(view === 'month'){
            start = moment(date).startOf('month').format('YYYY-MM-DD');
            end   = moment(date).endOf('month').add(1, 'days').format('YYYY-MM-DD');
          }
          // if view is agenda: from moment(date).startOf('day') to moment(date).endOf('day').add(1, 'month');
          else if(view === 'agenda'){ 
            start = moment(date).startOf('month').format('YYYY-MM-DD');
            end   = moment(date).endOf('month').add(1, 'days').format('YYYY-MM-DD');
            //console.log(date);
          }

          this.setState({
            start: start,
            end: end,
            view: view
          });

          this.renderEvents( start, end, this.state.selectedUser );

    }

  onNavigate(date, view){
      /*console.log('#### onNavigate');
      console.log('#### date=', date);
      console.log('#### view=', view);*/
      const new_date = moment(date);
      this.setState({
        current_date: new_date
      });

      this.updateTimes(new_date, view);
  }

  onView(view){
    this.updateTimes(this.state.current_date, view);
  }

  editEvent( event ){
      this.setState({
        token: event.token,
        showModal: true,
      });
  }

  showModal = ({ start, end } ) => {

    this.setState({
      start: start,
      end: end,
      showModal: true
    });

  }

  renderEvents( start, end, token ){
        //console.log(start); console.log(end);
        this.EventService.list( start, end, token ).then(res =>{

          if ( this._isMounted && Array.isArray(res) ) {
              //console.log(res);

              this.setState({
                events: res
              });
          }

        });

        let start_task = moment().startOf('day').subtract(365, 'days').format('YYYY-MM-DD');
        let end_task = moment().endOf('day').add(365, 'days').format('YYYY-MM-DD');

        this.EventService.listTasks( start_task, end_task, token ).then(res =>{

              if (this._isMounted) {
                    //console.log(res);

                    let tasks = [];

                    res.map( (task)=>{
                      if( (typeof task.events_situation !== "undefined" && task.events_situation !== null && task.events_situation.id === 2) && moment(task.start) < moment().subtract(5, 'days') ){

                      }else{
                        tasks.push(task);
                      }
                      return task;
                    });

                    tasks.sort(function(a, b){
                      //console.log(a);
                      if ( a.events_situation.id < b.events_situation.id ){
                        return -1;
                      }else{
                        return 1;
                      }

                    });

                    //console.log(tasks);

                    this.setState({
                      tasks: tasks
                    });

              }

        });
  }

  modifiedEvents(){
    //console.log("eventos modificados");
    this.updateTimes( this.state.current_date, this.state.view );
    this.setState({
      token: "",
      showModal: false,
    });
  }

  eventStyleGetter(event, start, end, isSelected) {
    var backgroundColor = event.hexColorPriority;
    console.log(backgroundColor);
    var style = {
        backgroundColor: backgroundColor,
        color: 'white',
        fontSize: '11px',
    };
    if(event.hexColorPriority ==  "yellow"){
      style.color = "black";
    }
    return {
        style: style
    };
  }

  compromisso ({ event }) {
    //console.log(event.start.toString());
    //+" — "+event.end.toString().substr(16,5)
    // tempo e titulo classes fc-time e fc-title
    let style = {
      backgroundColor: event.hexColor,
      /*border: '1px solid #dadada'*/
    }

    let stylePriority = {
      backgroundColor: event.hexColorPriority
    }

    return (
      <span style={stylePriority}>
        <p className="rbc-event-label fc-time">
          {event.start.toString().substr(16,5)+" "}
        <span style={style} className="round-flag"></span>
        </p>
        <p className="fc-title">{event.title}</p>
      </span>
    )
  }

  render() {

    //console.log(this.state.start);

      return <div className="sheet agenda">
                <HeaderBar />
                <NavigationMenu />
                <PopUp 
                  show={this.state.showAlert} 
                  text={this.state.message} 
                  text2={this.state.message2}
                  text3={this.state.message3} 
                  img_container_1={"/images/agenda1.png"} 
                  img_container_2={"/images/agenda2.jpg"} 
                  popup_name="popup_agenda"
                />
                <div className="content-wrapper">
                  <div className="content-wrapper-inner">
                      <section className="content-header">
                        <h1>Agenda</h1>
                        <p>Visualização de Compromissos e Tarefas</p>
                        <FormEvent modifiedEvents={this.modifiedEvents} token={this.state.token} showModal={this.state.showModal} start={this.state.start} end={this.state.end} events={this.state.events} />
                        <CalendarCharts history={this.props.history} />
                        <Tabs defaultActiveKey="1" size="large">
                              <TabPane tab={"Compromissos"}  key="1">
                                  <Calendar
                                     selectable
                                     localizer={localizer}
                                     date={moment(this.state.start).toDate()}
                                     defaultView="month"
                                     startAccessor="start"
                                     endAccessor="end"
                                     max={dates.add(dates.endOf(new Date(), 'day'), -1, 'hours')}
                                     onNavigate={this.onNavigate}
                                     onView={this.onView}
                                     onSelectEvent={event => this.editEvent( event )}
                                     onSelectSlot={this.showModal}
                                     views={{ month: true, week: true, day: true, agenda: true }}
                                     events={this.state.events}
                                     eventPropGetter={(this.eventStyleGetter)}
                                     style={{ minHeight: "980px", margin: "0px" }}
                                     messages={{
                                        month: 'Mês',
                                        agenda: 'Listagem Mensal',
                                        day: 'Dia',
                                        today: 'Hoje',
                                        previous: 'Anterior',
                                        next: 'Próximo',
                                        week: 'Semana',
                                        date: 'Data',
                                        time: 'Hora',
                                        event: 'Evento',
                                        showMore: total => `+ ${total} compromissos`,
                                        noEventsInRange: 'Nenhum compromisso encontrado neste intervalo de data.',
                                      }}
                                      components={{
                                        month: {
                                          event: this.compromisso,
                                        },
                                      }}
                                   />
                               </TabPane>
                               <TabPane tab={"Tarefas"}  key="2">
                                    <Tasks tasks={this.state.tasks} modifiedEvents={this.modifiedEvents}  />
                               </TabPane>
                           </Tabs>
                      </section>
                  </div>
                </div>
                <Footer />
             </div>
  }

}
