import React from 'react';
import Confirm from '../../containers/confirm';
import LawsuitService from '../../../services/LawsuitService';

import { Link } from 'react-router-dom';

export default class RelatedLawsuitsLawsuit extends React.Component { _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }


  constructor( props ) {
      super( props );
      this.roll = this.roll.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.myRef = React.createRef();

      this.lawsuitService = new LawsuitService();

      this.state = {
        token: props.lawsuit,
        relatedList: [],
        showConfirm: false,
        message: "",
        related_token_toDelete: false, 
        lawsuit_token_toDelete: false,
      }
  }

  roll = (event) => {

    if ( !event.target.classList.contains('collapsed') ) {
      setTimeout(() => {
        window.scrollTo(0, this.myRef.current.offsetTop+51)
      }, 200);
    }

  }

  componentWillReceiveProps(nextProps) {
    if ( nextProps.lawsuit !== this.props.lawsuit || nextProps.time !== this.props.time ) {

      this.lawsuitService.relatedList( nextProps.lawsuit ).then(res =>{
        //console.log("passou nesta porra");
          let relateds = []; let relateds_token = [];
          document.body.style.cursor = "default";

          res.map( ( lawsuit ) => {
            if( !relateds_token.includes(lawsuit.token) && nextProps.lawsuit !== lawsuit.token ){
              relateds.push(lawsuit);
              relateds_token.push(lawsuit.token);
              return relateds;
            }
            return relateds;
          });

          this.setState({
            relatedList: relateds,
            token: nextProps.lawsuit
          });

      });

    }
  }

  handleChange(e){

       this.setState(
           {
               [e.target.name]: e.target.value
           }
       )
   }

  componentDidMount( ) { 
    this._isMounted = true;

    document.addEventListener("cancelDialogConfirm", (e) => {
      if( this._isMounted ){
        this.setState({
          showConfirm: false,          
        });
      }
    });

    document.addEventListener("confirmDialogConfirm", (e) => {
      if( this._isMounted ){
        this.setState({
          showConfirm: false,
        });

        if( this.state.related_token_toDelete !== false ){
            this.deleteLawsuit(this.state.related_token_toDelete, this.state.lawsuit_token_toDelete, true);
        }
      }
    });

    document.body.style.cursor = "wait";
    this.lawsuitService = new LawsuitService();

    this.lawsuitService.relatedList( this.state.token ).then(res =>{
        let relateds = []; let relateds_token = [];
        document.body.style.cursor = "default";

        res.map( ( lawsuit ) => {
          if( !relateds_token.includes(lawsuit.token) && this.state.token !== lawsuit.token ){
            relateds.push(lawsuit);
            relateds_token.push(lawsuit.token);
            return relateds;
          }
          return relateds;
        });

        this.setState({
          relatedList: relateds,
        });

    });

  }

  deleteLawsuit( related_token, lawsuit_token, r = false ){

        //var r = window.confirm("Tem certeza que deseja desvincular este processo?");

        if ( r ){

              document.body.style.cursor = "wait";
              this.lawsuitService = new LawsuitService();

              if ( document.getElementById("relatedList") !== null ){
                let relatedList = JSON.parse(document.getElementById("relatedList").value);
                console.log(relatedList);
                for(var i=0; i < relatedList.length; i++){

                  if ( related_token !== relatedList[i].token ){
                    this.lawsuitService.deleteRelationship( related_token, relatedList[i].token )
                        .then(res =>{

                        });
                  }

                }

              }

              this.lawsuitService.deleteRelationship( related_token,  lawsuit_token).then(res =>{

                  document.body.style.cursor = "default";

                  this.lawsuitService.relatedList( lawsuit_token ).then(res =>{
                      let relateds = []; let relateds_token = [];
                      document.body.style.cursor = "default";

                      res.map( ( lawsuit ) => {
                        if( !relateds_token.includes(lawsuit.token) && this.state.token !== lawsuit.token ){
                          relateds.push(lawsuit);
                          relateds_token.push(lawsuit.token);
                          return relateds;
                        }
                        return relateds;
                      });

                      this.setState({
                        relatedList: relateds,
                      });

                  });

              });

        }else{
          this.setState({
            showConfirm: true,
            related_token_toDelete: related_token, 
            lawsuit_token_toDelete: lawsuit_token,
            message: "Tem certeza que deseja desvincular este processo?"
          });
        }
   }

  lawsuitItem = ( {record, index}, type ) => {

    //console.log(record);

      return (
        <tr key={index} className={type}>
              <td className="table_lawsuit_number">
                    <Link to={'/processo/detalhe_processo/'+record.token} >
                        {( record.number === null )? record.lawsuit_name : record.number}
                    </Link>
              </td>
              <td className="table_lawsuit_cliente hidden-xs hidden-sm" >
                        {( typeof record.tribunal_name !== "undefined" ) ? record.tribunal_name : "Processo Manual"}
              </td>
              <td className="table_lawsuit_actions">
                      <span className="btn"><i className="fa fa-minus" data-token={record.token} onClick={() => this.deleteLawsuit(record.token, this.state.token)}></i></span>
              </td>
        </tr>


      );
  };

  render() {
      if( this.state.relatedList.length > 0 ){
        //console.log(this.state.relatedList);

      return (
          <div className="panel panel-default container-related-processo">
                <Confirm show={this.state.showConfirm} text={this.state.message} />
                <div className="panel-heading text-center">
                    <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" ref={this.myRef} onClick={(event) => this.roll(event)} data-target="#related-lawsuit-target">
                        <strong>Processos Vinculados</strong>
                    </div>
                </div>
                <input type="hidden" id="relatedList" value={JSON.stringify(this.state.relatedList)} />
                <div className="panel-body collapse" id="related-lawsuit-target">
                <table>
                        <thead>
                            <tr>
                                  <th className="table_lawsuit_number">
                                      <div>Número do Processo</div>
                                  </th>
                                  <th className="table_tribunal hidden-xs hidden-sm">
                                      <div>Tribunal</div>
                                  </th>
                                  <th className="table_lawsuit_actions">
                                      <div>Desvincular</div>
                                  </th>
                            </tr>
                        </thead>
                        <tbody>
                              { ( typeof this.state.relatedList !== "undefined") ? this.state.relatedList.map( (record, index) => this.lawsuitItem( {record, index}, 'lawsuit-item' ) ) : null }
                        </tbody>
                </table>
                </div>
          </div>

        )
      }else{
        return (
              null
        )
      }
  }
}
