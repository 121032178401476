import AuthService from './AuthService';
import {api} from '../config';


export default class eDocsService {
    // Initializing important variables
    constructor(domain) {
        this.domain = domain || api.url // API server domain
        this.fetch = this.fetch.bind(this) // React binding stuff
        this.areaList = this.areaList.bind(this)
    }

    areaList ( ) {
      return this.fetch(`${this.domain}EdocsAreas`, {
          method: 'GET',
      }).then(res => {
          let response = [];
          //console.log(res);
          if( res.success === true ){
            var i = 0;
            res.data.EdocsAreas.map((value, index, array )=>{
              response[index] = { "key": array.length-i, "value": value.token, "label": value.name };
              i++;
              return response;
            });
          }

          //console.log(response);
          return Promise.resolve(response);
      })
    }

    addArea ( area ){
      return this.fetch(`${this.domain}EdocsAreas/add`, {
          method: 'POST',
          body: JSON.stringify(area)
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      })
    }

    deleteArea ( id ){
      return this.fetch(`${this.domain}EdocsAreas/delete/${id}`, {
          method: 'DELETE'
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      })
    }

    tipoList ( area_token ) {
      return this.fetch(`${this.domain}EdocsTypes/index/${area_token}`, {
          method: 'GET',
      }).then(res => {
          let response = [];
          //console.log(res);
          if( res.success === true ){
            var i = 0;
            res.data.EdocsTypes.map((value, index, array )=>{
              response[index] = { "key": array.length-i, "value": value.token, "label": value.name };
              i++;
              return response;
            });
          }

          //console.log(response);
          return Promise.resolve(response);
      })
    }

    addTipo ( tipo ){
      return this.fetch(`${this.domain}EdocsTypes/add`, {
          method: 'POST',
          body: JSON.stringify(tipo)
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      })
    }

    deleteTipo ( id ){
      return this.fetch(`${this.domain}EdocsTypes/delete/${id}`, {
          method: 'DELETE'
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      })
    }

    templateList ( type_token ) {
      return this.fetch(`${this.domain}EdocsModels/index/${type_token}`, {
          method: 'GET',
      }).then(res => {
          let response = [];
          //console.log(res);
          if( res.success === true ){
            var i = 0;
            res.data.EdocsModels.map((value, index, array )=>{
              response[index] = { "key": array.length-i, "value": value.token, "label": value.name, "content": value.content };
              i++;
              return response;
            });
          }

          //console.log(response);
          return Promise.resolve(response);
      })
    }

    addTemplate ( template ){
      return this.fetch(`${this.domain}EdocsModels/add`, {
          method: 'POST',
          body: JSON.stringify(template)
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      })
    }

    editTemplate ( template, token ){
      return this.fetch(`${this.domain}EdocsModels/edit/${token}`, {
          method: 'PUT',
          body: JSON.stringify(template)
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      })
    }

    deleteTemplate ( id ){
      return this.fetch(`${this.domain}EdocsModels/delete/${id}`, {
          method: 'DELETE'
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      })
    }

    viewTemplate ( id ){
      return this.fetch(`${this.domain}EdocsModels/view/${id}`, {
          method: 'GET'
      }).then(res => {
          //console.log(res);
          if( res.success === true ){
            return Promise.resolve(res.data.EdocsModels);
          }else {
            return Promise.resolve(res.message);
          }
      })
    }

    viewDocument ( id ){
      return this.fetch(`${this.domain}EdocsDocuments/view/${id}`, {
          method: 'GET'
      }).then(res => {
          //console.log(res);
          if( res.success === true ){
            return Promise.resolve(res.data.EdocsDocuments);
          }else {
            return Promise.resolve(res.message);
          }
      })
    }

    addDocument ( document ){
      return this.fetch(`${this.domain}EdocsDocuments/add`, {
          method: 'POST',
          body: JSON.stringify(document)
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      })
    }

    fetch(url, options) {
          let auth = new AuthService();

          options.headers = {
             'Authorization': 'Bearer ' + auth.getToken(),
             'Content-Type': 'application/json',
             'Accept': 'application/json'
           }

          return fetch(url, options)
                  .then(this._checkStatus)
                  .then(response => {

                          if(typeof response !== "undefined"){

                            return Promise.resolve( response.text().then(function(result){
                                //console.log(result);
                                let old_result = result;
                                result = result.split('{"success"');
                                if( result.length > 1 ){
                                    result = result[1].split('<pre');
                                    //console.log(result[0]);
                                    let json = '{"success"'+result[0];
                                    result = JSON.parse(json);
                                    //console.log(result);
                                    return result;
                                }else{
                                    return old_result;
                                }

                            }) );

                        }else{

                            let result = {
                                success: false,
                                message: "Erro desconhecido"
                            }

                            return Promise.resolve( result );

                        }

                  })
    }

    _checkStatus(response) {
      // raises an error in case response status is not a success
      if (response.status >= 200 && response.status < 300) { // Success status lies between 200 to 300
          return response
      } else if( response.status === 401 || response.status === "401" ) {
        console.log("Tentando trapacear? Você será desligado");


        localStorage.removeItem('id_token');
        localStorage.removeItem('loggedUser');
        window.location.href = '/login';

      } else if( response.status === 402 || response.status === "402" ) {
        
        window.location.href = '/config/mudar-plano?plano_vencido';

      }else {
          var error = new Error(response.statusText)
          error.response = response
          throw error
      }
  }

    /*fetch(url, options) {
        // performs api calls sending the required authentication headers
        let auth = new AuthService();

        options.headers = {
           'Authorization': 'Bearer ' + auth.getToken(),
           'Content-Type': 'application/json',
           'Accept': 'application/json'
         }

        return fetch(url, options)
            .then(this._checkStatus)
            .then(response => response.json())
    }*/
}
