import AuthService from './AuthService';
import {api} from '../config';

export default class LawsuitService {
    // Initializing important variables
    constructor(domain) {
        this.domain = domain || api.url // API server domain
        this.fetch = this.fetch.bind(this) // React binding stuff
        this.list = this.list.bind(this)
    }

    add ( lawsuit ){
      return this.fetch(`${this.domain}Lawsuits/add`, {
          method: 'POST',
          body: JSON.stringify(lawsuit)
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      })
    }

    edit ( lawsuit, token ){
      return this.fetch(`${this.domain}Lawsuits/edit/${token}`, {
          method: 'PUT',
          body: JSON.stringify(lawsuit)
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      })
    }

    addMovement( lawsuit ){
      return this.fetch(`${this.domain}Movements/addMovement`, {
          method: 'POST',
          body: JSON.stringify(lawsuit)
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      });
    }

    addToHighlight ( token ){
      return this.fetch(`${this.domain}lawsuits/favoritar/${token}`, {
          method: 'GET',
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      })
    }

    addToArchive ( token ){
      return this.fetch(`${this.domain}lawsuits/arquivar/${token}`, {
          method: 'GET',
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      })
    }

    listInformation ( lawsuit_token ) {
      return this.fetch(`${this.domain}LawsuitInformations/index/${lawsuit_token}`, {
          method: 'GET',
      }).then(res => {
          let response = [];
          //console.log("list information");
          if( res.success === true ){
            var i = 0;
            res.data.LawsuitInformations.map((value, index, array )=>{
              response[index] = { "key": array.length-i, "value": value.token, "information": value.information, "created": value.created, "user_name": value.user_name, "owner": value.owner };
              i++;
              return response;
            });
          }

          //console.log(response);
          return Promise.resolve(response);
      })
    }

    addInformation ( lawsuit ){
      return this.fetch(`${this.domain}LawsuitInformations/add`, {
          method: 'POST',
          body: JSON.stringify(lawsuit)
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      })
    }

    editInformation ( lawsuit, token ){
      return this.fetch(`${this.domain}LawsuitInformations/edit/${token}`, {
          method: 'POST',
          body: JSON.stringify(lawsuit)
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      })
    }

    deleteInformation ( token ){
      return this.fetch(`${this.domain}LawsuitInformations/delete/${token}`, {
          method: 'DELETE'
      }).then(res => {
          return Promise.resolve(res);
      })
    }

    get ( id ){

        return this.fetch(`${this.domain}lawsuits/${id}`, {
            method: 'GET',
        }).then(res => {
            //console.log(res);
            let message = "";

            if( res.success === true ){
              return Promise.resolve(res.data.Lawsuits);
            }else {
              if( typeof res == "string" ){
                message = res;
                prompt("Ocorreu um erro, aperte Ctrl+C para copiar e informe ao suporte técnico: \n",res);
              }else{
                message = res.message;
                prompt("Ocorreu um erro, aperte Ctrl+C para copiar e informe ao suporte técnico: \n",res.message);
              }
              console.log(res);
              let lawsuit = {
                tribunal: {},
                tribunal_id: 0,
                valorCausa: "",
                status: "ERRO",
                lawsuits_personages: [{token:1, personage_type_name:"", personage: "", modified: "1970-01-01 10:00"},{token: 2, personage_type_name:"", personage:"",modified: "1970-01-01 09:00"}],
                movements: [{id: 1, name: "", date: ""}],
                token: id,
                formIsHalfFilledOut: 1,
                message: message
              }
              return Promise.resolve(lawsuit);
            }

        })


    }

    list( type, highlight = null, archived = null, page = 1 ) {
      if ( typeof type === 'undefined' || type === 0 || type === null ){
            return this.fetch(`${this.domain}Lawsuits/index/?list_manual=1&page=${page}`, {
                method: 'GET',
            }).then(res => {
                //console.log(res);

                if( res.success === true ){
                  res.data.Lawsuits.map((value, index, array )=>{
                    res.data.Lawsuits[index].key = value.token;
                    return res.data.Lawsuits[index];
                  });
                  return Promise.resolve(res);
                }else {
                  return Promise.resolve(res.message);
                }

            });
      }else{
          let comps = "";
          if(highlight !== null){
            comps = "&favorito=1";
          }else{
            comps = "&favorito=0";
          }
          if(archived !== null){
            comps += "&arquivo=1";
          }else{
            comps += "&arquivo=0";
          }
          return this.fetch(`${this.domain}Lawsuits/?tribunal_id=${type}${comps}&page=${page}`, {
              method: 'GET',
          }).then(res => {

            //console.log(res);

              if( res.success === true ){
                res.data.Lawsuits.map((value, index, array )=>{
                  res.data.Lawsuits[index].key = value.token;
                  return res.data.Lawsuits[index];
                });
                return Promise.resolve(res);
              }else {
                return Promise.resolve(res.message);
              }

          });
      }

    }

    find( who, what, tribunals ) {

            return this.fetch(`${this.domain}lawsuits/?tipo_busca=${what}&q=${who}${tribunals}`, {
                method: 'GET',
            }).then(res => {
                //console.log(res);

                if( res.success === true ){
                  res.data.Lawsuits.map((value, index, array )=>{
                    res.data.Lawsuits[index].key = value.token;
                    return res;
                  });
                  return Promise.resolve(res);
                }else {
                  return Promise.resolve(res.message);
                }

            }).catch(err =>{
              return Promise.resolve("false");
            });
    }

    officeList() {
        return this.fetch(`${this.domain}Lawsuits/index/?list=true`, {
            method: 'GET',
        }).then(res => {
            var i = 0;

            if( res.success === true ){
              var response = [];
              res.data.Lawsuits.map((value, index, array )=>{
                response[index] = { "key": array.length-i, "value": value.token, "label": ( value.number !== null )? value.number: value.name };
                i++;
                return response;
              });
              return Promise.resolve(response);
            }else {
              console.log(res.message);
              return Promise.resolve([]);
            }

        });
    }

    relatedList( token ){
        //console.log(`${this.domain}LawsuitsRelateds/index/${token}`);
        return this.fetch(`${this.domain}LawsuitsRelateds/index/${token}`, {
            method: 'GET',
        }).then(res => {
            //console.log(res);

            if( res.success === true ){
              var response = [];
              //let i = 0;
              res.LawsuitsRelateds.map((value, index, array )=>{ //console.log(value);
                if ( value.lawsuits1 !== null ){
                  response.push({ "key": index, "token": value.lawsuits1.token, "number": value.lawsuits1.number,
                                      "tribunal_name": value.lawsuits1.tribunal_name, "tribunal_id": value.lawsuits1.tribunal_id,
                                      "lawsuit_name": value.lawsuits1.name
                               });
                  //i++;
                }
                  if( value.lawsuits2 !== null ){
                    response.push({ "key": index, "token": value.lawsuits2.token, "number": value.lawsuits2.number,
                                        "tribunal_name": value.lawsuits2.tribunal_name, "tribunal_id": value.lawsuits2.tribunal_id,
                                        "lawsuit_name": value.lawsuits2.name
                                 });

                  }
                  //i++;
                //}
                return response;
              });
              return Promise.resolve(response);
            }else {
              return Promise.resolve([]);
            }

        });
    }

    toRelate( data ) {
      return this.fetch(`${this.domain}LawsuitsRelateds/add`, {
          method: 'POST',
          body: JSON.stringify(data)
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      })
    }

    deleteRelationship( token, token2 ) {

      return this.fetch(`${this.domain}LawsuitsRelateds/delete/${token}/${token2}`, {
          method: 'DELETE',
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      })

    }

    delete( token ) {

      return this.fetch(`${this.domain}lawsuits/${token}`, {
          method: 'DELETE',
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      })

    }

    counterByTribunal( tribunal_id ){
      return this.fetch(`${this.domain}Lawsuits/counterByTribunal/${tribunal_id}`, {
          method: 'GET',
      }).then(res => {
          if( res.success === true ){
            return Promise.resolve(res.data.count);
          }else{
            return Promise.resolve(0);
          }
      });
      //return Promise.resolve(7);
    }

    atualizacaoAutomatica( token ){
      return this.fetch(`${this.domain}lawsuits/atualizaoAutomatica/${token}`, {
          method: 'GET',
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      })
    }

    report( lawsuit, client, destaque, sponsor, arquivo, tipo_parte, pasta, area, tipo, comarca, fase, filial, statusLawsuitTribunal, perda, date_movimento_start, date_movimento_end, movement_start, tribunals, created_start, created_end, dataDistribuicao_start, dataDistribuicao_end, page=1 ){

      if( lawsuit === null ){
        lawsuit = "";
      }else{
        lawsuit = "&lawsuit_token="+lawsuit.value;
      }

      if( client === null ){
        client = "";
      }else{
       // if(client.value == client.label){
          client = "&cliente_name="+client.label;
        //}else{
          //client = "&cliente_token="+client.value;
        //}
      }

      if( destaque === null ){
        destaque = "";
      }else{
        destaque = "&favorito="+destaque.value;
      }

      if( sponsor === null ){
        sponsor = "";
      }else{
        sponsor = "&user_token="+sponsor.value;
      }

      if( arquivo === null ){ //está lá no front como state.status
        arquivo = "";
      }else{
        arquivo = "&arquivo="+arquivo.value;
      }

      if( tipo_parte === null ){
        tipo_parte = "";
      }else{
        tipo_parte = "&personage_type_id="+tipo_parte.value;
      }

      if( pasta === "" ){
        pasta = "";
      }else{
        pasta = "&pasta="+pasta;
      }

      if( area === null ){
        area = "";
      }else{
        area = "&area="+area.label;
      }

      if( tipo === null ){
        tipo = "";
      }else{
        tipo = "&tipo="+tipo.label;
      }

      if( comarca === null ){
        comarca = "";
      }else{
        comarca = "&comarca="+comarca.label;
      }

      if( fase === null ){
        fase = "";
      }else{
        fase = "&faseProcessual="+fase.label;
      }

      if( filial === null ){
        filial = "";
      }else{
        filial = "&filial="+filial.label;
      }

      if( statusLawsuitTribunal === null){
        statusLawsuitTribunal = "";
      }else{
        statusLawsuitTribunal = "&status="+statusLawsuitTribunal.label;
      }

      if( perda === null ){
        perda = "";
      }else{
        perda = "&perda="+perda.label;
      }

      if( date_movimento_start === null ){
        date_movimento_start = "";
      }else{
        date_movimento_start = "&movement_last_start="+date_movimento_start.substr(0, 10).split('/').reverse().join('-');
      }

      if( date_movimento_end === null ){
        date_movimento_end = "";
      }else{
        date_movimento_end = "&movement_last_end="+date_movimento_end.substr(0, 10).split('/').reverse().join('-');
      }

      if( movement_start === null ){
        movement_start = "";
      }else{
        movement_start= "&movement_start="+movement_start.substr(0, 10).split('/').reverse().join('-');
      }

      if( created_start === null ){
        created_start = "";
      }else{
        created_start = "&created_start="+created_start.substr(0, 10).split('/').reverse().join('-');
      }

      if( created_end === null ){
        created_end = "";
      }else{
        created_end = "&created_end="+created_end.substr(0, 10).split('/').reverse().join('-');
      }

      if( dataDistribuicao_start === null ){
        dataDistribuicao_start = "";
      }else{
        dataDistribuicao_start = "&dataDistribuicao_start="+dataDistribuicao_start.substr(0, 10).split('/').reverse().join('-');
      }

      if( dataDistribuicao_end === null ){
        dataDistribuicao_end = "";
      }else{
        dataDistribuicao_end = "&dataDistribuicao_end="+dataDistribuicao_end.substr(0, 10).split('/').reverse().join('-');
      }

      let additionalData = pasta+area+tipo+comarca+fase+filial+statusLawsuitTribunal+perda;

      let dates = date_movimento_start+date_movimento_end+movement_start+created_start+created_end+dataDistribuicao_start+dataDistribuicao_end;

      //console.log(`${this.domain}lawsuits/${tribunals}${lawsuit}${client}${destaque}${sponsor}${arquivo}${tipo_parte}${additionalData}${dates}`);

      return this.fetch( `${this.domain}lawsuits/${tribunals}${lawsuit}${client}${destaque}${sponsor}${arquivo}${tipo_parte}${additionalData}${dates}&page=${page}`, {
          method: 'GET',
      }).then(res => {
        if( res.success === true  ){
          return Promise.resolve(res);
        }else{
          return Promise.resolve(res);
        }
      });
    }

    fetch(url, options) {
          let auth = new AuthService();

          options.headers = {
             'Authorization': 'Bearer ' + auth.getToken(),
             'Content-Type': 'application/json',
             'Accept': 'application/json'
           }

          return fetch(url, options)
                  .then(this._checkStatus)
                  .then(response => {

                      if(typeof response !== "undefined"){

                            return Promise.resolve( response.text().then(function(result){
                                //console.log(result);
                                let old_result = result;
                                result = result.split('{"success"');
                                if( result.length > 1 ){
                                    result = result[1].split('<pre');
                                    //console.log(result[0]);
                                    let json = '{"success"'+result[0];
                                    result = JSON.parse(json);
                                    //console.log(result);
                                    return result;
                                }else{
                                    return old_result;
                                }

                            }) );

                      }else{

                            let result = {
                                success: false,
                                message: "Erro desconhecido"
                            }

                            return Promise.resolve( result );

                        }

                  })
    }

    _checkStatus(response) {
        // raises an error in case response status is not a success
        if (response.status >= 200 && response.status < 300) { // Success status lies between 200 to 300
            return response
        } else if( response.status === 401 || response.status === "401" ) {
          console.log("Tentando trapacear? Você será desligado");


          localStorage.removeItem('id_token');
          localStorage.removeItem('loggedUser');
          window.location.href = '/login';

        } else if( response.status === 402 || response.status === "402" ) {
        
          window.location.href = '/config/mudar-plano?plano_vencido';
  
        }else {
            console.log(response);
            return response;
            //alert(response.text);
            /*var error = new Error(response.statusText)
            error.response = response
            throw error*/
        }
    }

    /*fetch(url, options) {
        // performs api calls sending the required authentication headers
        let auth = new AuthService();

        options.headers = {
           'Authorization': 'Bearer ' + auth.getToken(),
           'Content-Type': 'application/json',
           'Accept': 'application/json'
         }

        return fetch(url, options)
            .then(this._checkStatus)
            .then(response => {
                let res = {success: false, message: "Servidor não retornou os dados"};
                try {
                  res = response.json()
                }catch(e) {
                    console.log(e);
                    return res;
                }
                return res;
            })
    }*/
}
