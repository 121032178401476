import React from 'react';

export default function Clear() {
    window.localStorage.removeItem('entries');
    window.localStorage.removeItem('incomings');
    window.localStorage.removeItem('outgoings');
    window.localStorage.removeItem('categoriesEntryLawsuit');
    window.localStorage.removeItem('categoriesIncoming');
    window.localStorage.removeItem('categoriesOutgoing');

    return (
        <h1>Dados do Financeiro removidos com sucesso</h1>
    );
}
