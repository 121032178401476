import AuthService from './AuthService';
import {api} from '../config';

export default class TribunalService {
    // Initializing important variables
    constructor(domain) {
        this.domain = domain || api.url // API server domain
        this.fetch = this.fetch.bind(this) // React binding stuff
        this.list = this.list.bind(this)
    }

    list() {
        // Get a token from api server using the fetch api
        return this.fetch(`${this.domain}Tribunals/`, {
            method: 'GET'
        }).then(res => {
            if( typeof res !== "undefined" && typeof res.data !== "undefined" ){

                localStorage.setItem('tribunals', JSON.stringify(res.data.tribunals));
                return Promise.resolve(res.data.tribunals);

            }else{

                return Promise.resolve( [] );

            }
        })
    }

    listAllowed() {
        // Get a token from api server using the fetch api

        return this.fetch(`${this.domain}Tribunals/`, {
            method: 'GET'
        }).then(res => {
            let tribunals = [];

            return Promise.resolve( this.getPermissions().then(permissions =>{

                permissions.map( (permission)=>{

                    res.data.tribunals.map( ( tribunal ) =>{
                       if( tribunal.id === permission.tribunal_id && permission.allowed === 1 ){
                         tribunal.allowed = permission.allowed;
                         tribunals.push(tribunal);
                       }
                       return tribunal;
                    });

                    return permission;

                });

                //console.log(tribunals);

                localStorage.setItem('tribunals', JSON.stringify(tribunals));
                return Promise.resolve(tribunals);
                //return Promise.resolve(res.data.tribunals);


            }) );
        })
    }

    getPermissions() {
      return this.fetch(`${this.domain}RuleTribunals/index`, {
          method: 'GET'
      }).then(res => {
          return Promise.resolve(res.data.RuleTribunals);
      })
    }

    setPermissions( permissions ) {
        return this.fetch(`${this.domain}RuleTribunals/add/`, {
            method: 'POST',
            body: JSON.stringify( permissions )
        }).then(res => {
            //console.log(res);
            return Promise.resolve(res);
        })
    }

    counterTribunalsLawsuits(){
        //Lawsuits/countAllLawsuitsInTribunals
        return this.fetch(`${this.domain}Lawsuits/countAllLawsuitsInTribunals`, {
            method: 'GET',
        }).then(res => {
            if( res.success === true ){
              return Promise.resolve(res.data);
            }else{
              return Promise.resolve([{tribunal_id: -1, count: -999}]);
            }
        });
    }

    counterTribunalsMovements( ){
      return this.fetch(`${this.domain}Movements/?movements_visualized_all_tribunals=true`, {
          method: 'GET',
      }).then(res => {
          if( res.success === true ){
            return Promise.resolve(res.data);
          }else{
            return Promise.resolve([{tribunal_id: -1, count_movements: -999}]);
          }
      });
      //return Promise.resolve({"success":true,"data":[{"tribunal_id":1,"count_movements":0},{"tribunal_id":2,"count_movements":163}]});
    }

    counterTribunalsMovementsFavorites( ){
      return this.fetch(`${this.domain}Movements/?movements_visualized_all_tribunals_favorites=true`, {
          method: 'GET',
      }).then(res => {
          if( res.success === true ){
            return Promise.resolve(res.data);
          }else{
            return Promise.resolve([{tribunal_id: -1, count_movements: -999}]);
          }
      });
      //return Promise.resolve(120);
    }

    deleteMovements( tribunal_id ){
        return this.fetch(`${this.domain}Movements/visulizedByTribunalId/${tribunal_id}`, {
            method: 'GET',
        }).then(res => {
              return Promise.resolve(res);
        });
    }

    addSegredo ( data ){
        return this.fetch(`${this.domain}UserTribunalLogins/add`, {
            method: 'POST',
            body: JSON.stringify(data)
        }).then(res => {
            //console.log(res);
            return Promise.resolve(res);
        })
    }

    deleteSegredo( token ){
        return this.fetch(`${this.domain}UserTribunalLogins/delete/${token}`, {
            method: 'DELETE'
        }).then(res => {
            return Promise.resolve(res);
        })
    }

    getSegredos(token = null ) {
        // Get a token from api server using the fetch api
        if( token === null ){
            return this.fetch(`${this.domain}UserTribunalLogins/index`, {
                method: 'GET'
            }).then(res => {
                if( typeof res !== "undefined" && typeof res.data !== "undefined" ){
                    return Promise.resolve(res.data.UserTribunalLogins);
                }else{
                    return Promise.resolve( [] );
                }
            });
        }else{
            return this.fetch(`${this.domain}UserTribunalLogins/view/${token}`, {
                method: 'GET'
            }).then(res => {
                if( typeof res !== "undefined" && typeof res.data !== "undefined" ){
                    return Promise.resolve(res.data.UserTribunalLogins);
                }else{
                    return Promise.resolve( [] );
                }
            });
        }

    }

    fetch(url, options) {
          let auth = new AuthService();

          options.headers = {
             'Authorization': 'Bearer ' + auth.getToken(),
             'Content-Type': 'application/json',
             'Accept': 'application/json'
           }

          return fetch(url, options)
                    .then(this._checkStatus)
                        .then(response => {

                            if(typeof response !== "undefined"){

                                return Promise.resolve( response.text().then(function(result){
                                    //console.log(result);
                                    let old_result = result;
                                    result = result.split('{"success"');
                                    if( result.length > 1 ){
                                        result = result[1].split('<pre');
                                        //console.log(result[0]);
                                        let json = '{"success"'+result[0];
                                        result = JSON.parse(json);
                                        //console.log(result);
                                        return result;
                                    }else{
                                        return old_result;
                                    }

                                }) );

                            }else{

                                let result = {
                                    success: false,
                                    message: "Erro desconhecido"
                                }

                                return Promise.resolve( result );

                            }

                  })
    }

    _checkStatus(response) {
        // raises an error in case response status is not a success
        if (response.status >= 200 && response.status < 300) { // Success status lies between 200 to 300
            return response
        } else if( response.status === 401 || response.status === "401" ) {
          console.log("Tentando trapacear? Você será desligado");

          localStorage.removeItem('id_token');
          localStorage.removeItem('loggedUser');
          window.location.href = '/login';

        } else if( response.status === 402 || response.status === "402" ) {
        
            window.location.href = '/config/mudar-plano?plano_vencido';
    
          }else {
            var error = new Error(response.statusText)
            error.response = response
            throw error
        }
    }

    /*fetch(url, options) {
        // performs api calls sending the required authentication headers
        let auth = new AuthService();

        options.headers = {
           'Authorization': 'Bearer ' + auth.getToken(),
           'Content-Type': 'application/json',
           'Accept': 'application/json'
         }

        return fetch(url, options)
            .then(this._checkStatus)
            .then(response => response.json())
    }

    _checkStatus(response) {
        // raises an error in case response status is not a success
        if (response.status >= 200 && response.status < 300) { // Success status lies between 200 to 300
            return response
        } else {
            var error = new Error(response.statusText)
            error.response = response
            throw error
        }
    }*/
}
