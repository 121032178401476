import React from 'react';
import 'antd/dist/antd.css';
import Popover, { ArrowContainer } from 'react-tiny-popover';
import Confirm from '../../containers/confirm';

import PublicationsService from '../../../services/PublicationsService';
import AuthService from '../../../services/AuthService';

//import HaveComment from './haveComment';

import moment from "moment";
import 'moment/locale/pt-br';

moment.locale('pt-BR');

export default class PublicationsLawsuit extends React.Component { 
  _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount( ) { 
    this._isMounted = true;

    this.getPublications(); 

    /*document.addEventListener("cancelDialogConfirm", (e) => {
      if( this._isMounted ){
        this.setState({
          showConfirm: false,          
        });
      }
    });

    document.addEventListener("confirmDialogConfirm", (e) => {
      if( this._isMounted ){
        this.setState({
          showConfirm: false,
        });

        if( this.state.scrapToDelete !== false ){
            this.deleteScrap(this.state.scrapToDelete, true);
        }
      }
    });*/

  }


  constructor( props ) {
    super(props);
    /*this.addScrap = this.addScrap.bind(this);
    this.openAddScraps = this.openAddScraps.bind(this);
    this.openEditScraps = this.openEditScraps.bind(this);
    this.editScrap = this.editScrap.bind(this);
    this.deleteScrap = this.deleteScrap.bind(this);*/
    this.voltar_publications = this.voltar_publications.bind(this);
    /*this.commentItem = this.commentItem.bind(this);
    this.comment_button_container = this.comment_button_container.bind(this);*/
    this.modifiedPublications = this.props.modifiedPublications.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.publicationItem = this.publicationItem.bind(this);
    this.roll = this.roll.bind(this);
    this.myRef = React.createRef();

    this.authService = new AuthService();

    this.number_comments = 0;

    this.state = {
      lawsuit_token: this.props.lawsuit.token,
      lawsuit: this.props.lawsuit,
      scrapToDelete: false,
      showConfirm: false,
      publications: []
    }
  }

  componentWillReceiveProps(nextProps) {

      this.setState({
        lawsuit_token: nextProps.lawsuit.token,
        lawsuit: nextProps.lawsuit,
      });

  }

  roll = (event) => {

    this.PublicationsService = new PublicationsService();

  }

  /*openAddScraps ( e ) {

      document.getElementById("movements_add").classList.remove("hide");
      document.getElementById("movement-table").className += ' hide';

      if ( document.getElementById("mvmt-show-hide-button-container") !== null ){
        document.getElementById("mvmt-show-hide-button-container").className += ' hide';
      }

      let movement = this.props.lawsuit.movements.filter( ( movement )=>{ return movement.token === e.target.dataset.token } )[0];

      document.getElementById("date-form").innerHTML = movement.date.substr(0, 10).split('-').reverse().join('/');

      document.getElementById("movement-name-form").innerHTML = movement.name;

      document.getElementById("movement_token").value = e.target.dataset.token;

      this.setState(
        { scrap: ""}
      )

  }

  openEditScraps ( e ) {

      document.getElementById("movements_edit").classList.remove("hide");
      document.getElementById("movement-table").className += ' hide';

      if ( document.getElementById("mvmt-show-hide-button-container") !== null ){
        document.getElementById("mvmt-show-hide-button-container").className += ' hide';
      }

      document.getElementById("date-edit-form").innerHTML = e.target.dataset.date_comment.substr(0, 10).split('-').reverse().join('/');

      document.getElementById("movement_edit_token").value = e.target.dataset.comment_token;

      this.setState(
        { scrap: e.target.dataset.scrap}
      )

  }*/

  getPublications () {

    this.PublicationsService = new PublicationsService();

    this.PublicationsService.listPublicationsByLawsuit( this.state.lawsuit_token ).then( publications =>{

      console.log(publications);

        if( this._isMounted ) {
          this.setState({
            publications,
          });
        }

    });

  }

  handleChange(e){

       this.setState(
           {
               [e.target.name]: e.target.value
           }
       )
   }

  voltar_publications ( e ) {
    document.getElementById("publications_add").className += ' hide';
    document.getElementById("publications_edit").className += ' hide';
    document.getElementById("publications-table").classList.remove("hide");
    if ( document.getElementById("mvmt-show-hide-button-container") !== null ){
      document.getElementById("mvmt-show-hide-button-container").classList.remove("hide");
    }
  }

  /*addScrap( e ) {
      this.PublicationsService = new PublicationsService();

      let comment = {
          'movement_token': document.getElementById("movement_token").value,
          'comment': this.state.scrap
      };

      this.MovementService.addComment( comment ).then(res =>{

          document.getElementById("movements_add").className += ' hide';
          document.getElementById("movement-table").classList.remove("hide");

          if( res.success === true ){
              this.modifiedMovements();
              //alert("Comentário adicionado com sucesso");
          }else{
              //alert(res.message);
          }

      });
  }

  editScrap( e ) {
      this.MovementService = new MovementService();

      this.MovementService.editComment( document.getElementById("movement_edit_token").value, this.state.scrap ).then(res =>{

          document.getElementById("movements_edit").className += ' hide';
          document.getElementById("movement-table").classList.remove("hide");

          if( res.success === true ){
              this.modifiedMovements();
              //alert("Comentário alterado com sucesso");
          }else{
              //alert(res.message);
          }

      });
  }

  deleteScrap( e, r = false ) {

    //let result = window.confirm( "Deseja excluir definitivamente este comentário?");

    if ( r ) {

      this.MovementService = new MovementService();

      this.MovementService.deleteComment( e.target.dataset.comment_token ).then(res =>{

          if( res.success === true ){
              this.modifiedMovements();
              //alert("Comentário excluído com sucesso");
          }else{
              //alert(res.message);
          }

      });

    }else{
      let evt = {
        target: {
          dataset: {
            comment_token: e.target.dataset.comment_token
          }
        }
      }
      this.setState({
        showConfirm: true,
        scrapToDelete: evt,
        message: "Deseja excluir definitivamente este comentário?"
      });
    }

  }

  showComments = ( e ) => {
    let itens = document.getElementsByClassName("comment_item-"+e.target.dataset.index);

    for (let n = itens.length - 1; n >= 0; n--) {

         if ( n >= 3 ) {
           itens[n].className += ' show';
         }
     }

     document.getElementById("show-comment-button-"+e.target.dataset.index).className += ' hide';
     document.getElementById("hide-comment-button-"+e.target.dataset.index).className += ' show';

  }

  hideComments = ( e ) => {
    let itens = document.getElementsByClassName("comment_item-"+e.target.dataset.index);

    for (let n = itens.length - 1; n >= 0; n--) {

         if ( n >= 3 ) {
           itens[n].classList.remove('show');
         }
     }

     document.getElementById("hide-comment-button-"+e.target.dataset.index).classList.remove('show');
     document.getElementById("show-comment-button-"+e.target.dataset.index).classList.remove('hide');

  }

  commentItem = ( movement_comment, index, type ) => {

      let resto = index % 2;

      this.number_comments = index+1;

      return (
        <li key={movement_comment.token} className={ ( resto === 0 )? "comment_item comment_item-"+type: "reverse comment_item comment_item-"+type }>

            <span className="date_comment">{ ( movement_comment.created !== null ) ? moment( movement_comment.created ).format("DD/MM/YYYY") : "" } - ({movement_comment.user_name}) às { ( movement_comment.created !== null ) ? moment( movement_comment.created ).format('HH:mm') : ""}</span>
            <span className="scrap-box">{movement_comment.comment}</span>
            <i className={ ( movement_comment.owner === 1 ) ? "fa fa-pencil" : "" } title="Editar este Comentário" data-date_comment={movement_comment.created} data-scrap={movement_comment.comment} data-comment_token={movement_comment.token} onClick={this.openEditScraps}></i>
            <i className={ ( movement_comment.owner === 1 && this.authService.can('comentarios_andamentos_processo_excluir', true, 'processos') ) ? "fa fa-times text-danger" : "" } title="Excluir Comentário" data-comment_token={movement_comment.token} onClick={ (event)=>{ this.deleteScrap(event)} }></i>
        </li>
      );
  };

  comment_button_container = ( movement_comment, index, type ) => {

      if ( index > 2 && index < 4 ) {
        return (
          <div key={index} className="comment-button-container">
              <span id={"show-comment-button-"+type} className="btn-small btn-info" data-index={type} onClick={this.showComments}>Exibir todos os comentários</span>
              <span id={"hide-comment-button-"+type} className="btn-small btn-info hide-button" data-index={type} onClick={this.hideComments}>Ocultar comentários</span>
          </div>
        );
      }else {
        return "";
      }

  };*/

    publicationItem = ( {record, index}, type ) => {

      return (
        <tr key={record.token} className={type}>
                <td className="user">
                  {record.dataPublicacao.substr(0,10).split('-').reverse().join('/')}
                </td>
                <td className="publication " >
                  <span>{record.conteudoPublicacao}</span>
                </td>
                <td className="">
                      <b>Nome Pesquisado: </b><p>{record.nomePesquisado}</p>
                      <b>OAB: </b><p>{record.oab}</p>
                      <b>Diário: </b><p>{record.nomeDiario}</p>
                </td>
        </tr>
      )

    };

    showPublications = ( e ) => {


    }

    hidePublications = ( e ) => {


    }

  render() {

      let url_base = localStorage.getItem('url_base');

      if( !this.authService.can('publicacoes_visualizar_dentro_processo', true, 'publicacoes') ){
        return null;
      }


      return <div className="panel panel-default container-parte">
                <Confirm show={this.state.showConfirm} text={this.state.message} />
                <div className="panel-heading text-center">
                    <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" ref={this.myRef} onClick={(event) => this.roll(event)} data-target="#publications-lawsuit-target">
                        <strong>Publicações do Processo</strong>
                    </div>
                </div>

                <div className="panel-body collapse" id="publications-lawsuit-target">
                    <div className="alert alert-warning alert-pub">Alerta: Todas as publicações tem o caráter informativo, organizacional e facilitador para os usuários, não desobrigam os advogados do acompanhamento das publicações pelos Diários Oficiais e/ou tribunais.</div>
                    <div id="box-msg-alert" className="alert alert-warning" role="warning">
                      <label className="red">Atenção: </label>
                      <span> Devido a inconsistência na padronização dos tribunais, poderá acontecer de publicações com a numeração de processos com divergências, impossibilitando o cadastro. Solicitamos que as publicações sejam acompanhadas pela tela inicial, pois as buscas são realizadas pelo Nome e OAB do Advogado.</span>
                    </div>
                    <div className="table-responsive">
                        <table id="publications-table" className="table">
                                <thead>
                                    <tr>
                                          <th className="table_date date ">
                                                <div>Data</div>
                                            </th>
                                            <th className="publication ">
                                                <div>
                                                    <p>Conteúdo da Publicação</p>
                                                </div>
                                            </th>
                                            <th className="">
                                                <div>Informações</div>
                                            </th>
                                    </tr>
                                </thead>
                                <tbody>
                                      { ( typeof this.state.publications !== "undefined") ? this.state.publications.map( (record, index) => this.publicationItem({ record, index }, 'publication-item') ) : null }
                                </tbody>
                        </table>
                    </div>
              </div>
          </div>

  }
}
