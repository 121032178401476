import React from 'react';
import Select from 'react-select';
import InputMask from 'react-input-mask';
import Popover, { ArrowContainer } from 'react-tiny-popover';

import MyPopover from '../../containers/mypopover';

import LawsuitPersonagesService from '../../../services/LawsuitPersonagesService';
import AddressService from '../../../services/AddressService';
import Loading from '../../containers/loading';
import Alert from '../../containers/alert';

export default class EditPersonagesLawsuit extends React.Component { _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }


  constructor( props ) {
      super(props);
      this.LawsuitPersonagesService = new LawsuitPersonagesService();
      this.AddressService = new AddressService();

      let opt_sides = [
        {value: 1, label: "Cliente"},
        {value: 2, label: "Oponente"}
      ]

      let options_estado_civil = [
        {value: "Casado", label: "Casado(a)"},
        {value: "Solteiro", label: "Solteiro(a)"},
        {value: "Separado", label: "Separado(a)"},
        {value: "Divorciado", label: "Divorciado(a)"},
        {value: "Viúvo", label: "Viúvo(a)"},
        {value: "União Estável", label: "União Estável"}
      ]

      let options_pessoaTipo = [
        {value: "1", label: "Pessoa Física"},
        {value: "2", label: "Pessoa Jurídica"},
      ]

      let options_nacionalidade = [
        {value: "Brasileiro", label: "Brasileiro(a)"},
        {value: "Estrangeiro", label: "Estrangeiro(a)"},
      ]

      this.state = {
        opt_types: [
          {value: 1, label: "ACUSADO"}
        ],
        opt_partes: [],
        opt_sides: opt_sides,
        options_estado_civil: options_estado_civil,
        options_pessoaTipo: options_pessoaTipo,
        options_nacionalidade: options_nacionalidade,
        loadingVisible: false,
        loadingMessage: [],
        inp_nome: "",
        inp_rua: "",
        rg:"",
        cpf:"",
        cnpj: "",
        cep: "",
        data_nascimento:"",
        inp_numero: "",
        inp_bairro: "",
        inp_cidade: "",
        complemento: "",
        tel: "",
        cel: "",
        telcom: "",
        email: "",
        email2: "",
        email3: "",
        profissao: "",
        pis_pasep: "",
        ctps: "",
        token: "",
        errors: {},
        message: "",
        showAlert: false
      }

      this.cancel = this.cancel.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.selectChange = this.selectChange.bind(this);
      this.editPersonage = this.editPersonage.bind(this);
      this.modifiedPersonages = this.props.modifiedPersonages.bind(this);
      this.getCEP = this.getCEP.bind(this);
  }

  componentDidMount( ) { 
    this._isMounted = true;

    document.addEventListener("cancelAlert", (e) => {
      if( this._isMounted ){
        this.setState({
          showAlert: false
        });
      }
    });

    document.addEventListener("cancelAlert", (e) => {
      if( this._isMounted ){
        this.setState({
          showAlert: false
        });
      }
    });
    document.addEventListener("cancelLoading", (e) => {
      this.setState({
        loadingVisible: false
      });
    });

  }

  componentDidUpdate = () => {

    this.LawsuitPersonagesService = new LawsuitPersonagesService();


    this.LawsuitPersonagesService = new LawsuitPersonagesService();
    this.AddressService = new AddressService();

    let opt_types = ""; let opt_partes = ""; let opt_estados = "";

    if ( this.state.token !== this.props.personage ){
            document.body.style.cursor = "wait";

            this.LawsuitPersonagesService.view( this.props.personage ).then( personage =>{

              //let person = personage;

              this.LawsuitPersonagesService.getTypes( ).then(res =>{

                    opt_types = res;

                    this.LawsuitPersonagesService.list( ).then(res =>{
                          opt_partes = res;

                          this.AddressService.getEstado( ).then(res =>{
                                opt_estados = res;

                                let estado_id = ( typeof personage.personage_addresses[0] !== "undefined" && personage.personage_addresses[0].state_id !== null ) ? personage.personage_addresses[0].state_id : '2';

                                this.AddressService.getCidades( estado_id ).then( cities=>{

                                    document.body.style.cursor = "default";

                                      //console.log( "vai setar o personage na tela edit");
                                      //console.log(personage);

                                      let personage_uf = "";
                                      let personage_city = "";

                                      let personage_category_id = (personage.personage_category_id !== null) ? personage.personage_category_id : "";

                                      let personage_type = opt_types.filter( ( type )=>{  return type.value.toString() === this.props.type  } );
                                      let personage_side = this.state.opt_sides.filter( ( side )=>{  return side.value.toString() === this.props.side  } );
                                      let personage_tipo_pessoa = this.state.options_pessoaTipo.filter( ( tipo )=>{  return tipo.value.toString() === personage_category_id.toString() } );
                                      let personage_estado_civil = this.state.options_estado_civil.filter( ( civil )=>{  return civil.value === personage.estado_civil  } );
                                      let personage_nacionalidade = this.state.options_nacionalidade.filter( ( nacionalidade )=>{  return nacionalidade.value === personage.nacionalidade  } );

                                      if ( typeof personage.personage_addresses[0] !== "undefined" && personage.personage_addresses[0].state_id !== null ) {
                                        personage_uf = opt_estados.filter( ( estado )=>{  return estado.value === personage.personage_addresses[0].state_id  } );
                                        personage_city = cities.filter( ( cidade )=>{  return cidade.value === personage.personage_addresses[0].city_id  } );
                                      }

                                      personage_tipo_pessoa = ( personage_tipo_pessoa.length === 0 ) ? this.state.options_pessoaTipo[0] : personage_tipo_pessoa;
                                      personage_nacionalidade = ( personage_nacionalidade.length === 0 ) ? this.state.options_nacionalidade[0] : personage_nacionalidade;

                                      if ( personage_category_id === 2 ) {
                                        document.getElementById("edit-form-group-rg").className += ' hide';
                                        document.getElementById("edit-form-group-cpf").className += ' hide';
                                        document.getElementById("edit-form-group-nascimento").className += ' hide';
                                        document.getElementById("edit-form-group-estado-civil").className += ' hide';
                                        document.getElementById("edit-form-group-nacionalidade").className += ' hide';
                                        document.getElementById("edit-form-group-cnpj").classList.remove("hide");
                                      }

                                      this.setState({
                                        opt_types: opt_types,
                                        opt_partes: opt_partes,
                                        opt_estados: opt_estados,
                                        cities: cities,
                                        inp_nome: ( personage.name !== null ) ? personage.name : "",
                                        opt_side: personage_side,
                                        opt_type: personage_type,
                                        opt_pessoaTipo: personage_tipo_pessoa,
                                        profissao: ( personage.profissao !== null ) ? personage.profissao : "",
                                        pis_pasep: ( personage.pis_pasep !== null ) ? personage.pis_pasep : "",
                                        ctps: ( personage.ctps !== null ) ? personage.ctps : "",
                                        rg: ( personage.rg !== null ) ? personage.rg : "",
                                        cpf: ( personage.cpf !== null ) ? personage.cpf : "",
                                        cnpj: ( personage.cnpj !== null ) ? personage.cnpj : "",
                                        data_nascimento: ( personage.data_nascimento !== null ) ? personage.data_nascimento : "",
                                        opt_estado_civil: personage_estado_civil,
                                        opt_nacionalidade: personage_nacionalidade,
                                        obs: ( personage.observation !== null ) ? personage.observation : "",
                                        token: personage.token,
                                        cep: ( typeof personage.personage_addresses[0] !== "undefined" ) ? personage.personage_addresses[0].cep : "",
                                        inp_rua: ( typeof personage.personage_addresses[0] !== "undefined" ) ? personage.personage_addresses[0].street : "",
                                        inp_numero: ( typeof personage.personage_addresses[0] !== "undefined" ) ? personage.personage_addresses[0].number : "",
                                        complemento: ( typeof personage.personage_addresses[0] !== "undefined" ) ? personage.personage_addresses[0].complement : "",
                                        inp_bairro: ( typeof personage.personage_addresses[0] !== "undefined" ) ? personage.personage_addresses[0].neighbor : "",
                                        opt_estado: personage_uf,
                                        cidade: personage_city,
                                        tel: ( typeof personage.personage_contacts[0] !== "undefined" ) ? personage.personage_contacts[0].value : "",
                                        cel: ( typeof personage.personage_contacts[1] !== "undefined" ) ? personage.personage_contacts[1].value : "",
                                        email: ( typeof personage.personage_contacts[2] !== "undefined" ) ? personage.personage_contacts[2].value : "",
                                        email2: ( typeof personage.personage_contacts[3] !== "undefined" ) ? personage.personage_contacts[3].value : "",
                                        email3: ( typeof personage.personage_contacts[4] !== "undefined" ) ? personage.personage_contacts[4].value : "",
                                        telcom: ( typeof personage.personage_contacts[5] !== "undefined" ) ? personage.personage_contacts[5].value : "",
                                      });
                              });

                          });
                    });

              });

            });

    }

  }

  stateChange = (selected, actionMeta) => {
    let pops = [];
    pops[ actionMeta.name ] = selected;
    console.log(selected);

    if ( typeof actionMeta.name !== undefined && actionMeta.name !== "" && actionMeta.name !== null ){

      this.AddressService.getCidades( selected.value ).then( cities=>{
        this.setState({
          cities: cities
        });
      });

      if ( typeof selected !== undefined && selected !== "" && selected !== null ) {
        this.setState( pops );
      }

    }
  }

  selectChange = (selected, actionMeta) => {
    let pops = [];
    pops[ actionMeta.name ] = selected;
    if ( typeof actionMeta.name !== undefined && actionMeta.name !== "" && actionMeta.name !== null ){

      if ( typeof selected !== undefined && selected !== "" && selected !== null ) {
        this.setState( pops );
      }

    }
  }

  selectCPFChange = (selected, actionMeta) => {
    let pops = [];
    pops[ actionMeta.name ] = selected;
    if ( typeof actionMeta.name !== undefined && actionMeta.name !== "" && actionMeta.name !== null ){

      if ( typeof selected !== undefined && selected !== "" && selected !== null ) {
        this.setState( pops );
      }

      console.log(selected);
      if ( selected.value === "2" ) {
          document.getElementById("edit-form-group-rg").className += ' hide';
          document.getElementById("edit-form-group-cpf").className += ' hide';
          document.getElementById("edit-form-group-nascimento").className += ' hide';
          document.getElementById("edit-form-group-estado-civil").className += ' hide';
          document.getElementById("edit-form-group-nacionalidade").className += ' hide';
          document.getElementById("edit-form-group-cnpj").classList.remove("hide");
      }else{
          document.getElementById("edit-form-group-rg").classList.remove("hide");
          document.getElementById("edit-form-group-cpf").classList.remove("hide");
          document.getElementById("edit-form-group-nascimento").classList.remove("hide");
          document.getElementById("edit-form-group-estado-civil").classList.remove("hide");
          document.getElementById("edit-form-group-nacionalidade").classList.remove("hide");
          document.getElementById("edit-form-group-cnpj").className += ' hide';
      }

    }
  }

  handleChange(e){

       this.setState(
           {
               [e.target.name]: e.target.value
           }
       )
   }

   getCEP(e){
       this.AddressService.getCEP( this.state.cep.replace(".", "").replace("-","") ).then(res =>{
         //console.log(res);69905074

         if ( res !== null ){

               this.AddressService.getCidades( res.state_id ).then( cities=>{


                         this.setState(
                             {
                                 'inp_rua': res.rua,
                                 'inp_bairro': res.bairro,
                                 'cities': cities,
                                 'cidade': cities.filter( ( cidade )=>{ return cidade.value === res.city_id } ),
                                 'opt_estado': this.state.opt_estados.filter( ( ufs )=>{ return ufs.uf === res.uf } )
                             }
                         );

               });

         }else{
              //window.alert("CEP não encontrado, preencha manualmente os campos de rua, bairro, cidade e estado");
              this.setState({
                showAlert: true,
                message: "CEP não encontrado, preencha manualmente os campos de rua, bairro, cidade e estado"
              });
         }

       });
   }

  cancel() {
      document.getElementById("table-partes").classList.remove("hide");
      document.getElementById("editPersonagesLawsuit").className += ' hide';
      document.getElementById("bnt-incluir-parte").classList.remove("hide");
      document.getElementById("help_icon_incluir_parte").classList.remove("hide");
  }

  editPersonage (e) {
    e.preventDefault();

    if (this.validateForm()) {

          let opt_type_value = ""; let opt_pessoaTipo = ""; let opt_estado_civil = ""; let opt_nacionalidade = "";
          let opt_estado = ""; let opt_side_value = ""; let cidade = "";

          if ( typeof this.state.opt_side !== "undefined" ){
            if ( this.state.opt_side instanceof Array && this.state.opt_side.length > 0 ){
              opt_side_value = this.state.opt_side[0].value;
            }else{
              opt_side_value = this.state.opt_side.value;
            }
          }

          if ( typeof this.state.opt_type !== "undefined" ){
            if ( this.state.opt_type instanceof Array && this.state.opt_type.length > 0 ){
              opt_type_value = this.state.opt_type[0].value;
            }else{
              opt_type_value = this.state.opt_type.value;
            }
          }

          if ( typeof this.state.opt_pessoaTipo !== "undefined"){
            if ( this.state.opt_pessoaTipo instanceof Array && this.state.opt_pessoaTipo.length > 0 ){
              opt_pessoaTipo = this.state.opt_pessoaTipo[0].value;
            }else{
              opt_pessoaTipo = this.state.opt_pessoaTipo.value;
            }
          }
          if ( typeof this.state.opt_estado_civil !== "undefined"){
            if ( this.state.opt_estado_civil instanceof Array && this.state.opt_estado_civil.length > 0 ){
              opt_estado_civil = this.state.opt_estado_civil[0].value;
            }else{
              opt_estado_civil = this.state.opt_estado_civil.value;
            }
          }

          if ( typeof this.state.opt_nacionalidade !== "undefined"){
            if ( this.state.opt_nacionalidade instanceof Array && this.state.opt_nacionalidade.length > 0 ){
              opt_nacionalidade = this.state.opt_nacionalidade[0].value;
            }else{
              opt_nacionalidade = this.state.opt_nacionalidade.value;
            }
          }

          if ( typeof this.state.opt_estado !== "undefined"){
            if ( this.state.opt_estado instanceof Array && this.state.opt_estado.length > 0 ){
              opt_estado = this.state.opt_estado[0].value;
            }else{
              opt_estado = this.state.opt_estado.value;
            }
          }

          if ( typeof this.state.cidade !== "undefined"){
            if ( this.state.cidade instanceof Array && this.state.cidade.length > 0 ){
              cidade = this.state.cidade[0].value;
            }else{
              cidade = this.state.cidade.value;
            }
          }

          document.getElementById("bnt-editar-parte").disabled = true;
          document.getElementById("bnt-editar-parte-2").disabled = true;
          document.body.style.cursor = "wait";
          this.setState({
            loadingVisible: true,
            loadingMessage: ["A edição de parte está sendo efetuada.", "Por gentileza, aguarde...", "Tempo estimado de 15 a 90 segundos"]
          });

          let data = {
            'name': this.state.inp_nome,
            'personage_type_id': opt_type_value,
            'personage_category_id': opt_pessoaTipo,
            'cpf': this.state.cpf,
            'cnpj': this.state.cnpj,
            'rg': this.state.rg,
            'profissao': this.state.profissao,
            'pis_pasep': this.state.pis_pasep,
            'ctps': this.state.ctps,
            'data_nascimento': this.state.data_nascimento.split('/').reverse().join('-'),
            'estado_civil': opt_estado_civil,
            'nacionalidade': opt_nacionalidade,
            'observation': this.state.obs,
            'PersonageAddresses': [
              {
                'cep': this.state.cep.replace(".", "").replace("-", ""),
                'street': this.state.inp_rua,
                'number': this.state.inp_numero,
                'complement': this.state.complemento,
                'neighbor': this.state.inp_bairro,
                'state_id': opt_estado,
                'city_id': cidade
              }
            ],
            'PersonageContacts': [
              {
                'personage_contact_type_id': 1,
                'value': this.state.tel
              },
              {
                'personage_contact_type_id': 1,
                'value': this.state.cel
              },
              {
                'personage_contact_type_id': 3,
                'value': this.state.email
              },
              {
                'personage_contact_type_id': 3,
                'value': this.state.email2
              },
              {
                'personage_contact_type_id': 3,
                'value': this.state.email3
              },
              {
                'personage_contact_type_id': 1,
                'value': this.state.telcom
              }
            ]

          }

          this.LawsuitPersonagesService.edit( data, this.state.token ).then(res =>{

            if ( res.success === true ) {
                let personage_token = res.data.Personages.token;


                let data_lawsuit = {
                  'personage_token': personage_token,
                  'lawsuit_token': document.getElementById("lawsuit_token").value,
                  'personage_side_id': opt_side_value,
                  'personage_type_id': opt_type_value,
                }

                this.LawsuitPersonagesService.addToLawsuit( data_lawsuit ).then(res =>{

                  document.getElementById("bnt-editar-parte").disabled = false;
                  document.getElementById("bnt-editar-parte-2").disabled = false;
                  document.body.style.cursor = "default";
                  this.setState({
                    loadingVisible: false,
                    loadingMessage: []
                  });

                  if ( res.success === true ) {
                    localStorage.setItem('alert_message', "Salvo com sucesso");
                    localStorage.setItem('alert_type', 'alert-success');
                    let time = new Date().getTime();
                    localStorage.setItem('alert_time', time );
                  }else {
                    localStorage.setItem('alert_message', res.message);
                    localStorage.setItem('alert_type', 'alert-danger');
                    let time = new Date().getTime();
                    localStorage.setItem('alert_time', time );
                  }

                  setTimeout(() => {
                    this.modifiedPersonages();
                  }, 100);

                  document.getElementById("table-partes").classList.remove("hide");
                  document.getElementById("editPersonagesLawsuit").className += ' hide';
                  document.getElementById("bnt-incluir-parte").classList.remove("hide");
                  document.getElementById("help_icon_incluir_parte").classList.remove("hide");

                  var url_string = window.location.href;
                  var url = new URL(url_string);
                  var parte = url.searchParams.get("parte");

                  if(parte !== null){
                    window.scrollTo(0, 221);
                  }

                }).catch(err =>{
                  document.getElementById("bnt-editar-parte").disabled = false;
                  document.getElementById("bnt-editar-parte-2").disabled = false;
                  document.body.style.cursor = "default";
                  this.setState({
                    loadingVisible: false,
                    loadingMessage: []
                  });
                  console.log(err);
                  document.getElementById("table-partes").classList.remove("hide");
                  document.getElementById("editPersonagesLawsuit").className += ' hide';
                  document.getElementById("bnt-incluir-parte").classList.remove("hide");
               });

          }

        }).catch(err =>{
           document.getElementById("bnt-editar-parte").disabled = false;
           document.getElementById("bnt-editar-parte-2").disabled = false;
           document.body.style.cursor = "default";
           this.setState({
             loadingVisible: false,
             loadingMessage: []
           });
           console.log(err);
       });

  }
}

  validateForm() {
      let errors = {};
      let formIsValid = true;

      if ( this.state.inp_nome===0 || this.state.inp_nome==="" || typeof this.state.inp_nome === "undefined" ) {
        formIsValid = false;
        errors["inp_nome"] = "*Por favor entre com o nome da parte";
      }

      if ( typeof this.state.opt_side === "undefined" || this.state.opt_side === null ) {
        formIsValid = false;
        errors["opt_side"] = "*Escolha se é cliente ou oponente";
      }

      this.setState({
         errors: errors
       });

      return formIsValid;

  }

  render() {

      let url_base = localStorage.getItem('url_base');

      return <div id="editPersonagesLawsuit" className="wrap-form hide">
                  <Alert show={this.state.showAlert} text={this.state.message} />
                  <Loading visible={this.state.loadingVisible} message1={this.state.loadingMessage[0]} message2={this.state.loadingMessage[1]} message3={this.state.loadingMessage[2]} cancel={true}  />
                  <form  id="edit-form" name="form" className="form-horizontal  cadastro-partes-cadastrada bv-form" noValidate="novalidate">
                        <div className="header-personage">
                          <div className="col-md-12 text-center">
                              <h4>
                                    Editar Parte
                                    <Popover
                                        isOpen={this.state.isAddPopoverOpen}
                                        position={['top', 'right', 'left', 'bottom']}
                                        padding={10}
                                        onClickOutside={() => this.setState({ isAddPopoverOpen: false })}
                                        content={({ position, targetRect, popoverRect }) => (
                                            <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                                                position={position}
                                                targetRect={targetRect}
                                                popoverRect={popoverRect}
                                                arrowColor={'#94bcd8'}
                                                arrowSize={10}
                                            >
                                                <div className="popover-box"
                                                    onClick={() => this.setState({ isAddPopoverOpen: !this.state.isAddPopoverOpen })}
                                                >
                                                      Preencha todos os dados para que seja possível extrair informações precisas do processo e gerar relatórios mais completos, e possibilitar gerar documentos no Doc-Facil
                                                </div>
                                            </ArrowContainer>
                                        )}
                                    >
                                        <img onMouseOut={() => this.setState({ isAddPopoverOpen: false })}  onMouseOver={() => this.setState({ isAddPopoverOpen: !this.state.isAddPopoverOpen })} className="help_icon" alt="ajuda" src={url_base+"/images/i_branco.png"}></img>
                                    </Popover>
                              </h4>
                          </div>
                        </div>

                        <div id="div-parte">
                              <div className="form-group col-md-12 text-right action-buttons">
                                    <input type="submit" className="btn btn-primary horizontal-space" value="Salvar" id="bnt-editar-parte-2" onClick={this.editPersonage} />
                                    <span className="btn btn-danger horizontal-space" id="bnt-voltar" onClick={this.cancel}>Cancelar</span>
                              </div>
                              <div className="form-group col-md-12 text-left">
                                      <label htmlFor="inp_nome" className="control-label">
                                              Nome<span className="text-red">*</span>
                                              <MyPopover icon="white" text='Alguns Tribunais utilizam números antes dos nomes das partes, delete-os para uma melhor visualização e resultado nos relatórios.' />
                                      </label>
                                      <input type="text" name="inp_nome" value={this.state.inp_nome} id="edit-nome" className="form-control input-lg uppercase" onChange={this.handleChange} required="required" data-bv-field="inp_nome" />
                                      <div className="errorMsg">{this.state.errors.inp_nome}</div>
                              </div>
                              <div className="form-group col-md-4 text-left">
                                    <label htmlFor="opt_sides" className="">Cliente ou Oponente<span className="text-red">*</span></label>
                                    <Select
                                        className="opt_side"
                                        name="opt_side"
                                        placeholder="Selecione"
                                        options={this.state.opt_sides}
                                        value={this.state.opt_side}
                                        onChange={this.selectChange}
                                      />
                                      <div className="errorMsg">{this.state.errors.opt_side}</div>
                              </div>
                              <div className="form-group col-md-4">
                                    <label htmlFor="opt_tipo" className="">Tipo<span className="text-red"></span></label>
                                    <Select
                                        className="opt_type"
                                        name="opt_type"
                                        placeholder="Selecione"
                                        value={this.state.opt_type}
                                        options={this.state.opt_types}
                                        onChange={this.selectChange}
                                    />
                              </div>
                              <div className="form-group col-md-4 text-left">
                                      <label htmlFor="opt_pessoaTipo" className="control-label"> Tipo de pessoa</label>
                                      <Select
                                          className="opt_pessoaTipo"
                                          name="opt_pessoaTipo"
                                          placeholder="Selecione"
                                          value={this.state.opt_pessoaTipo}
                                          options={this.state.options_pessoaTipo}
                                          defaultValue={this.state.options_pessoaTipo[0]}
                                          onChange={this.selectCPFChange}
                                      />
                              </div>
                              <div id="edit-form-group-rg" className="form-group col-md-4 text-left">
                                  <label htmlFor="inp_rg" className="control-label">RG / Orgão Expedidor</label>
                                  <input className="form-control input-lg" value={this.state.rg}  name="rg" onChange={this.handleChange} />
                              </div>
                              <div id="edit-form-group-cpf" className="form-group col-md-4 text-left">
                                    <label htmlFor="inp_cpf" className="control-label">CPF</label>
                                    <InputMask className="form-control input-lg" value={this.state.cpf}  name="cpf" mask="999.999.999-99" maskChar="_" onChange={this.handleChange} />
                              </div>
                              <div id="edit-form-group-cnpj" className="form-group col-md-4 text-left hide">
                                    <label htmlFor="inp_cnpj" className="control-label">CNPJ</label>
                                    <InputMask className="form-control input-lg" name="cnpj" value={this.state.cnpj} mask="99.999.999/9999-99" maskChar="_" onChange={this.handleChange} />
                              </div>
                              <div id="edit-form-group-nascimento" className="form-group col-md-4 text-left">
                                      <label htmlFor="inp_aniversario" className="control-label">Nascimento</label>
                                      <InputMask className="form-control input-lg" value={this.state.data_nascimento.substr(0,10).split('-').reverse().join('/')}  name="data_nascimento" mask="99/99/9999" maskChar="_" onChange={this.handleChange} />
                              </div>
                              <div id="form-group-profissao" className="form-group col-md-4 text-left">
                                      <label htmlFor="profissao" className="control-label">Profissão</label>
                                      <input className="form-control input-lg" name="profissao" value={this.state.profissao} onChange={this.handleChange} />
                              </div>
                              <div id="form-group-pis" className="form-group col-md-4 text-left">
                                      <label htmlFor="data_pis_pasep" className="control-label">PIS/PASEP</label>
                                      <input className="form-control input-lg" name="pis_pasep" value={this.state.pis_pasep} onChange={this.handleChange} />
                              </div>
                              <div id="form-group-ctps" className="form-group col-md-4 text-left">
                                      <label htmlFor="data_ctps" className="control-label">CTPS</label>
                                      <input className="form-control input-lg" name="ctps" value={this.state.ctps} onChange={this.handleChange} />
                              </div>
                              <div id="edit-form-group-estado-civil" className="form-group col-md-4 text-left">
                                    <label htmlFor="opt_estado_civil" className="control-label">Estado Civil</label>
                                    <Select
                                        className="opt_estado_civil"
                                        name="opt_estado_civil"
                                        options={this.state.options_estado_civil}
                                        value={this.state.opt_estado_civil}
                                        placeholder="Selecione"
                                        onChange={this.selectChange}
                                      />
                              </div>
                              <div id="edit-form-group-nacionalidade" className="form-group col-md-3 text-left">
                                      <label htmlFor="opt_nacionalidade" className="control-label"> Nacionalidade</label>
                                      <Select
                                          className="opt_nacionalidade"
                                          name="opt_nacionalidade"
                                          options={this.state.options_nacionalidade}
                                          value={this.state.opt_nacionalidade}
                                          defaultValue={this.state.options_nacionalidade[0]}
                                          placeholder="(escolha um)"
                                          onChange={this.selectChange}
                                        />
                              </div>
                              <div className="form-group col-md-3 text-left">
                                    <label htmlFor="inp_cep" className="control-label">CEP</label>
                                    <InputMask className="form-control input-lg" id="edit-cep" value={this.state.cep}  name="cep" mask="99.999-999" maskChar="_" onChange={this.handleChange} />
                              </div>
                              <div className="form-group text-left">
                                    <span id="edit-cep-btn" className="btn btn-success cep-btn" onClick={this.getCEP}><i className="fa fa-search"></i> Pesquisa CEP</span>
                              </div>
                              <div className="form-group col-md-4 text-left">
                                    <label htmlFor="inp_rua" className="control-label">Endereço</label>
                                    <input type="text" name="inp_rua" value={this.state.inp_rua} id="edit-rua" className="form-control input-lg uppercase" onChange={this.handleChange} />
                              </div>
                              <div className="form-group col-md-4 text-left">
                                    <label htmlFor="inp_numero" className="control-label">N°</label>
                                    <input type="text" name="inp_numero" value={this.state.inp_numero} autoComplete="new-password" id="edit-numero" className="form-control input-lg uppercase" onChange={this.handleChange} />
                              </div>
                              <div className="form-group col-md-4 text-left">
                                    <label htmlFor="complemento" className="control-label">Complemento</label>
                                    <input type="text" name="complemento" value={this.state.complemento} id="edit-complemento" className="form-control input-lg uppercase" onChange={this.handleChange} />
                              </div>
                              <div className="form-group col-md-4 text-left">
                                    <label htmlFor="inp_bairro" className="control-label">Bairro</label>
                                    <input type="text" name="inp_bairro" value={this.state.inp_bairro} id="edit-bairro" className="form-control input-lg uppercase" onChange={this.handleChange} />
                              </div>
                              <div className="form-group col-md-4 text-left">
                                    <label htmlFor="inp_estado" className="control-label">Estado</label>
                                    <Select
                                        className="opt_estado"
                                        name="opt_estado"
                                        value={this.state.opt_estado}
                                        options={this.state.opt_estados}
                                        placeholder="Selecione"
                                        onChange={this.stateChange}
                                      />
                              </div>
                              <div className="form-group col-md-4 text-left">
                                    <label htmlFor="cidade" className="control-label">Cidade</label>
                                    <Select
                                        className="cidades"
                                        name="cidade"
                                        value={this.state.cidade}
                                        options={this.state.cities}
                                        placeholder="Selecione"
                                        onChange={this.selectChange}
                                      />
                              </div>
                              <div className="form-group col-md-4 text-left">
                                    <label htmlFor="tel" className="control-label">Telefone</label>
                                    <InputMask className="form-control input-lg" id="edit-edit-tel" value={this.state.tel}  name="tel" mask={this.state.tel.replace(/\D/g, "").length === 11 ? '(99) 99999-9999' : '(99) 9999-99999'}  maskChar="_" onChange={this.handleChange} />
                              </div>
                              <div className="form-group col-md-4 text-left">
                                    <label htmlFor="cel" className="control-label">Celular</label>
                                    <InputMask className="form-control input-lg" id="edit-cel" value={this.state.cel}  name="cel" mask={this.state.cel.replace(/\D/g, "").length === 11 ? '(99) 99999-9999' : '(99) 9999-99999'} maskChar="_" onChange={this.handleChange} />
                              </div>
                              <div className="form-group col-md-4 text-left">
                                    <label htmlFor="telCom" className="control-label">Telefone Comercial</label>
                                    <InputMask className="form-control input-lg" id="edit-telcom" name="telcom" value={this.state.telcom} mask={this.state.telcom.replace(/\D/g, "").length === 11 ? '(99) 99999-9999' : '(99) 9999-99999'}  maskChar="_" onChange={this.handleChange} />
                              </div>
                              <div className="form-group col-md-4 text-left">
                                    <label htmlFor="email" className="control-label">Email</label>
                                    <input type="email" name="email" value={this.state.email} id="edit-email" className="form-control input-lg" onChange={this.handleChange} />
                              </div>
                              <div className="form-group col-md-4 text-left">
                                    <label htmlFor="email2" className="control-label">Email 2</label>
                                    <input type="email" name="email2" value={this.state.email2} id="edit-email2" className="form-control input-lg" onChange={this.handleChange} />
                              </div>
                              <div className="form-group col-md-4 text-left">
                                    <label htmlFor="email3" className="control-label">Email 3</label>
                                    <input type="email" name="email3" value={this.state.email3} id="edit-email3" className="form-control input-lg" onChange={this.handleChange} />
                              </div>
                              <div className="form-group col-md-12 text-left">
                                    <label htmlFor="obs" className="control-label">Observações</label>
                                    <textarea name="obs" id="edit-obs" rows="5" className="form-control" value={this.state.obs}  onChange={this.handleChange}></textarea>
                              </div>
                              <div className="form-group col-md-12 text-right">
                                    <input type="submit" className="btn btn-primary horizontal-space" value="Salvar" id="bnt-editar-parte" onClick={this.editPersonage} />
                                    <span className="btn btn-danger horizontal-space" id="bnt-voltar" onClick={this.cancel}>Cancelar</span>
                              </div>

                        </div>
                    </form>
              </div>

  }
}
