import React from 'react';
import { Divider } from 'antd';
import Popover, { ArrowContainer } from 'react-tiny-popover';
import FormEvent from '../../events/FormEvent';
import FlashMessage from "../../containers/FlashMessage";
import Confirm from "../../containers/confirm";
import EventService from '../../../services/EventService';
import AuthService from '../../../services/AuthService';

import moment from "moment";
import 'moment/locale/pt-br';

export default class scheduleLawsuit extends React.Component { _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }


  constructor( props ) {
    super(props);
    this.deleteEvent = this.deleteEvent.bind(this);
    this.roll = this.roll.bind(this);
    this.modifiedEvents = this.modifiedEvents.bind(this);
    this.editEvent = this.editEvent.bind(this);
    this.myRef = React.createRef();

    this.EventService = new EventService();
    this.authService = new AuthService();

    this.state = {
      events: [],
      lawsuit_token: props.token,
      token: "",
      showConfirm: false,
      message: "",
      eventToDelete: false
    }

  }

  componentWillReceiveProps( nextProps ) {

    if ( nextProps.token !== this.props.token ) {
      this.setState(
        {
          'lawsuit_token': nextProps.token
        }
      );
    }

  }

  roll = (event) => {

    if ( !event.target.classList.contains('collapsed') ) {
      setTimeout(() => {
        window.scrollTo(0, this.myRef.current.offsetTop-67)
      }, 800);
    }

  }

  componentDidMount( ) { 
      this._isMounted = true;

      document.addEventListener("cancelDialogConfirm", (e) => {
        if( this._isMounted ){
          this.setState({
            showConfirm: false,          
          });
        }
      });
  
      document.addEventListener("confirmDialogConfirm", (e) => {
        if( this._isMounted ){
          this.setState({
            showConfirm: false,
          });
  
          if( this.state.eventToDelete !== false ){
              this.deleteEvent(this.state.eventToDelete, true);
          }
        }
      });

      this.listEvents();

  }

  listEvents () {
    this.EventService = new EventService();

    let url = window.location.pathname.split('/');

    let start = '1900-01-01';//moment().startOf('day').subtract(45, 'days').format('YYYY-MM-DD');
    let end = moment().endOf('day').add(2190, 'days').format('YYYY-MM-DD');

    this.EventService.listByLawsuit( url[ url.length-1], start, end ).then(res =>{

        this.setState(
          {
            'events': res,
            'isPopoverOpen': false,
            'isEditPopoverOpen': false
          }
        );

    });

    setTimeout(function(  ){


     }, 3000);
  }

  modifiedEvents(){
    console.log("eventos modificados");

    this.listEvents();

    this.setState({
      token: "",
      showModal: false,
    });
  }

    editEvent = (event) => {
        this.setState({
          token: event.target.dataset.event_token,
          showModal: true,
        });
    }

    deleteEvent = (event, r=false ) => {

      //let result = window.confirm( "Deseja excluir definitivamente este compromisso?");

      if ( r ) {

        this.EventService = new EventService();

        this.EventService.delete( event.target.dataset.event_token ).then(res =>{

            if( res.success === true ){
              let url = window.location.pathname.split('/');

              let start = '1900-01-01';//moment().startOf('day').subtract(45, 'days').format('YYYY-MM-DD');
              let end = moment().endOf('day').add(2190, 'days').format('YYYY-MM-DD');

              this.EventService.listByLawsuit( url[ url.length-1], start, end ).then(res =>{

                localStorage.setItem('alert_message', "Evento removido com sucesso.");
                localStorage.setItem('alert_type', 'alert-success');
                let time = new Date().getTime();
                localStorage.setItem('alert_time', time );

                setTimeout(() => {
                   this.setState({
                      'events': res,
                      updated: time,
                   });
                 }, 200);

              });
            }else{
                //alert(res.message);
            }

        });

      }else{
        let evt = {
          target: {
            dataset: {
              event_token: event.target.dataset.event_token
            }
          }
        }
        this.setState({
          showConfirm: true,
          eventToDelete: evt,
          message: "Deseja excluir definitivamente este compromisso?"
        });
      }

    }

   eventItem = ( {record, index}, type ) => {

     //console.log(record);

     let date = new Date(record.start);

     date = ('0' + date.getDate()).slice(-2)+"/"+('0' + (date.getMonth()+1)).slice(-2)+"/"+date.getFullYear();
     let priorities = [ "nula", "Baixa", "Média", "Nula" ];
     let status = "Em Andamento";
     if (record.events_situation !== null){
       //console.log(record.events_situation) ;
       if( record.events_situation.id === null || record.events_situation.id=== 1 ){
         status = "Em Andamento";
       }else if ( record.events_situation.id === 2 ){
         status = "Realizado";
       }else if ( record.events_situation.id === 3 ){
         status = "Cancelado";
       }
     }

       return (
         <tr key={record.key} className={type}>
               <td className="date">
                  {date}
               </td>
               <td className="title" >
                     <span className="">{record.title}</span>
               </td>
               <td className="category" >
                  {( record.event_category !== null ) ? record.event_category.name: null}
               </td>
               <td className="description" >
                  <span>{record.description}</span>
               </td>
               <td className="priority" >
                  {priorities[record.priority]}
               </td>
               <td className="status" >
                  {status}
               </td>
               <td className="actions">
                     <span className="pointer pull-right bt-edit">
                         <i className={ "fa fa-pencil-square-o" } data-event_token={record.token} onClick={( event ) => this.editEvent( event )} ></i>
                         <span className={ "edit-label" }>Editar</span>
                     </span>
                     <Divider type="vertical" />
                     <i className={(this.authService.can('agenda_processo_excluir', true, 'agenda')) ? "fa fa-trash" : "hide" } data-event_token={record.token} onClick={( event ) => this.deleteEvent( event )} ></i>
               </td>
         </tr>
       );
   };

   showEvents = ( e ) => {
     let itens = document.getElementsByClassName(e.target.dataset.type);

     for (let n = itens.length - 1; n >= 0; n--) {

          if ( n >= 10 ) {
            itens[n].className += ' show-table';
          }
      }

      document.getElementById("show-button-"+e.target.dataset.type).className += ' hide';
      document.getElementById("hide-button-"+e.target.dataset.type).className += ' show';

   }

   hideEvents = ( e ) => {
     let itens = document.getElementsByClassName(e.target.dataset.type);

     for (let n = itens.length - 1; n >= 0; n--) {

          if ( n >= 10 ) {
            itens[n].classList.remove('show-table');
          }
      }

      document.getElementById("hide-button-"+e.target.dataset.type).classList.remove('show');
      document.getElementById("show-button-"+e.target.dataset.type).classList.remove('hide');

   }

   show_hide_button_container = ( record, index, type ) => {

       if ( index > 9 && index < 11 ) {
         return (
           <div key={index} id="event-show-hide-button-container" className="show-hide-button-container">
               <span id={"show-button-"+type} className="btn-small btn-info show-button" data-type={type} onClick={this.showEvents}>Ver mais</span>
               <span id={"hide-button-"+type} className="btn-small btn-info hide-button" data-type={type} onClick={this.hideEvents}>Ocultar registros</span>
           </div>
         );
       }else {
         return "";
       }

   };

  render() {

    if( !this.authService.can("agenda_processo", true, 'agenda') ){
      return null;
    }

    let url_base = localStorage.getItem('url_base');

      return (
        <div className="panel panel-default container-parte">
              <Confirm show={this.state.showConfirm} text={this.state.message} />
              <FlashMessage time={ new Date().getTime() } />
              <div className="panel-heading text-center">
                  <div className="pointer panel-title" data-toggle="collapse" data-parent="#box-body" ref={this.myRef} onClick={(event) => this.roll(event)} data-target="#schedule-lawsuit-target">
                      <strong>Compromissos do Processo - Agenda</strong>
                  </div>
              </div>
              <div className="panel-body collapse" id="schedule-lawsuit-target">
                <FormEvent modifiedEvents={this.modifiedEvents} token={this.state.token} showModal={this.state.showModal} lawsuitToken={this.state.lawsuit_token} events={this.state.events} />
                <Popover
                    isOpen={this.state.isPopoverOpen}
                    position={['top', 'right', 'left', 'bottom']}
                    padding={10}
                    onClickOutside={() => this.setState({ isPopoverOpen: false })}
                    content={({ position, targetRect, popoverRect }) => (
                        <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                            position={position}
                            targetRect={targetRect}
                            popoverRect={popoverRect}
                            arrowColor={'#94bcd8'}
                            arrowSize={10}
                        >
                            <div className="popover-box"
                                onClick={() => this.setState({ isPopoverOpen: !this.state.isPopoverOpen })}
                            >
                                  Possibilita o agendamento de todos os Compromissos, Tarefas e Notificações  relacionados a este processo, como audiências, perícias, prazos,  peticionamentos, pendências, reunioes e outros.
                            </div>
                        </ArrowContainer>
                    )}
                >
                    <img onMouseOut={() => this.setState({ isPopoverOpen: false })}  onMouseOver={() => this.setState({ isPopoverOpen: !this.state.isPopoverOpen })} className="help_icon " alt="ajuda" src={url_base+"/images/interrogation_cropped.png"}></img>
                </Popover>
                <div className="table-responsive">
                      <table id="schedule-table" className="table">
                              <thead>
                                  <tr>
                                        <th className="date">
                                            <div>Data</div>
                                        </th>
                                        <th className="title">
                                            <div>Título</div>
                                        </th>
                                        <th className="category">
                                            <div>Categoria</div>
                                        </th>
                                        <th className="description ">
                                            <div>Descrição</div>
                                        </th>
                                        <th className="priority">
                                            <div>Prioridade</div>
                                        </th>
                                        <th className="status">
                                            <div>Status</div>
                                        </th>
                                        <th className="actions">
                                            <div>Ações</div>
                                        </th>
                                  </tr>
                              </thead>
                              <tbody>
                                    { ( typeof this.state.events !== "undefined" &&  this.state.events.length > 0 && Array.isArray( this.state.events ) ) ? this.state.events.map( (record, index) => this.eventItem({ record, index }, 'event-item') ) : null }
                              </tbody>
                      </table>
                </div>
                { ( typeof this.state.events !== "undefined" &&  this.state.events.length > 0 && Array.isArray( this.state.events ) ) ? this.state.events.map( (record, index) =>
                  this.show_hide_button_container( record, index, 'event-item' ) ) : ""
                }
              </div>
        </div>
      )

  }
}
