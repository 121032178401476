import React from 'react';
import Popover, { ArrowContainer } from 'react-tiny-popover';
import HeaderBar from '../header-bar/header-bar';
import NavigationMenu from '../sidebar/navigation-menu';
import Footer from "../containers/footer";
import Select from 'react-select';
import { DatePicker } from 'antd'; import locale from 'antd/es/date-picker/locale/pt_BR';

import UsersService from '../../services/UsersService';
import LawsuitService from '../../services/LawsuitService';
import EventService from '../../services/EventService';
import LawsuitPersonagesService from '../../services/LawsuitPersonagesService';

import {isTablet} from '../config/mobileDetect';

import moment from "moment";

import 'moment/locale/pt-br';

moment.locale('pt-BR');

const statusList = [
  {value: '1', label: 'Em andamento', color: '#00875A'},
  {value: '2', label: 'Realizado', color: '#0052CC'},
  {value: '3', label: 'Cancelado', color: '#FF5630'},

]

const prioridade = [
  { value: '1', label: 'Baixa' },
  { value: '2', label: 'Média' },
  { value: '3', label: 'Alta' },
];

const customStyles = {
  clearIndicator: (provided, state) => {
    const color = 'red';

    return { ...provided, color };
  },
}

export default class ReportSchedules extends React.Component { _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }


  constructor(props) {
    super(props);

    this.doReport = this.doReport.bind(this);

    const eventList = [
      { value: '1', label: 'Compromisso' },
      { value: '2', label: 'Tarefa' },
      { value: '3', label: 'Notificação' },
    ];

    this.state = {
      eventList: eventList,
      sponsorsList: [],
      date_start: null,
      date_end: null,
      event: null,
      status: null,
      priority: null,
      user: null,
      category: null,
      sponsorLawsuit: null,
      client: null,
      lawsuit: null,
      errors: []
    }

    if(isTablet()){
      alert('Para melhor experiência do usuário esta tela não pode ser acessada por tablets e smartphones. Este acesso será possível somente atravéz de um computador. Você será redirecionado para o Dashboard');
      window.location.href = '/';
    }

  }

  componentDidMount( ) { this._isMounted = true;

    this.usersService = new UsersService();
    this.lawsuitService = new LawsuitService();
    this.LawsuitPersonagesService = new LawsuitPersonagesService();
    this.EventService = new EventService();

    this.usersService.list( ).then(res =>{

      let options = res; let sponsor = [];

      let user = JSON.parse( localStorage.getItem( 'loggedUser' ) );

      options.unshift ( {value: -99, label: "Todos"} );

      this.setState({
        sponsorsList: options,
      });

    });

    this.lawsuitService.officeList().then(response =>{

      this.setState({
        lawsuitList: response
      });

    });

    let categoryList = [];

      this.EventService.categoryList( ).then(res =>{
            categoryList = res;

            //categoryList.unshift({value: -9999, label: "Não Definido"});
            document.body.style.cursor = "default";

            this.setState({
              categoryList: categoryList,
            });

      });

      this.LawsuitPersonagesService.list( ).then(res =>{
        this.setState({
          clientList: res,
        });

      });

  }

  selectEventChange = (selected, actionMeta) => {
    let pops = [];
    pops[ actionMeta.name ] = selected;

    if( actionMeta.name === "event" && selected !== null && selected.value === "2" ){

      document.getElementById("lawsuit-container").className += ' hide';
      document.getElementById("category-container").className += ' hide';
      document.getElementById("status-container").classList.remove("hide");
      document.getElementById("priority-container").classList.remove("hide");

    }else if( actionMeta.name === "event" && selected !== null && selected.value === "3" ){

      document.getElementById("lawsuit-container").className += ' hide';
      document.getElementById("status-container").className += ' hide';
      document.getElementById("priority-container").className += ' hide';
      document.getElementById("category-container").className += ' hide';

    }else{
      document.getElementById("clientContainer").classList.remove("hide");
      document.getElementById("responsibleContainer").classList.remove("hide");
      document.getElementById("lawsuit-container").classList.remove("hide");
      document.getElementById("status-container").classList.remove("hide");
      document.getElementById("priority-container").classList.remove("hide");
      document.getElementById("category-container").classList.remove("hide");
    }

      if ( typeof selected !== undefined && selected !== "" ) {

        this.setState( pops );

      }
  }

  selectChange = (selected, actionMeta) => {
    let pops = [];
    pops[ actionMeta.name ] = selected;

    if( actionMeta.name === "lawsuit" && selected !== null){
      document.getElementById("clientContainer").className += ' hide';
      document.getElementById("responsibleContainer").className += ' hide';
      pops['client'] = null;
    }else if( actionMeta.name === "client" ){

      pops['lawsuit'] = null;
      document.getElementById("clientContainer").classList.remove("hide");
      document.getElementById("responsibleContainer").classList.remove("hide");

    }else{
      document.getElementById("clientContainer").classList.remove("hide");
      document.getElementById("responsibleContainer").classList.remove("hide");
    }

      if ( typeof selected !== undefined && selected !== "" ) {

        this.setState( pops );

      }
  }

  onDatePickerChange = (date, dateString, name) => {

    if (dateString === "" ){
      dateString = null;
    }

    if( name === 'date_start'){
      this.setState(
          {
              'date_end': dateString,
          }
      )
    }

     this.setState(
         {
             [name]: dateString,
         }
     )

   }

   disabledEndDate = endValue => {
     const startValue = moment(this.state.date_start, "DD/MM/YYYY");
     if (!endValue || !startValue) {
       return false;
     }
     return endValue.valueOf() < startValue.valueOf();
   }

   validateForm = () => {
     let errors = {};
     let formIsValid = true;

     if ( this.state.date_start === 0 || this.state.date_start === "" || typeof this.state.date_start === "undefined" || this.state.date_start === null ) {
       formIsValid = false;
       errors["date_start"] = "*Preencha a data";
     }
     this.setState({
        errors: errors
      });

     return formIsValid;
   }

   doReport = () => {

     if (this.validateForm()) {
       let date_start = (this.state.date_start === null) ? 0 : this.state.date_start.replace(/\//g,'-');
       let date_end = (this.state.date_end === null) ? 0 : this.state.date_end.replace(/\//g,'-');

       let event = (this.state.event===null)? 0 : this.state.event.value;
       let status = (this.state.status===null)? 0 : this.state.status.value;
       let priority = (this.state.priority===null)? 0 : this.state.priority.value;
       let user = (this.state.user===null)? 0 : this.state.user.value;
       let category = (this.state.category===null)? 0 : this.state.category.value;
       let sponsorLawsuit = (this.state.sponsorLawsuit===null)? 0 : this.state.sponsorLawsuit.value;
       let client = (this.state.client===null)? 0 : this.state.client.value;
       let lawsuit = (this.state.lawsuit===null)? 0 : this.state.lawsuit.value;

       this.props.history.push( '/relatorio/agenda/listar/'+event+'/'+status+'/'+
       priority+'/'+user+'/'+category+'/'+sponsorLawsuit+'/'+client+'/'+lawsuit+'/'+date_start+'/'+
       date_end);
     }

   }

  render() {

      let date_end_disabled = true;
      let url_base = localStorage.getItem('url_base');

      if( this.state.date_start !== null ){
        date_end_disabled = false;
      }

      return <div className="report report-event  sheet">
                <HeaderBar />
                <NavigationMenu />
                <div className="content-wrapper">
                  <div className="content-wrapper-inner">
                      <div className="panel panel-primary">
                            <div className="panel-heading text-center">
                                  <h4>Relatório das Agendas</h4>
                            </div>
                      </div>
                      <div className="box-body">
                          <div className="col-md-6">
                              <h5>
                                    Filtro da Agenda
                                    <Popover
                                        isOpen={this.state.isFilterPopoverOpen}
                                        position={['top', 'right', 'left', 'bottom']}
                                        padding={10}
                                        onClickOutside={() => this.setState({ isFilterPopoverOpen: false })}
                                        content={({ position, targetRect, popoverRect }) => (
                                            <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                                                position={position}
                                                targetRect={targetRect}
                                                popoverRect={popoverRect}
                                                arrowColor={'#94bcd8'}
                                                arrowSize={10}
                                            >
                                                <div className="popover-box"
                                                    onClick={() => this.setState({ isFilterPopoverOpen: !this.state.isFilterPopoverOpen })}
                                                >
                                                      Permite gerar relatórios de Compromissos, Tarefas e Norificações de todos os usuários (escritório) ou individualmente.
                                                </div>
                                            </ArrowContainer>
                                        )}
                                    >
                                        <img onMouseOut={() => this.setState({ isFilterPopoverOpen: false })}  onMouseOver={() => this.setState({ isFilterPopoverOpen: !this.state.isFilterPopoverOpen })} className="help_icon " alt="ajuda" src={url_base+"/images/interrogation_cropped.png"}></img>
                                    </Popover>
                              </h5>
                              <div className="form-group col-md-4 text-left">
                                  <label htmlFor="date_start" className="control-label">Data de Início</label>
                                  <DatePicker locale={locale} className=" " placeholder="Data Inicial" showToday={false} allowClear={false} format="DD/MM/YYYY" value={( this.state.date_start !== null ) ? moment(this.state.date_start, "DD/MM/YYYY") : null} onChange={(date, dateString) => this.onDatePickerChange(date, dateString, "date_start")} />
                                  <div className="errorMsg">{this.state.errors.date_start}</div>
                              </div>
                              <div className="form-group col-md-4 text-left">
                                  <label htmlFor="date_end" className="control-label">Até</label>
                                  <DatePicker locale={locale} className=" " disabled={date_end_disabled} placeholder="Data Limite" showToday={false} disabledDate={this.disabledEndDate} allowClear={false} format="DD/MM/YYYY" value={( this.state.date_end !== null ) ? moment(this.state.date_end, "DD/MM/YYYY") : null} onChange={(date, dateString) => this.onDatePickerChange(date, dateString, "date_end")} />
                              </div>
                              <div id="category-container" className="form-group col-md-4">
                                  <label htmlFor="category">Categoria</label>
                                  <Select
                                      className="category"
                                      options={this.state.categoryList}
                                      placeholder="Todos"
                                      name="category"
                                      styles={customStyles}
                                      isClearable={true}
                                      value={this.state.category}
                                      onChange={this.selectChange}
                                    />
                              </div>
                              <div className="form-group col-md-12">
                                  <label htmlFor="event">Evento</label>
                                  <Select
                                      className="event"
                                      placeholder="Todos"
                                      options={this.state.eventList}
                                      value={this.state.event}
                                      styles={customStyles}
                                      isClearable={true}
                                      name="event"
                                      onChange={this.selectEventChange}
                                    />
                              </div>
                              <div id="status-container" className="form-group col-md-6 ">
                                      <label htmlFor="status" className="control-label">Status</label>
                                      <Select
                                          placeholder="Todas"
                                          className="status"
                                          options={statusList}
                                          value={this.state.status}
                                          styles={customStyles}
                                          isClearable={true}
                                          name="status"
                                          onChange={this.selectChange}
                                        />
                              </div>
                              <div id="priority-container" className="form-group col-md-6">
                                  <label htmlFor="priority">Prioridade</label>
                                  <Select
                                      className="priority"
                                      defaultValue={prioridade[0]}
                                      options={prioridade}
                                      placeholder="Todos"
                                      styles={customStyles}
                                      isClearable={true}
                                      name="priority"
                                      value={this.state.priority}
                                      onChange={this.selectChange}
                                    />
                              </div>
                              <div id="users-container" className="form-group col-md-12">
                                  <label htmlFor="user">Usuários</label>
                                  <Select
                                      className="sponsor"
                                      options={this.state.sponsorsList}
                                      placeholder="Todos"
                                      styles={customStyles}
                                      isClearable={true}
                                      value={this.state.user}
                                      name="user"
                                      onChange={this.selectChange}
                                    />
                              </div>
                          </div>
                          <div id="lawsuit-container" className="col-md-6">
                              <h5>
                                  Filtro por Processo
                                  <Popover
                                      isOpen={this.state.isFilterLawsuitPopoverOpen}
                                      position={['top', 'right', 'left', 'bottom']}
                                      padding={10}
                                      onClickOutside={() => this.setState({ isFilterLawsuitPopoverOpen: false })}
                                      content={({ position, targetRect, popoverRect }) => (
                                          <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                                              position={position}
                                              targetRect={targetRect}
                                              popoverRect={popoverRect}
                                              arrowColor={'#94bcd8'}
                                              arrowSize={10}
                                          >
                                              <div className="popover-box"
                                                  onClick={() => this.setState({ isFilterLawsuitPopoverOpen: !this.state.isFilterLawsuitPopoverOpen })}
                                              >
                                                    Permite gerar Relatórios da agenda de um cliente com vários processos ou  de um processo específico.
                                              </div>
                                          </ArrowContainer>
                                      )}
                                  >
                                      <img onMouseOut={() => this.setState({ isFilterLawsuitPopoverOpen: false })}  onMouseOver={() => this.setState({ isFilterLawsuitPopoverOpen: !this.state.isFilterLawsuitPopoverOpen })} className="help_icon " alt="ajuda" src={url_base+"/images/interrogation_cropped.png"}></img>
                                  </Popover>
                              </h5>
                              <div id="clientContainer" className="form-group col-md-12">
                                  <label htmlFor="user">Cliente</label>
                                  <Select
                                      className="client"
                                      options={this.state.clientList}
                                      placeholder="Todos"
                                      styles={customStyles}
                                      isClearable={true}
                                      value={this.state.client}
                                      name="client"
                                      onChange={this.selectChange}
                                    />
                              </div>
                              <div className="form-group col-md-12">
                                  <label htmlFor="lawsuit">Nº do Processo</label>
                                  <Select
                                      className="lawsuit"
                                      options={this.state.lawsuitList}
                                      placeholder="Todos"
                                      styles={customStyles}
                                      isClearable={true}
                                      name="lawsuit"
                                      value={this.state.lawsuit}
                                      onChange={this.selectChange}
                                    />
                              </div>
                              <div id="responsibleContainer" className="form-group col-md-12">
                                  <label htmlFor="user">Responsável</label>
                                  <Select
                                      className="sponsor"
                                      options={this.state.sponsorsList}
                                      placeholder="Todos"
                                      styles={customStyles}
                                      isClearable={true}
                                      value={this.state.sponsorLawsuit}
                                      name="sponsorLawsuit"
                                      onChange={this.selectChange}
                                    />
                              </div>
                          </div>
                          <div className="col-md-12">
                                <span className="btn btn-primary form-submit view-report right" onClick={this.doReport}>Gerar Relatório</span>
                          </div>
                      </div>
                  </div>
                </div>
                <Footer />
             </div>
  }

}
