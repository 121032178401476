import decode from 'jwt-decode';
import {api} from '../config';
import LevelService from './LevelsService';
import FinanceService from './FinanceService';
import OfficeService from './OfficeService';
import UsersService from './UsersService';

import moment from "moment";
import 'moment/locale/pt-br';

moment.locale('pt-BR');

//import { browserHistory } from 'react-router';

export default class AuthService {
    // Initializing important variables
    constructor(domain) {
        this.domain = domain || api.url // API server domain
        this.fetch = this.fetch.bind(this) // React binding stuff
        this.login = this.login.bind(this)
        this.register = this.register.bind(this)
        this.getProfile = this.getProfile.bind(this)
    }

    can( action, check = false, others = false ){

          let user_permissions = null; let check_permission = false;

          if( localStorage.getItem('user_hard_permissions') === "undefined"){
            user_permissions = null;
          }else{
            user_permissions = JSON.parse(localStorage.getItem('user_hard_permissions')); //let check_permission = false;
          }

          if(user_permissions === null){ //console.log("tem que passar aqui");
                this.levelService = new LevelService();

                const loggedUser = JSON.parse(localStorage.getItem('loggedUser'));

                return this.levelService.permissionsHardList( loggedUser.token ).then(permissions =>{

                  if(permissions !== "undefined"){
                    localStorage.setItem('user_hard_permissions', JSON.stringify(permissions) );

                      //user_permissions = JSON.parse(localStorage.getItem('user_hard_permissions'));
                      return check_permission = this.check_permissions( action, check, others, permissions );

                  }else{
                    return false;
                  }

                });

          }else{
              //console.log(user_permissions);

              check_permission = this.check_permissions( action, check, others, user_permissions );
          }

          //console.log(check_permission+" "+action);

          return check_permission;

    }

    check_permissions( action, check = false, others = false, user_permissions ){
        let allowed = false;

        if( action === 'gerenciar_doc_facil' && typeof user_permissions.gerenciar_doc_facil !== "undefined" && user_permissions.gerenciar_doc_facil[0].allowed === 1 ){
          allowed = true;
        }else if( action === 'avisos_de_andamentos' && typeof user_permissions.avisos_de_andamentos !== "undefined" && user_permissions.avisos_de_andamentos[0].allowed === 1 ){
          allowed = true;
        }else if ( action === 'acesso_configuracoes' && typeof user_permissions.acesso_configuracoes !== "undefined"&& user_permissions.acesso_configuracoes[0].allowed === 1 ){
          allowed = true;
        }else if ( others === 'processos' ) {

            user_permissions.processos.map( (permissao)=>{
              if( permissao.keycode === action && permissao.allowed === 1 ) {
                allowed = true;
              }
              return permissao;
            });

        }else if ( others === 'ged' ) {

            user_permissions.ged.map( (permissao)=>{
              if( permissao.keycode === action && permissao.allowed === 1 ) {
                allowed = true;
              }
              return allowed;
            });

        }else if ( others === 'agenda' ) {

            user_permissions.agenda.map( (permissao)=>{
              if( permissao.keycode === action && permissao.allowed === 1 ) {
                allowed = true;
              }
              return allowed;
            });

        }else if ( others === 'financeiro' ) {

            user_permissions.financeiro.map( (permissao)=>{
              if( permissao.keycode === action && permissao.allowed === 1 ) {
                allowed = true;
              }
              return allowed;
            });

        }else if ( others === 'publicacoes' ) {

          user_permissions.publicacoes.map( (permissao)=>{
            if( permissao.keycode === action && permissao.allowed === 1 ) {
              allowed = true;
            }
            return allowed;
          });

      }else{
          allowed = false;
        }

        if(check === true){

                if(allowed){
                  return true;
                }else{
                  return false;
                }

        }else{

                if(!allowed){
                  localStorage.setItem('alert_message', 'Você não tem permissão para acessar esta página');
                  localStorage.setItem('alert_type', 'alert-danger');
                  let time = new Date().getTime();
                  localStorage.setItem('alert_time', time );

                  let url_base = localStorage.getItem('url_base');

                  window.location.href = url_base;
                  return false;
                }else{
                  return true;
                }
        }

    }

    get_permissions(){
      this.levelService = new LevelService();

      const loggedUser = JSON.parse(localStorage.getItem('loggedUser'));

      return this.levelService.permissionsHardList( loggedUser.token ).then(permissions =>{

        localStorage.setItem('user_hard_permissions', JSON.stringify(permissions) );

        return Promise.resolve("success");

        //return this.setConfigs();

      });
    }

    setConfigs(){
      this.FinanceService = new FinanceService();
      this.OfficeService = new OfficeService();

      return this.FinanceService.financialTypes(  ).then( fTypes =>{

        let credito_token = "";
        let debito_token = "";

        fTypes.map( (record, index ) => {
          //console.log(record.name);
            if(record.name === "Crédito"){
              credito_token = record.token;
              return record;
            }
            if(record.name === "Débito"){
              debito_token = record.token;
              return record;
            }
            return record;
        });

        localStorage.setItem('credito_token', credito_token );
        localStorage.setItem('debito_token', debito_token );

        return  this.OfficeService.getPlan( ).then( plan =>{
          let user = JSON.parse( localStorage.getItem('loggedUser') );

          console.log(user);
  
          let now = moment(new Date()); //todays date
          let end = moment(plan.data_expiracao); // another date
          let duration = moment.duration(end.diff(now));
          let days = duration.asDays();
          //console.log(plan.data_expiracao);
          //console.log(days);
  
          if( days <= 1 ){
              user.final_teste = 1;
          }else{
              user.final_teste = 0;
          }
  
          localStorage.setItem('loggedUser', JSON.stringify(user) );

          console.log(user.admin);
          console.log(plan.teste_gratis);
  
          if( user.admin && plan.teste_gratis === 0 ){
  
            return this.OfficeService.getConfigs( user.office_token ).then( office  =>{
              
                        if( 
                            (office.cep === null || office.cep === "") ||
                            (office.city_id === null || office.cep === "") ||
                            (office.number === null || office.number === "") ||
                            (office.neighborhood === null || office.neighborhood === "") ||
                            (office.phone === null || office.phone === "") ||
                            (office.state_id === null || office.state_id === "") ||
                            (office.street === null || office.street === "") 
                        ){
                            //se um desses campos estão vazios - manda o cara pra tela meus dados e obriga ele a cadastrar.
                            window.location.href = '/config/escritorio?preencher_info';
  
                        }else {
                            return Promise.resolve("success");
                        }
            });
  
          }else{
            return Promise.resolve("success");
          }
  
        });

      });

    }

    forgotPassword( email ) {
        // Get a token from api server using the fetch api
        return this.fetch(`${this.domain}Users/forgotPassword`, {
            method: 'POST',
            body: JSON.stringify({
                email,
            })
        }).then(res => {
            return Promise.resolve(res);
        })
    }

    forgotPasswordConfirm( token ) {
        // Get a token from api server using the fetch api
        return this.fetch(`${this.domain}Users/forgotPasswordConfirm/${token}`, {
            method: 'GET',
        }).then(res => {
            return Promise.resolve(res);
        })
    }

    changePassword(password, password_confirm, jwt) {

      let url = `${this.domain}Users/changePassword`;

      let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + jwt
      };

      let options = {
        method: 'POST',
        body: JSON.stringify({
            password,
            password_confirm
        })
      };

      return fetch(url, {
          headers,
          ...options
      })
          .then(this._checkStatus)
          .then(response => response.json())
    }

    login(email, password) {
        this.usersService = new UsersService();
        // Get a token from api server using the fetch api
        return this.fetch(`${this.domain}Users/login`, {
            method: 'POST',
            body: JSON.stringify({
                email,
                password
            })
        }).then(res => {

            //console.log(res);

            if(res.success){
              let user = {
                name: res.data.name,
                email: res.data.email,
                token: res.data.token,
                office_token: res.data.office_token,
              }

              this.setToken(res.data.jwt) // Setting the token in localStorage
              
              return this.usersService.view( user.token ).then(response =>{
                console.log(response);
                if(response !== null){
                  user.admin = response.admin;
                  localStorage.setItem( 'loggedUser', JSON.stringify(user) );
                  return this.get_permissions();
                }else{
                  alert("Você não tem permissão para acessar o sistema neste momento.");
                  this.logout();
                }
              });

            }else{
              alert(res.message);
              return Promise.resolve("error");
            }

        })
    }

    register( name, email, password, city, uf ) {

        return this.fetch(`${this.domain}Users/add`, {
            method: 'POST',
            body: JSON.stringify({
                name,
                email,
                password,
                city,
                uf
            })
        }).then(res => {
            let user = {
              name: res.data.name,
              email: res.data.email,
              token: res.data.token,
            }
            localStorage.setItem( 'loggedUser', JSON.stringify(user) );
            this.setToken(res.data.jwt) // Setting the token in localStorage
            return Promise.resolve(res);
        })

    }

    loggedIn() {
        // Checks if there is a saved token and it's still valid
        const token = this.getToken() // GEtting token from localstorage
        return !!token && !this.isTokenExpired(token) // handwaiving here
    }

    isTokenExpired(token) {
        try {
            const decoded = decode(token);
            if (decoded.exp < Date.now() / 1000) { // Checking if token is expired. N
                return true;
            }
            else
                return false;
        }
        catch (err) {
            return false;
        }
    }

    setToken(idToken) {
        // Saves user token to localStorage
        localStorage.setItem('id_token', idToken)
    }

    getToken() {
        // Retrieves the user token from localStorage
        return localStorage.getItem('id_token')
    }

    logout() {
        // Clear user token and profile data from localStorage
        localStorage.removeItem('id_token');
        localStorage.removeItem('loggedUser');
    }

    getProfile() {
        // Using jwt-decode npm package to decode the token
        return decode(this.getToken());
    }


    fetch(url, options) {
        // performs api calls sending the required authentication headers
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }

        // Setting Authorization header
        // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
        if (this.loggedIn()) {
            headers['Authorization'] = 'Bearer ' + this.getToken()
        }

        return fetch(url, {
            headers,
            ...options
        })
            .then(this._checkStatus)
            .then(response => response.json())
    }

    _checkStatus(response) {
        // raises an error in case response status is not a success
        if (response.status >= 200 && response.status < 300) { // Success status lies between 200 to 300
            return response
        } else if( response.status === 401 || response.status === "401" ) {
          console.log("Tentando trapacear? Você será desligado");


          localStorage.removeItem('id_token');
          localStorage.removeItem('loggedUser');
          window.location.href = '/login';

        }else {
            var error = new Error(response.statusText)
            error.response = response
            throw error
        }
    }
}
