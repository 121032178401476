import React from 'react';
import PublicRoutes from "./router";
import './App.css';

//localStorage.setItem('url_base', "http://173.249.28.254/avvocato_html/");
localStorage.setItem('url_base', "https://app.avvocatosistemas.com.br");
//localStorage.setItem('url_base', "http://localhost:3000");

const App = () => (
            <PublicRoutes history={"hello"} />
);

export default App;
