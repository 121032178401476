import AuthService from './AuthService';
import {api} from '../config';

import moment from "moment";
import 'moment/locale/pt-br';

export default class AfiliadosService {
    // Initializing important variables
    constructor(domain) {
        this.domain = domain || api.url // API server domain
        this.fetch = this.fetch.bind(this) // React binding stuff
    }

    listComissoes( start, end ){
        let endpoint = ""; let sort = '&sort=data_vencimento&direction=ASC';
        start = start.substr(0, 10).split('/').reverse().join('-');
        end = end.substr(0, 10).split('/').reverse().join('-');

        endpoint = `${this.domain}Financial/?financial_type_id=1&start=${start}&end=${end+sort}`;

        return this.fetch( endpoint, {
            method: 'GET',
        }).then(res => {
            //console.log(res);
            if( res.success === true ){
                return Promise.resolve(res.data.Financials);
            }

            return Promise.resolve(res);
        });
    }

    generateUUID() { // Public Domain/MIT
          var d = new Date().getTime();//Timestamp
          var d2 = (performance && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
          return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
              var r = Math.random() * 16;//random number between 0 and 16
              if(d > 0){//Use timestamp until depleted
                  r = (d + r)%16 | 0;
                  d = Math.floor(d/16);
              } else {//Use microseconds since page-load if supported
                  r = (d2 + r)%16 | 0;
                  d2 = Math.floor(d2/16);
              }
              return (c === 'x' ? r : (r & (0x3 | 0x8) )).toString(16);
          });
    }

    fetch(url, options) {
          let auth = new AuthService();

          options.headers = {
             'Authorization': 'Bearer ' + auth.getToken(),
             'Content-Type': 'application/json',
             'Accept': 'application/json'
           }

          return fetch(url, options)
                  .then(this._checkStatus)
                  .then(response => {

                            if(typeof response !== "undefined"){

                              return Promise.resolve( response.text().then(function(result){
                                  //console.log(result);
                                  let old_result = result;
                                  result = result.split('{"success"');
                                  if( result.length > 1 ){
                                      result = result[1].split('<pre');
                                      //console.log(result[0]);
                                      let json = '{"success"'+result[0];
                                      result = JSON.parse(json);
                                      //console.log(result);
                                      return result;
                                  }else{
                                      return old_result;
                                  }

                              }) );

                          }else{

                              let result = {
                                  success: false,
                                  message: "Erro desconhecido"
                              }

                              return Promise.resolve( result );

                          }

                  })
    }

    _checkStatus(response) {
        // raises an error in case response status is not a success
        if (response.status >= 200 && response.status < 300) { // Success status lies between 200 to 300
            return response
        } else if( response.status === 401 || response.status === "401" ) {
          console.log("Tentando trapacear? Você será desligado");


          localStorage.removeItem('id_token');
          localStorage.removeItem('loggedUser');
          window.location.href = '/login';

        } else if( response.status === 402 || response.status === "402" ) {
        
          window.location.href = '/config/mudar-plano?plano_vencido';
  
        }else {
            var error = new Error(response.statusText)
            error.response = response
            throw error
        }
    }

    /*fetch(url, options) {
        // performs api calls sending the required authentication headers
        let auth = new AuthService();

        options.headers = {
           'Authorization': 'Bearer ' + auth.getToken(),
           'Content-Type': 'application/json',
           'Accept': 'application/json'
         }

        return fetch(url, options)
            .then(this._checkStatus)
            .then(response => response.json())
    }*/
}
