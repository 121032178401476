import React from 'react';
import IntlCurrencyInput from "react-intl-currency-input";
import HeaderBar from './header-bar/header-bar';
import NavigationMenu from './sidebar/navigation-menu';
import { DatePicker } from 'antd'; 
import AuthService from '../services/AuthService';
import AfiliadosService from '../services/AfiliadosService';

import 'antd/dist/antd.css';
import { Tabs } from 'antd';

import Select from 'react-select';

import Footer from "./containers/footer";
import FlashMessage from "./containers/FlashMessage";
import MyPopover from './containers/mypopover';

import Loading from './containers/loading';
import Alert from './containers/alert';
import Confirm from './containers/confirm';

import moment from "moment";
//import ptBR from 'antd/lib/locale-provider/pt_BR';
import 'moment/locale/pt-br';
import locale from 'antd/es/date-picker/locale/pt_BR';

moment.locale('pt-BR');

const TabPane = Tabs.TabPane;

const { RangePicker, MonthPicker } = DatePicker;

const customStyles = {
  clearIndicator: (provided, state) => {
    const color = 'red';

    return { ...provided, color };
  }
}

const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
}

const statusList = [
  "Saque em Revisão",
  "Saque Concluído"
];

export default class Afiliados extends React.Component { 
    _isMounted = false;

    componentWillUnmount() {
      this._isMounted = false;
    }

    componentDidMount( ) { 
          this._isMounted = true;

          document.addEventListener("confirmDialogConfirm", (e) => {
            if( this._isMounted ){
              this.setState({
                showConfirm: false,
              });
      
              if( this.state.action === "deleteSaque" ){
                  this.deleteSaque(this.state.saqueToDelete, true);
              }
            }
          });
    }

    constructor( { match } ) {
        super();

        this.authService = new AuthService();

        this.solicitarSaque = this.solicitarSaque.bind(this);
        this.deleteSaque = this.deleteSaque.bind(this);
        this.moeda = this.moeda.bind(this);


        this.state = {

            date_start: moment().startOf('month').format('DD/MM/YYYY'),
            date_end: moment().endOf('month').format('DD/MM/YYYY'),
            errors: {},
            saqueToDelete: false,
            afiliadosList: [
              {
                name: "João Augusto Gomes Barbosa",
                email: "joao.augusto@gmail.com",
                valor: 99.00,
                status: "teste",
                created: "2021-03-20",
                token: 1234
              },
              {
                name: "Rony Barbosa Dias",
                email: "rony.barbosa@gmail.com",
                valor: 99.00,
                status: "cancelado",
                created: "2021-02-20",
                token: 12345
              },
              {
                name: "Kelly Rodrigues Santos da Silva",
                email: "kelly.rodrigues@gmail.com",
                valor: 99.00,
                status: "ativo",
                created: "2021-10-15",
                token: 123456
              },
            ],
            updated: "",
            loadingVisible: false,
            loadingMessage: [],
            cashflow: {
              saldo: 50.00,
              lancamentos: [
                {
                  mes_referencia: "04/2021",
                  n_indicados: 33,
                  indicados: [
                    {name: "Kelly Rodrigues Santos da Silva"},
                    {name: "Rony Barbosa Dias"},
                    {name: "João Augusto Gomes Barbosa"},
                    {name: "Gilmar Mendes"},
                    {name: "Bruno Mendes"},
                    {name: "Diego Mendes Reis"},
                    {name: "José Silva"},
                    {name: "Carla Santos"},
                    {name: "Beatriz Lima"},
                    {name: "Eduardo Saverin"},
                    {name: "João Wilde Carlos"},
                    {name: "Alexandre Fonseca"},
                    {name: "Sérgio Benedito"},
                    {name: "Bento Costa"},
                    {name: "Francisca Paula de Jesus"},
                    {name: "Shirley Costa"},
                    {name: "Agnaldo Dias"},
                    {name: "Anderson Ferreira"},
                    {name: "Paulo Martins"},
                    {name: "Rodrigo Santos"},
                    {name: "Sérgio Alves"},
                  ],
                  tipo: "credito",
                  valor: 330.00,
                  token: 334089847
                },
                {
                  mes_referencia: "03/2021",
                  n_indicados: 45,
                  indicados: [
                    {name: "Kelly Rodrigues Santos da Silva"},
                    {name: "Rony Barbosa Dias"},
                    {name: "João Augusto Gomes Barbosa"},
                  ],
                  tipo: "credito",
                  valor: 450.00,
                  token: 3340898478
                },
                {
                  mes_referencia: "02/2021",
                  n_indicados: 41,
                  indicados: [
                    {name: "Kelly Rodrigues Santos da Silva"},
                    {name: "Rony Barbosa Dias"},
                    {name: "João Augusto Gomes Barbosa"},
                  ],
                  tipo: "credito",
                  valor: 410.50,
                  token: 3340898479
                },
                {
                  mes_referencia: "01/2021",
                  n_indicados: null,
                  indicados: [
                  ],
                  tipo: "debito",
                  valor: 50.00,
                  token: 1234089847,
                  status: 1,
                },
              ]
            },
            statusIndicadosList: [
              {value: 'todos', label: 'Todos'},
              {value: 'teste', label: 'Teste'},
              {value: 'cancelado', label: 'Cancelado'},
              {value: 'ativo', label: 'Ativo'}
            ],
            statusIndicados: {value: 'todos', label: 'Todos'},
            message: "",
            showAlert: false,
            showConfirm: false,
        }

    }

  componentWillReceiveProps(nextProps) {
  }

  componentWillMount( ) {

  }

  clear = ( event ) => {
    this.setState({
      date_start: moment().startOf('month').format('DD/MM/YYYY'),
      date_end: moment().endOf('month').format('DD/MM/YYYY'),
    });
}

  onDatePickerChange = (date, dateString, name) => {

    if (dateString === "" ){
      dateString = null;
    }

    console.log(dateString);

     this.setState(
         {
             [name]: dateString,
         }
     )

   }

   onMonthPickerChange = (date, dateString, name) => {

     this.setState(
         {
             [name]: date,
         }
     )

   }


   selectChange = (selected, actionMeta) => {
      let pops = [];
      pops[ actionMeta.name ] = selected;

      if ( typeof actionMeta.name !== undefined && actionMeta.name !== "" && actionMeta.name !== null ){

        if ( typeof selected !== undefined && selected !== "" ) {
          this.setState( pops );
        }

      }
    }

   disabledStartDate = startValue => {
     const endValue = moment(this.state.date_end, "DD/MM/YYYY");
     if (!startValue || !endValue) {
       return false;
     }
     return  startValue.valueOf() > endValue.valueOf()  ;
   };

   disabledEndDate = endValue => {
     const startValue = moment(this.state.date_start, "DD/MM/YYYY");
     if (!endValue || !startValue) {
       return false;
     }
     return endValue.valueOf() < startValue.valueOf() ;
   }

  moeda( event, value, maskedValue ) {
      event.preventDefault();

      this.setState(
          {
              [event.target.name]: maskedValue
          }
      )
  }

  formataMoeda( valor ){
    let total2 = valor*100;
    total2 = parseInt( total2 );
    var tmp = total2+'';
    total2 = tmp;
    total2 = total2.replace('-','');
    tmp = tmp.replace(/([0-9]{2})$/g, ",$1");
    if( total2.length > 5 ){
        valor = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
    }else{
      valor = valor.toFixed(2);
      valor = valor.replace('.',',');
    }
    return valor;
  }

  getComissoes(){
    this.AfiliadosService = new AfiliadosService();
    this.AfiliadosService.listComissoes( this.state.date_start, this.state.date_end ).then(res =>{
    });
  }

  deleteSaque( e, r = false ){
      if( r ){
        console.log(e);
        //método para deletar saque
        let cashflow = this.state.cashflow;
        cashflow.lancamentos.pop();

        this.setState({
          cashflow
        });

      }else{
        let evt = {
          target: {
            dataset: {
              saque_token: e.target.dataset.saque_token
            }
          }
        }
        this.setState({
          showConfirm: true,
          action: 'deleteSaque',
          saqueToDelete: evt,
          message: "Tem certeza de que deseja excluir o saque?"
        });
      }
  }

  solicitarSaque(){
    let errors = {};
    let valorSaque = this.state.valorSaque.replaceAll('R$ ', '').replaceAll(',', '.');
    //console.log(valorSaque);

    if( valorSaque > this.state.cashflow.saldo ){
      errors["valorSaque"] = "*O valor solicitado é maior que o saldo atual";
      this.setState(
        {
          errors: errors
        }
      );
      //console.log("maior");
    }else{
      //chama o service para inserir a nova retirada no endpoint e chama um novo service com a listagem da conta corrente
      let saldoAtual = this.state.cashflow.saldo - valorSaque;

      let cashflow = this.state.cashflow;
      cashflow.saldo = saldoAtual;

      cashflow.lancamentos.push({
        mes_referencia: moment().format("MM/YYYY"),
        n_indicados: null,
        indicados: [
        ],
        tipo: "debito",
        valor: parseFloat(valorSaque),
        token: Math.random()*1000000,
        status: 0,
      }, );

      localStorage.setItem('alert_message', "Saque solicitado com sucesso");
      localStorage.setItem('alert_type', 'alert-success');
      let time = new Date().getTime();
      localStorage.setItem('alert_time', time );

      document.getElementsByClassName("modal-backdrop")[0].style.display = "none";
      document.getElementById("saqueModal").style.display = "none";
      document.getElementById("saqueModal").classList.remove('in');

      this.setState(
        {
          cashflow
        }
      );
    }

  }

  indicadosListText( indicados ){
    let indicadosText = "";

    indicados.map( (object)=>{
      indicadosText += object.name+"\n";
    });

    return indicadosText;
  }

  afiliadosItem = ( {record, index}, type ) => {

    console.log(record);

      return (
        <tr key={record.token} className={type}>
              <td className="table_lawsuit_number">
                    {record.name}
              </td>
              <td className="table_lawsuit_number">
                {record.email}
              </td>
              <td className="table_lawsuit_cliente " >
                  R$ { this.formataMoeda(record.valor) }
              </td>
              <td className="table_lawsuit_oponente " >
                      { record.status }
              </td>
              <td className="">
                      { record.created.substr(0, 10).split('-').reverse().join('/') }
              </td>
        </tr>


      );
  };

  comissoesItem = ( {record, index}, type ) => {

    console.log(record);
    let valor  = this.formataMoeda(record.valor);
    let indicados = <div>
          <span>{record.n_indicados}</span>
          <MyPopover icon="white" text={this.indicadosListText(record.indicados)} />
    </div>;
    let debito_container = null;
    if( record.status === 0 ){
      debito_container = <i className={ "fa fa-ban align-text-center" } data-saque_token={record.token} onClick={ this.deleteSaque.bind(this)  }></i>;
    }
      return (
        <tr key={record.token} className={type}>
              <td className="">
                    {record.mes_referencia}
              </td>
              <td>
                  {(record.tipo === "credito") ?  indicados : null}
              </td>
              <td className="debito">
                      {(record.tipo === "debito") ? valor: null}
              </td>
              <td className="credit "  >
                      {(record.tipo === "credito") ? valor : null}
              </td>
              <td className=""  >
                      {(record.tipo === "debito") ? statusList[record.status]: null}
              </td>
              <td className=""  >
                      {(record.tipo === "debito") ? debito_container : null}
              </td>
        </tr>


      );
  };

  render() {

      let url_base = localStorage.getItem('url_base');

      let saldo_periodo = 0;

      this.state.cashflow.lancamentos.map( (lancamento)=>{
          if( lancamento.tipo == "credito" ){
            saldo_periodo += lancamento.valor;
          }
          if( lancamento.tipo == "debito" ){
            saldo_periodo -= lancamento.valor;
          }
      });

      return <div className="afiliados sheet">
                <FlashMessage time={ new Date().getTime() } />
                <Confirm show={this.state.showConfirm} text={this.state.message} />
                <Alert show={this.state.showAlert} text={this.state.message} />
                <Loading visible={this.state.loadingVisible} message1={this.state.loadingMessage[0]} message2={this.state.loadingMessage[1]} message3={this.state.loadingMessage[2]} cancel={true}   />
                <HeaderBar />
                <NavigationMenu />
                <div className="content-wrapper">
                  <div className="content-wrapper-inner">
                      <section className="content-header">
                        <h1 className="page-header">Afiliados</h1>
                      </section>
                      <div className="box-body">
                        <label>Meu Código de Indicação: </label> 
                        <div className="input-icons"> 
                            <span className="hashtag-icon"> 
                            #
                          </span> 
                          <input className="input-lg input-field" readOnly={true} name="codigo_afiliado" value="FULANODETAL10" />
                        </div> 
                        <Tabs defaultActiveKey="1" size="large">
                              <TabPane tab={<span>Meus Indicados </span>} key="1">
                                <div className="row filter">
                                      <div className="form-group date text-left">
                                          <label htmlFor="date_start" className="control-label">Data de Cadastro de</label>
                                          <DatePicker locale={locale} className="" disabledDate={this.disabledStartDate} placeholder="De" showToday={false} allowClear={false} format="DD/MM/YYYY" value={( this.state.date_start !== null ) ? moment(this.state.date_start, "DD/MM/YYYY") : null} onChange={(date, dateString) => this.onDatePickerChange(date, dateString, "date_start")} />
                                      </div>
                                      <div className="form-group date text-left">
                                          <label htmlFor="date_end" className="control-label">Data de Cadastro até</label>
                                          <DatePicker locale={locale} className="" disabledDate={this.disabledEndDate} placeholder="Até" showToday={false} allowClear={false} format="DD/MM/YYYY" value={( this.state.date_end !== null ) ? moment(this.state.date_end, "DD/MM/YYYY") : null} onChange={(date, dateString) => this.onDatePickerChange(date, dateString, "date_end")} />
                                      </div>
                                      <div className="form-group date text-left">
                                          <label htmlFor="statusIndicados" className="control-label">Status:</label>
                                          <Select
                                            className="statusIndicados"
                                            options={this.state.statusIndicadosList}
                                            placeholder="Status"
                                            value={this.state.statusIndicados}
                                            name="statusIndicados"
                                            isClearable={true}
                                            styles={customStyles}
                                            onChange={this.selectChange}
                                          />
                                      </div>
                                      <div className="container-search">
                                          <span className="btn btn-primary" onClick={ ( event ) => { this.getComissoes() } }>Filtrar</span>
                                          <span className="btn btn-danger" data-type={this.state.type} onClick={ ( event ) => { this.clear(event) } } >Limpar</span>
                                      </div>
                                </div>
                                <div className="table-responsive">
                                  <table className="table">
                                          <thead>
                                              <tr>
                                                    <th className="table_lawsuit_number">
                                                        <div>Nome do Indicado</div>
                                                    </th>
                                                    <th className="table_lawsuit_number">
                                                        <div>E-mail</div>
                                                    </th>
                                                    <th className="">
                                                        <div>Valor do Plano</div>
                                                    </th>
                                                    <th className="">
                                                        <div>Status</div>
                                                    </th>
                                                    <th className="">
                                                        <div>Cadastrado em</div>
                                                    </th>
                                              </tr>
                                          </thead>
                                          <tbody>
                                                { ( typeof this.state.afiliadosList !== "undefined") ? this.state.afiliadosList.map( (record, index) => this.afiliadosItem( {record, index}, 'indicados-item' ) ) : null }
                                          </tbody>
                                  </table>
                                </div>
                              </TabPane>
                              <TabPane tab={<span>Conta Corrente</span>} key="2">
                              <span className="btn btn-primary" data-toggle="modal" data-target="#saqueModal">Nova Retirada</span>
                                <div className="row filter">
                                      <div className="form-group date text-left">
                                          <label htmlFor="date_start" className="control-label">De</label>
                                          <MonthPicker 
                                            locale={locale} 
                                            className="" 
                                            picker="month" 
                                            format="MM/YYYY" 
                                            value={ (this.state.month_start !== null ) ? moment(this.state.month_start) : null } 
                                            onChange={(date, dateString) => this.onMonthPickerChange(date, dateString, "month_start")}
                                          />
                                      </div>
                                      <div className="form-group date text-left">
                                          <label htmlFor="date_end" className="control-label">Até</label>
                                          <MonthPicker 
                                            locale={locale} 
                                            className="" 
                                            picker="month" 
                                            format="MM/YYYY" 
                                            value={ (this.state.month_end !== null ) ? moment(this.state.month_end) : null } 
                                            onChange={(date, dateString) => this.onMonthPickerChange(date, dateString, "month_end")}
                                          />
                                      </div>
                                      <div className="container-search">
                                          <span className="btn btn-primary" onClick={ ( event ) => { this.getComissoes() } }>Filtrar</span>
                                          <span className="btn btn-danger" data-type={this.state.type} onClick={ ( event ) => { this.clear(event) } } >Limpar</span>
                                      </div>
                                </div>
                                <div className="resultado-saldo">
                                      <div className="resultado-acumulado col-sm-6 col-md-6">
                                      Saldo Atual = <b className={(this.state.cashflow.saldo > 0) ? "credit" : "debito"}>R$ {this.formataMoeda(this.state.cashflow.saldo)}</b>
                                      </div>
                                      <div className="resultado-periodo col-sm-6 col-md-6">
                                      Saldo do Período = <b className={(saldo_periodo > 0) ? "credit" : "debito"}>R$ {this.formataMoeda(saldo_periodo)}</b>
                                      </div>
                                </div>
                                <br />
                                <div className="table-responsive">
                                  <table className="highlight-table table" >
                                          <thead>
                                              <tr>
                                                    <th className="table_lawsuit_number">
                                                        <div>Mês de Referência</div>
                                                    </th>
                                                    <th className="">
                                                        <div>Nº de Indicados</div>
                                                    </th>
                                                    <th className="table_debito">
                                                        <div>Débito (R$)</div>
                                                    </th>
                                                    <th className="table_credit">
                                                        <div>Crédito (R$)<MyPopover icon="white" text="Total apurado do 1º ao último dia do mês" /></div>                                                        
                                                        
                                                    </th>
                                                    <th className="">
                                                        <div>Status</div>
                                                    </th>
                                                    <th className="">
                                                        <div>Cancelar Retirada</div>
                                                    </th>
                                              </tr>
                                          </thead>
                                          <tbody>
                                                { ( typeof this.state.cashflow.lancamentos !== "undefined") ? this.state.cashflow.lancamentos.map( (record, index) => this.comissoesItem( {record, index}, 'comissoes-item' ) ) : null }
                                          </tbody>
                                  </table>
                                </div>
                              </TabPane>
                        </Tabs>
                      </div>

                  <div className="modal fade" id="saqueModal" tabIndex="-1" role="dialog" aria-labelledby="saqueModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="saqueModalLabel">Solicitar Retirada</h5>
                        </div>
                        <div className="modal-body">
                          <form>
                            <div className="form-group">
                              <label htmlFor="valorSaque" className="col-form-label col-md-12 col-xs-12">Valor do Saque: </label>
                              <IntlCurrencyInput className="form-control" currency="BRL" config={currencyConfig} valor={this.state.valorSaque} onChange={this.moeda} className="form-control" id="valorSaque" name="valorSaque" />
                              <span className="btn btn-primary" id="solicitar-saque" onClick={ ( event ) => { this.solicitarSaque() } } >Solicitar</span>
                              <div className="errorMsg">{this.state.errors.valorSaque}</div>
                            </div>
                          </form>
                        </div>
                        <div className="modal-footer">
                          <button type="button" className="btn btn-danger" data-dismiss="modal">Sair</button>
                        </div>
                      </div>
                    </div>
                  </div>

                  </div>
                </div>
                <Footer />
             </div>
  }

}
