import React from 'react';
import { Link } from 'react-router-dom';

import eDocsService from '../../../services/eDocsService'

export default class ListModels extends React.Component { _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }


  constructor( props ){
    super( props );
    this.emptyModel = this.props.emptyModel.bind(this);

    this.state = {
        models: [],
    }
  }

 componentDidMount( ) { this._isMounted = true;
   this.eDocsService = new eDocsService();

   this.eDocsService.templateList( this.props.tipo_token ).then(response =>{
      console.log(response);
      this.setState({
        models: response,
      });
      if( response.length < 1){
        this.emptyModel( this.props.tipo_token );
      }

   });
 }

 modelPanel = ( {record, index}, type ) => {
     return (
       <div key={record.value} className={ (record.content === null) ? "panel panel-info hide" : "panel panel-info" }>
             <div className="panel-heading" role="tab" id={"accordion_parte"+record.value}>
                  <Link to={'/modelo/visualizar/'+record.value}>{record.label}</Link>
             </div>
       </div>
     );
 };


  render() {

      return <div className="">
                  {this.state.models.map( (record, index) => this.modelPanel({ record, index }, 'area-item') )}
             </div>
  }

}
