import React from 'react';
import Chart from "react-google-charts";

import AuthService from '../../services/AuthService';

export default class pubChart extends React.Component { 
  _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }


  constructor( props ) {

      super( props );

      this.authService = new AuthService();

      this.state = {
          count_nao_cadastrados: props.count_nao_cadastrados,
          count_hoje: props.count_hoje,
          total: props.total,
          label_title: props.label_title,
          label_value: props.label_value,
          color: props.color,
          title: props.title,
          class_title: props.class_title,
          height: props.height,
          naoCadastradosTitle: props.naoCadastradosTitle
      }

  }

  componentWillReceiveProps(nextProps) {

    //if ( nextProps.total !== this.props.total && this._isMounted ) {
      this.setState({
        count_nao_cadastrados: nextProps.count_nao_cadastrados,
        count_hoje: nextProps.count_hoje,
        total: nextProps.total,
        label_title: nextProps.label_title,
        label_value: nextProps.label_value,
        color: nextProps.color,
        title: nextProps.title,
        class_title: nextProps.class_title,
        height: nextProps.height,
        naoCadastradosTitle: nextProps.naoCadastradosTitle,
        chart_data: [
          [nextProps.label_title, nextProps.label_value],
          ["Hoje", 20],
          ["", 0],
          ["Futuros", 0],
        ],
      });
      //console.log( nextProps.count_hoje );
    //}

  }

  componentDidMount(){
    this._isMounted = true;
    console.log(this.state.count_hoje);
    this.setState({
      chart_data: [
        [this.state.label_title, this.state.label_value],
        ["Hoje", this.state.count_hoje],
        ["", 0],
        ["Futuros", 0],
      ],
      chart_options: {
          title: "",
          pieHole: 0.85,
          backgroundColor: {fill: this.state.color, stroke: this.state.color },
          pieSliceBorderColor : this.state.color,
          legend: 'none',
          is3D: false,
          pieSliceText: 'none',
          slices: {
            0: { color: 'green' },
            1: { color: 'red' },
            2: { color: 'orange' }
          }
        }
    });
  }

  doReport = () => {
    if( this.props.url !== ''){
      this.props.history.push( this.props.url );
      //window.location.href =  localStorage.getItem('url_base')+this.props.url ;
    }
  }

  render() { //console.log(this.state.total);

      return (
              <div style={{background: this.state.color}} className={this.state.class_title+" donut-chart"} onClick={()=>{this.doReport()} }>
                    <div className="chart-box chart-box-pub">
                          <Chart
                              chartType="PieChart"
                              width="180px"
                              height={this.state.height+"px"}
                              data={this.state.chart_data}
                              options={this.state.chart_options}
                          />
                          <div className="chart" style={{top: this.props.top }}>
                                <span className="number green">{this.state.count_hoje}</span>
                                <span className="total">Recentes</span>
                          </div>

                    </div>
                    <div className="info"><span className="atrasados">{this.state.count_nao_cadastrados}</span>{this.state.naoCadastradosTitle}</div>
              </div>
      );

  }
}
