import React from 'react';
import MyPopover from '../containers/mypopover';
import { Switch } from 'antd';
import HeaderBar from '../header-bar/header-bar';
import NavigationMenu from '../sidebar/navigation-menu';
import Loading from '../containers/loading';
import Confirm from '../containers/confirm';
import FlashMessage from '../containers/FlashMessage';
import Footer from "../containers/footer";
import FormUser from "./containers/formUser";
import LevelSwitch from "./containers/levelSwitch";
import ActiveSwitch from "./containers/activeSwitch";
import { Link } from 'react-router-dom';
import UsersService from '../../services/UsersService';
import LevelService from '../../services/LevelsService';
import AuthService from '../../services/AuthService';

import moment from "moment";
import 'moment/locale/pt-br';

import {isTablet} from './mobileDetect';

moment.locale('pt-BR');

export default class ConfigUser extends React.Component { 
  _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }

  constructor(){
    super();

    this.modifiedFormUser = this.modifiedFormUser.bind(this);
    this.openEditUser = this.openEditUser.bind(this);
    this.deleteUser = this.deleteUser.bind(this);

    this.authService = new AuthService();

    this.authService.can('acesso_configuracoes');

    this.state = {
        users: [],
        token: "",
        loadingMessage: [],
        loadingVisible: false,
        showModal: false,
        showConfirm: false,
        userToDelete: false,
        message: "",
    }

    if(isTablet()){
      alert('Para melhor experiência do usuário esta tela não pode ser acessada por tablets e smartphones. Este acesso será possível somente atravéz de um computador. Você será redirecionado para o Dashboard');
      window.location.href = '/';
    }

  }

  componentDidMount( ) { 
      this._isMounted = true;

      document.addEventListener("cancelDialogConfirm", (e) => {
        if( this._isMounted ){
          this.setState({
            showConfirm: false,
            userToDelete: false
          });
        }
      });
  
      document.addEventListener("confirmDialogConfirm", (e) => {
        if( this._isMounted ){
          this.setState({
            showConfirm: false
          });
          if( this.state.userToDelete !== false){
            this.deleteUser(this.state.userToDelete, true);
          }
        }
      });

      document.addEventListener("cancelLoading", (e) => {
        this.setState({
          loadingVisible: false
        });
      });

      this.getUsers(  );

  }

  getUsers(){
        this.UsersService = new UsersService();

        this.UsersService.usersList().then(usersList =>{

              if (this._isMounted) {

                    this.levelService = new LevelService();

                    this.levelService.usersPermissionsList( this.state.user_token ).then(res =>{

                        if (this._isMounted) {

                            usersList.map( (user, i, users) => {

                              res.map( (perma, j, array) => {

                                if(perma.user.token === user.token){
                                  perma.user.last_login = ( user.last_login !== null ) ? moment(user.last_login).format("DD/MM/YYYY HH:mm") : "";
                                  perma.user.active = user.active;
                                  perma.user.admin = user.admin
                                }

                                return perma;

                              });

                              return user;

                            });

                            this.setState({
                              users: res,
                            });

                        }

                    });

              }

        });
  }

  deleteUser = ( event, r = false ) => {
    this.UsersService = new UsersService();

    //var r = window.confirm("Deseja realmente excluir o usuário? \n Esta ação é irreversível!");

    if ( r ){
      this.UsersService.delete( event.target.dataset.token )
          .then(res =>{

                if (this._isMounted) {
                      if( res.success === true ){

                        localStorage.setItem('alert_message', "Usuário deletado com sucesso");
                        localStorage.setItem('alert_type', 'alert-success');
                        let time = new Date().getTime();
                        localStorage.setItem('alert_time', time );
                        setTimeout(() => {
                          this.setState({
                            updated: time,
                          });
                        }, 100);
                        this.getUsers();

                      }else{
                        localStorage.setItem('alert_message', res.message);
                        localStorage.setItem('alert_type', 'alert-danger');
                        let time = new Date().getTime();
                        localStorage.setItem('alert_time', time );
                        setTimeout(() => {
                          this.setState({
                            updated: time,
                            userToDelete: false,
                          });
                        }, 100);
                      }

                }

          })
          .catch(err =>{
              document.body.style.cursor = "default";
              console.log(err);
          })
    }else{
      let evt = {
        target: {
          dataset: {
            token: event.target.dataset.token
          }
        }
      }
      this.setState({
        showConfirm: true,
        userToDelete: evt,
        message: "Deseja realmente excluir o usuário? \n Esta ação é irreversível!"
      });
    }
  }

  modifiedFormUser = ()=>{
    //console.log("lançamentos modificados");
    this.setState({
        showModal: false
    });
    this.getUsers();
  }

  openEditUser = ( event ) => { //console.log(event.target.dataset.token);
    this.setState({
        token: event.target.dataset.token,
        showModal: true
    });
  }

  userItem = ( {record, index}, type ) => {
       /*let category = "";*/ let user_admin = "";

       let access_permissions = null;
       let not_permission =    <span className="center" title="Este é um usuário admin. As permissões não podem ser modificadas" >
                                  <i className="material-icons disabled" >lock</i>
                               </span>;
      let have_permission = <Link className="pointer center" id="bt-edit-" to={"/config/permissoes/"+record.user.token}>
                               <i className="material-icons" >lock</i>
                            </Link>;
      let lixeira_enabled = <i className="fa fa-trash" data-token={record.user.token} onClick={ ( event ) => {this.deleteUser(event, false)} }></i>;
      let lixeira_disabled = <i className="fa fa-trash disabled" title="Este é um usuário admin. Não pode ser deletado"></i>;
      let lixeira = null;

      let editar_enabled = <i className="fa fa-pencil-square-o" data-token={record.user.token} onClick={ ( event ) => {this.openEditUser(event)} }></i>;
      let editar_disabled = <i className="fa fa-pencil-square-o disabled" data-token={record.user.token}></i>;
      let editar = null;

       if(record.user.admin === true ){
         access_permissions = not_permission;
         lixeira = lixeira_disabled;
         editar = editar_disabled;
         user_admin = " (Admin)";
       }else{
         access_permissions = have_permission;
         lixeira = lixeira_enabled;
         editar = editar_enabled;
       }

       if( JSON.parse(localStorage.getItem('loggedUser')).token === record.user.token ){
         editar = editar_enabled;
       }

       this.UsersService.viewRuleTimes( record.user.token ).then(rules =>{

            if (this._isMounted) {

                  //console.log(record.user.name);
                  //console.log(rules[0]);
                  if( typeof rules[0] !== "undefined" && rules[0] != null ){

                      if( (rules[0].start === ""  || rules[0].start === null ) && ( rules[0].end === "" || rules[0].end === null ) &&
                            rules[0].wednesday === 1 && rules[0].thursday === 1 && rules[0].friday === 1 &&
                            rules[0].saturday === 1 && rules[0].sunday === 1 && rules[0].monday === 1 && rules[0].tuesday === 1 &&
                          document.getElementById(record.user.token+"-liberado") !== null )
                      {
                        document.getElementById(record.user.token+"-liberado").classList.remove("hide");
                        document.getElementById(record.user.token+"-restrito").className += ' hide';

                      }else if( document.getElementById(record.user.token+"-liberado") !== null ) {
                            document.getElementById(record.user.token+"-restrito").classList.remove("hide");
                            document.getElementById(record.user.token+"-liberado").className += ' hide';
                      }

                  }
            }

       });

       //console.log(record);

      return (
        <tr key={record.user.token} className={type}>
              <td className="user" >
                  {record.user.name}{user_admin}
              </td>
              <td className=" hidden-xs hidden-sm" >
                {access_permissions}
              </td>
              <td className=" hidden-xs hidden-sm" >
                    <LevelSwitch admin={record.user.admin} permission={record.avisos_de_andamentos[0].keycode} permission_id={record.avisos_de_andamentos[0].id} action={'allow'} value={record.avisos_de_andamentos[0].allowed} user_token={record.user.token}  />
              </td>
              <td className=" hidden-xs hidden-sm" >
                    <LevelSwitch admin={record.user.admin} permission={record.gerenciar_doc_facil[0].keycode} permission_id={record.gerenciar_doc_facil[0].id} action={'allow'} value={record.gerenciar_doc_facil[0].allowed} user_token={record.user.token}  />
              </td>
              <td className=" hidden-xs hidden-sm" >
                    <LevelSwitch admin={record.user.admin} permission={record.acesso_configuracoes[0].keycode} permission_id={record.acesso_configuracoes[0].id} action={'allow'} value={record.acesso_configuracoes[0].allowed} user_token={record.user.token}  />
              </td>
              <td id={record.user.token+"-liberado"} className=" hours liberado center hidden-xs hidden-sm" >
                  Liberado
              </td>
              <td id={record.user.token+"-restrito"} className=" hours restrito center hide hidden-xs hidden-sm" >
                                                                Restrito
                                                            </td>
              <td className="last hidden-xs hidden-sm" >
                  {record.user.last_login}
              </td>
              <td className=" hidden-xs hidden-sm" >
                  <ActiveSwitch admin={record.user.admin} user_token={record.user.token} active={record.user.active} />
              </td>
              <td className="actions hidden-xs hidden-sm" >
                  <span className="pointer bt-edit pull-right edit-user" id="bt-edit-user" >
                      {editar}
                      <span className="edit-label">Editar</span>
                  </span>
                  {lixeira}
              </td>

        </tr>


      );
  };

  render() {
      //let url_base = localStorage.getItem('url_base');

    /*                                              <td className=" hours restrito center hidden-xs hidden-sm" >
                                                      Restrito
                                                  </td>*/

      return <div className="config-user sheet">
                <FlashMessage time={ new Date().getTime() } />
                <Confirm show={this.state.showConfirm} text={this.state.message} />
                <Loading visible={this.state.loadingVisible} message1={this.state.loadingMessage[0]} message2={this.state.loadingMessage[1]} message3={this.state.loadingMessage[2]} cancel={true}   />
                <HeaderBar />
                <NavigationMenu />
                <div className="content-wrapper">
                  <div className="content-wrapper-inner">
                      <section className="content-header">
                        <h1 className="page-header">Configurações - Administrador</h1>
                      </section>
                      <div className="panel panel-primary">
                            <div className="panel-heading text-center">
                                  <h4>Usuários e Permissões de acesso</h4>
                            </div>
                      </div>
                      <div className="box-body">
                          <FormUser modifiedFormUser={this.modifiedFormUser} history={this.props.history} token={this.state.token} showModal={this.state.showModal} />
                          <table>
                                  <thead>
                                      <tr>
                                            <th className="table_user">
                                                <div>Nome</div>
                                            </th>
                                            <th className="table_level_users hidden-xs hidden-sm">
                                                <div>
                                                      Definir Permissões de acesso
                                                      <MyPopover icon="white" text="Clique no cadeado para definir ou alterar  as restrições e liberações do respectivo usuário em relação aos processos.  Algumas funções já estarão bloqueadas ao incluir um novo usuário, podendo desbloqueá-las quando desejar." />
                                                </div>
                                            </th>
                                            <th className="table_movements hidden-xs hidden-sm">
                                                <div>
                                                      Notificações de Andamentos
                                                      <MyPopover icon="white" text="Ao liberar o acesso, este usuário receberá os avisos de andamentos dos processos (Laranjinhas)." />
                                                </div>
                                            </th>
                                            <th className="table_docs hidden-xs hidden-sm">
                                                <div>
                                                      <p>Gerenciar</p>
                                                      <p>Doc-facil</p>
                                                      <MyPopover icon="white" text="Ao liberar o acesso, este usuário poderá criar, alterar e excluir os modelos de documentos criados previamente para serem utilizados no Doc-Fácil. Preferencialmente, concentre esta tarefa a um único usuário." />
                                                </div>
                                            </th>
                                            <th className="table_config hidden-xs hidden-sm">
                                                <div>
                                                      Acesso à Configurações
                                                      <MyPopover icon="white" text='Ao liberar o acesso, este usuário terá livre acesso a "TODAS" as funções de configurações, inclusive das suas próprias!  Preferencialmente, esta liberação se destina aos administradores/donos do escritório.' />
                                                </div>
                                            </th>
                                            <th className="table_hours hidden-xs hidden-sm">
                                                <div>
                                                      Restrições de Horários
                                                      <MyPopover icon="white" text="Permite visualizar  se há restrições de horários para os usuários acessarem o sistema." />
                                                </div>
                                            </th>
                                            <th className="table_last">
                                                <div>
                                                      Último Acesso
                                                      <MyPopover icon="white" text="Permite ao administrador, ter a informação do último horário que o usuário teve acesso ao sistema." />
                                                </div>
                                            </th>
                                            <th className="table_active">
                                                <div>
                                                      Ativar Usuário
                                                      <MyPopover icon="white" text="Permite ao Administrador ativar ou bloquear o acesso do usuário sem que seja preciso excluir o seu cadastro. Muito utilizado em períodos de licença, férias ou afastamento temporário do usuário." />
                                                </div>
                                            </th>
                                            <th className="table_actions">
                                                <div>
                                                      Ações
                                                      <MyPopover icon="white" text="Permite fazer alterações no cadastro do usuário tais como, troca de senhas, e-mails,  horários de acesso, cadastro de senha p/ processos em Segredo de Justiça. Permite exclusão de usuários definitivamente." />
                                                </div>
                                            </th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                        { ( typeof this.state.users !== "undefined") ? this.state.users.map( (record, index) => this.userItem( {record, index}, 'user-item' ) ) : null }
                                  </tbody>
                          </table>
                      </div>
                  </div>
                </div>
                <Footer />
             </div>
  }

}
