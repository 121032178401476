import React from 'react';
import { Modal, Button } from 'antd';

export default class PopUp extends React.Component { 
  _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }

  constructor(props) {
    super(props);

    this.cancel = this.cancel.bind(this);

    this.state = {
      showAlert: (/true/i).test(props.show)
    }
  }

  componentWillReceiveProps(nextProps) {

      //if( nextProps.show !== this.props.show ){
        this.setState({
            showAlert: (/true/i).test(nextProps.show)
        });
      //}

  }

  cancel = () => {

    var event = new CustomEvent("cancelAlert", { "detail": "Example of an event" });

    // Dispatch/Trigger/Fire the event
    document.dispatchEvent(event);

    this.setState(
      {
        showAlert: false
      }
    )
  }

  check = (event) => {
    console.log("check no popup");

    localStorage.setItem(this.props.popup_name, event.target.checked);

    this.setState(
      {
        [this.props.popup_name]: event.target.checked
      }
    )
  }

  render() {
              let url_base = localStorage.getItem('url_base');
              let avvocato_logo = "/images/logo_avvocato.png";

              //console.log( this.props.text );
              //console.log( this.state.showAlert);

              let link_container = null; let img_container_1 = null; let img_container_2 = null;

              if( typeof this.props.link !==  "undefined" && this.props.link !== null ){
                link_container = <a className="btn btn-success alert-action" href={this.props.link}>{this.props.textLink}</a>;
              }

              if( typeof this.props.img_container_1 !==  "undefined" && this.props.img_container_1 !== null ){
                img_container_1 = <img height="300" width="400" src={this.props.img_container_1} />;
              }

              if( typeof this.props.img_container_2 !==  "undefined" && this.props.img_container_2 !== null ){
                img_container_2 = <img height="136" width="324" src={this.props.img_container_2} />;
              }

              return (
                        <Modal
                          visible={this.state.showAlert}
                          className={"alert_dialog"}
                          title=""
                          width="550px"
                          onOk={this.cancel}
                          onCancel={this.cancel}
                          footer={[
                            <Button key="submit" id="cancel" type="primary" onClick={this.cancel}>
                              OK
                            </Button>
                          ]}
                        >                          
                          <img className=" " alt="Logotipo Avvocato" src={url_base+avvocato_logo}></img>
                          <div className="my-alert-box">
                                { (typeof this.props.text !== "undefined") ? this.props.text.split('\n').map(i => {return <h3 key={i}>{i}</h3>}) : "" }
                                <br />
                                {img_container_1}
                                <br />
                                { (typeof this.props.text2 !== "undefined") ? this.props.text2.split('\n').map(i => {return <h3 key={i}>{i}</h3>}) : "" }
                                <br />
                                {img_container_2}
                                <br />
                                { (typeof this.props.text3 !== "undefined") ? this.props.text3.split('\n').map(i => {return <h3 key={i}>{i}</h3>}) : "" }
                                <br />
                                {link_container}
                                <span><input type="checkbox" name={this.props.popup_name} onChange={this.check} /> Não visualizar mais essa mensagem</span>
                          </div>
                        </Modal>
                    );
  }
}
