import React from 'react';
import HeaderBar from '../header-bar/header-bar';
import NavigationMenu from '../sidebar/navigation-menu';
import LawsuitService from '../../services/LawsuitService';
import TribunalService from '../../services/TribunalService';
import { Link } from 'react-router-dom';

import Badge from '../containers/Badge';

import 'antd/dist/antd.css';
import { Tabs } from 'antd';

import Footer from "../containers/footer";
import Alert from '../containers/alert';
import FlashMessage from "../containers/FlashMessage";

const TabPane = Tabs.TabPane;

let totalSteps = ( list ) => {

  var sum = 0;

  if( list instanceof Array ){
    for  ( var item of list ) {
      sum += item.movimentos_nao_visualizados;
    }
  }

  return sum;

}

export default class SearchLawsuit extends React.Component { 
  _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }


  constructor( props ) {
      super(props);
      this.lawsuitService = new LawsuitService();

      var url_string = window.location.href;
      var url = new URL(url_string);
      var q = url.searchParams.get("q");

      this.state = {
          q: q,
          lawsuitList: [],
          updated: "",
          message: "",
          showAlert: false
      }

  }

  componentDidMount(){

        document.addEventListener("cancelAlert", (e) => {
          if( this._isMounted ){
            this.setState({
              showAlert: false
            });
          }
        });

  }

  componentWillReceiveProps(nextProps) {

      var url_string = window.location.href;
      var url = new URL(url_string);
      var q = url.searchParams.get("q");

      this.setState( {
          q: q
      });

      this.lawsuitService = new LawsuitService();

      document.body.style.cursor = "wait";

      this.getLawsuits( null );


  }

  componentWillMount( ) {
    this.lawsuitService = new LawsuitService();

    document.body.style.cursor = "wait";

    this.getLawsuits( null );

  }

  getLawsuits( message ) {

    this.lawsuitService = new LawsuitService();
    this.tribunalService = new TribunalService();

    this.tribunalService.list( ).then( tribunalList  =>{

          let tribunals = "";

          for(let i=0; i < tribunalList.length; i++){
            tribunals += "&tribunals[]="+tribunalList[i].id;
          }
          //tribunals += "&list_manual=1";

          this.lawsuitService.find( this.state.q, 'cliente_e_oponente', tribunals ).then(res =>{
            function arrayUnique(array) {
              var a = array.concat();
              for(var i=0; i<a.length; ++i) {
                  for(var j=i+1; j<a.length; ++j) {
                      if(a[i].token === a[j].token)
                          a.splice(j--, 1);
                  }
              }
          
              return a;
          }

            if( Array.isArray(res.data.Lawsuits) ){ console.log( res.data.Lawsuits );

                this.lawsuitService.find( this.state.q, 'processo', tribunals ).then(response =>{

                    if( Array.isArray(response.data.Lawsuits) ){ //console.log( response );

                          res = res.data.Lawsuits.concat(response.data.Lawsuits);

                          this.lawsuitService.find( this.state.q, 'processo', '&list_manual=1' ).then(manual_processo =>{
                              if( Array.isArray(manual_processo.data.Lawsuits) ){ //console.log( manual_processo);
                                res = arrayUnique( res.concat(manual_processo.data.Lawsuits) );
                              }
                              this.lawsuitService.find( this.state.q, 'cliente_e_oponente', '&list_manual=1' ).then(manual_cli_opo =>{

                                    if( Array.isArray(manual_cli_opo.data.Lawsuits) ){ //console.log( manual_cli_opo );
                                      res = arrayUnique( res.concat(manual_cli_opo.data.Lawsuits) );
                                    }

                                    this.lawsuitService.report( null, null, null, null, null, null, this.state.q, null, null, null, null, null, null, null, null, null, null, tribunals.replace("&","?")+"&list_manual=1" , null, null, null, null ).then(pasta_response =>{

                                        if( Array.isArray(pasta_response.data.Lawsuits) ){

                                          res = arrayUnique( res.concat(pasta_response.data.Lawsuits) );
                                          
                                        }

                                            document.body.style.cursor = "default";

                                            let response = ""; let highlight = [];

                                            response = this.getHighlights ( res );
                                    
                                            highlight = response.highlight;
                                    
                                            response = this.getArchived ( response.lawsuit );
                                    
                                            document.body.style.cursor = "default";
                                            this.setState({
                                              loadingVisible: false,
                                              loadingMessage: []
                                            });
                                    
                                            this.setState({
                                    
                                              lawsuitList: response.lawsuit,
                                              qtd: response.lawsuit.length,
                                              totalStepsActive: totalSteps ( response.lawsuit ),
                                              lawsuitListHighlight: highlight,
                                              qtdHighlight: highlight.length,
                                              totalStepsHighlight: totalSteps ( highlight ),
                                              lawsuitListArchived: response.archived,
                                              qtdArchived: response.archived.length,
                                    
                                            });

                                            /*this.setState({

                                              lawsuitList: res,
                                              qtd: res.length,

                                            });*/

                                    });

                            });
                          });

                    }else{
                      localStorage.setItem('alert_message', "Resultado não encontrado" );
                      localStorage.setItem('alert_type', 'alert-danger');
                      let time = new Date().getTime();
                      localStorage.setItem('alert_time', time );
                      setTimeout(() => {
                        this.setState({
                          updated: time,
                        });
                      }, 100);

                      this.setState({

                        lawsuitList: [],
                        qtd: 0,

                      });
                    }

                })
           }else{
             localStorage.setItem('alert_message', "Resultado não encontrado" );
             localStorage.setItem('alert_type', 'alert-danger');
             let time = new Date().getTime();
             localStorage.setItem('alert_time', time );
             setTimeout(() => {
               this.setState({
                 updated: time,
               });
             }, 100);

             this.setState({

               lawsuitList: [],
               qtd: 0,

             });
           }

          })
          .catch(err =>{
              document.body.style.cursor = "default";
              this.setState({

                lawsuitList: [],
                qtd: 0,

              });
              console.log(err);
              //alert("Processo não encontrado.");
              this.setState({
                showAlert: true,
                message: "Processo não encontrado."
              });
          });
    });

  }

  getHighlights ( lawsuits ) {

    let highlight = []; let newe = []; let response = {};

    lawsuits.map( ( record, index, array ) => {

          if ( record.favorito === 1 ) {
            highlight.push(record);
          }else{
            newe.push(record);
          }

          return record;

    });

    response = {
      'highlight': highlight,
      'lawsuit': newe
    };

    return response;

  }

  getArchived ( lawsuits ) {

    let archived = []; let newe = []; let response = {};

    lawsuits.map( ( record, index, array ) => {

          if ( record.arquivo === 1 ) {
            archived.push(record);
          }else{
            newe.push(record);
          }

          return record;

    });

    response = {
      'archived': archived,
      'lawsuit': newe
    };

    return response;

  }

   getClientOrOponent ( record, side ) {

     let name = []; let response = "";

     if ( typeof record.lawsuits_personages !== "undefined" ) {

         record.lawsuits_personages.map((value, index, array )=>{

           if ( value.personage_side_id === side ) {
             name.push( value.personage.name );
           }

           return "";

         });

         if( name.length >= 2 ) {
           response = name[0]+" (e outros)";
         }else{
           response = name[0];
         }

     }

     return response;

   }

  lawsuitItem = ( {record, index}, type ) => {

      return (
        <tr key={record.token} >
              <td className="table_lawsuit_number">
                    <Link to={'/processo/detalhe_processo/'+record.token} >
                        {(record.name === null) ? record.number : record.name}
                    </Link>
              </td>
              <td className="table_pasta" title={this.getClientOrOponent(record, 2)} >
                      { record.pasta }
              </td>
              <td className="table_lawsuit_cliente hidden-xs hidden-sm" title={this.getClientOrOponent(record, 1)} >
                      { this.getClientOrOponent(record, 1) }
              </td>
              <td className="table_lawsuit_oponente hidden-xs hidden-sm" title={this.getClientOrOponent(record, 2)} >
                      { this.getClientOrOponent(record, 2) }
              </td>
        </tr>


      );
  };

  render() {

      return <div className="list-lawsuit manual-list-lawsuit sheet">
                <HeaderBar />
                <NavigationMenu />
                <FlashMessage time={ new Date().getTime() } />
                <Alert show={this.state.showAlert} text={this.state.message} />
                <div className="content-wrapper">
                  <div className="content-wrapper-inner">
                      <section className="content-header">
                        <h1 className="page-header">Listar Pesquisa</h1>
                      </section>
                      <div className="box-body">
                          <div className="alert alert-info" role="alert">Processos encontrados: <strong>{this.state.qtd}</strong> / Processos destacados encontrados: <strong>{this.state.qtdHighlight}</strong> / Processos arquivados encontrados: <strong>{this.state.qtdArchived}</strong> </div>
                        <p></p>
                        <p></p>
                        <p></p>
                        <Tabs defaultActiveKey="1" size="large">
                              <TabPane tab={<span>Ativos <Badge color="bg-blue" info={this.state.qtd} title="N° de processos ativos" /> <Badge color="bg-yellow" info={this.state.totalStepsActive} title="Andamentos Não visualizados" /></span>} key="1">
                                <div className="table-responsive">
                                  <table className="table">
                                          <thead>
                                              <tr>
                                                    <th className="table_lawsuit_number">
                                                        <div>Processo</div>
                                                    </th>
                                                    <th className="table_pasta">
                                                        <div>Pasta</div>
                                                    </th>
                                                    <th className="table_lawsuit_cliente">
                                                        <div>Cliente</div>
                                                    </th>
                                                    <th className="table_lawsuit_oponente">
                                                        <div>Oponente</div>
                                                    </th>
                                              </tr>
                                          </thead>
                                          <tbody>
                                                { ( typeof this.state.lawsuitList !== "undefined") ? this.state.lawsuitList.map( (record, index) => this.lawsuitItem( {record, index}, 'lawsuit-item' ) ) : null }
                                          </tbody>
                                  </table>
                                </div>
                              </TabPane>
                              <TabPane tab={<span><i className="fa fa-star"></i><span> Destacados <Badge color="bg-blue" info={this.state.qtdHighlight} title="N° de processos destacados" /> <Badge color="bg-yellow" info={this.state.totalStepsHighlight} title="Andamentos Não visualizados" /></span></span>} key="2">
                                <div className="table-responsive">
                                  <table className="highlight-table table" >
                                          <thead>
                                              <tr>
                                                    <th className="table_lawsuit_number">
                                                        <div>Processo</div>
                                                    </th>
                                                    <th className="table_pasta">
                                                        <div>Pasta</div>
                                                    </th>
                                                    <th className="table_lawsuit_cliente">
                                                        <div>Cliente</div>
                                                    </th>
                                                    <th className="table_lawsuit_oponente">
                                                        <div>Oponente</div>
                                                    </th>
                                              </tr>
                                          </thead>
                                          <tbody>
                                                { ( typeof this.state.lawsuitListHighlight !== "undefined") ? this.state.lawsuitListHighlight.map( (record, index) => this.lawsuitItem( {record, index}, 'lawsuit-highlight-item' ) ) : null }
                                          </tbody>
                                  </table>
                                </div>
                              </TabPane>
                              <TabPane tab={<span>Arquivados  <Badge color="bg-black" info={this.state.qtdArchived} title="N° de processos arquivados" /></span>}  key="3">
                                  <div className="table-responsive">
                                    <table className="table">
                                            <thead>
                                                <tr>
                                                      <th className="table_lawsuit_number">
                                                          <div>Processo</div>
                                                      </th>
                                                      <th className="table_pasta">
                                                          <div>Pasta</div>
                                                      </th>
                                                      <th className="table_lawsuit_cliente">
                                                          <div>Cliente</div>
                                                      </th>
                                                      <th className="table_lawsuit_oponente">
                                                          <div>Oponente</div>
                                                      </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                  { ( typeof this.state.lawsuitListArchived !== "undefined") ? this.state.lawsuitListArchived.map( (record, index) => this.lawsuitItem({ record, index }, 'lawsuit-archived-item') ) : null }
                                            </tbody>
                                    </table>
                                  </div>
                              </TabPane>
                        </Tabs>
                      </div>
                  </div>
                </div>
                <Footer />
             </div>
  }

}
