import React from 'react';
import InputMask from 'react-input-mask';

export default class PRLawsuitInput extends React.Component { _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return <InputMask {...this.props} mask="9999999-99.9999.4.04.9999" maskChar="_" />;
  }
}
