import React, { Component } from 'react';
import { Switch } from 'antd';

import LevelService from '../../../services/LevelsService';

export default class extends Component {

  constructor( props ){
      super();

      this.setPermission = this.setPermission.bind(this);

      this.state = {
          permission: props.permission,
          switch: (props.value === 1)? true: false,
          permission_id: props.permission_id,
          user_token: props.user_token,
          admin: props.admin
      }

      if( this.state.permission_id === 13 ){
        this.state.switch = true;
        this.state.admin = true;
      }
  }

  componentWillReceiveProps(nextProps) {

      if ( nextProps.permission !== this.props.permission ) {

        this.setState({
          permission: nextProps.permission,
          switch: (nextProps.value === 1)? true: false,
          permission_id: nextProps.permission_id,
          user_token: nextProps.user_token,
          admin: nextProps.admin
        });

      }
  }

  setPermission( allow ){
        this.levelService = new LevelService();

        let permissions = {
          user_token: this.state.user_token,
          rule_permission_id: this.state.permission_id,
          allowed: allow
        }

        this.levelService.setPermission( permissions ).then(res =>{
          console.log("permissão setada");
          console.log(res);

        });
  }

  onSwitchChange = (checked) => {
    if ( checked ){
      /*Fazer Chamada endpoint para salvar permissão*/
      this.setState({
        switch: true,
      });

      this.setPermission(1);

    }else{
      /*Fazer Chamada endpoint para salvar permissão*/
      this.setState({
        switch: false,
      });

      this.setPermission(0);

    }
  }

  render() {

    return (
            <Switch disabled={this.state.admin} title={(this.state.switch)? "Desbloqueado": "Bloqueado"} id={this.state.permission} checked={this.state.switch} onChange={ this.onSwitchChange }  />
    );
  }
}
