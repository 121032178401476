import React from 'react';
import Select from 'react-select';
import Loading from '../../containers/loading';
import Popover, { ArrowContainer } from 'react-tiny-popover';

import eDocsService from '../../../services/eDocsService'

export default class Tipo extends React.Component { 
  _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }


  constructor( props ){
    super( props );
    this.selectChange = this.selectChange.bind(this);
    this.modifiedType = this.props.modifiedType.bind(this);
    this.addTipo = this.addTipo.bind(this);
    this.deleteTipo = this.deleteTipo.bind(this);
    console.log(props.area_id);
    this.state = {
        tipo: {value: 0, label: ""},
        tipoList: [],
        area_id: props.area_id,
        loadingVisible: false,
        loadingMessage: [],
        tipo_disabled: (props.area_id === "" || props.area_id === 0 )? true: false,
    }
  }

  componentWillReceiveProps(nextProps) {

    //console.log(nextProps.area_id);

    if ( ( this.state.tipo !== null && nextProps.type_id !== this.state.tipo.value ) || nextProps.area_id !== this.state.area_id ) {

      this.setState({
        tipo: {value: nextProps.type_id, label: ""},
        area_id: nextProps.area_id,
        updateList: ( nextProps.area_id !== this.props.area_id )? true: false,
        tipo_disabled: (nextProps.area_id === "" || nextProps.area_id === 0 )? true: false,
      });

      setTimeout(() => {

        this.populateSelect();

      }, 300);

    }
  }

  addTipo (e) {
    this.eDocsService = new eDocsService();
    this.setState({
      loadingVisible: true,
      loadingMessage: ["O tipo de modelo está sendo salvo.", "Por gentileza, aguarde...", "Tempo estimado de 05 a 30 segundos"]
    });

    let tipo = {
      name: document.getElementById("tipoName").value,
      edocs_area_token: this.state.area_id
    }
    this.eDocsService.addTipo( tipo ).then(res =>{

      this.eDocsService.tipoList( this.state.area_id ).then(res =>{

          if (this._isMounted) {

              document.getElementById("tipoName").value = "";

              this.setState({
                tipoList: res,
                loadingVisible: false
              });

          }

      });

    });
  }

  deleteTipo (e) {
    this.eDocsService = new eDocsService();

    this.eDocsService.templateList( document.getElementById("tipoSettings").value ).then(res =>{

          if (this._isMounted) {

                let tipoList = res;
                if ( tipoList.length > 0 ) {
                  alert ("Não é possível apagar Tipos que não estão vazios!");
                }else{
                      this.setState({
                        loadingVisible: true,
                        loadingMessage: ["O tipo de modelo está sendo deletado.", "Por gentileza, aguarde...", "Tempo estimado de 05 a 30 segundos"]
                      });

                      let id = document.getElementById("tipoSettings").value;

                      //console.log( id );

                      this.eDocsService.deleteTipo( id ).then(res =>{

                        this.eDocsService.tipoList( this.state.area_id ).then(res =>{

                            if (this._isMounted) {

                                this.setState({
                                  tipoList: res,
                                  loadingVisible: false,
                                });
                                
                            }

                        });

                      });
                }

          }
    });

  }

  populateSelect(  ){
    document.body.style.cursor = "wait";
    let tipoList = []; let tipo = this.state.tipo;

    this.setState({
      loadingVisible: true,
      loadingMessage: ["Os tipos de modelos estão sendo carregados.", "Por gentileza, aguarde...", "Tempo estimado de 05 a 30 segundos"]
    });

    this.eDocsService = new eDocsService();

      this.eDocsService.tipoList( this.state.area_id ).then(res =>{

          if (this._isMounted) {

              tipoList = res;
              //console.log(this.state.tipo)
              //tipoList.unshift({value: -9999, label: "Não Definida"});
              tipoList.map((value, index, array )=>{

                if ( value.value === this.state.tipo.value ) {
                  tipo = value;
                }

                return "";

              });

              this.setState({
                tipoList: tipoList,
                tipo: (tipo.label !== "")? tipo : null,
                loadingVisible: false,
                tipo_disabled: (this.state.area_id === "" || this.state.area_id === 0)? true: false,
              });
              console.log(this.state.area_id);
              document.body.style.cursor = "default";

          }

      });


  }

  componentDidUpdate( ){

  }

 componentDidMount( ) { 
     this._isMounted = true;
     document.addEventListener("cancelLoading", (e) => {
        this.setState({
          loadingVisible: false
        });
      });

     if ( this.state.area_id !== "" ){
       this.populateSelect();
     }
 }

  selectChange = (selected, actionMeta) => {
    let pops = [];
    pops[ actionMeta.name ] = selected;
    if ( typeof actionMeta.name !== undefined && actionMeta.name !== "" && actionMeta.name !== null ){

      if ( typeof selected !== undefined && selected !== "" && selected !== null ) {
        this.setState( pops );
      }

    }

    document.getElementById("type_id").value = selected.value;

    this.modifiedType();
  }

  render() {
      var url_base = localStorage.getItem('url_base');
      var arrTipo = [];
      for (var k = 0; k < this.state.tipoList.length; k++) {
          arrTipo.push(<option key={k} value={this.state.tipoList[k].value}> {this.state.tipoList[k].label} </option>);
      }

      let tipo = null;

      if (this.state.tipo !== null && this.state.tipo.value !== 0 && this.state.tipo.value !== "" ){
        tipo = this.state.tipo;
      }

      //console.log(this.state.tipo);

      return <div className="">
                    <Loading visible={this.state.loadingVisible} message1={this.state.loadingMessage[0]} message2={this.state.loadingMessage[1]} message3={this.state.loadingMessage[2]}  cancel={true} />
                    <div className="form-group col-md-4 text-left">
                            <label className="col-md-12">
                                    Tipo
                                    <Popover
                                        isOpen={this.state.isPopoverOpen}
                                        position={['top', 'right', 'left', 'bottom']}
                                        padding={10}
                                        onClickOutside={() => this.setState({ isPopoverOpen: false })}
                                        content={({ position, targetRect, popoverRect }) => (
                                            <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                                                position={position}
                                                targetRect={targetRect}
                                                popoverRect={popoverRect}
                                                arrowColor={'#94bcd8'}
                                                arrowSize={10}
                                            >
                                                <div className="popover-box"
                                                    onClick={() => this.setState({ isPopoverOpen: !this.state.isPopoverOpen })}
                                                >
                                                      Clique em <i className="material-icons settings">settings</i> para incluir Tipos de documentos, como Procurações, Iniciais, Atestados, Contratos, Recibos e outros.
                                                </div>
                                            </ArrowContainer>
                                        )}
                                    >
                                        <img onMouseOut={() => this.setState({ isPopoverOpen: false })}  onMouseOver={() => this.setState({ isPopoverOpen: !this.state.isPopoverOpen })} className="help_icon " alt="ajuda" src={url_base+"/images/interrogation_cropped.png"}></img>
                                    </Popover>
                            </label>
                            <input type="hidden" id="type_id" name="type_id" />
                            <Select
                                className="tipo col-md-10"
                                placeholder="Selecione"
                                options={this.state.tipoList}
                                value={tipo}
                                name="tipo"
                                isDisabled={this.state.tipo_disabled}
                                onChange={this.selectChange}
                              />
                              <i className="material-icons settings" data-toggle="modal" data-target={(!this.state.tipo_disabled) ? "#tipoModal" : ""} title="Clique para adicionar ou excluir opções">settings</i>
                      </div>
                      <div className="modal fade" id="tipoModal" tabIndex="-1" role="dialog" aria-labelledby="tipoModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="tipoModalLabel">Incluir ou Excluir Tipo</h5>
                            </div>
                            <div className="modal-body">
                              <form>
                                <div className="form-group">
                                  <label htmlFor="tipoName" className="col-form-label col-md-12">Novo Tipo: </label>
                                  <input type="text" className="form-control" id="tipoName" />
                                  <span className="btn btn-primary" id="add-tipo" onClick={this.addTipo} >Incluir</span>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="tipoSettings" className="col-form-label col-md-12">Opções: </label>
                                    <select multiple id="tipoSettings" className="tipo settings">
                                      {arrTipo}
                                    </select>
                                    <i className="material-icons delete" id="delete-tipo" title="remover item selecionado" onClick={this.deleteTipo} >delete</i>
                                </div>
                              </form>
                            </div>
                            <div className="modal-footer">
                              <p className="info">As alterações são salvas automaticamente</p>
                              <button type="button" className="btn btn-danger" data-dismiss="modal">Sair</button>
                            </div>
                          </div>
                        </div>
                      </div>
             </div>
  }

}
