import React from 'react';
import { BrowserRouter, Switch, Redirect, Route } from 'react-router-dom'
import AccessDenied from './components/AccessDenied'
import Home from './components/Home'
import Clear from './components/Clear'
import Login from './components/Login'
import ForgotPassword from './components/ForgotPassword'
import ForgotPasswordConfirm from './components/ForgotPasswordConfirm'
import Logout from './components/Logout'
import Partes from './components/Partes'
import Eventos from './components/Eventos'
import Perfil from './components/Perfil'
import EditPartes from './components/partes/EditPartes'
import Sobre from './components/Sobre'
import SignUp from './components/SignUp'
import AddManualLawsuit from './components/tribunals/addManualLawsuit'
import AddLawsuit from './components/tribunals/addLawsuit'
import ListLawsuit from './components/tribunals/listLawsuit'
import ManualLawsuit from './components/tribunals/manualLawsuit'
import SearchLawsuit from './components/tribunals/searchLawsuit'
import Tribunal from './components/Tribunal'
import CoverLawsuit from './components/tribunals/coverLawsuit'
import PrintLawsuit from './components/tribunals/printLawsuit'
import Event from './components/Event'
import EditTemplate from './components/doc-facil/editTemplate'
import ViewTemplate from './components/doc-facil/viewTemplate'
import ViewDocument from './components/doc-facil/viewDocument'
import ManagerTemplates from './components/managerTemplates'
import GenerateDocuments from './components/generateDocuments'
import ListTemplate from './components/listTemplate'
import IncomingFinance from './components/IncomingFinance'
import OutgoingFinance from './components/OutgoingFinance'
import Cashflow from './components/Cashflow'
import Publications from './components/Publications'
import ReportSchedules from './components/reports/reportSchedules'
import ReportEventList from './components/reports/reportEventList'
import ReportEventChartList from './components/reports/reportEventChartList'
import ReportFinanceChartList from './components/reports/reportFinanceChartList'
import ReportClientList from './components/reports/reportClientList'
import ReportFinanceList from './components/reports/reportFinanceList'
import ReportLawsuit from './components/reports/reportLawsuit'
import ReportFinance from './components/reports/reportFinance'
import ReportClient from './components/reports/reportClient'
import ConfigUser from './components/config/configUser'
import ConfigLevel from './components/config/configLevel'
import ConfigAccount from './components/config/configAccount'
import ConfigLogo from './components/config/configLogo'
import ConfigOffice from './components/config/configOffice'
import ChangePlan from './components/config/changePlan'
import ConfigTribunals from './components/config/configTribunals'
import DetailsLawsuit from './components/tribunals/detailsLawsuit'
import Afiliados from './components/Afiliados'
import AuthService from './services/AuthService';
const Auth = new AuthService();

const PublicRoutes = ({ history, isLoggedIn }) => {

  let agenda_listar_path = "/relatorio/agenda/listar/:event/:status/:priority/:user/:category/"+
  ":sponsorLawsuit/:client/:lawsuit/:date_start/:date_end";
  let agenda_chart_path = "/relatorio/agenda/chart/:event/:status/:priority/:user/:category/"+
  ":sponsorLawsuit/:client/:lawsuit/:date_start/:date_end";
  let cliente_listar_path = "/relatorio/cliente/listar/:data_nasc_exata/:mes_nasc/:periodo_nasc/"+
  ":client/:date/:date_start/:date_end/:month/:n_processo/:rg/:cpf/:email/:tel/:profissao/"+
  ":estado_civil/:endereco_completo/:n_pasta/:cidade/:estado";
  let finance_list_path = "/relatorio/financeiro/listar/:client/:lawsuit/:categoria/:type/:status/"+
  ":exibir_detalhes/:centroCusto/:excluirRepassesFuturos/:excluirRecebimentoFuturos/:liquido";
  let finance_chart_path = "/relatorio/financeiro/chart/:type/";

  return (
      <BrowserRouter basename="/">
          <Switch>
              <PrivateRoute path="/" exact={true} component={Home} />
              <PrivateRoute path="/limpar_testes" component={Clear} />
              <PrivateRoute path="/active/:active" component={Home} />
              <PrivateRoute path="/add-manual" component={AddManualLawsuit} />
              <PrivateRoute path="/partes/" exact={true} component={Partes} />
              <PrivateRoute path="/perfil/" exact={true} component={Perfil} />
              <PrivateRoute path="/eventos/" exact={true} component={Eventos} />
              <PrivateRoute path="/partes/edit/:id" component={EditPartes} />
              <PrivateRoute path="/tribunal/" exact={true} component={Tribunal} />
              <PrivateRoute path="/tribunal/:type" component={AddLawsuit} />
              <PrivateRoute path="/publicacoes" component={Publications} />
              <PrivateRoute path="/processo-manual" component={ManualLawsuit} />
              <PrivateRoute path="/processo/listar_processo/:type" component={ListLawsuit} />
              <PrivateRoute path="/processo/detalhe_processo/:id" component={DetailsLawsuit} />
              <PrivateRoute path="/processo/pesquisa/" component={SearchLawsuit} />
              <PrivateRoute path="/cover/:id" component={CoverLawsuit} />
              <PrivateRoute path="/print-lawsuit/:id" component={PrintLawsuit} />
              <PrivateRoute path="/agenda" exact={true} component={Event} />
              <PrivateRoute path="/financeiro/pagar" component={OutgoingFinance} />
              <PrivateRoute path="/financeiro/receber" component={IncomingFinance} />
              <PrivateRoute path="/financeiro/fluxo-caixa" component={Cashflow} />
              <PrivateRoute path="/gerenciar_modelos" component={ManagerTemplates} />
              <PrivateRoute path="/modelo/editar/:id" component={EditTemplate} />
              <PrivateRoute path="/listar_modelos" component={ListTemplate} />
              <PrivateRoute path="/modelo/visualizar/:id" component={ViewTemplate} />
              <PrivateRoute path="/docfacil/:id" component={ViewDocument} />
              <PrivateRoute path="/gerar_documento" component={GenerateDocuments} />
              <PrivateRoute path="/relatorio/agenda" exact={true} component={ReportSchedules} />
              <PrivateRoute path={agenda_listar_path} component={ReportEventList} />
              <PrivateRoute path={agenda_chart_path} component={ReportEventChartList} />
              <PrivateRoute path={cliente_listar_path} component={ReportClientList} />
              <PrivateRoute path={finance_list_path} component={ReportFinanceList} />
              <PrivateRoute path={finance_chart_path} component={ReportFinanceChartList} />
              <PrivateRoute path="/relatorio/processo" component={ReportLawsuit} />
              <PrivateRoute path="/relatorio/financeiro"  exact={true} component={ReportFinance} />
              <PrivateRoute path="/relatorio/cliente" component={ReportClient} />
              <PrivateRoute path="/config/usuario" component={ConfigUser} />
              <PrivateRoute path="/config/permissoes/:id" component={ConfigLevel} />
              <PrivateRoute path="/config/logo" component={ConfigLogo} />
              <PrivateRoute path="/config/tribunal" component={ConfigTribunals} />
              <PrivateRoute path="/config/escritorio" component={ConfigOffice} />
              <PrivateRoute path="/config/mudar-plano" component={ChangePlan} />
              <PrivateRoute path="/config/conta" component={ConfigAccount} />
              <PrivateRoute path="/afiliados" component={Afiliados} />
              <Route path="/access-denied" exact={true} component={AccessDenied} />
              <Route path="/login" exact={true} component={Login} />
              <Route path="/logout" exact={true} component={Logout} />
              <Route path="/signup" exact={true} component={SignUp} />
              <PrivateRoute path="/sobre" component={Sobre} />
              <Route path="/forgotPassword" component={ForgotPassword} />
              <Route path="/forgotPasswordConfirm" component={ForgotPasswordConfirm} />
          </Switch>
      </ BrowserRouter>
  );
};

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    Auth.loggedIn() === true
      ? <Component {...props} />
      : <Redirect to='/login' />
  )} />
)

export default PublicRoutes;
