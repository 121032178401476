import AuthService from './AuthService';
import {api} from '../config';


export default class AdditionalDataLawsuitService {
    // Initializing important variables
    constructor(domain) {
        this.domain = domain || api.url // API server domain
        this.fetch = this.fetch.bind(this) // React binding stuff
        this.areaList = this.areaList.bind(this)
    }

    areaList ( ) {
      return this.fetch(`${this.domain}LawsuitAreas`, {
          method: 'GET',
      }).then(res => {
          let response = [];
          //console.log(res);
          if( res.success === true ){
            var i = 0;
            res.data.LawsuitAreas.map((value, index, array )=>{
              response[index] = { "key": array.length-i, "value": value.id, "label": value.value };
              i++;
              return response;
            });
          }

          //console.log(response);
          return Promise.resolve(response);
      })
    }

    addArea ( area ){
      return this.fetch(`${this.domain}LawsuitAreas/add`, {
          method: 'POST',
          body: JSON.stringify(area)
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      })
    }

    deleteArea ( id ){
      return this.fetch(`${this.domain}LawsuitAreas/delete/${id}`, {
          method: 'DELETE'
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      })
    }

    tipoList ( ) {
      return this.fetch(`${this.domain}LawsuitTypes`, {
          method: 'GET',
      }).then(res => {
          let response = [];
          //console.log(res);
          if( res.success === true ){
            var i = 0;
            res.data.LawsuitTypes.map((value, index, array )=>{
              response[index] = { "key": array.length-i, "value": value.id, "label": value.value };
              i++;
              return response;
            });
          }

          //console.log(response);
          return Promise.resolve(response);
      })
    }

    addTipo ( tipo ){
      return this.fetch(`${this.domain}LawsuitTypes/add`, {
          method: 'POST',
          body: JSON.stringify(tipo)
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      })
    }

    deleteTipo ( id ){
      return this.fetch(`${this.domain}LawsuitTypes/delete/${id}`, {
          method: 'DELETE'
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      })
    }

    faseList ( ) {
      return this.fetch(`${this.domain}LawsuitPhases`, {
          method: 'GET',
      }).then(res => {
          let response = [];
          //console.log(res);
          if( res.success === true ){
            var i = 0;
            res.data.LawsuitPhases.map((value, index, array )=>{
              response[index] = { "key": array.length-i, "value": value.id, "label": value.value };
              i++;
              return response;
            });
          }

          //console.log(response);
          return Promise.resolve(response);
      })
    }

    addFase ( fase ){
      return this.fetch(`${this.domain}LawsuitPhases/add`, {
          method: 'POST',
          body: JSON.stringify(fase)
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      })
    }

    deleteFase ( id ){
      return this.fetch(`${this.domain}LawsuitPhases/delete/${id}`, {
          method: 'DELETE'
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      })
    }

    comarcaList ( ) {
      return this.fetch(`${this.domain}LawsuitComarcas`, {
          method: 'GET',
      }).then(res => {
          let response = [];
          //console.log(res);
          if( res.success === true ){
            var i = 0;
            res.data.LawsuitComarcas.map((value, index, array )=>{
              response[index] = { "key": array.length-i, "value": value.id, "label": value.value };
              i++;
              return response;
            });
          }

          //console.log(response);
          return Promise.resolve(response);
      })
    }

    addComarca ( comarca ){
      return this.fetch(`${this.domain}LawsuitComarcas/add`, {
          method: 'POST',
          body: JSON.stringify(comarca)
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      })
    }

    deleteComarca ( id ){
      return this.fetch(`${this.domain}LawsuitComarcas/delete/${id}`, {
          method: 'DELETE'
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      })
    }

    perdaList ( ) {
      return this.fetch(`${this.domain}LawsuitLosses`, {
          method: 'GET',
      }).then(res => {
          let response = [];
          //console.log(res);
          if( res.success === true ){
            var i = 0;
            res.data.LawsuitLosses.map((value, index, array )=>{
              response[index] = { "key": array.length-i, "value": value.id, "label": value.value };
              i++;
              return response;
            });
          }

          //console.log(response);
          return Promise.resolve(response);
      })
    }

    addPerda ( perda ){
      return this.fetch(`${this.domain}LawsuitLosses/add`, {
          method: 'POST',
          body: JSON.stringify(perda)
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      })
    }

    editPerda ( perda ){
      return this.fetch(`${this.domain}LawsuitLosses/edit`, {
          method: 'PUT',
          body: JSON.stringify(perda)
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      })
    }

    deletePerda ( id ){
      return this.fetch(`${this.domain}LawsuitLosses/delete/${id}`, {
          method: 'DELETE'
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      })
    }

    filialList ( ) {
      return this.fetch(`${this.domain}Offices/index/`, {
          method: 'GET',
      }).then(res => {
          //console.log(res);
          let response = [];
          var i = 0;
          res.data.Offices.map((value, index, array )=>{
            response[index] = { "key": array.length-i, "value": value.token, "label": value.name };
            i++;
            return response;
          });

          return Promise.resolve(response);
      });
    }

    addFilial ( filial ){
      return this.fetch(`${this.domain}Offices/add`, {
          method: 'POST',
          body: JSON.stringify(filial)
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      })
    }

    deleteFilial ( id ){
      return this.fetch(`${this.domain}Offices/delete/${id}`, {
          method: 'DELETE'
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      })
    }

    statusList ( ) {
      return this.fetch(`${this.domain}LawsuitStatus`, {
          method: 'GET',
      }).then(res => {
          let response = [];
          //console.log(res);
          if( res.success === true ){
            var i = 0;
            res.data.LawsuitStatus.map((value, index, array )=>{
              response[index] = { "key": array.length-i, "value": value.id, "label": value.value };
              i++;
              return response;
            });
          }

          //console.log(response);
          return Promise.resolve(response);
      })
    }

    addSituacao ( status ){
      return this.fetch(`${this.domain}LawsuitStatus/add`, {
          method: 'POST',
          body: JSON.stringify(status)
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      })
    }

    deleteSituacao ( id ){
      return this.fetch(`${this.domain}LawsuitStatus/delete/${id}`, {
          method: 'DELETE'
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      })
    }

    fetch(url, options) {
          let auth = new AuthService();

          options.headers = {
             'Authorization': 'Bearer ' + auth.getToken(),
             'Content-Type': 'application/json',
             'Accept': 'application/json'
           }

          return fetch(url, options)
                  .then(this._checkStatus)
                  .then(response => {

                          if(typeof response !== "undefined"){

                            return Promise.resolve( response.text().then(function(result){
                                //console.log(result);
                                let old_result = result;
                                result = result.split('{"success"');
                                if( result.length > 1 ){
                                    result = result[1].split('<pre');
                                    //console.log(result[0]);
                                    let json = '{"success"'+result[0];
                                    result = JSON.parse(json);
                                    //console.log(result);
                                    return result;
                                }else{
                                    return old_result;
                                }

                            }) );

                        }else{

                            let result = {
                                success: false,
                                message: "Erro desconhecido"
                            }

                            return Promise.resolve( result );

                        }

                  })
    }

    _checkStatus(response) {
      // raises an error in case response status is not a success
      if (response.status >= 200 && response.status < 300) { // Success status lies between 200 to 300
          return response
      } else if( response.status === 401 || response.status === "401" ) {
        console.log("Tentando trapacear? Você será desligado");


        localStorage.removeItem('id_token');
        localStorage.removeItem('loggedUser');
        window.location.href = '/login';

      } else if( response.status === 402 || response.status === "402" ) {
        
        window.location.href = '/config/mudar-plano?plano_vencido';

      }else {
          var error = new Error(response.statusText)
          error.response = response
          throw error
      }
  }

    /*fetch(url, options) {
        // performs api calls sending the required authentication headers
        let auth = new AuthService();

        options.headers = {
           'Authorization': 'Bearer ' + auth.getToken(),
           'Content-Type': 'application/json',
           'Accept': 'application/json'
         }

        return fetch(url, options)
            .then(this._checkStatus)
            .then(response => response.json())
    }*/
}
