import React from 'react';
import Select from 'react-select';
import InputMask from 'react-input-mask';
import Loading from '../../containers/loading';
import MyPopover from '../../containers/mypopover';

import LawsuitService from '../../../services/LawsuitService';
import UsersService from '../../../services/UsersService';

import FlashMessage from "../../containers/FlashMessage";
import Alert from '../../containers/alert';import LawsuitInput from './LawsuitInput';

export default class AddINSS extends React.Component { _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }


  constructor( props ) {
      super(props);
      this.handleChange = this.handleChange.bind(this);
      this.radioChange = this.radioChange.bind(this);
      this.handleFormSubmit = this.handleFormSubmit.bind(this);
      this.LawsuitService = new LawsuitService();

      let message = "";
      let showAlert = "";
      if( props.tribunal_id === 999 ){
       // alert("Não foi possível estabelecer conexão com o servidor. Contate o administrador");
        message = "Não foi possível estabelecer conexão com o servidor. Contate o administrador";
        showAlert = true;
      }

      console.log(props.tribunal_id);

      this.state = { sponsorsList: [{}], sponsor: 0, lawsuit_number: "", message, showAlert,
       tribunal_id: props.tribunal_id, errors: {}, loadingVisible: false, loadingMessage: [], 
       cpf: "", senha: "", lawsuit_input1: "recurso", name: "" };

  }

  handleFormSubmit(e){
       e.preventDefault();

         document.getElementById("addButton").disabled = true;
         document.body.style.cursor = "wait";

         this.setState({
          loadingVisible: true,
          loadingMessage: [
            "O cadastro está sendo efetuado.", 
            "Por gentileza, aguarde...", 
            "Tempo estimado para leitura de Captchas/Dados: \nde 15 a 90 segundos \n"
           ] 
         });

         let lawsuit = {
           user_token: this.state.sponsor.value,
           tribunal_id: this.state.tribunal_id.toString(),
           input1: this.state.lawsuit_input1,
           atualizacao_automatica: (this.state.lawsuit_input1 === "recurso") ? 1: 0,
         }

         if (this.state.lawsuit_input1 !== "recurso"){
            lawsuit.name = this.state.name
         }else{
           lawsuit.number = this.state.lawsuit_number;
           lawsuit.username =  this.state.cpf;
           lawsuit.password =  this.state.senha;
         }

         this.LawsuitService.add( lawsuit ).then(res =>{

          if (this._isMounted) {

                document.getElementById("addButton").disabled = false;
                document.body.style.cursor = "default";

                console.log(res);

                console.log(typeof res);

                if(typeof res === "string"){
                  localStorage.setItem('alert_message', "Erro ao salvar processo: \n"+res);
                  localStorage.setItem('alert_type', 'alert-danger');
                  let time = new Date().getTime();
                  localStorage.setItem('alert_time', time );
                }else{
                  if ( res.success === true ) {
                    localStorage.setItem('alert_message', "Processo cadastrado com sucesso");
                    localStorage.setItem('alert_type', 'alert-success');
                    let time = new Date().getTime();
                    localStorage.setItem('alert_time', time );
                    this.props.history.push( '/processo/detalhe_processo/'+res.data.Lawsuits[0].token+'?parte=true&manual=true' );
                  }else {
                    localStorage.setItem('alert_message', res.message);
                    localStorage.setItem('alert_type', 'alert-danger');
                    let time = new Date().getTime();
                    localStorage.setItem('alert_time', time );
                  }
                }
    

    
                setTimeout(() => {
    
                  this.setState({
                    loadingVisible: false,
                    loadingMessage: []
                  });
    
                }, 1000);

          }

         })
         .catch(err =>{

          if (this._isMounted) {

            document.getElementById("addButton").disabled = false;
            document.body.style.cursor = "default";
            console.log(err);
            this.setState({
              loadingVisible: false,
              loadingMessage: []
            });

          }

         });

  }

  sponsorChange = (selectedOption) => {
    this.setState({ "sponsor": selectedOption });
  }

  instanceChange = (selectedOption) => {
    this.setState({ "instance": selectedOption });
  }

  handleChange(e){

       this.setState(
           {
               [e.target.name]: e.target.value
           }
       )
   }

   validateForm() {
       let errors = {};
       let formIsValid = true;

       if (this.state.lawsuit_input1 === "recurso") {


         if ( !this.state.lawsuit_number ) {
            formIsValid = false;
            errors["lawsuit_number"] = "*Preencha o número do processo";
          }

          if ( this.state.lawsuit_number.search("_") !== -1 ) {
             formIsValid = false;
             errors["lawsuit_number"] = "*Número de Processo incorreto";
           }

          if (typeof this.state.lawsuit_number !== "undefined") {
            if ( this.state.lawsuite_number==="" ) {
              formIsValid = false;
              errors["lawsuit_number"] = "*Preencha o número do processo";
            }
          }

          if ( !this.state.senha ) {
            formIsValid = false;
            errors["senha"] = "*Preencha a senha";
          }

          if (typeof this.state.senha !== "undefined") {
            if ( this.state.senha==="" ) {
              formIsValid = false;
              errors["senha"] = "*Preencha a senha";
            }
          }

          if ( !this.state.cpf ) {
            formIsValid = false;
            errors["cpf"] = "*Preencha o CPF";
          }

          if (typeof this.state.cpf !== "undefined") {
            if ( this.state.cpf==="" ) {
              formIsValid = false;
              errors["cpf"] = "*Preencha o CPF";
            }
          }

          this.setState({
            errors: errors
          });

        }

         return formIsValid;
  }


  componentDidMount( ) { 
    this._isMounted = true;


    document.addEventListener("cancelAlert", (e) => {
      if( this._isMounted ){
        this.setState({
          showAlert: false
        });
      }
    });
    document.addEventListener("cancelLoading", (e) => {
      this.setState({
        loadingVisible: false
      });
    });

    this.usersService = new UsersService();

    this.usersService.list( ).then(res =>{

      if (this._isMounted) {

        let options = res; let sponsor = [];

        let user = JSON.parse( localStorage.getItem( 'loggedUser' ) );
  
        options.map((value, index, array )=>{
          if ( user.token === value.value ) {
            sponsor = value;
          }
          return value;
        });
  
        this.setState({
          sponsorsList: options,
          sponsor: sponsor,
        });

      }


    });

  }

  radioChange(e){

      this.setState(
          {
              lawsuit_input1: e.target.value
          }
      )
  }

  /*
  <div className="form-group">
      <label htmlFor="tribunal-type">Tribunal</label>
      <input className="form-control input-lg" name="tribunal-type" type="text" placeholder= "INSS - Previdência Social" readOnly="readonly" />
  </div>
  <div className="form-group">
      <label htmlFor="sponsor">Responsável pelo Processo<span className="text-red">*</span></label>
      <Select
          className="sponsor"
          options={this.state.sponsorsList}
          defaultValue={this.state.sponsor}
          value={this.state.sponsor}
          name="sponsor"
          onChange={this.sponsorChange}
        />
        <div className="errorMsg">{this.state.errors.sponsor}</div>
  </div>

  */

  render() {
    /*
        <div className="form-group">
            <label htmlFor="lawsuit_number">Número do Benefício</label>
            <input className="form-control input-lg" name="lawsuit_input1" onChange={this.handleChange} />
        </div>
     */
      return <div className="box box-success">
                  <FlashMessage time={ new Date().getTime() } />
                  <Alert show={this.state.showAlert} text={this.state.message} />
                  <Loading visible={this.state.loadingVisible} message1={this.state.loadingMessage[0]} message2={this.state.loadingMessage[1]} message3={this.state.loadingMessage[2]} cancel={true}   />
                  <div className="box-header with-border">
                    <h3 className="box-title">Cadastrar processo - INSS - Previdência Social</h3>
                  </div>
                  <div className="box-body">
                    <br />
                    <div className="form-group">
                        <input type="radio" name="lawsuit_input1" 
                              value={'recurso'} 
                              className= "inssRadio"
                              checked={this.state.lawsuit_input1 === "recurso"} 
                              onChange={this.radioChange} 
                        />
                        <label htmlFor="lawsuit_input1" className= "inssLabel">Processo em Recurso</label>
                        <input type="radio" name="lawsuit_radio2" 
                              value={'manual'} 
                              className= "inssRadio"
                              checked={this.state.lawsuit_input1 === "manual"} 
                              onChange={this.radioChange} 
                        />
                        <label htmlFor="lawsuit_radio2" className= "inssLabel">Processo Manual (Administrativo)</label>
                        <div className="errorMsg">{this.state.errors.recurso}</div>
                    </div>
                    <div className={(this.state.lawsuit_input1 === "recurso") ? "" : "hide"}>

                          <div className="form-group">
                              <label htmlFor="lawsuit_number">Número do Processo em Recurso</label>
                              <InputMask className="form-control input-lg" name="lawsuit_number" onChange={this.handleChange} required mask="99999.999999/9999-99" maskChar="_" />
                              <div className="errorMsg">{this.state.errors.lawsuit_number}</div>
                          </div>
                          <div className="form-group">
                              <label htmlFor="lawsuit_number">CPF</label>
                              <InputMask className="form-control input-lg" name="cpf" onChange={this.handleChange} required mask="999.999.999-99" maskChar="_" autoComplete="new-password" />
                              <div className="errorMsg">{this.state.errors.cpf}</div>
                          </div>
                          <div className="form-group">
                              <label htmlFor="lawsuit_number">Senha</label>
                              <input type="password" className="form-control input-lg" name="senha" onChange={this.handleChange} autoComplete="new-password"  />
                              <div className="errorMsg">{this.state.errors.senha}</div>
                          </div>


                          <div className="well">
                              <strong>Exemplos:</strong>
                              <p>Número do Processo: 99999.999999/9999-99</p>
                          </div>

                    </div>

                    <div className={(this.state.lawsuit_input1 !== "recurso") ? "" : "hide"}>
                          <div className="form-group">
                                <label htmlFor="name">Digite um nome ou número para controle interno</label>

                                <MyPopover icon="white" text="Indique um N° de Pasta, N° de Benefício, nome do cliente, etc... ,algo para identificar o processo. Padronize esta informação para facilitar a sua localização." />
                                <input id="name" className="name form-control input-lg" name="name" onChange={this.handleChange} />
                          </div>
                    </div>


                    <div className="form-group">
                        <input
                            className="btn btn-primary form-submit"
                            value="Cadastrar"
                            id="addButton"
                            type="submit"
                            onClick={this.handleFormSubmit.bind(this)}
                        />
                    </div>
                  </div>

          </div>

  }

}
