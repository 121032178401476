const print = [
    {
        key:`
                    * {
                      background:transparent !important;
                      text-shadow:none !important;
                      filter:none !important;
                      -ms-filter:none !important;
                    }
                    body {
                      margin:0;
                      padding:0;
                      line-height: 1.4em;
                      color: #000;
                      font-size: 14pt;
                    }
                    @page {
                      margin: 2.5cm;
                      margin-right: 1.0cm;
                    }
                    nav, footer, video, audio, object, embed {
                      display:none;
                    }
                    #root {
                      margin-top: 20px;
                    }
                    img {
                      max-width: 100%;
                    }
                    hr {
                      border-top: 2px solid #000;
                    }
                    .wrapper {
                      background-color: #fff !important;
                      width: 90%;
                      float: none !important;
                      margin: 2.5cm;
                      padding-left: 2.5cm;
                      padding-right: 2.5cm;
                      padding-top: 1cm;
                      padding-bottom: 0.5cm;
                    }
                    .type_personage {
                      width: 20%;
                    }
                    .ant-table-content thead > tr > th {
                        background: #666 !important;
                        color: #fff !important;
                        font-weight: bold !important;
                        font-size: 14pt !important;
                    }
                    @media print {
                      #root {
                        margin-top: 0px;
                      }
                      .wrapper {
                        margin: 0cm;
                        padding-left: 0cm;
                        padding-right: 0cm;
                        padding-top: 0cm;
                        padding-bottom: 0cm;
                      }
                    }
      `}];
export default print;
