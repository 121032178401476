import React from 'react';
import MyPopover from '../containers/mypopover';
import Alert from '../containers/alert';
import InputMask from 'react-input-mask';
import Select from 'react-select';
import { Link } from 'react-router-dom';

import HeaderBar from '../header-bar/header-bar';
import NavigationMenu from '../sidebar/navigation-menu';
import Footer from "../containers/footer";
import Loading from '../containers/loading';
import FlashMessage from '../containers/FlashMessage';

import ConfigPublication from './configPublication';

import OfficeService from '../../services/OfficeService';
import PublicationsService from '../../services/PublicationsService';
import AddressService from '../../services/AddressService';
import TribunalService from '../../services/TribunalService';
import LawsuitService from '../../services/LawsuitService';
import AuthService from '../../services/AuthService';

import { Progress } from 'antd';

import {isTablet} from './mobileDetect';

export default class ConfigOffice extends React.Component { 
  _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }


  constructor( props ) {
    super(props);

    this.authService = new AuthService();

    this.handleChange = this.handleChange.bind(this);
    this.selectChange = this.selectChange.bind(this);
    this.update = this.update.bind(this);
    this.getCEP = this.getCEP.bind(this);

    this.state = {
      nome: "",
      nome_fantasia: "",
      expiration: "",
      plan: 0,
      actives: 0,
      manuals: 0,
      archived: 0,
      percent: 0,
      ged_percent: 0,
      ged: 0,
      ged_utilized: 0,
      cep: "",
      rua: "",
      numero: "",
      bairro: "",
      cidade: "",
      opt_estado: "",
      complemento: "",
      email: "",
      total_manuals_count: 0,
      loadingVisible: false,
      loadingMessage: [],
      message: "",
      showAlert: false,
      free: "",
      required: false,
      phone: "",
      errors: {},
      formIsHalfFilledOut: false
    }

        
    if(isTablet()){
      alert('Para melhor experiência do usuário esta tela não pode ser acessada por tablets e smartphones. Este acesso será possível somente atravéz de um computador. Você será redirecionado para o Dashboard');
      window.location.href = '/';
    }

    window.scrollTo(0,0);

    if( !this.authService.can('acesso_configuracoes', true) ){
      this.props.history.replace('/');
    }

    let url_string = window.location.href;
    let url = new URL(url_string);
    let incomplete = url.searchParams.get("preencher_info");
    //console.log(incomplete); 
    //console.log(movements);
    if( incomplete !== null ){
      this.state.showAlert = true;
      this.state.required = true;
      this.state.formIsHalfFilledOut = true;
      this.state.message = "Seus dados estão incompletos, por favor preencha!";
      //alert("Seu plano venceu, renove agora!");
    }

  }

  componentDidMount( ) { 
    this._isMounted = true; let plan = 0;

    document.addEventListener("cancelAlert", (e) => {
        if( this._isMounted ){
          this.setState({
            showAlert: false
          });
        }
    });

    document.addEventListener("cancelLoading", (e) => {
      this.setState({
        loadingVisible: false
      });
    });

    this.OfficeService = new OfficeService();
    this.AddressService = new AddressService();
    this.PublicationsService = new PublicationsService();

    let token = JSON.parse(localStorage.getItem('loggedUser')).office_token;

    let qtd_ativos = 0;
    let qtd_arquivados = 0;

    this.OfficeService.getPlan().then(response =>{

          if (this._isMounted) {
              if( response.success === false ){

              }else{
                let ged = response.armazenamento_disponivel/1000000000;
                let ged_utilized = response.armazenamento_usado/1000000000;
                plan = response.plan_lawsuit.quantidade_processos;
                let qtd_ativos = response.ativos;
                let qtd_arquivados = response.arquivados;
                let total_manuals_count = response.manuais;
                
                this.setState({
                  plan: plan,
                  ged: ged,
                  ged_utilized: parseFloat(ged_utilized).toFixed(4),
                  ged_percent: parseFloat( (ged_utilized*100/ged).toFixed(4) ),
                  expiration: response.data_expiracao,
                  free: ( response.teste_gratis === 0 ) ? "" : "Plano FREE ",
                  publicacoes_contratadas: response.plan_publication_number,
                  qtd_arquivados,
                  qtd_ativos,
                  total_manuals_count,
                  percent: parseFloat( (qtd_ativos*100/plan).toFixed(4) )
                });
              }
          }

    });

    this.OfficeService.getConfigs( token ).then(response =>{

          if (this._isMounted) {

            let formIsHalfFilledOut = false;

            if( 
                (response.cep === null || response.cep === "") ||
                (response.city_id === null || response.city_id === "") ||
                (response.number === null || response.number === "") ||
                (response.neighborhood === null || response.neighborhood === "") ||
                (response.phone === null || response.phone === "") ||
                (response.state_id === null || response.state_id === "") ||
                (response.street === null || response.street === "") 
            ){
              formIsHalfFilledOut = true;
            }

            this.setState({
              nome: response.name,
              nome_fantasia: (response.name_fantasy !== null)?response.name_fantasy : "",
              rua: (response.street !== null)?response.street : "",
              numero: (response.number !== null)?response.number : "",
              complemento: (response.complement !== null)?response.complement : "",
              bairro: (response.neighborhood !== null)?response.neighborhood : "",
              cep: (response.cep !== null) ? response.cep : "",
              email: (response.email !== null)?response.email : "",
              phone: (response.phone !== null)?response.phone : "",
              formIsHalfFilledOut
            });

            this.AddressService.getEstado( ).then(res =>{

                  if (this._isMounted) {
                        let opt_estados = res;
                        let estado_id = ( response.state_id !== null ) ? response.state_id : '2';
                        let personage_uf = "";
                        let personage_city = "";

                        this.AddressService.getCidades( estado_id ).then( cities=>{
                          if (this._isMounted) {

                                //console.log(response);

                                if ( response.state_id !== null ) {
                                  personage_uf = opt_estados.filter( ( estado )=>{  return estado.value === response.state_id  } );
                                  personage_city = cities.filter( ( cidade )=>{  return cidade.value === response.city_id  } );
                                }

                                this.setState({
                                  opt_estados: opt_estados,
                                  cities: cities,
                                  opt_estado: personage_uf,
                                  cidade: personage_city,
                                });

                          }

                        });
                  }

            });

          }

    });

    //processos quantidade
   /* this.tribunalService = new TribunalService();
    this.lawsuitService = new LawsuitService();

    this.lawsuitService.list(  ).then(res =>{
        if (this._isMounted) {

            this.setState({
                total_manuals_count: res.data.Lawsuits.length
            });

        }
    });

    this.tribunalService.list().then(res =>{

          if (this._isMounted) {

                res.map( ( tribunal ) => {

                      this.lawsuitService.list( tribunal.id ).then(res =>{

                            if (this._isMounted) {

                                res.data.Lawsuits.map( (lawsuit)=>{
                                    if ( lawsuit.arquivo === 1 ) {
                                      qtd_arquivados++;
                                    }else{
                                      qtd_ativos++;
                                    }return lawsuit;

                                });

                                this.setState({
                                  qtd_arquivados,
                                  qtd_ativos,
                                  percent: parseFloat( (qtd_ativos*100/plan).toFixed(4) )
                                });

                            }

                      });

                      return tribunal

                });
                //console.log(res);

          }

    });*/

    let getParentAnchor =  (element) => {
      while (element !== null) {
        if (typeof element.tagName !== "undefined" && element.tagName.toUpperCase() === "A" && element.href.indexOf("#") === -1  ) {
          //console.log(element.href);
          return element;
        }
        element = element.parentNode;
      }
      return null;
    };

    document.querySelector("body").addEventListener('click', (e) => {
      //console.log(e.target.id);
      let test = this.state.formIsHalfFilledOut;
      //let shouldTest = false;
      let message = "Os dados do escritório precisam ser preenchidos!";

      if( this._isMounted ){
        var anchor = getParentAnchor(e.target);
        if( anchor !== null && test && this.state.required /*&& shouldTest*/ ) {
          this.setState({
            showAlert: true,
            message: message
          });
          e.preventDefault();
        }else if( e.target.id == "btn-back" ){
          this.props.history.goBack();
        }
      }


    }, false);

  }

  handleChange(e){

       this.setState(
           {
               [e.target.name]: e.target.value
           }
       )
   }

   selectChange = (selected, actionMeta) => {
     let pops = [];
     pops[ actionMeta.name ] = selected;
     if ( typeof actionMeta.name !== undefined && actionMeta.name !== "" && actionMeta.name !== null ){

       if ( typeof selected !== undefined && selected !== "" && selected !== null ) {
         this.setState( pops );
       }

     }
   }

   validateForm = () => {

      let errors = {};
      let formIsValid = true;
      let message = "";

      if( this.state.required ){

        if( this.state.rua === 0 || this.state.rua === "" || typeof this.state.rua === "undefined" ){
          formIsValid = false;
          errors["rua"] = "*Por favor preencha a rua";
        }

        if( this.state.cep === 0 || this.state.cep === "" || typeof this.state.cep === "undefined" ){
          formIsValid = false;
          errors["cep"] = "*Por favor preencha o CEP";
        }

        if( this.state.numero === 0 || this.state.numero === "" || typeof this.state.numero === "undefined" ){
          formIsValid = false;
          errors["numero"] = "*Por favor preencha o número";
        }

        if( this.state.bairro === 0 || this.state.bairro === "" || typeof this.state.bairro === "undefined" ){
          formIsValid = false;
          errors["bairro"] = "*Por favor preencha o bairro";
        }

        if( this.state.phone === 0 || this.state.phone === "" || typeof this.state.phone === "undefined" ){
          formIsValid = false;
          errors["phone"] = "*Por favor preencha o telefone";
        }

        if( this.state.opt_estado === 0 || this.state.opt_estado === "" || typeof this.state.opt_estado === "undefined" ){
          formIsValid = false;
          errors["opt_estado"] = "*Por favor preencha o estado";
        }

        if( this.state.cidade === 0 || this.state.cidade === "" || typeof this.state.cidade === "undefined" ){
          formIsValid = false;
          errors["cidade"] = "*Por favor preencha a cidade";
        }

        this.setState({
          errors: errors,
        });

        return formIsValid;

      }else{
        return true;
      }

    }

   update = (event) => {

      if ( this.validateForm() ) {

          this.setState({
            loadingVisible: true,
            loadingMessage: ["As alterações estão sendo salvas.", "Por gentileza, aguarde...", "Tempo estimado de 05 a 15 segundos"]
          });
   
          this.OfficeService = new OfficeService();
          let token = JSON.parse(localStorage.getItem('loggedUser')).office_token;
          let estado = ""; let cidade = "";

          console.log(this.state.opt_estado);
          console.log(this.state.cidade);
          console.log(this.state.rua);

          if( Array.isArray(this.state.opt_estado) ){
            estado = this.state.opt_estado[0].value;
          }else{
            estado = this.state.opt_estado.value;
          }

          if( Array.isArray(this.state.cidade) ){
            cidade = this.state.cidade[0].value;
          }else{
            cidade = this.state.cidade.value;
          }
   
          let dados = {
            //name: this.state.nome,
            name_fantasy: this.state.nome_fantasia,
            state_id: estado,
            city_id: cidade,
            street: this.state.rua,
            number: this.state.numero,
            complement: this.state.complemento,
            neighborhood: this.state.bairro,
            cep: this.state.cep,
            phone: this.state.phone,
            //email: this.state.email
          };
   
          this.OfficeService.update( token, dados ).then(response =>{
   
               if (this._isMounted) {
   
                     if( response.success === true ){
                         localStorage.setItem('alert_message', "Informações atualizadas com sucesso");
                         localStorage.setItem('alert_type', 'alert-success');
                         let time = new Date().getTime();
                         localStorage.setItem('alert_time', time );
                         setTimeout(() => {
                           this.setState({
                             updated: time,
                             required: false,
                             formIsHalfFilledOut: false
                           });
                         }, 300);
                         //alert("Comentário adicionado com sucesso");
                     }
   
                     this.setState({
                       loadingVisible: false,
                     });
   
               }
   
          });

      }

   }

   stateChange = (selected, actionMeta) => {
     let pops = [];
     pops[ actionMeta.name ] = selected;
     console.log(selected);

     if ( typeof actionMeta.name !== undefined && actionMeta.name !== "" && actionMeta.name !== null ){

       this.AddressService.getCidades( selected.value ).then( cities=>{
            if (this._isMounted) {
                this.setState({
                  cities: cities
                });
            }
       });

       if ( typeof selected !== undefined && selected !== "" && selected !== null ) {
         this.setState( pops );
       }

     }
   }

   getCEP(e){
       this.AddressService.getCEP( this.state.cep.replace(".", "").replace("-","") ).then(res =>{

            if (this._isMounted) {
                  //console.log(res);69905074

                  if ( res !== null ){

                        this.AddressService.getCidades( res.state_id ).then( cities=>{

                              if (this._isMounted) {

                                      this.setState(
                                          {
                                              'rua': res.rua,
                                              'bairro': res.bairro,
                                              'cities': cities,
                                              'cidade': cities.filter( ( cidade )=>{ return cidade.value === res.city_id } ),
                                              'opt_estado': this.state.opt_estados.filter( ( ufs )=>{ return ufs.uf === res.uf } )
                                          }
                                      );

                              }

                        });

                  }else{
                    //window.alert("CEP não encontrado, preencha manualmente os campos de rua, bairro, cidade e estado");
                    this.setState({
                      showAlert: true,
                      message: "CEP não encontrado, preencha manualmente os campos de rua, bairro, cidade e estado"
                    });
                  }

            }

       });
   }

  render() {

      let color2_perc_ged = ""; let color1_perc_ged = ""; let color2_perc = ""; let color1_perc = "";
      let free_container = null;

      let required = null;

      if(this.state.required){
        required = <span className="text-red">*</span>;
      }

      if( this.state.percent < 50 ){
        color1_perc = "#108ee9";
        color2_perc = "#87d068";
      }else if ( this.state.percent > 50 && this.state.percent < 75 ){
        color1_perc = "#87d068";
        color2_perc = "#ffa500";
      }else{
        color1_perc = "#ffa500";
        color2_perc = "#f10";
      }

      if( this.state.ged_percent < 50 ){
        color1_perc_ged = "#108ee9";
        color2_perc_ged = "#87d068";
      }else if ( this.state.ged_percent > 50 && this.state.ged_percent < 75 ){
        color1_perc_ged = "#87d068";
        color2_perc_ged = "#ffa500";
      }else{
        color1_perc_ged = "#ffa500";
        color2_perc_ged = "#f10";
      }

      if( this.state.free !== ""){
        free_container = <span className="red">{this.state.free}</span>
      }

      return <div className="config-office sheet">
                <HeaderBar />
                <NavigationMenu />
                <FlashMessage time={ new Date().getTime() } />
                <Alert show={this.state.showAlert} text={this.state.message} />
                <Loading visible={this.state.loadingVisible} message1={this.state.loadingMessage[0]} message2={this.state.loadingMessage[1]} message3={this.state.loadingMessage[2]} cancel={true}   />
                <div className="content-wrapper">
                  <div className="content-wrapper-inner">
                      <section className="content-header">
                        <h1 className="page-header">Configurações</h1>
                        <a className="btn btn-info right btn-contrato" href={'/contrato_avvocato.pdf'}>Baixar Contrato</a>
                      </section>
                      <div className="panel panel-primary">
                            <div className="panel-heading text-center">
                                  <h4>Meus Dados</h4>
                            </div>
                      </div>
                      <div className="box-body">
                        <h5>
                             Dados do Escritório
                             <MyPopover icon="white" text='Preencha todos os dados, pois os e-mails que serão enviados pelo sistema, terão o "Nome Fantasia" como remetente! ' />
                        </h5>
                        <div className="form-group col-md-4">
                              <label htmlFor="nome" className="control-label">Nome de Cadastro <span className="text-red">*</span></label>
                              <input type="text" name="nome" value={this.state.nome} id="nome" autoComplete="off" disabled={true} className="form-control input-lg" onChange={this.handleChange} />
                        </div>
                        <div className="form-group col-md-4">
                              <label htmlFor="nome_fantasia" className="control-label">Nome Fantasia <span className="text-red">*</span></label>
                              <input type="text" name="nome_fantasia" value={this.state.nome_fantasia} id="nome_fantasia" autoComplete="off" className="form-control input-lg" onChange={this.handleChange} />
                        </div>
                        <div className="form-group col-md-2 text-left">
                              <label htmlFor="cep" className="control-label">CEP {required}</label>
                              <InputMask className="form-control input-lg" id="cep" value={this.state.cep}  name="cep" mask="99.999-999" maskChar="_" onChange={this.handleChange} />
                              <div className="errorMsg">{this.state.errors.cep}</div>
                        </div>
                        <div className="form-group col-md-2 text-left">
                              <span id="cep-btn" className="btn btn-success cep-btn" onClick={this.getCEP}><i className="fa fa-search"></i> Pesquisa CEP</span>
                        </div>
                        <div className="form-group col-md-4">
                              <label htmlFor="rua" className="control-label">Endereço <span className="text-red">*</span></label>
                              <input type="text" name="rua" value={this.state.rua} id="rua" autoComplete="off" className="form-control input-lg" onChange={this.handleChange} />
                              <div className="errorMsg">{this.state.errors.rua}</div>
                        </div>

                        <div className="form-group col-md-2 text-left">
                              <label htmlFor="numero" className="control-label">N° {required}</label>
                              <input type="text" name="numero" value={this.state.numero} autoComplete="new-password" id="numero" className="form-control input-lg uppercase" onChange={this.handleChange} />
                              <div className="errorMsg">{this.state.errors.numero}</div>
                        </div>
                        <div className="form-group col-md-2 text-left">
                              <label htmlFor="complemento" className="control-label">Complemento</label>
                              <input type="text" name="complemento" value={this.state.complemento} id="inp_complemento" className="form-control input-lg uppercase" onChange={this.handleChange} />
                        </div>
                        <div className="form-group col-md-4 text-left">
                              <label htmlFor="bairro" className="control-label">Bairro {required}</label>
                              <input type="text" name="bairro" value={this.state.bairro} id="bairro" className="form-control input-lg uppercase" onChange={this.handleChange} />
                              <div className="errorMsg">{this.state.errors.bairro}</div>
                        </div>
                        <div className="form-group col-md-4 text-left">
                              <label htmlFor="opt_estado" className="control-label">Estado {required}</label>
                              <Select
                                  className="opt_estado"
                                  name="opt_estado"
                                  value={this.state.opt_estado}
                                  options={this.state.opt_estados}
                                  placeholder="Selecione"
                                  onChange={this.stateChange}
                                />
                                <div className="errorMsg">{this.state.errors.opt_estado}</div>
                        </div>
                        <div className="form-group col-md-4 text-left">
                              <label htmlFor="cidade" className="control-label">Cidade {required}</label>
                              <Select
                                  className="cidades"
                                  name="cidade"
                                  value={this.state.cidade}
                                  options={this.state.cities}
                                  placeholder="Selecione"
                                  onChange={this.selectChange}
                                />
                                <div className="errorMsg">{this.state.errors.cidade}</div>
                        </div>
                        <div className="form-group col-md-4 text-left email-container">
                              <label htmlFor="email" className="control-label">E-mail de Cadastro {required}</label>
                              <input type="text" name="email" value={this.state.email} autoComplete="new-password" disabled={true} id="email" className="form-control input-lg uppercase" onChange={this.handleChange} />
                        </div>
                        <div className="form-group col-md-4 text-left">
                              <label htmlFor="phone" className="control-label">Telefone {required}</label>
                              <InputMask type="text" name="phone" value={this.state.phone} autoComplete="new-password" id="phone" className="form-control input-lg" onChange={this.handleChange} mask="(99)99999-9999" maskChar="_" />
                              <div className="errorMsg">{this.state.errors.phone}</div>
                        </div>
                        <div className="form-group col-md-8 text-right">
                              <span id="alterar" className="btn btn-success " onClick={this.update}> Alterar</span>
                        </div>

                        <h5>
                             Dados da conta
                             <MyPopover icon="white" text='Acompanhe por esta tela como estão os números internos do seu sistema, como plano contratado, data de expiração,  n° de processos ativos, arquivados, espaço disponível para guardar documentos (GED).' />
                             <Link to="/config/mudar-plano"><span className="btn btn-warning change-plan">Alterar Plano</span></Link>
                              <span className="pull-right">{free_container} Data de expiração: {this.state.expiration.substr(0,10).split('-').reverse().join('/')}</span>
                        </h5>
                        <div className="form-group col-md-3 info-account">
                              <span><b>Plano: </b></span><span>{this.state.plan} Processos</span>
                        </div>
                        <div className="form-group col-md-3 info-account">
                              <span><b>Ativos: </b></span><span>{this.state.qtd_ativos}</span>
                        </div>
                        <div className="form-group col-md-3 info-account">
                              <span><b>Manuais: </b></span><span>{this.state.total_manuals_count}</span>
                        </div>
                        <div className="form-group col-md-3 info-account">
                              <span><b>Arquivados: </b></span><span>{this.state.qtd_arquivados}</span>
                        </div>
                        <div className="form-group col-md-12 info-account">
                              <span><b>Status do Plano</b></span>
                              <Progress
                                strokeColor={{
                                  '0%': color1_perc,
                                  '100%': color2_perc,
                                }}
                                percent={this.state.percent}
                              />
                        </div>
                        <div className="form-group col-md-3 info-account">
                              <span><b>GED Contratado: </b></span><span>{this.state.ged} GB</span>
                        </div>
                        <div className="form-group col-md-3 info-account">
                              <span><b>GED Utilizado: </b></span><span>{this.state.ged_utilized} GB</span>
                        </div>
                        <div className="form-group col-md-6 info-account">
                              <span><b>Status:</b></span>
                              <Progress
                                strokeColor={{
                                  '0%': color1_perc_ged,
                                  '100%': color2_perc_ged,
                                }}
                                percent={this.state.ged_percent}
                              />
                        </div>
                        <ConfigPublication publicacoes_contratadas={this.state.publicacoes_contratadas} />
                      </div>
                  </div>
                </div>
                <Footer />
             </div>
  }

}
